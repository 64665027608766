import { FC } from "react"
import PhoneInput from "react-phone-input-2"
import { Select } from "../Select"
import closeIcon from "../../../assets/fonts/closeBlack.svg"
import "react-phone-input-2/lib/style.css"
import "./styles.scss"
import { Box } from "@mui/material"
import { PHONE_TYPES } from "../../../constants/CreateHousehold"
import { useTranslation } from "../../../hooks/useTranslation"

type Props = {
  label: string
  value: string
  firstEndIcon?: string
  secondEndIcon?: string
  type: string
  index: string
  onChange: (value: string, type: string, index: string) => void;
  onTypeChange: (type: string, index: string) => void;
  onDeletePhone: (index: number) => void;
  isRequired?: boolean
}

export const PhoneNumberInputWithType: FC<Props> = ({
  label,
  value,
  firstEndIcon,
  secondEndIcon,
  type,
  index,
  onChange,
  onTypeChange,
  onDeletePhone,
  isRequired
}) => {

  const phoneTypesOptions = PHONE_TYPES

  const { p } = useTranslation('contact_info');
  return (
    <Box
      sx={{
        flexDirection: { md: "row", xs: "column" }
      }}
      className="phone-number-container"
    >
      <Box
        sx={{
          width: { md: "auto", xs: "100%" }
        }}
        className="phone-number-input__type-container"
      >
        <Select
          onChangeClose
          label={p('type')}
          list={phoneTypesOptions}
          value={type}
          placeholder={p('select')}
          handleSelect={(value) => onTypeChange(value, index)}
        />
      </Box>

      <div className="phone-number-block__container">
        <div className="phone-number-input__input-container">
          <label className="phone-number-input__label">{label}{isRequired && <span style={{ color: "red" }}>*</span>}</label>
          {/* <div className="phone-number-input__end-icon-container">
            {firstEndIcon && (
              <label className="phone-number-input__end-icon">
                <a href={`tel:${value}`}>
                  <img src={firstEndIcon} alt="end-icon" />
                </a>
              </label>
            )}
            {secondEndIcon && type === 'Mobile' && (
              <label className="phone-number-input__end-icon">
                <img src={secondEndIcon} alt="end-icon" />
              </label>
            )}
            
          </div> */}
          <PhoneInput
            inputClass={"phone-number-input__input-class"}
            containerClass={"phone-number-input__container-class"}
            buttonClass={"phone-number-input__button-class"}
            country={"us"}
            value={value}
            // renderStringAsFlag="asd"
            onChange={(value) => onChange(value, type, index)}
          // disableDropdown
          />
        </div>
        <label
          className="phone-number-input__delete-icon"
          onClick={() => onDeletePhone(Number(index))}
        >
          <img src={closeIcon} alt="delete-icon" />
        </label>
        {/* <div className="phone-number-input__absolute-number">+44</div> */}
      </div>
    </Box>
  )
}
