import { FC } from "react"
import "./styles.scss"

interface IProps {
  title: string
  onClick?: () => void
}

export const GlobalButton: FC<IProps> = ({ title, onClick }) => {
  return (
    <button
      type="button"
      className="contacts-page-global-button"
      onClick={onClick}
    >
      {title}
    </button>
  )
}
