import dayjs, { Dayjs } from "dayjs"
import TextField from "@mui/material/TextField"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker"
import vectorMini from "../../../assets/fonts/vectorMini.svg"
import { FC, useState } from "react"
import "./Calendar.scss"

type Props = {
  value: string
  handleDate: (value: string) => void,
  isRequired?: boolean
  placeholder?:string
}

export const Calendar: FC<Props> = ({ value, handleDate, isRequired, placeholder }) => {
  const formattedValue = value
    .replace(/\./g, "-")
    .split("-")
    .reverse()
    .join("-")

  const [time, setTime] = useState<Dayjs | null>(dayjs(formattedValue))
  const [isOpen, setIsOpen] = useState(false)


  const getMonthNumber = (date: dayjs.Dayjs | null) => {
    if (date) {
      let i = date?.month() + 1;
      return i < 10 ? "0" + i : i;
    }

    return "";
  }

  const getMonthDay = (date: dayjs.Dayjs | null) => {
    if (date) {
      let i = date?.date();
      return i < 10 ? "0" + i : i;
    }

    return "";
  }
  const handleChange = (date: dayjs.Dayjs | null) => {
    setTime(date)
    const formattedDate =
        date?.year() + '-' + getMonthNumber(date) + "-" + getMonthDay(date)
    setIsOpen(!isOpen)
    handleDate(formattedDate)
  }

  return (
    <div className="calendar">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="calendar__input-container">
          <div className="calendar__input" onClick={() => setIsOpen(!isOpen)}>
            <div className="calendar__input-label">{placeholder ? placeholder : "Date"}{isRequired && <span style={{color: "red"}}>*</span>}</div>
            <div className="calendar__input-placeholder">{value ? value: "Select Date"}</div>
            <div
              className="calendar__input-arrow"
              style={{
                transform: isOpen ? "rotate(180deg)" : "",
              }}
            >
              <img src={vectorMini} alt="arrow" />
            </div>
          </div>
        </div>
        <div
          className="calendar__desktop"
          style={{
            display: isOpen ? "block" : "none",
          }}
        >
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={time}
            inputFormat={"MM/dd/yyyy"}
            onChange={(newValue) => {
              handleChange(newValue)
            }}
            renderInput={(params) => <TextField {...params} />}
            dayOfWeekFormatter={(day) => `${day}`}
          />
        </div>
      </LocalizationProvider>
    </div>
  )
}
