import { FC } from "react"
import { Checkbox } from "../../../../base/Checkbox"
import { FormikType } from "../../../../../types/VisitReports/types"
import { Person } from "../../../Person"
import { Household } from "../../../../../types/global/types"
import "./styles.scss"
import { useTranslation } from "../../../../../hooks/useTranslation"

type Props = {
  formik: FormikType
  household: Household
  salutations: string[]
  addressesTypes: string[]
  openUpdate: boolean
  setOpenUpdate: (value: boolean) => void
}

export const UpdateContact: FC<Props> = ({
  formik,
  household,
  salutations,
  addressesTypes,
  openUpdate,
  setOpenUpdate,
}) => {
  const { p } = useTranslation('contact_info');
  const handleOpenUpdateContactInfo = (value: boolean) => {
    if (value) {
      formik.setFieldValue("updated_persons", [...household.persons])
    }
    if (!value) {
      formik.setFieldValue("updated_persons", undefined)
    }
    setOpenUpdate(value)
  }

  return (
    <div className="visit-report-update-contact">
      <div className="visit-report-update-contact__checkbox">
        <Checkbox
          label={p('update_contact_info')}
          checked={openUpdate}
          onCheck={() => handleOpenUpdateContactInfo(!openUpdate)}
        />
      </div>
      <div
        className={`visit-report-update-contact__container ${
          !openUpdate ? "visit-report-update-contact__container_closed" : ""
        }`}
      >
        {formik.values.updated_persons &&
          formik.values.updated_persons.map((_, index) => {
            return (
              <Person
                key={index}
                formik={formik}
                isUpdate
                personIndex={index}
                salutations={salutations}
                addressesTypes={addressesTypes}
              />
            )
          })}
      </div>
    </div>
  )
}
