import React, {Dispatch, FC, SetStateAction} from 'react';
import Box from '@mui/material/Box';
import {FilterHeader20} from '../../base/FilterHeader20';
import deleteRouteIcon from '../../../assets/images/deleteRouteLogo.svg';
import {WhiteButton} from '../../HomePage/WhiteButton';
import {RedButton} from '../../HomePage/RedButton';
import {Modal} from '@mui/material';
import './styled.scss';
import { useTranslation } from '../../../hooks/useTranslation';

interface IDeleteHouseholdModalProps {
    isVisible: boolean,
    setIsVisible: Dispatch<SetStateAction<boolean>>,
    handleDelete: () => void;
}

export const DeleteHouseholdModal: FC<IDeleteHouseholdModalProps> = ({ isVisible, setIsVisible, handleDelete }) => {
    
    const { p } = useTranslation('contacts');
    const { p: pGeneral } = useTranslation('general');
    return (
        <Modal
            open={isVisible}
            onClose={() => setIsVisible(!isVisible)}
            sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        >
            <Box
                sx={{
                    width: {xs: "90%", md: "30%"},
                }}
                className={'modal-delete-household'}>
                <FilterHeader20
                    title={p('deleting_contact')}
                    disableUnderline
                    onClose={() => {
                        setIsVisible(!isVisible)
                    }}
                />
                <Box className={'modal-delete-household__body'}>
                    <img src={deleteRouteIcon} alt="route"/>
                    <h4 className={'modal-delete-household__title'}>
                        {p('are_you_sure_to_delete')}
                    </h4>
                </Box>
                <Box className={'modal-delete-household__footer'}>
                    <WhiteButton title={pGeneral('cancel')} forModal onClick={() => setIsVisible(!isVisible)}/>
                    <RedButton title={pGeneral('delete')} forModal onClick={handleDelete}/>
                </Box>
            </Box>
        </Modal>
    )
}