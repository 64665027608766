import { Outlet } from "react-router-dom";
import { LayoutHeader } from "../../base/LayoutHeader";
import "./styles.scss";
import { useTranslation } from "../../../hooks/useTranslation";

export const SavedRoutesLayout = () => {
  const { p } = useTranslation('saved_routes');
  const { p: pGeneral } = useTranslation('general');
  return (
    <div className="saved-routes-layout">
      <div className="saved-routes-layout__header">
        <LayoutHeader
          title={p('name')}
          link="/saved-routes"
          subtitles={[
            { title: pGeneral('dashboard'), path: "" },
            { title: p('routes'), path: "" },
            { title: p('route_info'), path: "" },
          ]}
        />
      </div>
      <div className="saved-routes-layout__container">
        <div className="saved-routes-layout__children">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
