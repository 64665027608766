import { FC } from "react"
import plus from "../../../assets/fonts/plus.svg"
import "./styles.scss"

type Props = {
  title: string
  onClick: () => void
}

export const Button: FC<Props> = ({ title, onClick }) => {
  return (
    <button className="create-household-button" onClick={onClick}>
      <div className="create-household-button__start-icon">
        <img className="create-household-button__img" src={plus} alt="plus" />
      </div>
      <div className="create-household-button__title">{title}</div>
    </button>
  )
}
