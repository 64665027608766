import { InferActionsTypes } from "./../index"

export const actionTypes = {
  GET_CATEGORIES_TRIGGER: "GET_CATEGORIES_TRIGGER",
  GET_CATEGORIES: "GET_CATEGORIES",
}

type InitialState = {
  categories: null
}

const initialState: InitialState = {
  categories: null,
}

export const categoriesReducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case actionTypes.GET_CATEGORIES:
      return { ...state, categories: action.payload }

    default:
      return state
  }
}

export const actions = {
  getCategoriesAC: (data: any) => ({
    type: actionTypes.GET_CATEGORIES,
    payload: data,
  }),
}

export const categoriesTriggerActions = {
  getCategoriesTriggerAC: () => ({
    type: actionTypes.GET_CATEGORIES_TRIGGER,
  }),
}

export type Actions = InferActionsTypes<typeof actions>
