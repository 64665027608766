import {asCreator, withCallbacks} from '../../../../lib/utils';
import {compose} from '../../../../utils/functions';
import {actionTypes} from '../../reducers/notificationsReducer';

export const updateAddedPersonNotification = compose(
    withCallbacks,
    asCreator((data: any, id: number) => ({
        type: actionTypes.UPDATE_ADDED_PERSON_NOTIFICATION,
        payload: data,
        id
    }))
);