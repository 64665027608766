import { FC, useState } from "react"
import vectorMini from "../../../assets/fonts/vectorMini.svg"
import closeIcon from "../../../assets/fonts/close.svg"
import { useClickOutside } from "../../../hooks/useClickOutside"
import "./styles.scss"
import { Checkbox } from "../../base/Checkbox"

type Props = {
  label: string
  list: any
  selected: number[]
  checkbox?: boolean
  handleSelect: (elem: any) => void;
  placeholder?: string
}

export const MultiSelect: FC<Props> = ({
  label,
  list,
  checkbox,
  selected,
  handleSelect,
  placeholder
}) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(!open)
  }

  const handleBlur = () => {
    setOpen(false)
  }

  const ref = useClickOutside({ handler: handleBlur })

  return (
    <div className="create-household-multi-select" ref={ref}>
      <div className="create-household-multi-select__selected-items">
        {selected.map((elem: any) => {
          return (
            <div
              className="create-household-multi-select__selected-items-container"
              key={elem.name}
            >
              {elem.name}
              <img
                className="create-household-multi-select__selected-items-img"
                src={closeIcon}
                alt="close"
                onClick={() => handleSelect(elem)}
              />
            </div>
          )
        })}
      </div>
      <div className="create-household-multi-select__input-container">
        <label className="create-household-multi-select__label">{label}</label>
        <span
          className={`create-household-multi-select__span ${
            open && "create-household-multi-select_active-arrow"
          }`}
        >
          <img src={vectorMini} alt="vector-mini" />
        </span>
        <input
          className="create-household-multi-select__input"
          type="text"
          name="salutation"
          placeholder={placeholder || "Select"}
          readOnly
          onFocus={handleOpen}
        />

        <div
          className={`create-household-multi-select__list-container ${
            open && "create-household-multi-select_open"
          }`}
        >
          <div className='create-household-multi-select__list-items'>
            {list.map((elem: any) => {
              return (
                  <div
                      className="create-household-multi-select__list"
                      key={elem.name}
                      onClick={() => handleSelect(elem)}
                  >
                    <div className="create-household-multi-select__list-item-container">
                      {checkbox && (
                          <div className="create-household-multi-select__list-item">
                            <Checkbox
                                checked={selected.some(
                                    (item: any) => item.id === elem.id
                                )}
                                onCheck={() => {}}
                            />
                          </div>
                      )}
                      <div className="create-household-multi-select__list-item">
                        {elem.name}
                      </div>
                    </div>
                  </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
