import { FC } from "react"
import "./styles.scss"

type Props = {
  placeholder: string
  title?: string
  icon?: string
  formik?: any
  value: string
  disabled?: boolean
  onChange: (e: any) => void
}

export const Input: FC<Props> = ({
  placeholder,
  icon,
  title,
  value,
  disabled,
  onChange,
}) => {
  return (
    <div className="create-household-input">
      {icon && (
        <label className="create-household-input__end-icon">
          <img
            className="create-household-input__img"
            src={icon}
            alt="end-icon"
          />
          {title && (
            <span className="create-household-input__span">{title}</span>
          )}
        </label>
      )}
      <input
        className="create-household-input__input"
        type="text"
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  )
}
