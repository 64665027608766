import { put, takeEvery } from "redux-saga/effects"
import { updateToken } from "../../utils/updateToken"
import {
  householdsActions,
  actionTypes,
} from "../store/reducers/householdsReducer"
import { householdsAPI } from "../api/api"
import {SagaWrapper} from "./index";

function* getHouseholds({payload}: any) {
  try {
    yield put(householdsActions.toggleIsLoading(true))
    const response: { status: number; data: { data: {} } } =
      yield householdsAPI.getHouseholds({ ...payload })
    if (response.status === 200) {
      updateToken()
      yield put(householdsActions.getHouseholdsAC(response.data))
      yield put(householdsActions.toggleIsLoading(false))
    }
  } catch (error) {
    console.log(error)
  }
}

function* getHouseholdCategories(payload: any) {
  try {
    const response: { status: number; data: { data: {} } } =
        yield householdsAPI.getHouseholdCategories()
    if (response.status === 200) {
      yield put(householdsActions.getHouseholdCategories(response.data))
    }
  } catch (error) {
    console.log(error)
  }
}

function* createRegularCategory(data: any) {
  try {
    const response: { data: null, status: number } = yield householdsAPI.createRegularCategory(data.name);
    if(response.status === 201) {
      yield getHouseholdCategories(null)
    }
  } catch (e) {
    console.error(e);
  }
}

function* deleteRegularCategory(data: any) {
  try {
    yield householdsAPI.deleteRegularCategory(data.id);
    yield getHouseholdCategories(null)
  } catch (e) {
    console.error(e);
  }
}

function* updateRegularCategory(data: any) {
  try {
    yield householdsAPI.updateRegularCategory(data.id, data.name);
    yield getHouseholdCategories(null)
  } catch (e) {
    console.error(e);
  }
}

function* deleteHouseholdsByIds({ request, action, dispatchResponse }: any) {
  const { data, error } = yield request({
    method: "post",
    endpoint: '/api/auth/households/bulk-actions',
    body: {
      action: "delete",
      ids: action.payload.data.ids,
    }
  });

  yield dispatchResponse(
      { data, error },
      actionTypes.DELETE_HOUSEHOLDS_BY_IDS_SUCCESS,
      actionTypes.DELETE_HOUSEHOLDS_BY_IDS_ERROR,
  )
}

function* deleteAllHouseholdsExcludeIds({ request, action, dispatchResponse }: any) {
  const { data, error } = yield request({
    method: "post",
    endpoint: '/api/auth/households/bulk-actions',
    body: {
      action: "delete",
      all: true,
      excluded_ids: action.payload.data.excluded_ids,
    }
  });

  yield dispatchResponse(
      { data, error },
      actionTypes.DELETE_ALL_HOUSEHOLDS_EXCLUDE_IDS_SUCCESS,
      actionTypes.DELETE_ALL_HOUSEHOLDS_EXCLUDE_IDS_ERROR,
  )
}

function* updateRegularCategoriesForHouseholdsByIds({ request, action, dispatchResponse }: any) {
  const { data, error } = yield request({
    method: "post",
    endpoint: '/api/auth/households/bulk-actions',
    body: {
      action: "update_regular_categories",
      ids: action.payload.data.ids,
      category_ids: action.payload.data.category_ids,
    }
  });

  yield dispatchResponse(
      { data, error },
      actionTypes.UPDATE_REGULAR_CATEGORIES_FOR_HOUSEHOLDS_BY_IDS_SUCCESS,
      actionTypes.UPDATE_REGULAR_CATEGORIES_FOR_HOUSEHOLDS_BY_IDS_ERROR,
  )
}

function* updateRegularCategoriesForAllHouseholdsExcludeIds({ request, action, dispatchResponse }: any) {
  const { data, error } = yield request({
    method: "post",
    endpoint: '/api/auth/households/bulk-actions',
    body: {
      action: "update_regular_categories",
      all: true,
      excluded_ids: action.payload.data.excluded_ids,
      category_ids: action.payload.data.category_ids,
    }
  });

  yield dispatchResponse(
      { data, error },
      actionTypes.UPDATE_REGULAR_CATEGORIES_FOR_ALL_HOUSEHOLDS_EXCLUDE_IDS_SUCCESS,
      actionTypes.UPDATE_REGULAR_CATEGORIES_FOR_ALL_HOUSEHOLDS_EXCLUDE_IDS_ERROR,
  )
}

function* updateSchedulingForHouseholdsByIds({ request, action, dispatchResponse }: any) {
  const { data, error } = yield request({
    method: "post",
    endpoint: '/api/auth/households/bulk-actions',
    body: {
      action: "update_periodicity",
      ids: action.payload.data.ids,
      periodicity: action.payload.data.periodicity,
    }
  });

  yield dispatchResponse(
      { data, error },
      actionTypes.UPDATE_SCHEDULING_FOR_HOUSEHOLDS_BY_IDS_SUCCESS,
      actionTypes.UPDATE_SCHEDULING_FOR_HOUSEHOLDS_BY_IDS_ERROR,
  )
}

function* updateSchedulingForAllHouseholdsExcludeIds({ request, action, dispatchResponse }: any) {
  const { data, error } = yield request({
    method: "post",
    endpoint: '/api/auth/households/bulk-actions',
    body: {
      action: "update_periodicity",
      all: true,
      excluded_ids: action.payload.data.excluded_ids,
      periodicity: action.payload.data.periodicity,
    }
  });

  yield dispatchResponse(
      { data, error },
      actionTypes.UPDATE_SCHEDULING_FOR_ALL_HOUSEHOLDS_EXCLUDE_IDS_SUCCESS,
      actionTypes.UPDATE_SCHEDULING_FOR_ALL_HOUSEHOLDS_EXCLUDE_IDS_ERROR,
  )
}

function* getHousehold(payload: any) {
  try {
    const response: { status: number; data: { data: {} } } =
      yield householdsAPI.getHouseholdsById(payload.id)
    if (response.status === 200) {
      updateToken()
      yield put(householdsActions.getHouseholdAC(response.data.data))
    }
  } catch (error) {
    console.log(error)
  }
}

function* exportHouseholds({ request, dispatchResponse }: any) {
  const { data, error } = yield request({
    method: "get",
    endpoint: "/api/auth/households/export-as-download-file"
  })

  yield dispatchResponse(
      { data, error },
      actionTypes.EXPORT_HOUSEHOLDS_SUCCESS,
      actionTypes.EXPORT_HOUSEHOLDS_ERROR,
  )
}

function* importHouseholds({ request, action, dispatchResponse }: any) {
  const { data, error } = yield request({
    method: "post",
    endpoint: "/api/auth/households/import",
    body: action.payload.data
  })

  yield dispatchResponse(
      { data, error },
      actionTypes.IMPORT_HOUSEHOLDS_SUCCESS,
      actionTypes.IMPORT_HOUSEHOLDS_ERROR,
  )
}

function* updateHousehold ({ request, action, dispatchResponse }: any) {
  const { data, error } = yield request({
    method: 'put',
    endpoint: '/api/auth/households/' + action.payload.id,
    body: action.payload.data,
  });

  yield dispatchResponse(
      { data, error },
      actionTypes.UPDATE_HOUSEHOLD_SUCCESS,
      actionTypes.UPDATE_HOUSEHOLD_ERROR,
  );
}

function* createHousehold({ request, action, dispatchResponse }: any) {
  const { data, error } = yield request({
    method: "post",
    endpoint: '/api/auth/households',
    body: action.payload.data
  });

  yield dispatchResponse(
      { data, error },
      actionTypes.CREATE_HOUSEHOLD_SUCCESS,
      actionTypes.CREATE_HOUSEHOLD_ERROR,
  )
}

function* getPeriodicity() {
  try {
    const response: { status: number; data: { data: {} } } =
      yield householdsAPI.getPeriodicity()

    if (response.status === 200) {
      yield updateToken()
      yield put(householdsActions.getPeriodicityAC(response.data))
    }
  } catch (error) {
    console.log(error)
  }
}

function* deleteHousehold({ request, action, dispatchResponse }: any) {
  const { data, error } = yield request({
    method: "delete",
    endpoint: `/api/auth/households/${action.payload.data}`
  })

  yield dispatchResponse(
      { data, error },
      actionTypes.DELETE_HOUSEHOLD_SUCCESS,
      actionTypes.DELETE_HOUSEHOLD_ERROR,
  )
}

function* getHouseholdsForMap({ request, action, dispatchResponse }: any) {
  const { data, status, error } = yield request({
    method: "get",
    endpoint: `/api/auth/households-for-map/`,
    params: {
      ...action.payload.data
    }
  })

  if(status === 200) {
    put(householdsActions.getHouseholdsForMap(data.data))
  }

  yield dispatchResponse(
      { data, error },
      actionTypes.GET_HOUSEHOLDS_TRIGGER_FOR_MAP_SUCCESS,
      actionTypes.GET_HOUSEHOLDS_TRIGGER_FOR_MAP_ERROR,
  );
}

export function* householdsWatcher() {
  yield takeEvery(actionTypes.GET_HOUSEHOLDS_TRIGGER_FOR_MAP, SagaWrapper.withRequest(getHouseholdsForMap))
  yield takeEvery(actionTypes.GET_HOUSEHOLDS_TRIGGER, getHouseholds)
  yield takeEvery(actionTypes.GET_HOUSEHOLD_TRIGGER, getHousehold)
  yield takeEvery(actionTypes.CREATE_HOUSEHOLD, SagaWrapper.withRequest(createHousehold))
  yield takeEvery(actionTypes.UPDATE_HOUSEHOLD_TRIGGER, SagaWrapper.withRequest(updateHousehold))
  yield takeEvery(actionTypes.GET_PERIODICITY_TRIGGER, getPeriodicity)
  yield takeEvery(actionTypes.DELETE_HOUSEHOLD_TRIGGER, SagaWrapper.withRequest(deleteHousehold))
  yield takeEvery(actionTypes.EXPORT_HOUSEHOLDS_TRIGGER, SagaWrapper.withRequest(exportHouseholds))
  yield takeEvery(actionTypes.IMPORT_HOUSEHOLDS_TRIGGER, SagaWrapper.withRequest(importHouseholds))
  yield takeEvery(actionTypes.CREATE_REGULAR_CATEGORY, createRegularCategory)
  yield takeEvery(actionTypes.DELETE_REGULAR_CATEGORY, deleteRegularCategory)
  yield takeEvery(actionTypes.UPDATE_REGULAR_CATEGORY, SagaWrapper.withRequest(updateRegularCategory))
  yield takeEvery(actionTypes.GET_HOUSEHOLDS_CATEGORIES_TRIGGER, getHouseholdCategories)
  yield takeEvery(actionTypes.DELETE_HOUSEHOLDS_BY_IDS, SagaWrapper.withRequest(deleteHouseholdsByIds))
  yield takeEvery(actionTypes.DELETE_ALL_HOUSEHOLDS_EXCLUDE_IDS, SagaWrapper.withRequest(deleteAllHouseholdsExcludeIds))
  yield takeEvery(actionTypes.UPDATE_REGULAR_CATEGORIES_FOR_HOUSEHOLDS_BY_IDS, SagaWrapper.withRequest(updateRegularCategoriesForHouseholdsByIds))
  yield takeEvery(actionTypes.UPDATE_REGULAR_CATEGORIES_FOR_ALL_HOUSEHOLDS_EXCLUDE_IDS, SagaWrapper.withRequest(updateRegularCategoriesForAllHouseholdsExcludeIds))
  yield takeEvery(actionTypes.UPDATE_SCHEDULING_FOR_HOUSEHOLDS_BY_IDS, SagaWrapper.withRequest(updateSchedulingForHouseholdsByIds))
  yield takeEvery(actionTypes.UPDATE_SCHEDULING_FOR_ALL_HOUSEHOLDS_EXCLUDE_IDS, SagaWrapper.withRequest(updateSchedulingForAllHouseholdsExcludeIds))
}