import { ChangeEvent, FC } from "react";

import { ErrorText } from "../ErrorText";
import "./styles.scss";

export interface IInputProps {
  label?: string;
  placeholder: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  isRequired?: boolean;
  isDisabled?: boolean;
  error?: string;
  isError?: boolean
}

export const Input: FC<IInputProps> = ({
  label,
  placeholder,
  value,
  onChange,
  isRequired,
  isDisabled,
  error,
  isError
}) => {
  return (
    <div className="home-page-input">
      <label className="home-page-input__label">
        {label}
        {isRequired && <span style={{ color: "red" }}>*</span>}
      </label>
      <input
        className={`home-page-input__input ${ isError ? "error" : "" }`}
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={isDisabled}
      />
      {error && <ErrorText title={error} />}
    </div>
  );
};
