import "./styles.scss";
import vectorMini from "../../../assets/fonts/vectorMini.svg";
import vectorSelect from "../../../assets/fonts/vectorSelect.svg";
import { FC, useState, useEffect } from "react";
import { useClickOutside } from "../../../hooks/useClickOutside";
import axios from "axios";
import { API_URL } from "../../../constants/base";
import { useDispatch } from "react-redux";

type Props = {
  isRequired?: boolean;
  label: string;
  list: any;
  value: string;
  placeholder: string;
  handleSelect: (value: string) => void;
  onChangeClose?: boolean;
  setOpenModal: (value: boolean) => void;
  setIdSelectedWorkspaces: (value: number) => void;
};

export const SelectWorkspaceModal: FC<Props> = ({
  onChangeClose,
  label,
  list,
  value,
  placeholder,
  handleSelect,
  isRequired,
  setOpenModal,
  setIdSelectedWorkspaces,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleBlur = () => {
    setOpen(false);
  };

  const ref = useClickOutside({ handler: handleBlur });

  return (
    <div className="create-household-select-workspace" ref={ref}>
      <label className="create-household-select-workspace__label">
        {label}
        {isRequired && <span style={{ color: "red" }}>*</span>}
      </label>
      <span
        className={`create-household-select-workspace__span ${
          open && "create-household-select-workspace_active-arrow"
        }`}
      >
        <img src={vectorMini} alt="vector-mini" />
      </span>
      <div
        className="create-household-select-workspace__input"
        onClick={handleOpen}
      >
        {value ? value : placeholder}
      </div>
      <div
        className={`create-household-select-workspace__list-container ${
          open && "create-household-select-workspace_open"
        }`}
      >
        <div className="create-household-multi-select__list-items">
          {list.map((elem: any) => {
            return (
              <div
                className={`create-household-select-workspace__list ${
                  value === elem && "create-household-select-workspace_active"
                }`}
                key={elem.id}
                onClick={() => {
                  if (onChangeClose) handleOpen();
                  handleSelect(elem.name);
                  setIdSelectedWorkspaces(elem.id);
                  setOpenModal(false);
                }}
              >
                {elem.name}
                <div
                  className={`create-household-select-workspace__list-arrow ${
                    value === elem.name &&
                    "create-household-select-workspace_active-check"
                  }`}
                >
                  <img src={vectorSelect} alt="arrow" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
