import { useDispatch } from "react-redux"

export const TestPage = () => {
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch({ type: "TEST" })
  }
  return (
    <button
      onClick={handleClick}
      style={{
        background: "red",
        cursor: "pointer",
        height: "20px",
        width: "100px",
      }}
    >
      TEST API
    </button>
  )
}
