/**
 * Toast a notification of different types.
 */
import { useContext } from 'react';
import { NotificationContext } from '../contexts/NotificationContext';

export const useAddNotification = () => {
    const { addNotification } = useContext(NotificationContext);

    return addNotification;
};