import {compose} from "../../../../utils/functions";
import {asCreator, withCallbacks} from "../../../../lib/utils";
import {actionTypes} from "../../reducers/authReducer";

export const changeUserLanguage = compose(
    withCallbacks,
    asCreator((language: string) => ({
        type: actionTypes.CHANGE_USER_LANGUAGE,
        language,
    }))
);