import {asCreator, withCallbacks} from '../../../../lib/utils';
import {compose} from '../../../../utils/functions';
import {actionTypes} from '../../reducers/householdsReducer';

export const exportContacts = compose(
    withCallbacks,
    asCreator((data: number) => ({
        type: actionTypes.EXPORT_HOUSEHOLDS_TRIGGER,
        payload: data,
    }))
);