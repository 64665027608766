import React, { FC } from "react"
import userIcon from "../../../assets/fonts/userGrey.svg"
import menu from "../../../assets/fonts/hor-menu.svg"
import basket from "../../../assets/fonts/deleteBasket.svg"
import doubleDots from "../../../assets/fonts/double-dots-vert.svg"
import "./styles.scss"
import {Button, Menu, MenuItem} from '@mui/material';
import {useNavigate} from 'react-router-dom';

type Props = {
  name: string
  address: string | undefined
  coordinates: string
  id: number
  setDeletingID: (id: number) => void
  setOpenDeleteModal: (value: boolean) => void
}

export const SavedPoint: FC<Props> = ({
  name,
  address,
  coordinates,
  id,
  setDeletingID,
  setOpenDeleteModal,
}) => {
  const navigate = useNavigate()
  const handleDelete = () => {
    setDeletingID(id)
    setOpenDeleteModal(true)
  }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const options = [
    { id: 1, label: "Duch", path: `/households/update/history/${id}?tab=duch` },
    { id: 2, label: "Contact info", path: `/households/update/household-info/${id}?tab=info` },
    { id: 3, label: "History", path: `/households/update/history/${id}?tab=history` },
  ];

  return (
    <div className="home-page-saved-point">
      <div className="home-page-saved-point__container">
        <div className="home-page-saved-point__container-inner">
          <div className="home-page-saved-point__double-dots">
            <img src={doubleDots} alt="double-dots" />
          </div>
          <div className="home-page-saved-point__address-container">
            <div className="home-page-saved-point__name">
              <img src={userIcon} alt="user" />
              {name}
            </div>
            <div className="home-page-saved-point__address">{address}</div>
            <div className="home-page-saved-point__coordinates">
              {coordinates}
            </div>
          </div>
        </div>
        <div className="home-page-saved-point__delete-container">
          <div>
            <Button
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
              <div className={'saved-point__menu'}>
                <img src={menu} alt="dots" />
              </div>
            </Button>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    color: "#0E0D5B",
                    fontFamily: "Poppins, sans-serif",
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 25,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {options.map(option => (
                  <MenuItem key={option.id} onClick={() => navigate(option.path)}>{option.label}</MenuItem>
              ))}
            </Menu>
          </div>
          <div className="home-page-saved-point__basket" onClick={handleDelete}>
            <img src={basket} alt="basket" />
          </div>
        </div>
      </div>
    </div>
  )
}
