import React from 'react';
import './styled.scss';
import emptyStateIcon from '../../../assets/images/emptyStateVisitReports.svg';
import { useTranslation } from '../../../hooks/useTranslation';

export const EmptyStatePastVisitsTab = () => {
    const { p } = useTranslation('contact_info');
    return (
        <div className={'empty-state-past-visits-tab'}>
            <img src={emptyStateIcon} alt={'empty-state-icon'} />
            <h4 className='empty-state-past-visits-tab__title'>{ p('no_visit_history') }</h4>
        </div>
    )
}