import { Modal } from "@mui/material";
import { FilterHeader40 } from "../../base/FilterHeader40";
import { Input } from "../Input";
import { BlackButton } from "../BlackButton";
import { FC } from "react";
import "./styles.scss";
import {useTranslation} from "../../../hooks/useTranslation";

interface IProps {
  openSaveNameModal: boolean;
  routeName: string;
  setRouteName: (name: string) => void;
  setOpenSaveNameModal: (value: boolean) => void;
  handleCreateRoute: () => void;
  setOptimize: (value: boolean) => void;
}

export const SaveRouteNameModal: FC<IProps> = ({
  openSaveNameModal,
  routeName,
  setRouteName,
  handleCreateRoute,
  setOpenSaveNameModal,
  setOptimize,
}) => {
  const { p } = useTranslation('map');
  const { p: pGeneral } = useTranslation('general');

  const handleConfirm = () => {
    setOpenSaveNameModal(false);
    setOptimize(false);
    handleCreateRoute();
  };

  const handleClose = () => {
    setRouteName("");
    setOptimize(false);
    setOpenSaveNameModal(false);
  };

  return (
    <Modal
      disableAutoFocus
      disableRestoreFocus
      disableEnforceFocus
      open={openSaveNameModal}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
    >
      <div className="home-page-save-route-name-modal">
        <FilterHeader40 
          title={p('save_route').toUpperCase()} 
          onClose={handleClose} 
        />
        <div className="home-page-save-route-name-modal__container">
          <Input
            label={p('route_name')}
            placeholder={p('write_route_name')}
            value={routeName}
            onChange={(e: any) => setRouteName(e.target.value)}
          />
        </div>
        <div className="home-page-save-route-name-modal__footer">
          <div className="home-page-save-route-name-modal__footer-container">
            <BlackButton title={pGeneral('save')} onClick={handleConfirm} />
          </div>
        </div>
      </div>
    </Modal>
  );
};
