import { FC, useContext, useEffect, useState } from "react";
import { Autocomplete } from "@react-google-maps/api";

import { geocodePlaceId } from "../../../utils/geocodePlaceId";
import startPosition from "../../../assets/icons/startPosition.png";
import { BlueButton } from "../../base/BlueButton";
import { DeleteRouteButton } from "../DeleteRouteButton";
import { Input } from "../Input";
import telegram from "../../../assets/fonts/telegram.svg";
import save from "../../../assets/fonts/save.svg";
import { VerticalStepper } from "../VerticalStepper";
import { useNavigate } from "react-router-dom";
import { SearchModal } from "../SearchModal";
import { useDispatch } from "react-redux";
import { routesTriggerActions } from "../../../backend/store/reducers/routesReducer";
import { Route } from "../../../types/global/types";
import { UpdateFooter } from "../../UpdateHouseholdPage/UpdateFooter";
import "./styles.scss";
import { DeleteRouteModal } from "../DeleteRouteModal";
import { NavMethodModal } from "../../base/NavMethodModal";
import { DeleteItemRouteModal } from "../DeleteItemRouteModal";
import { GoogleMapComponent } from "../../SavedRoutesPage/GoogleMap";
import { Box } from "@mui/material";
import { UserPermissionsEnum } from "../../../enums/userPermissionsEnum";
import { UserContext } from "../../../contexts/UserContext";
import { useTranslation } from "../../../hooks/useTranslation";

type Props = {
  id: number | undefined;
  route: Route;
};

export const Layout: FC<Props> = ({ id, route }) => {
  const { p } = useTranslation('saved_routes');
  const { p: pGeneral } = useTranslation('general');
  const { user } = useContext(UserContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [routeName, setRouteName] = useState(route.name);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [pointList, setPointList] = useState(route.points);
  const [currentPoint, setCurrentPoint] = useState<null | any>(null);
  const [isVisibleDeleteModal, setIsVisibleDeleteModal] = useState(false);
  const [isVisibleDeleteItemRouteModal, setIsVisibleDeleteItemRouteModal] =
    useState(false);
  const [isVisibleStartRouteModal, setIsVisibleStartRouteModal] =
    useState(false);
  const [idRoutePoint, setIdRoutePoint] = useState<number | null>(null);

  const [startPositionName, setStartPositionName] = useState(
    route?.startAddress?.fullAddress || ""
  );
  const [searchResult, setSearchResult] = useState<any>(null);
  const [fullStartPosition, setFullStartPosition] = useState<any>(
    route?.startAddress || null
  );

  const [isVisibleNavModal, setIsVisibleNavModal] = useState(false);
  const [selectedPoint, setSelectedPoint] = useState("");

  const dragStartHandler = (e: any, point: any) => {
    setCurrentPoint(point);
    e.target.style.border = "2px solid rgba(77, 148, 255, 1)";
    e.target.style.boxShadow = "0px 4px 10px 0px rgba(14, 13, 91, 0.16)";
  };

  const dragEndHandler = (e: any) => {
    e.target.style.background = "";
    e.target.style.border = "";
    e.target.style.boxShadow = "";
  };

  const dragOverHandler = (e: any) => {
    e.preventDefault();
    e.target.style.background = "lightgray";
  };

  const dropHandler = (e: any, point: any) => {
    e.preventDefault();
    setPointList(
      pointList!.map((elem: any) => {
        if (elem.id === point.id) {
          return { id: elem.id, ...currentPoint };
        }

        if (elem.id === currentPoint.id) {
          return { id: elem.id, ...point };
        }
        return elem;
      })
    );
    e.target.style.background = "";
  };

  const handleDeleteRoute = () => {
    dispatch(routesTriggerActions.deleteRouteTriggerAC(id));
    return navigate("/saved-routes");
  };

  const handleDeletePoint = (id: number) => {
    setPointList(pointList?.filter((elem: any) => elem.id !== id));
  };

  const handleAddPoint = async (value: any) => {
    if (value.length === 1) {
      const updatedRoutes: any = {
        name: routeName,
        startAddress: fullStartPosition,
        points: pointList?.map((elem: any) => {
          return {
            ...(elem.id ? { id: elem.id } : {}),
            household_id: elem.household_id,
            address_id: elem.address_id,
          };
        }),
      };

      updatedRoutes.points.push({
        household_id: value[0].id,
        address_id: value[0].address_id,
      });
      dispatch(
        routesTriggerActions.updateRouteTriggerAC({
          id,
          data: updatedRoutes,
        })
      );
      dispatch(
        routesTriggerActions.getRouteTriggerAC({
          id: null,
          address: true,
          household: true,
        })
      );
    }
    setOpenSearchModal(false);
  };

  const handleUpdate = () => {
    const updatedRoutes: any = {
      name: routeName,
      startAddress: fullStartPosition,
      points: pointList?.map((elem: any) => {
        return {
          ...(elem.id ? { id: elem.id } : {}),
          household_id: elem.household_id,
          address_id: elem.address_id,
        };
      }),
    };

    dispatch(
      routesTriggerActions.updateRouteTriggerAC({ id, data: updatedRoutes })
    );
  };

  const coordinates = pointList?.map((household: any) => {
    return household?.address?.fullAddress;
  });

  const googleLink = `https://www.google.com/maps/dir/${
    fullStartPosition?.fullAddress
  }/${coordinates && coordinates.length !== 0 && coordinates.join("/")}`;

  function onLoad(autocomplete: any) {
    setSearchResult(autocomplete);
  }

  const handleCoordinates = (place: any) => {
    const latitude = place.geometry.location.lat();
    const longitude = place.geometry.location.lng();

    const unit_number = place.address_components.find((element: any) => {
      return element.types[0] === "street_number";
    })?.long_name;

    const street_name = place.address_components.find((element: any) => {
      return element.types[0] === "route";
    })?.long_name;
    const city = place.address_components.find((element: any) => {
      return element.types[0] === "locality";
    })?.long_name;
    const state = place.address_components.find((element: any) => {
      return element.types[0] === "administrative_area_level_1";
    })?.long_name;
    const country = place.address_components.find((element: any) => {
      return element.types[0] === "country";
    })?.long_name;
    const zip_code = place.address_components.find((element: any) => {
      return element.types[0] === "postal_code";
    })?.long_name;
    const place_id = place.place_id;

    setFullStartPosition({
      place_id,
      zip_code,
      state,
      city,
      country,
      street_name,
      unit_number,
      latitude,
      longitude,
    });
  };

  async function onPlaceChanged() {
    if (searchResult != null) {
      const place = searchResult.getPlace();

      const address = (await geocodePlaceId(place.place_id)) as string;
      setStartPositionName(address);
      handleCoordinates(place);
    }
  }

  const getAddress = (e: any) => {
    setStartPositionName(e.target.value);
  };

  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedPlaceId, setSelectedPlaceId] = useState("");

  let link = `https://www.google.com/maps/dir/?api=1&origin_place_id=${fullStartPosition?.place_id}&origin=${fullStartPosition?.fullAddress}&destination_place_id=${fullStartPosition?.place_id}&destination=${fullStartPosition?.fullAddress}
  &waypoints=${selectedAddress}&waypoint_place_ids=${selectedPlaceId}&dir_action=navigate&travelmode=driving`;

  const handleTakeMeThere = (fullAddress: string, place_id: string) => {
    setSelectedAddress(fullAddress);
    setSelectedPlaceId(place_id);
    setIsVisibleNavModal(true);
  };

  useEffect(() => {
    if (!user) return;
    if (user?.permissions[UserPermissionsEnum.readRoutes]) return;
    navigate("/saved-routes");
  }, [user]);

  return (
    <div className="saved-routes-layout__container">
      <div className="saved-routes-layout__children">
        <div className="route-page-layout">
          <div className="route-page-layout__container">
            <Box
              className="route-page-layout__header"
              sx={{
                flexDirection: { xs: "column", sm: "row" },
                pointerEvents: !!user?.permissions[
                  UserPermissionsEnum.updateRoutes
                ]
                  ? "auto"
                  : "none",
              }}
            >
              <div className="route-page-layout__input">
                <Input
                  label={p('route_name')}
                  placeholder={p('enter_route_name')}
                  value={routeName}
                  onChange={(e) => setRouteName(e.target.value)}
                />
              </div>

              <div className="route-page-layout__delete">
                <DeleteRouteButton
                  onClick={() => setIsVisibleDeleteModal(!isVisibleDeleteModal)}
                />
              </div>
            </Box>
            {/* <Input
              label="Route Name"
              placeholder="Enter route name"
              value={routeName}
              onChange={(e) => setRouteName(e.target.value)}
            /> */}
            {/* <Autocomplete
              onLoad={onLoad}
              onPlaceChanged={onPlaceChanged}
              types={["address"]}
            > */}

            {/* </Autocomplete> */}
            <div className="route-page-layout__button">
              <BlueButton
                title={p('start_route')}
                icon={telegram}
                onClick={() =>
                  setIsVisibleStartRouteModal(!isVisibleStartRouteModal)
                }
              />
            </div>

            <Box
              className="saved-routes-layout__map"
              sx={{
                display: { xs: "block", md: "none" },
                minWidth: { xs: "100%", md: "440px" },
                marginBottom: "40px",
              }}
            >
              <GoogleMapComponent points={pointList} />
            </Box>
            <Box
              className="route-page-layout__startPosition"
              sx={{
                marginBottom: { xs: "40px", md: "60px" },
                pointerEvents: !!user?.permissions[
                  UserPermissionsEnum.updateRoutes
                ]
                  ? "auto"
                  : "none",
              }}
            >
              <img
                className="home-page-add-route__start-position_img"
                src={startPosition}
                alt="close"
              />

              <div className="route-page-layout__startPosition_input">
                <Autocomplete
                  onLoad={onLoad}
                  onPlaceChanged={onPlaceChanged}
                  types={["address"]}
                >
                  <Input
                    placeholder={p('start_position')}
                    value={startPositionName}
                    label={p('start_position')}
                    onChange={getAddress}
                  />
                </Autocomplete>
              </div>
            </Box>
            <Box
              sx={{
                pointerEvents: !!user?.permissions[
                  UserPermissionsEnum.updateRoutes
                ]
                  ? "auto"
                  : "none",
              }}
              className="route-page-layout__route-container"
            >
              <VerticalStepper
                pointList={pointList}
                dragStartHandler={dragStartHandler}
                dragEndHandler={dragEndHandler}
                dragOverHandler={dragOverHandler}
                dropHandler={dropHandler}
                handleDeletePoint={(id: number) => {
                  setIdRoutePoint(id);
                  setIsVisibleDeleteItemRouteModal(
                    !isVisibleDeleteItemRouteModal
                  );
                }}
                handleTakeMeThere={handleTakeMeThere}
              />
            </Box>
            <div className="route-page-layout__button">
              <BlueButton
                title={p('add_stop')}
                icon={telegram}
                onClick={() => setOpenSearchModal(true)}
              />
            </div>
            <div className="route-page-layout__footer">
              {!!user?.permissions[UserPermissionsEnum.updateRoutes] && (
                <UpdateFooter
                  icon={save}
                  label={pGeneral('save')}
                  title={pGeneral('cancel')}
                  onClick={handleUpdate}
                  cancelClick={() => {}}
                />
              )}
            </div>
          </div>
          <SearchModal
            openSearchModal={openSearchModal}
            setOpenSearchModal={setOpenSearchModal}
            handleAddPoint={handleAddPoint}
          />
          <DeleteRouteModal
            isVisible={isVisibleDeleteModal}
            handleDelete={() => {
              handleDeleteRoute();
              setIsVisibleDeleteModal(!isVisibleDeleteModal);
            }}
            setIsVisible={setIsVisibleDeleteModal}
          />
          <DeleteItemRouteModal
            handleDelete={() => {
              if (!idRoutePoint) return;
              handleDeletePoint(idRoutePoint);
              setIsVisibleDeleteItemRouteModal(!isVisibleDeleteItemRouteModal);
              setIdRoutePoint(null);
            }}
            isVisible={isVisibleDeleteItemRouteModal}
            setIsVisible={setIsVisibleDeleteItemRouteModal}
          />
          <NavMethodModal
            googleLink={googleLink}
            openNavModal={isVisibleStartRouteModal}
            setOpenNavModal={setIsVisibleStartRouteModal}
          />
          <NavMethodModal
            openNavModal={isVisibleNavModal}
            setOpenNavModal={setIsVisibleNavModal}
            googleLink={link}
          />
        </div>
      </div>
      <Box
        className="saved-routes-layout__map"
        sx={{
          display: { xs: "none", md: "block" },
          minWidth: { md: "440px" },
        }}
      >
        <GoogleMapComponent points={pointList} />
      </Box>
    </div>
  );
};
