import React, {FC} from 'react';
import xIcon from '../../../assets/images/xIconLarge.svg';
import {Modal} from '@mui/material';
import './styled.scss';
import closeIconFont from "../../../assets/fonts/close.svg"

interface IErrorModal {
    isVisible: boolean;
    setIsVisible: () => void,
    title: string
}

export const ErrorModal: FC<IErrorModal> = ({ isVisible, setIsVisible, title }) => {
    return (
        <Modal
            onClose={setIsVisible}
            sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            open={isVisible}>
            <div className='error-modal'>
                <div className='error-modal__close-icon'>
                    <img src={closeIconFont} alt="close-icon" onClick={setIsVisible}/>
                </div>
                <img src={xIcon} alt={'x-icon-red'} />
                <h5 className={'error-modal__title'}>{title}</h5>
            </div>
        </Modal>
    )
}