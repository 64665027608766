
import React, { useState } from 'react';
import menuIcon from "../../../assets/fonts/hor-menu.svg"

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import "./styles.scss"

type MenuOption = {
  label: string;
  action: string;
  variant?: string;
  isDisabled?: boolean;
}
interface DropdownProps {
  options: MenuOption[];
  onItemClick?: (option: string) => void;
}

export const MenuActions: React.FC<DropdownProps> = ({ options, onItemClick }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, option: string) => {
    setAnchorEl(null);

    if (onItemClick) {
      onItemClick(option);
    }
  };

  return (
    <div className={'menu-actions'}>
      <div className={'menu-actions__image-container'} onClick={handleClick}>
        <img src={menuIcon} alt="menu" className="menu-actions__img" />
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem
            key={option.action}
            disabled={option.isDisabled}
            onClick={(event) => handleMenuItemClick(event, option.action)}
          >
            <span className={`menu-actions__text ${
              option.variant ? option.variant : ''
            }`}>{option.label}</span>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
