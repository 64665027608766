import {FC, useContext} from 'react';
import { ADDRESS, PERSON, PHONE } from "../../../constants/CreateHousehold";
import { FormikType } from "../../../types/CreateHousehold/types";
import { Button } from "../../CreateHouseholdPage/Button";
import saveIcon from "../../../assets/fonts/save.svg";
import { UpdateFooter } from "../UpdateFooter";
import { HouseholdInfo } from "../../CreateHouseholdPage/HouseholdInfo";
import "./styles.scss";
import {Box} from '@mui/material';
import {UserPermissionsEnum} from '../../../enums/userPermissionsEnum';
import {UserContext} from '../../../contexts/UserContext';
import { useTranslation } from '../../../hooks/useTranslation';

interface IProps {
  formik: FormikType;
  householdValue: any;
  salutationOptions: string[];
  addressesTypesOptions: string[];
}

export const UpdateHouseholdInfo: FC<IProps> = ({
  formik,
  householdValue,
  salutationOptions,
  addressesTypesOptions,
}) => {
  const { p } = useTranslation('contact_info');
  const { p: pGeneral } = useTranslation('general');
  const { user } = useContext(UserContext);
  const addPerson = () => {
    formik.setFieldValue(`persons`, [...formik.values.persons, PERSON]);
  };
  const addAddress = (index: number) => {
    formik.setFieldValue(`persons[${index}].addresses`, [
      ...formik.values.persons[index].addresses,
      ADDRESS,
    ]);
  };

  const handleSalutation = (value: string, index: number) => {
    formik.setFieldValue(`persons[${index}].salutation`, value);
  };

  const handleFirstName = (value: string, index: number) => {
    formik.setFieldValue(`persons[${index}].first_name`, value);
  };
  const handleLastName = (value: string, index: number) => {
    formik.setFieldValue(`persons[${index}].last_name`, value);
  };

  const setGender = (value: string, index: number) => {
    formik.setFieldValue(`persons[${index}].gender`, value);
  };

  const setIsJewish = (value: number, index: number) => {
    formik.setFieldValue(`persons[${index}].is_jewish`, value);
  };

  const setIsChild = (value: number, index: number) => {
    formik.setFieldValue(`persons[${index}].is_child`, value);
  };

  const handleTextarea = (value: string, index: number) => {
    formik.setFieldValue(`persons[${index}].info_note`, value);
  };

  const handleAddressType = (
    value: string,
    personIndex: number,
    addressIndex: number
  ) => {
    formik.setFieldValue(
      `persons[${personIndex}].addresses[${addressIndex}].street_type`,
      value
    );
  };

  const handleAddress = (
    value: string,
    personIndex: number,
    addressIndex: number
  ) => {
    formik.setFieldValue(
      `persons[${personIndex}].addresses[${addressIndex}].fullAddress`,
      value
    );
  };

  const handleCoordinates = (
    place: any,
    personIndex: number,
    addressIndex: number
  ) => {
    const latitude = place.geometry.location.lat();
    const longitude = place.geometry.location.lng();

    const streetNumber = place.address_components.find((element: any) => {
      return element.types[0] === "street_number";
    });

    const streetName = place.address_components.find((element: any) => {
      return element.types[0] === "route";
    });
    const city = place.address_components.find((element: any) => {
      return element.types[0] === "locality";
    });
    const state = place.address_components.find((element: any) => {
      return element.types[0] === "administrative_area_level_1";
    });
    const country = place.address_components.find((element: any) => {
      return element.types[0] === "country";
    });
    const zipCode = place.address_components.find((element: any) => {
      return element.types[0] === "postal_code";
    });
    const placeId = place.place_id;
    formik.setFieldValue(
      `persons[${personIndex}].addresses[${addressIndex}].unit_number`,
      streetNumber ? streetNumber.long_name : ""
    );
    formik.setFieldValue(
      `persons[${personIndex}].addresses[${addressIndex}].street_name`,
      streetName ? streetName.long_name : ""
    );
    formik.setFieldValue(
      `persons[${personIndex}].addresses[${addressIndex}].city`,
      city ? city.long_name : ""
    );
    formik.setFieldValue(
      `persons[${personIndex}].addresses[${addressIndex}].state`,
      state ? state.long_name : ""
    );
    formik.setFieldValue(
      `persons[${personIndex}].addresses[${addressIndex}].country`,
      country ? country.long_name : ""
    );
    formik.setFieldValue(
      `persons[${personIndex}].addresses[${addressIndex}].zip_code`,
      zipCode ? zipCode.long_name : ""
    );
    formik.setFieldValue(
      `persons[${personIndex}].addresses[${addressIndex}].place_id`,
      placeId
    );

    formik.setFieldValue(
      `persons[${personIndex}].addresses[${addressIndex}].latitude`,
      latitude
    );
    formik.setFieldValue(
      `persons[${personIndex}].addresses[${addressIndex}].longitude`,
      longitude
    );
  };

  const handleAddressNote = (
    value: string,
    personIndex: number,
    addressIndex: number
  ) => {
    formik.setFieldValue(
      `persons[${personIndex}].addresses[${addressIndex}].info_note`,
      value
    );
  };

  const handlePhoneNumber = (
    number: string,
    type: string,
    personIndex: number,
    phoneIndex: string
  ) => {
    formik.setFieldValue(
      `persons[${personIndex}].phones[${phoneIndex}].type`,
      type
    );
    formik.setFieldValue(
      `persons[${personIndex}].phones[${phoneIndex}].number`,
      number
    );
  };

  const handlePhoneNumberType = (
    type: string,
    personIndex: number,
    phoneIndex: string
  ) => {
    formik.setFieldValue(
      `persons[${personIndex}].phones[${phoneIndex}].type`,
      type
    );
  };

  const addPhone = (index: number) => {
    formik.setFieldValue(`persons[${index}].phones`, [
      ...formik.values.persons[index].phones,
      PHONE,
    ]);
  };

  const handleDeletePhone = (personIndex: number, phoneIndex: number) => {
    formik.values.persons[personIndex].phones.splice(phoneIndex, 1);

    formik.setFieldValue(`persons[${personIndex}].phones`, [
      ...formik.values.persons[personIndex].phones,
    ]);
  };

  const handleEmail = (value: string, personIndex: number) => {
    formik.setFieldValue(`persons[${personIndex}].email`, value);
  };

  const handleIsPrimary = (e: any, index: number) => {
    if (e.target.type === "radio") {
      formik.values.persons.forEach((_, ind) => {
        if (index === ind) {
          formik.setFieldValue(`persons[${ind}].isMain`, true);
        } else {
          formik.setFieldValue(`persons[${ind}].isMain`, false);
        }
      });
    }
  };

  const onPrimaryAddressChanged = (personIndex: number, addressesIndex: number) => {
    formik.values?.persons[personIndex]?.addresses.forEach((_: any, index: number) => {
      if (index === addressesIndex) {
        formik.setFieldValue(
            `persons[${personIndex}].addresses[${index}].set_as_household_address`,
            true
        );
      } else {
        formik.setFieldValue(
            `persons[${personIndex}].addresses[${index}].set_as_household_address`,
            false
        );
      }
    })
  }

  const handleDeletePerson = (index: number) => {
    formik.values.persons.splice(index, 1);
    formik.setFieldValue("persons", [...formik.values.persons]);
  };

  const handleDeleteAddress = (personIndex: number, addressIndex: number) => {
    formik.values.persons[personIndex].addresses.splice(addressIndex, 1);

    formik.setFieldValue(`persons[${personIndex}].addresses`, [
      ...formik.values.persons[personIndex].addresses,
    ]);
  };

  const cancelChanges = () => {
    formik.setValues({
      ...householdValue,
    });
  };

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  return (
    <div>
      <div className="update-household-info">
        <div className="update-household-info__container">
          <div className="update-household-info__children-container">
            <Box
                sx={{
                  pointerEvents: user?.permissions[UserPermissionsEnum.updateHouseholds] ? "auto" : "none"
                }}
                className="update-household-info__children">
              <div className="update-household-info__children-container-inner">
                {formik.values.persons?.length &&
                  formik.values.persons.map((_, personIndex) => {
                    return (
                      <HouseholdInfo
                        key={personIndex}
                        salutationOptions={salutationOptions}
                        addressesTypesOptions={addressesTypesOptions}
                        formik={formik}
                        personIndex={personIndex}
                        setGender={setGender}
                        setIsChild={setIsChild}
                        setIsJewish={setIsJewish}
                        onAddressPrimaryChange={onPrimaryAddressChanged}
                        handleTextarea={handleTextarea}
                        handleSalutation={handleSalutation}
                        handleAddressType={handleAddressType}
                        handleAddress={handleAddress}
                        addAddress={addAddress}
                        addPhone={addPhone}
                        handleFirstName={handleFirstName}
                        handleLastName={handleLastName}
                        handleCoordinates={handleCoordinates}
                        handleAddressNote={handleAddressNote}
                        handlePhoneNumber={handlePhoneNumber}
                        handlePhoneNumberType={handlePhoneNumberType}
                        handleDeletePhone={handleDeletePhone}
                        handleEmail={handleEmail}
                        handleIsPrimary={handleIsPrimary}
                        handleDeletePerson={handleDeletePerson}
                        handleDeleteAddress={handleDeleteAddress}
                      />
                    );
                  })}
              </div>
              <div className="update-household-info__button">
                <Button onClick={addPerson} title={p('add_contacts')} />
              </div>
            </Box>
          </div>
        </div>
      </div>
      {!!user?.permissions[UserPermissionsEnum.updateHouseholds] && (
              <div className="update-household-info__footer">
                <UpdateFooter
                    title={pGeneral('cancel')}
                    label={pGeneral('save_changes')}
                    icon={saveIcon}
                    cancelClick={cancelChanges}
                    onClick={handleSubmit}
                />
              </div>
          )}
    </div>
  );
};
