import changeQueryParams from '../utils/changeQueryParams';

interface IUseUrlQueryParamsRes {
    setUrlQueryParam: (param: string, value: string) => void;
    getUrlQueryParam: (param: string) => string | null;
    deleteUrlQueryParam: (paramName: string) => void
}

export const useUrlQueryParams = (): IUseUrlQueryParamsRes => {
    const params = new URLSearchParams(window.location.search);

    const setUrlQueryParam = (param: string, value: string) => {
        params.set(param, value);
        changeQueryParams(params);
    };

    const getUrlQueryParam = (param: string) => params.get(param);

    const deleteUrlQueryParam = (paramName: string) => {
        params.delete(paramName);
        changeQueryParams(params);
    }

    return { setUrlQueryParam, getUrlQueryParam, deleteUrlQueryParam };
};