import "./styles.scss"
import { FC } from "react"

type Props = {
  title: string
  icon: string
  value: string
  endIcon: string
  setGender: (value: string) => void
}

export const GenderButton: FC<Props> = ({
  title,
  icon,
  value,
  setGender,
  endIcon,
}) => {
  const handleClick = () => {
    setGender(value)
  }
  return (
    <div
      className={`visit-report-gender-button ${
        endIcon ? "visit-report-gender-button_active" : ""
      }`}
      onClick={handleClick}
    >
      <div className="visit-report-gender-button__start-icon">
        <img src={icon} alt="gender" />
      </div>
      <span className="visit-report-gender-button__span-title">{title}</span>
      {endIcon && (
        <div className="visit-report-gender-button__end-icon">
          <img src={endIcon} alt="check" />
        </div>
      )}
    </div>
  )
}
