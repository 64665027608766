import userBook from "../../../assets/fonts/userBook.svg";
import phoneIcon from "../../../assets/fonts/phoneBlue.svg";
import messageIcon from "../../../assets/fonts/messageBlue.svg";
import mailIcon from "../../../assets/fonts/mailBlue.svg";
import { FC } from "react";
import "./styles.scss";
import { FormikType } from "../../../types/CreateHousehold/types";
import { Panel } from "../Panel/Index";
import { Input } from "../Input";
import { PhoneNumberInputWithType } from "../PhoneNumberInputWithType";
import { ErrorText } from "../../base/ErrorText";
import { Box } from '@mui/material';
import { BlueButton } from "../../base/BlueButton";
import { Phone } from "../../../types/global/types";
import { useTranslation } from "../../../hooks/useTranslation";

type Props = {
  formik: FormikType | any;
  personIndex: number;
  handlePhoneNumber: (
    number: string,
    type: string,
    personIndex: number,
    phoneIndex: string
  ) => void;
  handlePhoneNumberType: (
    type: string,
    personIndex: number,
    phoneIndex: string
  ) => void;
  addPhone: (index: number) => void;
  handleEmail: (value: string, personIndex: number) => void;
  handleDeletePhone: (personIndex: number, phoneIndex: number) => void;
};

export const ContactInfo: FC<Props> = ({
  formik,
  personIndex,
  handlePhoneNumber,
  handlePhoneNumberType,
  handleEmail,
  addPhone,
  handleDeletePhone,
}) => {
  const { p } = useTranslation('contact_info');
  const { p: pGeneral } = useTranslation('general');
  const getEmail = (e: any) => {
    handleEmail(e.target.value, personIndex);
  };

  const handleNumber = (value: string, type: string, phoneIndex: string) => {
    handlePhoneNumber(value, type, personIndex, phoneIndex);
  };

  const handlePhoneType = (value: string, phoneIndex: string) => {
    handlePhoneNumberType(value, personIndex, phoneIndex);
  };

  const addNewPhoneNumber = () => {
    addPhone(personIndex);
  };

  const deletePhone = (phoneIndex: number) => {
    handleDeletePhone(personIndex, phoneIndex);
  };
  // const addNewPhoneNumber = formik.persons[personIndex].phones.push({})

  const validationCondition =
    formik?.errors?.persons &&
    formik?.errors?.persons[personIndex] &&
    formik?.touched?.persons &&
    formik?.touched?.persons[personIndex];

  return (
    <div className="create-household-contact-info">
      <div className="create-household-contact-info__panel">
        <Panel title={p('name')} icon={userBook} />
      </div>

      <div className="create-household-contact-info__email-container">
        <Box
          // sx={{
          //   flexBasis: { xs: "calc(100%/2 - 10px/2)" }
          // }}
          className="create-household-contact-info__email-input">
          <Input
            placeholder={pGeneral('email')}
            icon={mailIcon}
            value={formik.values.persons[personIndex].email}
            onChange={getEmail}
          />
          {validationCondition &&
            formik.touched.persons[personIndex].email &&
            formik.errors.persons[personIndex].email && (
              <ErrorText title={formik.errors.persons[personIndex].email} />
            )}
        </Box>
      </div>

      <div className="create-household-contact-info__container">
        {formik.values.persons[personIndex]?.phones && formik.values.persons[personIndex].phones.map((phone: Phone, phoneIndex: number) => {
          return (
            <div className="create-household-contact-info__container-inner" key={phoneIndex}>
              <PhoneNumberInputWithType
                label={`${phone.type} ${p('number')} ${phoneIndex + 1}`}
                firstEndIcon={phoneIcon}
                secondEndIcon={messageIcon}
                type={phone.type}
                index={phoneIndex.toString()}
                value={phone.number}
                onChange={handleNumber}
                onTypeChange={handlePhoneType}
                onDeletePhone={deletePhone}
              />
              {validationCondition &&
                formik.touched.persons[personIndex].phones &&
                formik.touched.persons[personIndex].phones[phoneIndex] &&
                formik.errors.persons[personIndex].phones &&
                formik.errors.persons[personIndex].phones[phoneIndex] && (
                  <ErrorText
                    title={formik.errors.persons[personIndex].phones[phoneIndex]}
                  />
                )}
            </div>
          )
        })}
        <div className="create-household-contact-info__container-inner">
          <BlueButton title={`+ ${p('add_phone_number')}`} onClick={addNewPhoneNumber} background="white" />
        </div>
      </div>
    </div>
  );
};
