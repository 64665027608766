import { FC } from "react"
import scheduledVisitIcon from "../../../assets/fonts/scheduledVisit.svg"
import { Visit } from "../../../types/VisitReports/types"
import "./styles.scss"
import { useTranslation } from "../../../hooks/useTranslation"

type Props = {
  scheduledVisits: Visit[]
}

export const ScheduledVisitsPanel: FC<Props> = ({ scheduledVisits }) => {
  const { p } = useTranslation('contact_info');
  const visitsOrder = [p('first_visit'), p('second_visit'), p('third_visit')]

  return (
    <div className="scheduled-visit-panel">
      {scheduledVisits.map((elem: Visit, index) => {
        return (
          <div className="scheduled-visit-panel__visit-container" key={index}>
            <img src={scheduledVisitIcon} alt="visit" />
            <div>
              <div className="scheduled-visit-panel__date-title">
                {visitsOrder[index]}
              </div>
              <div className="scheduled-visit-panel__date">
                {elem.planned_date_formatted}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
