import * as Yup from 'yup';
import { FC, useMemo } from 'react'
import { Notification } from "../../../types/global/types"
import "./styles.scss"
import { Button } from "../../ContactsPage/Button"
import { useFormik } from 'formik';
import { LeftTown } from '../../VisitReportPage/VisitReportModal/childrens/LeftTown';
import {useDispatch, useSelector} from 'react-redux';
import { addressSchema } from '../../../validationSchemas/addressSchema';
import {newAddressNotification} from '../../../backend/store/actions/creators/newAddressNotification';

type Props = {
  notification: Notification,
}

export const NewHouseholdAddressNotification: FC<Props> = ({
  notification,
}) => {
  const dispatch = useDispatch();
  const storedAddressesTypes = useSelector(
      (state: any) => state.addressesStore.types
  )

  const addressesTypes = useMemo(
      () => storedAddressesTypes || [],
      [storedAddressesTypes]
  )

  const formik = useFormik({
    initialValues: {
      new_address: notification.newHouseholdAddress,
    },
    validationSchema: Yup.object().shape({
      new_address: addressSchema
    }),
    onSubmit: values => {
      dispatch(newAddressNotification({
        id: notification.id,
        data: values.new_address,
        onSuccess: () => {

        },
        onError: () => {

        }
      }))
    }
  })

  return (
    <div
      className="notification"
    >
      <div className="notification__header">
        <div className="notification__title">
          <h3>
            {notification.title.startOfTitle}
            {notification.title.householdName}
            {notification.title.endOfTitle}
          </h3>
          <div className="notification__time">
            {notification.created_at_formatted}
          </div>
        </div>
      </div>
      <LeftTown
          openLeftTown
          formik={formik as any}
          setOpenLeftTown={() => {}}
          addressesTypes={addressesTypes}
      />
      <div className="notification__button">
        <Button title="Confirm changed" onClick={formik.handleSubmit} />
      </div>
    </div>
  )
}
