import React, {ChangeEvent, useState} from 'react';
import {Box} from '@mui/material';
import {Link} from 'react-router-dom';
import blueChevron from '../../assets/fonts/blueChevron24.svg';
import {Input} from '../../components/LoginPage/Input';
import {Button} from '../../components/LoginPage/Button';
import loginImageMobile from '../../assets/images/loginImageMobile.png';
import loginImage from '../../assets/images/loginImage.png';
import {useFormik} from 'formik';
import * as yup from 'yup';
import Typography from '@mui/material/Typography';
import {forgetPassword} from '../../backend/store/actions/creators/forgetPassword';
import {useDispatch} from 'react-redux';
import { useTranslation } from '../../hooks/useTranslation';

export const ForgotPassword = () => {
    const { p } = useTranslation('first_page');
    const { p: pGeneral } = useTranslation('general');
    const [isSuccessSent, setIsSuccessSent] = useState<boolean>(false)
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState<string>('')
    const formik = useFormik<{ email: string }>({
        initialValues: {
            email: ""
        },
        validationSchema: yup.object().shape({
           email: yup.string().email(p('invalid_email')).required(p('email_is_required'))
        }),
        onSubmit: (values) => {
            dispatch(forgetPassword({
                data: values,
                onSuccess: () => {
                    setIsSuccessSent(true);
                },
                onError: (error: any) => {
                    setErrorMessage(error.response.data.message)
                }
            }))
        }
    })

    return (
        <div className="registr-page-first-step">
            <Box
                className="registr-page-first-step__container"
                sx={{
                    flexDirection: { xs: "column", md: "row" },
                }}
            >
                <Box
                    className="registr-page-first-step__form-container"
                    sx={{
                        width: { xs: "100%", md: "50vw" },
                        order: { xs: "2", md: "1" },
                        padding: { xs: "25px 0", md: "125px 0" },
                    }}
                >
                    <Box
                        className="registr-page-first-step__form-container-inner"
                        sx={{
                            width: { xs: "90%", md: "100%" },
                            height: '100vh'
                        }}
                    >
                        <Link to={"/"}>
                            <img
                                src={blueChevron}
                                alt="chevron"
                                className="registr-page-first-step__chevron"
                            />
                        </Link>
                        <form
                            className="registr-page-first-step__form"
                            noValidate
                            autoComplete="new-password"
                        >
                            <Box
                                sx={{
                                    fontWeight: 700,
                                    color: 'rgba(14, 13, 91, 1)',
                                    fontSize: { xs: "22px", md: "32px" },
                                    textAlign: { xs: "left", md: "center" },
                                    marginBottom: 3
                                }}
                            >
                                {isSuccessSent ? "Check your email" : p('forgot_password_2')}
                            </Box>
                            <Typography sx={{
                                fontSize: 15,
                                color: "#000",
                                textAlign: "left",
                                fontFamily: "Open Sans",
                                marginBottom: 2
                            }}>
                                {isSuccessSent ?
                                    'If there is an account associated with the email you entered, you’ll receive password instructions.' :
                                    p('enter_email')
                                }
                            </Typography>
                            {isSuccessSent && (
                                <Typography sx={{
                                    fontSize: 15,
                                    color: "#000",
                                    textAlign: "left",
                                    fontFamily: "Open Sans",
                                    marginBottom: 2
                                }}>
                                    Didn’t receive an email? Check your spam folder, or
                                    <span
                                        onClick={() => setIsSuccessSent(false)}
                                        style={{textDecoration: "underline", fontWeight: 600, cursor: "pointer"}}> try entering your email again.</span>
                                </Typography>
                            )}
                            {!isSuccessSent && (
                                <>
                                    <Box
                                        className="registr-page-first-step__form-email"
                                        sx={{
                                            marginBottom: { xs: "15px", md: "40px" },
                                        }}
                                    >
                                        <Input
                                            label={pGeneral('email')}
                                            placeholder={pGeneral('email')}
                                            value={formik.values.email}
                                            error={formik.touched['email'] && formik?.errors?.email}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                formik.setTouched({ ...formik.touched, email: true })
                                                formik.setFieldValue('email', event.target.value)
                                            }}
                                        />
                                    </Box>
                                    {errorMessage && <Typography align={'left'} fontSize={14} marginY={1} color={'red'} fontWeight={500}>{errorMessage}</Typography>}
                                    <div className="registr-page-first-step__button">
                                        <Button
                                            title={p('email_instructions')}
                                            onClick={() => formik.handleSubmit()}
                                        />
                                    </div>
                                </>
                            )}
                        </form>
                    </Box>
                </Box>
                <Box
                    className="registr-page-first-step__image-container"
                    sx={{
                        order: { xs: "1", md: "2" },
                        height: { xs: "17vh", md: "100vh" },
                        backgroundImage: { xs: `url(${loginImageMobile})`, md: `url(${loginImage})` },
                        backgroundPosition: { xs: "bottom", md: "top" },
                    }}
                />
            </Box>
        </div>
    )
}