import { FC, useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { Navigate, useNavigate } from "react-router-dom"

type Props = {
  children: any
}

export const PrivateRoute: FC<Props> = ({ children }) => {
  const isUser = localStorage.getItem("user")
  const navigate = useNavigate()

  if (!isUser) {
    return <Navigate to="/login" />
  }

  return children
}
