import { Dispatch, SetStateAction, useState } from 'react';
import { useDispatch } from 'react-redux';
import { visitsTriggerActions } from "../../../backend/store/reducers/visitsReducers";
import { deleteAllHouseholdsExcludeIds, deleteHouseholdsByIds, updateRegularCategoriesForAllHouseholdsExcludeIds, updateRegularCategoriesForHouseholdsByIds, updateSchedulingForAllHouseholdsExcludeIds, updateSchedulingForHouseholdsByIds } from "../../../backend/store/actions/creators/household";
import { generalActions } from '../../../backend/store/reducers/generalReducer';

interface IUseGlobalSelectedContactsProps {
    visits: any,
}

interface IUseGlobalSelectedContactsRes {
    selectAllHouseholds: () => void
    handleChecked: (id: number) => void;
    ids: number[],
    excludedIds: number[],
    totalSelected: () => number,
    handleDeleteHouseholds: () => void;
    handleSubmitScheduledCategories: () => void;
    checkedGeneralCheckbox: boolean;
    isVisibleUpdateRegularCategories: boolean,
    isVisibleUpdateScheduledCategories: boolean,
    isVisibleDeleteHouseholds: boolean,
    setIsVisibleUpdateRegularCategories: Dispatch<SetStateAction<boolean>>
    setIsVisibleUpdateScheduledCategories: Dispatch<SetStateAction<boolean>>
    setIsVisibleDeleteHouseholds: Dispatch<SetStateAction<boolean>>
    selectedScheduledCategory: null | { value: string, label: string }
    setSelectedScheduledCategory: Dispatch<SetStateAction<null | { value: string, label: string }>>
    handleChangeRegularCategory: (id: number) => void;
    selectedRegularCategories: number[];
    handleSubmitRegularCategories: () => void;
}

export const useGlobalSelectedContacts = ({ visits }: IUseGlobalSelectedContactsProps): IUseGlobalSelectedContactsRes => {
    const dispatch = useDispatch();
    const [ids, setIds] = useState<number[]>([]);
    const [excludedIds, setExcludedIds] = useState<number[]>([]);
    const [checkedGeneralCheckbox, setCheckedGeneralCheckbox] = useState(false);
    const [isVisibleUpdateRegularCategories, setIsVisibleUpdateRegularCategories] = useState(false);
    const [isVisibleUpdateScheduledCategories, setIsVisibleUpdateScheduledCategories] = useState(false);
    const [isVisibleDeleteHouseholds, setIsVisibleDeleteHouseholds] = useState(false);
    const [selectedScheduledCategory, setSelectedScheduledCategory] = useState<null | { value: string, label: string }>(null);
    const [selectedRegularCategories, setSelectedRegularCategories] = useState<number[]>([])

    const totalSelected = (): number => {
        if (checkedGeneralCheckbox) {
            return visits?.meta?.total - excludedIds.length
        }
        return ids.length
    }

    const selectAllHouseholds = () => {
        if (checkedGeneralCheckbox) {
            resetSelectedData()
            return
        } else {
            setCheckedGeneralCheckbox(true)
            setExcludedIds([])
            setIds(visits.data.map((household: any) => household.id))
            return
        }
    };

    const handleChecked = (id: number) => {
        if (checkedGeneralCheckbox) {
            const res = ids.includes(id);
            if (res) {
                setIds((current) => current.filter((elem: any) => elem !== id))
                setExcludedIds(prevState => [...prevState, id])
                return
            } else {
                setExcludedIds((current) => current.filter((elem: any) => elem !== id))
                setIds(prevState => [...prevState, id])
                return
            }
        } else {
            const res = ids.includes(id);
            if (res) {
                setIds((current) => current.filter((elem: any) => elem !== id))
                return
            }
            setIds(prevState => [...prevState, id])
            return
        }
    };

    const handleChangeRegularCategory = (id: number) => {
        const res = selectedRegularCategories.includes(id);
        if (res) {
            return setSelectedRegularCategories(prevState => prevState.filter(state => state !== id))
        }
        return setSelectedRegularCategories([...selectedRegularCategories, id])
    }

    const handleDeleteHouseholds = () => {
        if (checkedGeneralCheckbox) {
            dispatch(
                deleteAllHouseholdsExcludeIds({
                    data: {
                        excluded_ids: excludedIds,
                    },
                    onSuccess: () => {
                        dispatch(visitsTriggerActions.getAllVisitsTriggerAC({ page: visits.meta.current_page, perPage: visits.meta.per_page }));
                    },
                    onError: (error: any) => {
                        dispatch(
                            generalActions.successAC({
                                message: error?.response?.data?.message,
                                type: "error",
                            })
                        );
                    },
                })
            )
        } else {
            dispatch(
                deleteHouseholdsByIds({
                    data: {
                        ids: ids,
                    },
                    onSuccess: () => {
                        dispatch(visitsTriggerActions.getAllVisitsTriggerAC({ page: visits.meta.current_page, perPage: visits.meta.per_page }));
                    },
                    onError: (error: any) => {
                        dispatch(
                            generalActions.successAC({
                                message: error?.response?.data?.message,
                                type: "error",
                            })
                        );
                    },
                })
            )
        }
        setIsVisibleDeleteHouseholds(prevState => !prevState)
        resetSelectedData()
    }

    const handleSubmitScheduledCategories = () => {
        if (!selectedScheduledCategory) return;
        if (checkedGeneralCheckbox) {
            dispatch(
                updateSchedulingForAllHouseholdsExcludeIds({
                    data: {
                        excluded_ids: excludedIds,
                        periodicity: selectedScheduledCategory?.value,
                    },
                    onSuccess: () => {
                        dispatch(visitsTriggerActions.getAllVisitsTriggerAC({ page: visits.meta.current_page, perPage: visits.meta.per_page }));
                    },
                    onError: (error: any) => {
                        dispatch(
                            generalActions.successAC({
                                message: error?.response?.data?.message,
                                type: "error",
                            })
                        );
                    },
                })
            )
        } else {
            dispatch(
                updateSchedulingForHouseholdsByIds({
                    data: {
                        ids: ids,
                        periodicity: selectedScheduledCategory?.value,
                    },
                    onSuccess: () => {
                        dispatch(visitsTriggerActions.getAllVisitsTriggerAC({ page: visits.meta.current_page, perPage: visits.meta.per_page }));
                    },
                    onError: (error: any) => {
                        dispatch(
                            generalActions.successAC({
                                message: error?.response?.data?.message,
                                type: "error",
                            })
                        );
                    },
                })
            )
        }
        setIsVisibleUpdateScheduledCategories(prevState => !prevState)
        resetSelectedData()
    }

    const handleSubmitRegularCategories = () => {
        if (checkedGeneralCheckbox) {
            dispatch(
                updateRegularCategoriesForAllHouseholdsExcludeIds({
                    data: {
                        excluded_ids: excludedIds,
                        category_ids: selectedRegularCategories,
                    },
                    onSuccess: () => {
                        dispatch(visitsTriggerActions.getAllVisitsTriggerAC({ page: visits.meta.current_page, perPage: visits.meta.per_page }));
                    },
                    onError: (error: any) => {
                        dispatch(
                            generalActions.successAC({
                                message: error?.response?.data?.message,
                                type: "error",
                            })
                        );
                    },
                })
            )
        } else {
            dispatch(
                updateRegularCategoriesForHouseholdsByIds({
                    data: {
                        ids: ids,
                        category_ids: selectedRegularCategories,
                    },
                    onSuccess: () => {
                        dispatch(visitsTriggerActions.getAllVisitsTriggerAC({ page: visits.meta.current_page, perPage: visits.meta.per_page }));
                    },
                    onError: (error: any) => {
                        dispatch(
                            generalActions.successAC({
                                message: error?.response?.data?.message,
                                type: "error",
                            })
                        );
                    },
                })
            );
        }
        setIsVisibleUpdateRegularCategories(prevState => !prevState)
        resetSelectedData()
    }

    const resetSelectedData = () => {
        setCheckedGeneralCheckbox(false)
        setExcludedIds([])
        setIds([])
    }

    return {
        handleChangeRegularCategory,
        selectedRegularCategories,
        selectedScheduledCategory,
        setSelectedScheduledCategory,
        isVisibleDeleteHouseholds,
        isVisibleUpdateScheduledCategories,
        setIsVisibleDeleteHouseholds,
        setIsVisibleUpdateScheduledCategories,
        setIsVisibleUpdateRegularCategories,
        isVisibleUpdateRegularCategories,
        checkedGeneralCheckbox,
        selectAllHouseholds,
        handleChecked,
        handleDeleteHouseholds,
        ids,
        excludedIds,
        totalSelected,
        handleSubmitScheduledCategories,
        handleSubmitRegularCategories,
    }
}