import "./styles.scss"
import { FC } from "react"

type Props = {
  title: string
  icon: string
  value: string
  endIcon: string
  index: number
  setGender: (value: string, index: number) => void
}

export const SelectGender: FC<Props> = ({
  title,
  icon,
  value,
  index,
  setGender,
  endIcon,
}) => {
  const handleClick = () => {
    setGender(value, index)
  }

  return (
    <div
      // className={`create-household-select-gender ${
      //   endIcon ? "create-household-select-gender_active" : ""
      // }`}
      className={`create-household-select-gender `}
      
    >
      <label
            className="create-household-info__header-form-control"
            onClick={handleClick}
          >

      {/* <div className="create-household-select-gender__start-icon">
        <img src={icon} alt="gender" />
      </div> */}

       {/* {endIcon && (
        <div className="create-household-select-gender__end-icon">
          <img src={endIcon} alt="check" />
        </div>
      )}
      <span className="create-household-select-gender__span-title">
        {title}
      </span> */}


      
    
            <input
               className={`${endIcon? 'create-household-info__header-form-control-radio-checked':'create-household-info__header-form-control-radio'}`}
              // name="create-household-info__person"
              checked={!!endIcon}
              // value={formik.values.persons[personIndex].set_as_main}
              type="radio"
              // onChange={(e: any) => handleIsPrimary(e, personIndex)}
            />
              <span className="create-household-select-gender__span-title">
               {title}
               </span>
          </label>
          {/* {!open && (
              <Box
                  sx={{borderLeft: "1px solid #C5CAE3", marginLeft: "25px", paddingLeft: "25px"}}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    color: "#0E0D5B",
                    fontFamily: 'Open Sans, sans-sarif',
                    fontWeight: "600"
                  }}
                >{formik.values.persons[personIndex]?.first_name} {formik.values.persons[personIndex].last_name}</Typography>
              </Box>
          )} */}
        </div>

      
   
    // </div>
  )
}
