import React from 'react';

import { Container, Language, Overlay } from './styled';

const options = [
  { value: 'en', label: 'EN' },
  { value: 'he', label: 'HE' },
  // { value: 'fr', label: 'FR' },
];

    const LanguageSelect = ({ language, onChange }) => {
  return (
    <Container>
      <Overlay>
        {options.sort(({ value }) => value === language ? 1 : -1).map(({ value, label }) => (
          <Language key={value} onClick={() => onChange(value)}>
            {label}
          </Language>
        ))}
      </Overlay>
      <Language>
        {(language || 'en').toUpperCase()}
      </Language>
    </Container>
  );
};

export default LanguageSelect;
