import { FC } from "react"
import chevron from "../../../assets/fonts/chevron.svg"
import "./styles.scss"

type Props = {
  title: string
  open: boolean
  onClick: () => void
}

export const SectionHeader: FC<Props> = ({ title, open, onClick }) => {
  return (
    <div className="home-section-header" onClick={onClick}>
      <div className="home-section-header__container">
        <div className="home-section-header__title">{title}</div>
        <div className={`${!open ? "home-section-header__img_open" : ""}`}>
          <img src={chevron} alt="chevron" />
        </div>
      </div>
    </div>
  )
}
