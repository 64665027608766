import React, {FC} from 'react';
import './styles.scss';

interface IEmptyStateContactsPageProps {
    img: any,
    title: string
    subtitle?: string
}

export const EmptyStateContactsPage: FC<IEmptyStateContactsPageProps> = ({ img, subtitle, title }) => {
    return (
        <div className='empty-state-contacts'>
            <img src={img} alt={'EmptyStateIcon'} />
            <p className='empty-state-contacts__title'>{title}</p>
            <p className='empty-state-contacts__subtitle'>{subtitle}</p>
        </div>
    )
};