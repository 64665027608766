import { ChangeEvent, FC } from "react";
import "./styles.scss";

export interface IErrorTextProps {
  title: string;
}

export const ErrorText: FC<IErrorTextProps> = ({ title }) => {
  return <div className="error-text">{title}</div>;
};
