import { FC } from "react"
import closeIconFont from "../../../assets/fonts/close.svg"
import "./styles.scss"

type Props = {
  title: string
  onClose: () => void
}

export const FilterHeader: FC<Props> = ({ title, onClose }) => {
  return (
    <div className="visit-report__filter-header">
      <h3>{title}</h3>
      <div className="visit-report__filter-header-img" onClick={onClose}>
        <img src={closeIconFont} alt="close" />
      </div>
    </div>
  )
}
