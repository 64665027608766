import {ChangeEvent, FC} from 'react'
import {Link} from 'react-router-dom'
import loginImage from '../../../assets/images/loginImage.png';
import loginImageMobile from '../../../assets/images/loginImageMobile.png';
import blueChevron from '../../../assets/fonts/blueChevron24.svg'
import {RegistrFormik} from '../../../pages/RegistrationPage/RegistrationPage'
import {Checkbox} from '../../base/Checkbox'
import {Button} from '../../LoginPage/Button'
import {ChabadButton} from '../../LoginPage/ChabadButton'
import {Input} from '../../LoginPage/Input'
import './styles.scss'
import {Box} from '@mui/material';
import PhoneInput, {CountryData} from 'react-phone-input-2';
import Typography from '@mui/material/Typography';
import { useTranslation } from '../../../hooks/useTranslation';

interface IProps {
    formik: RegistrFormik
    isDisabled: boolean
    handleFirstName: (e: any) => void
    handleLastName: (e: any) => void
    setStep: (value: string) => void
    handleCheck:  (e: any) => void
}

export const SecondStep: FC<IProps> = ({
                                           formik,
                                           isDisabled,
                                           handleFirstName,
                                           handleLastName,
                                           setStep,
                                           handleCheck,
                                       }) => {
    const handleStep = () => {
        setStep('first step')
    }

    const { p } = useTranslation('first_page');
    const { p: pGeneral } = useTranslation('general');
    return (
        <div className="registr-page-second-step">
            <Box
                className="registr-page-second-step__container"
                sx={{
                    flexDirection: {xs: 'column', md: 'row'},
                }}
            >
                <Box
                    className="registr-page-second-step__form-container"
                    sx={{
                        width: {xs: '100%', md: '50vw'},
                        order: {xs: '2', md: '1'},
                        padding: {xs: '25px 0', md: '125px 0'},
                    }}
                >
                    <Box
                        className="registr-page-second-step__form-container-inner"
                        sx={{
                            width: {xs: '90%', md: '100%'},
                        }}
                    >
                        <img
                            src={blueChevron}
                            alt="chevron"
                            className="registr-page-second-step__chevron"
                            onClick={handleStep}
                        />
                        <form
                            className="registr-page-second-step__form"
                            noValidate
                            autoComplete="new-password"
                        >
                            <Box
                                className="registr-page-second-step__form-title"
                                sx={{
                                    fontSize: {xs: '22px', md: '32px'},
                                    textAlign: {xs: 'left', md: 'center'},
                                }}
                            >
                                You...
                            </Box>
                            <Box
                                className="registr-page-second-step__form-email"
                                sx={{
                                    marginBottom: '40px',
                                }}
                            >
                                <Input
                                    label={pGeneral('first_name')}
                                    placeholder={pGeneral('first_name')}
                                    value={formik.values.firstName}
                                    error={formik.touched['firstName'] && formik?.errors?.firstName}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        formik.setTouched({...formik.touched, firstName: true})
                                        handleFirstName(event)
                                    }}
                                />
                            </Box>
                            <Box
                                className="registr-page-second-step__form-password"
                                sx={{
                                    marginBottom: '40px',
                                }}
                            >
                                <Input
                                    label={pGeneral('last_name')}
                                    placeholder={pGeneral('last_name')}
                                    value={formik.values.lastName}
                                    error={formik.touched['lastName'] && formik?.errors?.lastName}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        formik.setTouched({...formik.touched, lastName: true})
                                        handleLastName(event);
                                    }}
                                />
                            </Box>
                            <Box
                                className="registr-page-second-step__form-password"
                                sx={{
                                    marginBottom: {xs: '15px', md: '40px'},
                                }}
                            >
                                <div className="phone-number-input__input-container">
                                    <label className="phone-number-input__label">{ p('country_of_shlichus') }</label>
                                    <div style={{position: 'relative'}}>
                                        <PhoneInput
                                            inputClass={'phone-number-input__input-class'}
                                            containerClass={'phone-number-input__container-class'}
                                            buttonClass={'phone-number-input__button-class'}
                                            country={'us'}
                                            defaultMask={'USA'}
                                            value={'USA'}
                                            onChange={(value, countryData: CountryData) => {
                                                formik.setFieldValue('shlichus_country_code', value)
                                                formik.setFieldValue('shlichus_country', countryData?.name)
                                            }}
                                        />
                                        <div className={'registr-page-second-step__phone-container'}>
                                            <Typography align={'left'} fontWeight={500} sx={{paddingLeft: 1}}>
                                                {formik.values.shlichus_country}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                            <div className="registr-page-second-step__checkbox-container">
                                {/*<Checkbox checked label="Remember me" onCheck={() => {*/}
                                {/*}}/>*/}
                                <Checkbox value={String(formik.values.remember)}
                                          checked={!!formik.values.remember}
                                          label={p('remember_me')}
                                          onCheck={(event) => {
                                              handleCheck(event);
                                          }} />
                                <Link to={'/'} className="registr-page-second-step__forgot">
                                    { p('forgot_password_1') }
                                </Link>
                            </div>
                            <div className="registr-page-second-step__button">
                                <Button
                                    disabled={isDisabled}
                                    title={p('create_account')}
                                    onClick={formik.handleSubmit}
                                />
                            </div>
                            <div className="registr-page-second-step__line-container">
                                <div className="registr-page-second-step__line"/>
                            </div>
                            <div className="registr-page-second-step__or-subtitle">{ p('or') }</div>
                            <Box
                                className="registr-page-second-step__chabad-button"
                                sx={{
                                    marginBottom: {xs: '65px', md: '100px'},
                                }}
                            >
                                <ChabadButton/>
                            </Box>
                            <div className="registr-page-first-step__subtitle">
                                { p('or') }{' '}
                                <Link
                                    to={'/login'}
                                    className="registr-page-first-step__subtitle-link"
                                >
                                    { p('login') }
                                </Link>
                            </div>
                        </form>
                    </Box>
                </Box>
                <Box
                    className="registr-page-second-step__image-container"
                    sx={{
                        order: {xs: '1', md: '2'},
                        height: {xs: '17vh', md: '100vh'},
                        backgroundImage: {xs: `url(${loginImageMobile})`, md: `url(${loginImage})`},
                        backgroundPosition: {xs: 'bottom', md: 'top'},
                    }}
                />
            </Box>
        </div>
    )
}
