import { useClickOutside } from "../../../../hooks/useClickOutside"
import { FilterHeader20 } from "../../../base/FilterHeader20"
import { FilterSection } from "../FilterSection"
import filterIcon from "../../../../assets/fonts/filter.svg"
import "./styles.scss"
import {Box} from '@mui/material';
// import { FiltersHousehold } from "../../../../enums/filtersHouseholdEnum"
import {useTranslation} from "../../../../hooks/useTranslation";


export const Filters = ({ open, setOpen, resetFilters, isDisabled = false, filters, setRender}: any) => {
    const { p } = useTranslation('visits');
    const handleBlur = () => {
    setOpen(false)
  }
  
  
  const ref = useClickOutside({ handler: handleBlur })

  return (
    <div className={`home-page-filters`} ref={ref}>
      <div
        className={`home-page-filters__header ${
          open ? "home-page-filters__header_active" : ""
        } ${isDisabled ? "isDisabled" : ""}`}
        onClick={() => setOpen(!open)}
      >
        <img
          src={filterIcon}
          alt="filter"
          className={`home-page-filters__img ${
            open ? "home-page-filters__img_active" : ""
          }`}
        />
          {p('filters')}
      </div>
      {open && (
        <Box 
          className="home-page-filters__container" 
          sx={{
            position: {xs: 'fixed', sm: 'absolute'},
            minWidth: {xs: '100vw', sm: '350px'},
            height: {xs: '100vh', sm: '320px'},
            top: {xs: '0', sm: 'auto'},
            left: {xs: '0', sm: 'auto'},
          }}
        >
          <div className="home-page-filters__title">
            <FilterHeader20 title={p('filters').toUpperCase()} onClose={() => setOpen(false)} />
          </div>
          <div className="home-page-filters__content">
            <FilterSection {...filters} setOpen={setOpen} resFilters={resetFilters}  />
          </div>
        </Box>
      )}
    </div>
  )
}

