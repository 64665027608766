import "./styles.scss"
import reportNote from "../../../assets/fonts/reportNote.svg"
import reportDetails from "../../../assets/fonts/reportDetails.svg"
import { FC } from "react"

const commentType = [
  {
    type: "Visit note",
    icon: reportNote,
  },
  {
    type: "Follow up details",
    icon: reportDetails,
  },
]

type Props = {
  title: string
  text: string
}

export const ReportComment: FC<Props> = ({ title, text }) => {
  const type = commentType.find(
    (elem: { type: string; icon: string }) => elem.type === title
  )

  return (
    <div className="report-comment">
      <img className="report-comment__img" src={type?.icon} alt="note" />
      <div className="report-comment__container">
        <div className="report-comment__title">{title}</div>
        <div className="report-comment__text">{text}</div>
      </div>
    </div>
  )
}
