import {asCreator, withCallbacks} from '../../../../lib/utils';
import {compose} from '../../../../utils/functions';
import {actionTypes} from '../../reducers/teamReducer';

export const updateVisitor = compose(
    withCallbacks,
    asCreator((data: any, id: number) => ({
        type: actionTypes.UPDATE_VISITOR_TRIGER,
        payload: data,
        id
    }))
);