import { FC, useEffect, useState } from "react";
import { Autocomplete } from "@react-google-maps/api";

import { ErrorText } from "../../base/ErrorText";
import deleteBasket from "../../../assets/fonts/deleteBasket.svg";
// import telegram from "../../../assets/fonts/telegram.svg"
import { FormikType } from "../../../types/CreateHousehold/types";
import { Select } from "../Select";
import { Textarea } from "../Textarea";
import { geocodePlaceId } from "../../../utils/geocodePlaceId";
import { Input } from "../../base/Input";
import "./styles.scss";
import {Box} from '@mui/material';
import { useTranslation } from "../../../hooks/useTranslation";

type Props = {
  formik: FormikType | any;
  personIndex: number;
  addressesIndex: number;
  addressesTypesOptions: string[];
  handleAddressType: (
    value: string,
    personIndex: number,
    addressIndex: number
  ) => void;
  handleAddress: (
    value: string,
    personIndex: number,
    addressIndex: number
  ) => void;
  handleCoordinates: (
    value: string,
    personIndex: number,
    addressIndex: number
  ) => void;
  handleAddressNote: (
    value: string,
    personIndex: number,
    addressIndex: number
  ) => void;
  handleDeleteAddress: (personIndex: number, addressIndex: number) => void;
  onAddressPrimaryChange: (personIndex: number, addressIndex: number) => void;
};

export const Adress: FC<Props> = ({
  formik,
  addressesIndex,
  addressesTypesOptions,
  personIndex,
  handleAddressType,
  handleAddress,
  handleCoordinates,
  handleAddressNote,
  handleDeleteAddress,
  onAddressPrimaryChange
}) => {
  const { p } = useTranslation('contact_info');
  const [searchResult, setSearchResult] = useState<any>("");

  useEffect(() => {
    const myPromise = new Promise((resolve) => {
      resolve(
        geocodePlaceId(
          formik.values.persons[personIndex].addresses[addressesIndex].place_id
        )
      );
    });
    myPromise.then((fullStreet: any) => {
      formik.setFieldValue(
        `persons[${personIndex}].addresses[${addressesIndex}].fullAddress`,
        fullStreet
      );
    });
  }, [formik.values.persons[personIndex].addresses[addressesIndex].place_id]);

  const addressType = (value: string) => {
    handleAddressType(value, personIndex, addressesIndex);
  };

  const getAddress = (e: any) => {
    handleAddress(e.target.value, personIndex, addressesIndex);
  };

  const getAddressNote = (e: any) => {
    handleAddressNote(e.target.value, personIndex, addressesIndex);
  };

  async function onPlaceChanged() {
    if (searchResult != null) {
      const place = searchResult.getPlace();

      const address = (await geocodePlaceId(place.place_id)) as string;

      handleCoordinates(place, personIndex, addressesIndex);
      formik.setFieldValue(
        `persons[${personIndex}].addresses[${addressesIndex}].fullAddress`,
        address
      );
    }
  }

  function onLoad(autocomplete: any) {
    setSearchResult(autocomplete);
  }

  const longitudeAndLatitude = formik.values.persons[personIndex].addresses[
    addressesIndex
  ].latitude
    ? formik.values.persons[personIndex].addresses[addressesIndex].latitude +
      "; " +
      formik.values.persons[personIndex].addresses[addressesIndex].longitude
    : "";

  const validationCondition =
    formik?.errors?.persons &&
    formik?.errors?.persons[personIndex] &&
    formik.errors.persons[personIndex].addresses &&
    formik.errors.persons[personIndex].addresses[addressesIndex] &&
    formik?.touched?.persons &&
    formik?.touched?.persons[personIndex] &&
    formik.touched.persons[personIndex].addresses &&
    formik.touched.persons[personIndex].addresses[addressesIndex];

  return (
      <div className="create-household-address">
        <div className="create-household-address__header">
          <div className="create-household-address__header-container">
            <div className="create-household-address__title">
            { p('address') } {addressesIndex + 1}
            </div>
            <div className="create-household-address__radio-container">
              <label
                  className="create-household-address__form-control-radio">
                <input className={`${formik.values?.persons[personIndex]?.addresses[addressesIndex]?.set_as_household_address?
                "create-household-address__radio-checked": "create-household-address__radio"}`}    
                       type="radio"
                       checked={formik.values?.persons[personIndex]?.addresses[addressesIndex]?.set_as_household_address}
                       onClick={() => onAddressPrimaryChange(personIndex, addressesIndex)}
                />
                {p('primary_address')}
              </label>
            </div>
            {!formik.values?.persons[personIndex]?.addresses[addressesIndex]?.set_as_household_address && (
                <div className="create-household-address__delete">
                  <img
                      src={deleteBasket}
                      alt="delete"
                      onClick={() => handleDeleteAddress(personIndex, addressesIndex)}
                  />
                </div>
            )}
          </div>
        </div>
        <Box
    //       sx={{
    //         display: 'flex',
    // justifyContent: "space-between",
    // gap: '20px',
    // marginBottom: '20px',
    // maxWidth: '860px',
    //         // flexDirection: 
    //       }}
          // className="create-household-personal-info__input-container"

          // sx={{
          //   display:'flex',
                        
          // }}
          sx={{
            flexDirection: { xs: "column", md: "row" }
          }}
          className="create-household-address__box-container"
          >

        <div className="create-household-address__select">
          <Select
              onChangeClose
              label={p('type')}
              list={addressesTypesOptions}
              value={
                formik.values.persons[personIndex].addresses[addressesIndex]
                    .type
              }
              placeholder={p('select')}
              handleSelect={addressType}
          />
          {validationCondition &&
              formik.touched.persons[personIndex].addresses[addressesIndex]
                  .type &&
              formik.errors.persons[personIndex].addresses[addressesIndex]
                  .type && (
                  <ErrorText
                      title={
                        formik.errors.persons[personIndex].addresses[addressesIndex]
                            .type
                      }
                  />
              )}
        </div>
        <div className="create-household-address__input">
          <Autocomplete
              onLoad={onLoad}
              onPlaceChanged={onPlaceChanged}
              types={['address']}
          >
            <Input
                placeholder={p('address')}
                value={
                    formik.values.persons[personIndex].addresses[addressesIndex]
                        .fullAddress || ''
                }
                label={p('address')}
                // icon={telegram}
                // title="Take me there"
                onChange={getAddress}
                error={
                    validationCondition &&
                    formik.touched.persons[personIndex].addresses[addressesIndex]
                        .street_name &&
                    formik.errors.persons[personIndex].addresses[addressesIndex]
                        .street_name
                }
            />
          </Autocomplete>
        </div>
        </Box>
        <div className=" create-household-address__input-coordinates">
        {/* className="create-household-address__input  */}
          <Input
              placeholder={p('coordinates')}
              isDisabled
              value={longitudeAndLatitude}
              onChange={() => {
              }}
          />
        </div>
        
        <div className="create-household-address__textarea">
          <Textarea
              label={p('address_note')}
              onChange={getAddressNote}
              value={
                formik.values.persons[personIndex].addresses[addressesIndex]
                    .info_note
              }
          />
        </div>
      </div>
  );
};
