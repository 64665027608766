import { GetHouseholds } from "../../../types/api"
import { Households } from "../../../types/types"
import { InferActionsTypes } from "./../index"

export const actionTypes = {
  GET_HOUSEHOLDS_TRIGGER: "GET_HOUSEHOLDS_CATEGORIES_TRIGGER",
  GET_HOUSEHOLDS_TRIGGER_FOR_MAP: "GET_HOUSEHOLDS_TRIGGER_FOR_MAP",
  GET_HOUSEHOLDS_TRIGGER_FOR_MAP_SUCCESS: "GET_HOUSEHOLDS_TRIGGER_FOR_MAP_SUCCESS",
  GET_HOUSEHOLDS_TRIGGER_FOR_MAP_ERROR: "GET_HOUSEHOLDS_TRIGGER_FOR_MAP_ERROR",
  GET_HOUSEHOLD_TRIGGER: "GET_HOUSEHOLD_TRIGGER",
  CREATE_HOUSEHOLD_TRIGGER: "CREATE_HOUSEHOLD_TRIGGER",
  UPDATE_HOUSEHOLD_TRIGGER: "UPDATE_HOUSEHOLD_TRIGGER",
  DELETE_HOUSEHOLD_TRIGGER: "DELETE_HOUSEHOLD_TRIGGER",
  DELETE_HOUSEHOLD_SUCCESS: "DELETE_HOUSEHOLD_SUCCESS",
  DELETE_HOUSEHOLD_ERROR: "DELETE_HOUSEHOLD_ERROR",
  GET_PERIODICITY_TRIGGER: "GET_PERIODICITY_TRIGGER",
  EXPORT_HOUSEHOLDS_TRIGGER: "EXPORT_HOUSEHOLDS_TRIGGER",
  EXPORT_HOUSEHOLDS_SUCCESS: "EXPORT_HOUSEHOLDS_SUCCESS",
  EXPORT_HOUSEHOLDS_ERROR: "EXPORT_HOUSEHOLDS_ERROR",
  IMPORT_HOUSEHOLDS_TRIGGER: "IMPORT_HOUSEHOLDS_TRIGGER",
  IMPORT_HOUSEHOLDS_SUCCESS: "IMPORT_HOUSEHOLDS_SUCCESS",
  IMPORT_HOUSEHOLDS_ERROR: "IMPORT_HOUSEHOLDS_ERROR",
  GET_HOUSEHOLDS_CATEGORIES_TRIGGER: "GET_HOUSEHOLDS_TRIGGER",
  GET_PERIODICITY: "GET_PERIODICITY",
  GET_HOUSEHOLDS: "GET_HOUSEHOLDS",
  GET_HOUSEHOLD: "GET_HOUSEHOLD",
  UPDATE_HOUSEHOLD_SUCCESS: "UPDATE_HOUSEHOLD_SUCCESS",
  UPDATE_HOUSEHOLD_ERROR: "UPDATE_HOUSEHOLD_ERROR",
  CREATE_HOUSEHOLD: "CREATE_HOUSEHOLD",
  CREATE_HOUSEHOLD_SUCCESS: "CREATE_HOUSEHOLD_SUCCESS",
  CREATE_HOUSEHOLD_ERROR: "CREATE_HOUSEHOLD_ERROR",
  CREATE_REGULAR_CATEGORY: "CREATE_REGULAR_CATEGORY",
  DELETE_REGULAR_CATEGORY: "DELETE_REGULAR_CATEGORY",
  DELETE_HOUSEHOLDS_BY_IDS: "DELETE_HOUSEHOLDS_BY_IDS",
  DELETE_HOUSEHOLDS_BY_IDS_SUCCESS: "DELETE_HOUSEHOLDS_BY_IDS_SUCCESS",
  DELETE_HOUSEHOLDS_BY_IDS_ERROR: "DELETE_HOUSEHOLDS_BY_IDS_ERROR",
  DELETE_ALL_HOUSEHOLDS_EXCLUDE_IDS: "DELETE_ALL_HOUSEHOLDS_EXCLUDE_IDS",
  DELETE_ALL_HOUSEHOLDS_EXCLUDE_IDS_SUCCESS: "DELETE_ALL_HOUSEHOLDS_EXCLUDE_IDS_SUCCESS",
  DELETE_ALL_HOUSEHOLDS_EXCLUDE_IDS_ERROR: "DELETE_ALL_HOUSEHOLDS_EXCLUDE_IDS_ERROR",
  UPDATE_REGULAR_CATEGORIES_FOR_HOUSEHOLDS_BY_IDS: "UPDATE_REGULAR_CATEGORIES_FOR_HOUSEHOLDS_BY_IDS",
  UPDATE_REGULAR_CATEGORIES_FOR_HOUSEHOLDS_BY_IDS_SUCCESS: "UPDATE_REGULAR_CATEGORIES_FOR_HOUSEHOLDS_BY_IDS_SUCCESS",
  UPDATE_REGULAR_CATEGORIES_FOR_HOUSEHOLDS_BY_IDS_ERROR: "UPDATE_REGULAR_CATEGORIES_FOR_HOUSEHOLDS_BY_IDS_ERROR",
  UPDATE_REGULAR_CATEGORIES_FOR_ALL_HOUSEHOLDS_EXCLUDE_IDS: "UPDATE_REGULAR_CATEGORIES_FOR_ALL_HOUSEHOLDS_EXCLUDE_IDS",
  UPDATE_REGULAR_CATEGORIES_FOR_ALL_HOUSEHOLDS_EXCLUDE_IDS_SUCCESS: "UPDATE_REGULAR_CATEGORIES_FOR_ALL_HOUSEHOLDS_EXCLUDE_IDS_SUCCESS",
  UPDATE_REGULAR_CATEGORIES_FOR_ALL_HOUSEHOLDS_EXCLUDE_IDS_ERROR: "UPDATE_REGULAR_CATEGORIES_FOR_ALL_HOUSEHOLDS_EXCLUDE_IDS_ERROR",
  UPDATE_SCHEDULING_FOR_HOUSEHOLDS_BY_IDS: "UPDATE_SCHEDULING_FOR_HOUSEHOLDS_BY_IDS",
  UPDATE_SCHEDULING_FOR_HOUSEHOLDS_BY_IDS_SUCCESS: "UPDATE_SCHEDULING_FOR_HOUSEHOLDS_BY_IDS_SUCCESS",
  UPDATE_SCHEDULING_FOR_HOUSEHOLDS_BY_IDS_ERROR: "UPDATE_SCHEDULING_FOR_HOUSEHOLDS_BY_IDS_ERROR",
  UPDATE_SCHEDULING_FOR_ALL_HOUSEHOLDS_EXCLUDE_IDS: "UPDATE_SCHEDULING_FOR_ALL_HOUSEHOLDS_EXCLUDE_IDS",
  UPDATE_SCHEDULING_FOR_ALL_HOUSEHOLDS_EXCLUDE_IDS_SUCCESS: "UPDATE_SCHEDULING_FOR_ALL_HOUSEHOLDS_EXCLUDE_IDS_SUCCESS",
  UPDATE_SCHEDULING_FOR_ALL_HOUSEHOLDS_EXCLUDE_IDS_ERROR: "UPDATE_SCHEDULING_FOR_ALL_HOUSEHOLDS_EXCLUDE_IDS_ERROR",
  UPDATE_REGULAR_CATEGORY: "UPDATE_REGULAR_CATEGORY",
  UPDATE_REGULAR_CATEGORY_SUCCESS: "UPDATE_REGULAR_CATEGORY_SUCCESS",
  UPDATE_REGULAR_CATEGORY_ERROR: "UPDATE_REGULAR_CATEGORY_ERROR",
  GET_HOUSEHOLDS_CATEGORIES: "GET_HOUSEHOLDS_CATEGORIES",
  TOGGLE_IS_LOADING: "TOGGLE_IS_LOADING",
}

type InitialState = {
  households: Households | null
  householdsMap: Households | null
  categories: null,
  periodicity: null
  household: null
  csvFile: null
  isLoading: boolean
}

const initialState: InitialState = {
  households: null,
  categories: null,
  household: null,
  periodicity: null,
  csvFile: null,
  householdsMap: null,
  isLoading: false
}

export const householdsReducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case actionTypes.GET_HOUSEHOLDS_TRIGGER_FOR_MAP_SUCCESS:
      return { ...state, householdsMap: action.payload }

    case actionTypes.GET_HOUSEHOLDS:
      return { ...state, households: action.payload }

    case actionTypes.GET_HOUSEHOLD:
      return { ...state, household: action.payload }

    case actionTypes.GET_PERIODICITY:
      return { ...state, periodicity: action.payload }

    case actionTypes.GET_HOUSEHOLDS_CATEGORIES:
      return { ...state, categories: action.payload }

    case actionTypes.TOGGLE_IS_LOADING:
      return { ...state, isLoading: action.payload }

    default:
      return state
  }
}

export const householdsActions = {
  getHouseholdsAC: (data: any) => ({
    type: actionTypes.GET_HOUSEHOLDS,
    payload: data,
  }),
  getHouseholdAC: (data: any) => ({
    type: actionTypes.GET_HOUSEHOLD,
    payload: data,
  }),

  getHouseholdCategories: (data: any) => ({
    type: actionTypes.GET_HOUSEHOLDS_CATEGORIES,
    payload: data,
  }),

  getPeriodicityAC: (data: any) => ({
    type: actionTypes.GET_PERIODICITY,
    payload: data,
  }),

  getHouseholdsForMap: (data: any) => ({
    type: actionTypes.GET_HOUSEHOLDS_TRIGGER_FOR_MAP_SUCCESS,
    payload: data,
  }),

  toggleIsLoading: (data: any) => ({
    type: actionTypes.TOGGLE_IS_LOADING,
    payload: data,
  }),
}

export const householdsTriggerActions = {
  getHouseholdsTriggerAC: ({ page, perPage, mainPersonFullName, sortOrder, search, sortBy, address_longitude_max, address_latitude_max, address_latitude_min, address_longitude_min }: GetHouseholds) => ({
    type: actionTypes.GET_HOUSEHOLDS_TRIGGER,
    payload: {
      page,
      perPage,
      mainPersonFullName,
      sortBy,
      sortOrder,
      address_longitude_min,
      address_latitude_min,
      address_longitude_max,
      address_latitude_max,
      ...search,
    }
  }),

  getHouseholdsCategories: () => ({
    type: actionTypes.GET_HOUSEHOLDS_CATEGORIES_TRIGGER,
  }),

  createHouseholdCategory: (name: string) => ({
    type: actionTypes.CREATE_REGULAR_CATEGORY,
    name
  }),

  deleteHouseholdCategory: (id: number) => ({
    type: actionTypes.DELETE_REGULAR_CATEGORY,
    id
  }),

  deleteHouseholdsByIds: (ids: number[]) => ({
    type: actionTypes.DELETE_HOUSEHOLDS_BY_IDS,
    ids
  }),

  deleteAllHouseholdsExcludeIds: (excludedIds: number[]) => ({
    type: actionTypes.DELETE_ALL_HOUSEHOLDS_EXCLUDE_IDS,
    excludedIds,
  }),

  updateRegularCategoriesForHouseholdsByIds: (ids: number[], category_ids: number[]) => ({
    type: actionTypes.UPDATE_REGULAR_CATEGORIES_FOR_HOUSEHOLDS_BY_IDS,
    ids,
    category_ids
  }),

  updateRegularCategoriesForAllHouseholdsExcludeIds: (excludedIds: number[], category_ids: number[]) => ({
    type: actionTypes.UPDATE_REGULAR_CATEGORIES_FOR_ALL_HOUSEHOLDS_EXCLUDE_IDS,
    excludedIds,
    category_ids
  }),

  updateSchedulingForHouseholdsByIds: (ids: number[], periodicity: string) => ({
    type: actionTypes.UPDATE_SCHEDULING_FOR_HOUSEHOLDS_BY_IDS,
    ids,
    periodicity,
  }),

  updateSchedulingForAllHouseholdsExcludeIds: (excludedIds: number[], periodicity: string) => ({
    type: actionTypes.UPDATE_SCHEDULING_FOR_ALL_HOUSEHOLDS_EXCLUDE_IDS,
    excludedIds,
    periodicity,
  }),

  updateHouseholdCategory: (id: number, name: string) => ({
    type: actionTypes.UPDATE_REGULAR_CATEGORY,
    id,
    name
  }),

  getHouseholdTriggerAC: (id: number) => ({
    type: actionTypes.GET_HOUSEHOLD_TRIGGER,
    id,
  }),

  getPeriodicityTriggerAC: () => ({
    type: actionTypes.GET_PERIODICITY_TRIGGER,
  }),
}

export type Actions = InferActionsTypes<typeof householdsActions>
