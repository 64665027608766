import { FC } from "react"
import "./styles.scss"

type Props = {
  label?: string
  value?: string
  checked: boolean
  onCheck: (e: any) => void
}

export const Checkbox: FC<Props> = ({ label, checked, value, onCheck }) => {
  return (
    <label
      className="create-household-checkbox__form-control"
      htmlFor="create-household-checkbox"
    >
      <input
        className={`create-household-checkbox__checkbox ${
          checked && "create-household-checkbox_active"
        }`}
        type="checkbox"
        id="create-household-checkbox"
        value={value}
        checked={checked}
        onChange={onCheck}
      />
      {label}
    </label>
  )
}
