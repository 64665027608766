import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { FC, useState } from "react";
import { StepContent } from "@mui/material";
import "./styles.scss";
import { SavedPoint } from "../SavedPoint";
import { Point } from "../../../types/global/types";

type Props = {
  pointList: any;
  dragStartHandler: (e: any, card: any) => void;
  dragEndHandler: (e: any) => void;
  dragOverHandler: (e: any) => void;
  dropHandler: (e: any, card: any) => void;
  handleDeletePoint: (id: number) => void;
  handleTakeMeThere?: (fullAddress: string, placeId: string) => void;
};

export const VerticalStepper: FC<Props> = ({
  pointList,
  dragEndHandler,
  dragOverHandler,
  dragStartHandler,
  dropHandler,
  handleDeletePoint,
  handleTakeMeThere
}) => {
  const [activeStep, setActiveStep] = useState(0);

  //   const handleNext = () => {
  //     setActiveStep((prevActiveStep) => prevActiveStep + 1)
  //   }

  //   const handleBack = () => {
  //     setActiveStep((prevActiveStep) => prevActiveStep - 1)
  //   }
  return (
    <Stepper
      className="route-page-stepper"
      //   activeStep={activeStep}
      orientation="vertical"
    >
      {pointList &&
        pointList.map((point: any) => (
          <Step className="route-page-stepper__step" key={point.id} active>
            <StepLabel />
            <StepContent className="route-page-stepper__step-content">
              <div
                className="route-page-stepper__step-component"
                draggable
                onDragStart={(e) => dragStartHandler(e, point)}
                onDragLeave={dragEndHandler}
                onDragEnd={dragEndHandler}
                onDragOver={dragOverHandler}
                onDrop={(e) => dropHandler(e, point)}
              >
                <SavedPoint
                  key={point?.id}
                  id={point?.id}
                  householdId={point?.household_id}
                  address={point?.address?.fullAddress}
                  name={point.household?.mainPersonFullName}
                  handleDeletePoint={handleDeletePoint}
                  handleTakeMeThere={(fullAddress: string) => handleTakeMeThere && handleTakeMeThere(fullAddress, point.address.place_id)}
                />
              </div>
              {/* <div className="route-page-stepper__step-connector">
                Connector
              </div> */}
            </StepContent>
          </Step>
        ))}
    </Stepper>
  );
};
