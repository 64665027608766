export const HOUSEHOLD = {
  periodicity: "",
  info_note: "",
  categories: null,
  persons: [
    {
      first_name: "",
      last_name: "",
      email: "",
      salutation: "",
      gender: "",
      is_child: 0,
      is_jewish: 0,
      info_note: "",
      isMain: false,
      set_as_main: 0,
      phones: [
        {
          number: "",
          type: "",
          area_code: "",
          country: "",
        },
      ],
      addresses: [
        {
          country: "",
          state: "",
          city: "",
          street_type: "",
          street_name: "",
          unit_number: "",
          zip_code: "",
          latitude: "",
          longitude: "",
          place_id: "",
          type: "",
          info_note: "",
          fullAddress: "",
          set_as_household_address: true
        },
      ],
    },
  ],
}

export const PERSON = {
  first_name: "",
  last_name: "",
  email: "",
  salutation: "",
  gender: "",
  is_child: 0,
  is_jewish: 0,
  info_note: "",
  set_as_main: 0,
  phones: [
    {
      number: "",
      type: "",
      area_code: "",
      country: "",
    },
    {
      number: "",
      type: "",
      area_code: "",
      country: "",
    },
    {
      number: "",
      type: "",
      area_code: "",
      country: "",
    },
    {
      number: "",
      type: "",
      area_code: "",
      country: "",
    },
  ],
  addresses: [
    {
      country: "",
      state: "",
      city: "",
      street_type: "",
      street_name: "",
      unit_number: "",
      zip_code: "",
      latitude: "",
      longitude: "",
      place_id: "",
      type: "",
      info_note: "",
    },
  ],
}

export const ADDRESS = {
  country: "",
  state: "",
  city: "",
  street_type: "",
  street_name: "",
  unit_number: "",
  zip_code: "",
  latitude: "",
  longitude: "",
  place_id: "",
  type: "",
  info_note: "",
}

export const VISITS_ADDRESS = {
  id: "",
  country: "",
  state: "",
  city: "",
  street_type: "",
  street_name: "",
  unit_number: "",
  zip_code: "",
  latitude: "",
  longitude: "",
  place_id: "",
  person_id: "",
  type: "",
  info_note: "",
  isMain: "",
  created_at: "",
  updated_at: "",
  new_address: "",
}

export const PHONE = {
    number: "",
    type: "",
    area_code: "",
    country: "",
}

export const PHONE_TYPES = ['Mobile', 'Home', 'Work', 'Other']
