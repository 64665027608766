import { FC } from "react"

import "./styles.scss"

type Props = {
  title: string
  img?: string
  onClick?: () => void,
  isDisabled?: boolean
}

export const Button: FC<Props> = ({ title, img, onClick, isDisabled }) => {
  return (
    <button className="visit-report-button" type="button" onClick={onClick} disabled={isDisabled}>
      {img && (
        <img className={`visit-report-button__image ${isDisabled ? "disabled" : ""}`} src={img} alt="plus" />
      )}
      {title}
    </button>
  )
}
