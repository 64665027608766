import { FC } from "react";
import "./styles.scss";
import { useTranslation } from "../../../hooks/useTranslation";

interface IProps {
  currentPolygon: google.maps.Polygon | null;
  setDrawMode: (
    value: google.maps.drawing.OverlayType | null | undefined
  ) => void;
  setChosenHouseholds: (value: any) => void;
  setMenuOpen: (value: boolean) => void;
  handleDelete: () => void;
}

export const DrawPanel: FC<IProps> = ({
  currentPolygon,
  setDrawMode,
  setMenuOpen,
  setChosenHouseholds,
  handleDelete,
}) => {
  const { p } = useTranslation("map");
  const { p: pGeneral } = useTranslation("general");

  return (
    <div className="home-page-draw-panel">
      <div>{p("draw_a_shape")}</div>
      <div className="home-page-draw-panel__buttons-container">
        {!currentPolygon ? (
          <div
            className="home-page-draw-panel__button"
            onClick={() => {
              setDrawMode(null);
            }}
          >
            {pGeneral("cancel")}
          </div>
        ) : (
          <div className="home-page-draw-panel__button" onClick={handleDelete}>
            {p("remove_boundary")}
          </div>
        )}
      </div>
    </div>
  );
};
