import { FC } from "react";
import { useNavigate } from "react-router-dom";

import logo from "../../assets/images/logo.png";
import "./styles.scss";
import { useTranslation } from "../../hooks/useTranslation";

interface IProps { }

export const Error404Page: FC<IProps> = ({ }) => {
  const { p } = useTranslation('general');
  const navigate = useNavigate();

  return (
    <div className="error">
      <div className="error__container">
        <img className="error__image" src={logo} alt="logo" />

        <div className="error__form-title">
          {p('page_not_found')}
        </div>

        <button
          type="button"
          className={`error-page-button `}
          onClick={() => navigate(-1)}
        >
          {p('take_me_back')}
        </button>
      </div>
    </div>
  );
};
