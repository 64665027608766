import {compose} from "../../../../utils/functions";
import {asCreator, withCallbacks} from "../../../../lib/utils";
import {actionTypes} from "../../reducers/householdsReducer";

export const updateHousehold = compose(
    withCallbacks,
    asCreator((data: object, id: number) => ({
        type: actionTypes.UPDATE_HOUSEHOLD_TRIGGER,
        payload: data,
        id: id
    }))
);

export const deleteHouseholdsByIds = compose(
    withCallbacks,
    asCreator((data: object) => ({
        type: actionTypes.DELETE_HOUSEHOLDS_BY_IDS,
        payload: data,
    }))
);

export const deleteAllHouseholdsExcludeIds = compose(
    withCallbacks,
    asCreator((data: object) => ({
        type: actionTypes.DELETE_ALL_HOUSEHOLDS_EXCLUDE_IDS,
        payload: data,
    }))
);

export const updateRegularCategoriesForHouseholdsByIds = compose(
    withCallbacks,
    asCreator((data: object) => ({
        type: actionTypes.UPDATE_REGULAR_CATEGORIES_FOR_HOUSEHOLDS_BY_IDS,
        payload: data,
    }))
);

export const updateRegularCategoriesForAllHouseholdsExcludeIds = compose(
    withCallbacks,
    asCreator((data: object) => ({
        type: actionTypes.UPDATE_REGULAR_CATEGORIES_FOR_ALL_HOUSEHOLDS_EXCLUDE_IDS,
        payload: data,
    }))
);

export const updateSchedulingForHouseholdsByIds = compose(
    withCallbacks,
    asCreator((data: object) => ({
        type: actionTypes.UPDATE_SCHEDULING_FOR_HOUSEHOLDS_BY_IDS,
        payload: data,
    }))
);

export const updateSchedulingForAllHouseholdsExcludeIds = compose(
    withCallbacks,
    asCreator((data: object) => ({
        type: actionTypes.UPDATE_SCHEDULING_FOR_ALL_HOUSEHOLDS_EXCLUDE_IDS,
        payload: data,
    }))
);