import { FC } from "react";
import "./styles.scss";

interface IProps {
  title: string;
  white?: boolean;
  onClick?: () => void;
}

export const Button: FC<IProps> = ({ title, white, onClick }) => {
  return (
    <button
      type="button"
      className={`contacts-page-button ${
        white ? "contacts-page-button_white" : ""
      }`}
      onClick={onClick}
    >
      {title}
    </button>
  );
};
