import {compose} from "../../../../utils/functions";
import {asCreator, withCallbacks} from "../../../../lib/utils";
import {actionTypes} from "../../reducers/notificationsReducer";

export const markAsRead = compose(
    withCallbacks,
    asCreator(( id: string) => ({
        type: actionTypes.MARK_NOTIFICATION_AS_READ,
        id: id,
    }))
);

export const deleteNotification = compose(
    withCallbacks,
    asCreator(( id: string) => ({
        type: actionTypes.DELETE_NOTIFICATION_TRIGGER,
        id: id,
    }))
);