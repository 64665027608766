import { FC } from "react";
import "./styles.scss";

type Props = {
  label?: string;
  checked: boolean;
  name?: string;
  value?: string | number;
  onClick: (e: any) => void;
};

export const RadioButton: FC<Props> = ({
  label,
  checked,
  value,
  name,
  onClick,
}) => {
  return (
    <label className="radio-button__form-control" onClick={onClick}>
      <input
        className="radio-button__radio"
        name={name}
        checked={checked}
        value={value}
        type="radio"
        onChange={onClick}
      />
      {label}
    </label>
  );
};
