import { FC } from "react"
import chevronFont from "../../../assets/fonts/chevron.svg"
import { useClickOutside } from "../../../hooks/useClickOutside"
import "./styles.scss"

interface IProps {
  value: string
  open: boolean
  setOpen: (value: boolean) => void
  setValue: (value: string) => void,
  filterOptions?: string[],
  menuPlacement?: 'bottom' | 'top'
}

export const PerPageFilter: FC<IProps> = ({
  value,
  open,
  setOpen,
  setValue,
  filterOptions,
  menuPlacement = 'bottom'
}) => {
  const handleSelect = (value: string) => {
    setOpen(false)
    setValue(value)
  }

  const handleBlur = () => {
    setOpen(false)
  }

  const ref = useClickOutside({ handler: handleBlur })
  const options = filterOptions ? filterOptions : ["10", "25", "50"]
  return (
    <div
      className="contacts-page-perpage-filter"
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
        setOpen(!open)
      }}
    >
      <div
        className={`contacts-page-perpage-filter__header ${
          open ? "contacts-page-perpage-filter__header_active" : ""
        }`}
      >
        {value}
        <img
          src={chevronFont}
          alt="chevron"
          className={`contacts-page-perpage-filter__img ${
            open ? "contacts-page-perpage-filter__img_active" : ""
          }`}
        />
      </div>
      {open && (
        <div className="contacts-page-perpage-filter__container" style={ menuPlacement === 'top' ? {bottom: 35} : {top: 35} }>
          {options.map((item) => {
            return (
              <div
                className={`contacts-page-perpage-filter__item ${
                  value === item
                    ? "contacts-page-perpage-filter__item_active"
                    : ""
                }`}
                key={item}
                onClick={() => handleSelect(item)}
              >
                {item}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
