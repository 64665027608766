export enum UserPermissionsEnum {
    createCategories = "create_categories",
    createHouseholds = "create_households",
    createInvitations = 'create_invitations',
    createRoutes = "create_routes",
    createVisits = "create_visits",
    deleteCategories = 'delete_categories',
    deleteHouseholds = 'delete_households',
    deleteInvitations = "delete_invitations",
    deleteRoutes = "delete_routes",
    deleteVisits = "delete_visits",
    readCategories = "read_categories",
    readHouseholds = "read_households",
    readInvitations = 'read_invitations',
    readNotifications = "read_notifications",
    readRoutes = 'read_routes',
    readVisits = "read_visits",
    readWorkspaces = "read_workspaces",
    updateCategories = "update_categories",
    updateHouseholds = "update_households",
    updateInvitations = "update_invitations",
    updateRoutes = "update_routes",
    updateVisits = "update_visits"
}