import { Route } from "../../../types/global/types"
import {
  CreateRoute,
  GetRoutesTriggerAC,
  GetRouteTriggerAC,
  UpdateRouteTriggerAC,
} from "../../../types/reducers/routesReducer"
import { InferActionsTypes } from "./../index"

export const actionTypes = {
  GET_ROUTES_TRIGGER: "GET_ROUTES_TRIGGER",
  GET_ROUTE_TRIGGER: "GET_ROUTE_TRIGGER",
  CREATE_ROUTE_TRIGGER: "CREATE_ROUTE_TRIGGER",
  UPDATE_ROUTE_TRIGGER: "UPDATE_ROUTE_TRIGGER",
  DELETE_ROUTE_TRIGGER: "DELETE_ROUTE_TRIGGER",
  GET_ROUTES: "GET_ROUTES",
  GET_ROUTE: "GET_ROUTE",
  CREATE_ROUTE: "CREATE_ROUTE",
  UPDATE_ROUTE: "UPDATE_ROUTE",
}

type InitialState = {
  routes: null | Route[]
  route: null | Route
}

const initialState: InitialState = {
  routes: null,
  route: null,
}

export const routesReducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case actionTypes.GET_ROUTES:
      return { ...state, routes: action.payload }
    case actionTypes.GET_ROUTE:
      return { ...state, route: action.payload }

    default:
      return state
  }
}

export const actions = {
  getRoutesAC: (data: { data: Route | null }) => ({
    type: actionTypes.GET_ROUTES,
    payload: data,
  }),

  getRouteAC: (data: Route | null) => ({
    type: actionTypes.GET_ROUTE,
    payload: data,
  }),
  // getRouteAC: (data: Route | null) => ({
  //   type: actionTypes.GET_ROUTE,
  //   payload: data,
  // }),
}

export const routesTriggerActions = {
  getRoutesTriggerAC: ({ household, address, page, perPage, searchValue }: GetRoutesTriggerAC) => ({
    type: actionTypes.GET_ROUTES_TRIGGER,
    household,
    address,
    page,
    perPage,
    searchValue
  }),
  getRouteTriggerAC: ({ id, household, address }: GetRouteTriggerAC) => ({
    type: actionTypes.GET_ROUTE_TRIGGER,
    household,
    address,
    id,
  }),
  createRouteTriggerAC: (data: CreateRoute) => ({
    type: actionTypes.CREATE_ROUTE_TRIGGER,
    data,
  }),
  updateRouteTriggerAC: ({ id, data }: UpdateRouteTriggerAC) => ({
    type: actionTypes.UPDATE_ROUTE_TRIGGER,
    id,
    data,
  }),
  deleteRouteTriggerAC: (id: number | undefined) => ({
    type: actionTypes.DELETE_ROUTE_TRIGGER,
    id,
  }),
}

export type Actions = InferActionsTypes<typeof actions>
