import React, {FC} from 'react';
import {IInputProps, Input} from '../../base/Input';
import './styled.scss';
import checkIcon from '../../../assets/images/CheckGreen.svg';
import closeIcon from '../../../assets/images/CloseIconRed.svg';

interface IChangeNameCategoryInputProps extends IInputProps {
    onClose?: () => void;
    onSave?: () => void;
}

export const ChangeNameCategoryInput: 
    FC<IChangeNameCategoryInputProps> = ({
                                             value,
                                             onChange,
                                             onClose,
                                             onSave,
                                             label,
                                             placeholder}) => {
    return (
        <div className='change-name-category-input-container'>
            <div style={{width: '100%'}}>
                <Input
                    value={value}
                    onChange={onChange}
                    label={label}
                    placeholder={placeholder} />
            </div>
            <div
                onClick={onClose}
                className='change-name-category-input-container__icon-box'>
                <img src={closeIcon} alt="Close icon"/>
            </div>
            <div
                onClick={onSave}
                className='change-name-category-input-container__icon-box'>
                <img src={checkIcon} alt="Check icon"/>
            </div>
        </div>
    )
}