import { FC } from "react"
import { ReportComment } from "../ReportComment"
import { StatusPanel } from "../StatusPanel"
import { VisitReportHeader } from "../VisitReportHeader"
import "./styles.scss"
import dayjs from 'dayjs';

type Props = {
  reaction: string
  remind: boolean
  remindedDate: string
  status: string
  visitNote: string
  followUpDetails: string
  plannedDate: string
}

export const VisitReport: FC<Props> = ({
  reaction,
  remind,
  remindedDate,
  status,
  visitNote,
  followUpDetails,
  plannedDate
}) => {
    return (
    <div className="visit-report">
      <div className="visit-report__header">
        <VisitReportHeader date={dayjs(plannedDate).format('MM.DD.YYYY')} />
      </div>
      <div className="visit-report__status-panel">
        <StatusPanel
          remind={remind}
          reactionStatus={reaction}
          remindedDate={remindedDate}
          status={status}
        />
      </div>
      <div className="visit-report__comment">
        <ReportComment title="Visit note" text={visitNote} />
      </div>
      <div className="visit-report__comment">
        <ReportComment title="Follow up details" text={followUpDetails} />
      </div>
    </div>
  )
}
