import React, {Dispatch, FC, SetStateAction} from 'react';
import Box from '@mui/material/Box';
import {FilterHeader20} from '../../base/FilterHeader20';
import deleteRouteIcon from '../../../assets/images/deleteRouteLogo.svg';
import Typography from '@mui/material/Typography';
import {WhiteButton} from '../../HomePage/WhiteButton';
import {RedButton} from '../../HomePage/RedButton';
import {Modal} from '@mui/material';
import './styes.scss';

interface IDeleteItemRouteModalProps {
    isVisible: boolean,
    setIsVisible: Dispatch<SetStateAction<boolean>>,
    handleDelete: () => void;
}

export const DeleteItemRouteModal: FC<IDeleteItemRouteModalProps> = ({ isVisible, setIsVisible, handleDelete }) => {
    return (
        <Modal
            open={isVisible}
            onClose={() => setIsVisible(!isVisible)}
            sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        >
            <Box className={'modal-delete-route-item'}>
                <FilterHeader20
                    title="DELETING HOUSEHOLD"
                    disableUnderline
                    onClose={() => {
                        setIsVisible(!isVisible)
                    }}
                />
                <Box className={'modal-delete-route-item__body'}>
                    <img src={deleteRouteIcon} alt="route"/>
                    <Typography className={'modal-delete-visitor__title'}>Are you sure you want to remove this household from the route?</Typography>
                </Box>
                <Box className={'modal-delete-route-item__footer'}>
                    <WhiteButton title="Cancel" forModal onClick={() => setIsVisible(!isVisible)}/>
                    <RedButton title="Delete" forModal onClick={handleDelete}/>
                </Box>
            </Box>
        </Modal>
    )
}