import {ChangeEvent, FC, useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import loginImage from "../../../assets/images/loginImage.png";
import loginImageMobile from "../../../assets/images/loginImageMobile.png";
import blueChevron from "../../../assets/fonts/blueChevron24.svg";
import { LoginFormik } from "../../../pages/LoginPage/LoginPage";
import { Checkbox } from "../../base/Checkbox";
import { Button } from "../Button";
import { ChabadButton } from "../ChabadButton";
import { Input } from "../Input";
import "./styles.scss";
import { Box } from '@mui/material';
import { useTranslation } from '../../../hooks/useTranslation';

interface IProps {
  formik: LoginFormik;
  isDisabled: boolean;
}



export const Layout: FC<IProps> = ({ formik, isDisabled }) => {
  const { p } = useTranslation('first_page');
  const { p: pGeneral } = useTranslation('general');
  const handleEmail = (e: any) => {
    formik.setFieldValue("email", e.target.value);
  };

  const handlePassword = (e: any) => {
      formik.setFieldValue("password", e.target.value);
  }
  const handleCheck = (e: any) => {
      let check = +e.target.value ? 0 : 1
      formik.setFieldValue("remember", check);

  }

  return (
    <div className="layout-login-page">
      <Box 
        className="layout-login-page__container"
        sx={{
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box 
          className="layout-login-page__form-container"
          sx={{
            width: { xs: "100%", md: "50vw" },
            order: { xs: "2", md: "1" },
            padding: { xs: "25px 0", md: "125px 0" },
          }}
        >
          <Box
            className="layout-login-page__form-container-inner"
            sx={{
              width: { xs: "90%", md: "100%" },
            }}
          >
            <Link to={"/"}>
              <img
                src={blueChevron}
                alt="chevron"
                className="layout-login-page__chevron"
              />
            </Link>
            <form
              className="layout-login-page__form"
              noValidate
              autoComplete="new-password"
            >
              <Box
                className="layout-login-page__form-title"
                sx={{
                  fontSize: { xs: "22px", md: "32px" },
                  textAlign: { xs: "left", md: "center" },
                }}
              >
                {p('lets_sign_in')}
              </Box>
              <Box
                className="layout-login-page__form-email"
                sx={{
                  marginBottom: { xs: "15px", md: "40px" },
                }}
              >
                <Input
                  label={pGeneral('email')}
                  placeholder={pGeneral('email')}
                  value={formik.values.email }
                  // value={localStorage.getItem('email') ? localStorage.getItem('email') :  formik.values.email }
                  error={formik.touched['email'] && formik?.errors?.email}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    formik.setTouched({ ...formik.touched, email: true })
                    handleEmail(event)
                  }}
                />
              </Box>
              <Box
                className="layout-login-page__form-password"
                sx={{
                  marginBottom: { xs: "15px", md: "40px" },
                }}
              >
                <Input
                  label={pGeneral('password')}
                  placeholder={pGeneral('password')}
                  value={formik.values.password}
                  error={formik.touched['password'] && formik?.errors?.password}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    formik.setTouched({ ...formik.touched, password: true })
                    handlePassword(event)
                  }}
                />
              </Box>
              <div className="layout-login-page__checkbox-container">
                <Checkbox value={String(formik.values.remember)}
                          checked={!!formik.values.remember}
                          label={p('remember_me')}
                          onCheck={(event) => {
                    handleCheck(event);
                }} />
                <Link to={"/forgot-password"} className="layout-login-page__forgot">
                  {p('forgot_password_2')}
                </Link>
              </div>
              <div className="layout-login-page__button">
                <Button
                  disabled={isDisabled}
                  title={p('sign_in')}
                  onClick={()=>{
                      formik.handleSubmit();
                  }}
                />
              </div>
              <div className="layout-login-page__line-container">
                <div className="layout-login-page__line" />
              </div>
              <div className="layout-login-page__or-subtitle">{p('or')}</div>
              <Box
                className="layout-login-page__chabad-button"
                sx={{
                  marginBottom: '30px',
                }}
               >
                <ChabadButton />
              </Box>
              <div className="layout-login-page__subtitle">
                {p('or')}{" "}
                <Link
                  to={"/registration"}
                  className="layout-login-page__subtitle-link"
                >
                  {p('create_account')} 
                </Link>
              </div>
            </form>
          </Box>
        </Box>
        <Box 
          className="layout-login-page__image-container"
          sx={{
            order: { xs: "1", md: "2" },
            height: { xs: "17vh", md: "100vh" },
            backgroundImage: { xs: `url(${loginImageMobile})`, md: `url(${loginImage})` },
            backgroundPosition: { xs: "bottom", md: "top" },
          }}
        />
      </Box>
    </div>
  );
};
