import React, {Dispatch, FC, SetStateAction, useMemo, useState} from 'react';
import { BlueButton } from '../BlueButton';
import { Button } from '../../ContactsPage/Button';
import { Notification, Person} from '../../../types/global/types';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { UpdateAddedPersonNotification } from '../../Notifications/UpdateAddedPersonNotification';
import * as Yup from 'yup';
import { personSchema } from '../../../validationSchemas/personSchema';
import {generalActions} from '../../../backend/store/reducers/generalReducer';
import {
    confirmUpdateAddedPersonNotification
} from '../../../backend/store/actions/creators/confirmUpdateAddedPersonNotification';
import {updateAddedPersonNotification} from '../../../backend/store/actions/creators/updateAddedPersonNotification';

interface INewContactNotificationProps {
    notification: Notification,
    setSelectedNotification: Dispatch<SetStateAction<Notification | null>>
}

export const NewContactNotification: FC<INewContactNotificationProps> = ({ notification, setSelectedNotification }) => {
    const dispatch = useDispatch();
    const storedSalutations = useSelector(
        (state: any) => state.personsStore.salutations
    )

    const storedAddressesTypes = useSelector(
        (state: any) => state.addressesStore.types
    )

    const salutations = useMemo(
        () => storedSalutations || [],
        [storedSalutations]
    )
    const addressesTypes = useMemo(
        () => storedAddressesTypes || [],
        [storedAddressesTypes]
    )

    const formik = useFormik<{ new_persons: Person[] }>({
        initialValues: { new_persons: notification.newPersons },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            new_persons: Yup.array().of(personSchema)
        }),
        onSubmit: (values) => {
            dispatch(updateAddedPersonNotification({
                id: notification.id,
                data: values.new_persons,
                onSuccess: () => {
                    generalActions.successAC({ message: "Success", type: "success" })
                    setIsVisibleModal(false)
                },
                onError: () => {
                    generalActions.successAC({ message: "Something went wrong", type: "error" })
                }
            }))
        }
    });

    const handleConfirm = () => {
        dispatch(confirmUpdateAddedPersonNotification({
            id: notification.id,
            data: formik.values,
            onSuccess: () => {
                generalActions.successAC({ message: "Success", type: "success" });
                setSelectedNotification(null);
            },
            onError: () => {
                generalActions.successAC({ message: "Something went wrong", type: "error" })
            }
        }))
    }


    const [isVisibleModal, setIsVisibleModal] = useState(false);
    return (
        <div className="notification"
        >
            <div className="notification__header">
                <div className="notification__title">
                    <h3>
                        {notification.title.startOfTitle}
                        {notification.title.householdName}
                        {notification.title.endOfTitle}
                    </h3>
                    <div className="notification__time">
                        {notification.created_at_formatted}
                    </div>
                </div>
            </div>

            <div className="notification__info">
                You can view information and change information
            </div>
            <div className="notification__info-button">
                <BlueButton title="View changed information" onClick={() => setIsVisibleModal(prevState => !prevState)} />
            </div>
            <div className="notification__button">
                <Button title="Confirm Changes" onClick={handleConfirm} />
            </div>
            <UpdateAddedPersonNotification
                isVisible={isVisibleModal}
                setIsVisible={() => {
                    setIsVisibleModal(prevState => !prevState)
                }}
                formik={formik}
                addressesTypes={addressesTypes}
                salutations={salutations}
            />
        </div>
    )
}