import dayjs, { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import vectorMini from "../../../assets/fonts/vectorMini.svg";
import { FC, useEffect, useState } from "react";
import "./Calendar.scss";
import { useTranslation } from "../../../hooks/useTranslation";

type Props = {
  value: string
  handleDate: (value: string) => void,
  isRequired?: boolean
 
  // setFormattedValue?:(value: string) => void,
}

export const Calendar: FC<Props> = ({ value, handleDate, isRequired, }) => {
  const { p } = useTranslation('contact_info');
  // let valueChenge= value
  // .replace(/\./g, "-")
  // .split("-")
  // .join("-")
  // let placeValue = value.split("-")
  const [touched, setTouched] = useState(false);

  let valueChenge = value;
  let formattedValue= null;
  if(valueChenge?.[4]==="-"){
    const dateMdY= valueChenge.split("-");
    valueChenge = dateMdY[2] + "." + dateMdY[1] + "." + dateMdY[0];
     formattedValue = dayjs(valueChenge, "DD.MM.YYYY");

  }

  if(touched){
   const dateMdY= value.split("-");
    valueChenge = dateMdY[1] + '.' + dateMdY[2] + '.' + dateMdY[0];
    formattedValue = dayjs(valueChenge, 'MM.DD.YYYY')
  }


  const [time, setTime] = useState<Dayjs | null >(formattedValue);
  const [isOpen, setIsOpen] = useState(false);

  const getMonthNumber = (date: dayjs.Dayjs | null) => {
    if (date) {
      let i = date?.month() + 1;
      return i < 10 ? "0" + i : i;
    }
    return "";
  };

  const getMonthDay = (date: dayjs.Dayjs | null) => {
    if (date) {
      let i = date?.date();
      return i < 10 ? "0" + i : i;
    }

    return "";
  };
  const handleChange = (date: dayjs.Dayjs | null) => {
    setTime(date);
    const formattedDate =
        date?.year() + "-" + getMonthNumber(date) + "-" + getMonthDay(date) ;
        // getMonthDay(date) + "-" + getMonthNumber(date) + '-' + date?.year() ;
          // getMonthDay(time) +"-"+getMonthNumber(time) +"-"+ date?.year() ;
    handleDate(formattedDate);
    setIsOpen(!isOpen);
  };

  return (
    <div className="calendar">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="calendar__input-container">
          <div className="calendar__input" onClick={() => setIsOpen(!isOpen)}>
            <div className="calendar__input-label">{p('date')}{isRequired && <span style={{color: "red"}}>*</span>}</div>
            <div className="calendar__input-placeholder">{value ? valueChenge: "Select Date"}</div>
            <div
              className="calendar__input-arrow"
              style={{
                transform: isOpen ? "rotate(180deg)" : "",
              }}
            >
              <img src={vectorMini} alt="arrow" />
            </div>
          </div>
        </div>
        <div
          className="calendar__desktop"
          style={{
            display: isOpen ? "block" : "none",
          }}
        >
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={time}
            // inputFormat={"MM-DD-YYYY"}
            onChange={(newValue) => {
              handleChange(newValue);
              setTouched(true)
            }}
            renderInput={(params) => <TextField  {...params}   value={dayjs(time).format("MM-DD-YYYY")}/>}
            dayOfWeekFormatter={(day) => `${day}`}
          />
        </div>
      </LocalizationProvider>
    </div>
  );
};
