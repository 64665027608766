import { FC } from "react"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import "./styles.scss"

type Props = {
  label: string
  value: string | undefined
  firstEndIcon?: string
  secondEndIcon?: string
  type: string
  index: string
  onChange: (value: string, type: string, index: string) => void;
  isRequired?: boolean;
  isError?: boolean
}

export const PhoneNumberInput: FC<Props> = ({
  label,
  value,
  firstEndIcon,
  secondEndIcon,
  type,
  index,
  onChange,
  isRequired,
  isError
}) => {
  return (
    <div className="visit-report-phone-number-input__input-container">
        <label className="visit-report-phone-number-input__label">{label}{isRequired && <span style={{ color: "red" }}>*</span>}</label>
      <div className="visit-report-phone-number-input__end-icon-container">
        {firstEndIcon && (
          <label className="visit-report-phone-number-input__end-icon">
            <img src={firstEndIcon} alt="end-icon" />
          </label>
        )}
        {secondEndIcon && (
          <label className="visit-report-phone-number-input__end-icon">
            <img src={secondEndIcon} alt="end-icon" />
          </label>
        )}
      </div>
      <PhoneInput
        inputClass={`visit-report-phone-number-input__input-class ${isError ? "error" : ""}`}
        containerClass={"visit-report-phone-number-input__container-class"}
        buttonClass={`visit-report-phone-number-input__button-class ${isError ? "error" : ""}`}
        country={"us"}
        value={value}
        // renderStringAsFlag="asd"
        onChange={(value) => onChange(value, type, index)}
        // disableDropdown
      />
    </div>
  )
}
