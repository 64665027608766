import { call, put, takeEvery } from "redux-saga/effects"
import { IResponse, Route } from "../../types/global/types"
import {
  CreateRoute,
  DeleteRoute,
  GetRoute,
  GetRoutes,
  UpdateRoute,
} from "../../types/sagas"
import { updateToken } from "../../utils/updateToken"
import { routesAPI } from "../api/api"
import { generalActions } from "../store/reducers/generalReducer"
import {
  actions,
  actionTypes,
  routesTriggerActions,
} from "../store/reducers/routesReducer"

function* getRoutes(payload: GetRoutes) {
  try {
    const response: IResponse<Route> = yield routesAPI.getRoutes({ ...payload })
    if (response.status === 200) {
      updateToken()
      yield put(actions.getRoutesAC(response.data))
    }
  } catch (error) {
    console.log(error)
  }
}

function* getRoute(payload: GetRoute) {
  try {
    if (payload.id) {
      const response: IResponse<Route> = yield routesAPI.getRoute({
        ...payload,
      })
      if (response.status === 200) {
        updateToken()
        yield put(actions.getRouteAC(response.data.data))
      }
    } else {
      yield put(actions.getRouteAC(null))
    }
  } catch (error) {
    console.log(error)
  }
}

function* createRoute(payload: CreateRoute) {
  try {
    const response: { status: number; data: {} } = yield routesAPI.createRoute(
      payload.data
    )
    if (response.status === 201) {
      updateToken()
      yield put(
        generalActions.successAC({ message: "Created", type: "success" })
      )
    }
  } catch (error: any) {
    yield put(
      generalActions.errorAC({
        message: error.response.data.message,
        type: "error",
      })
    )
  }
}

function* updateRoute(payload: UpdateRoute) {
  try {
    const response: { status: number } = yield routesAPI.updateRoute({
      ...payload,
    })
    if (response.status === 200) {
      console.log(response)
      updateToken()
      yield call(
        getRoute,
        routesTriggerActions.getRouteTriggerAC({
          id: payload.id,
          household: true,
          address: true,
        })
      )
      yield put(
        generalActions.successAC({ message: "Updated", type: "success" })
      )
    }
  } catch (error: any) {
    yield put(
      generalActions.errorAC({
        message: error.response.data.message,
        type: "error",
      })
    )
  }
}

function* deleteRoute(payload: DeleteRoute) {
  try {
    const response: IResponse<Route> = yield routesAPI.deleteRoute(payload.id)
    if (response.status === 200) {
      updateToken()
      yield put(actions.getRoutesAC({ data: null }))
      yield call(
        getRoutes,
        routesTriggerActions.getRoutesTriggerAC({
          address: true,
          household: true,
        })
      )
      yield put(
        generalActions.successAC({ message: "Deleted", type: "success" })
      )
    }
  } catch (error: any) {
    yield put(
      generalActions.errorAC({
        message: error.response.data.message,
        type: "error",
      })
    )
  }
}

export function* routesWatcher() {
  yield takeEvery(actionTypes.GET_ROUTES_TRIGGER, getRoutes)
  yield takeEvery(actionTypes.GET_ROUTE_TRIGGER, getRoute)
  yield takeEvery(actionTypes.CREATE_ROUTE_TRIGGER, createRoute)
  yield takeEvery(actionTypes.UPDATE_ROUTE_TRIGGER, updateRoute)
  yield takeEvery(actionTypes.DELETE_ROUTE_TRIGGER, deleteRoute)
}
