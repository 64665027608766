import React, { Dispatch, FC, SetStateAction } from 'react';
import {Modal} from '@mui/material';
import Box from '@mui/material/Box';
import './styles.scss';
import Typography from '@mui/material/Typography';
import { FilterHeader20 } from '../../base/FilterHeader20';
import { RedButton } from '../../HomePage/RedButton';
import { WhiteButton } from '../../HomePage/WhiteButton';
import deleteRouteLogo from "../../../assets/images/deleteRouteLogo.svg";
import {useTranslation} from "../../../hooks/useTranslation";

interface IDeleteVisitorModalProps {
    isVisible: boolean;
    setIsVisible: Dispatch<SetStateAction<boolean>>,
    handleDelete: () => void
}

export const DeleteVisitorModal: FC<IDeleteVisitorModalProps> = ({ isVisible, setIsVisible, handleDelete }) => {
    const { p, t, i18n } = useTranslation('my_team');

    return (
        <Modal
            open={isVisible}
            onClose={() => setIsVisible(!isVisible)}
            sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        >
            <Box className={'modal-delete-visitor'}>
                <FilterHeader20
                    title={p('remove_visitor')}
                    disableUnderline
                    onClose={() => {
                        setIsVisible(!isVisible)
                    }}
                />
                <Box className={'modal-delete-visitor__body'}>
                    <img src={deleteRouteLogo} alt="route"/>
                    <Typography className={'modal-delete-visitor__title'}>{p('delete_modal')}</Typography>
                </Box>
                <Box className={'modal-delete-visitor__footer'}>
                    <WhiteButton title="Cancel" forModal onClick={() => setIsVisible(!isVisible)}/>
                    <RedButton title="Delete" forModal onClick={handleDelete}/>
                </Box>
            </Box>
        </Modal>
    );
}