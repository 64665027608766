import { FC } from "react"
import closeIconFont from "../../../assets/fonts/close.svg"
import chevron from "../../../assets/fonts/chevron.svg"
import { Checkbox } from "../Checkbox"
import "./styles.scss"

type Props = {
  title: string
  checked: boolean
  checkboxValue?: string
  onClick: () => void
  onCheck: (e: any) => void
}

export const SectionHeaderCheckbox: FC<Props> = ({
  title,
  checked,
  checkboxValue,
  onClick,
  onCheck,
}) => {
  return (
    <div className="section-header-checkbox" onClick={onClick}>
      <div className="section-header-checkbox__container">
        <div className="section-header-checkbox__title">
          <Checkbox value={checkboxValue} checked={checked} onCheck={onCheck} />
          {title}
        </div>
      </div>
    </div>
  )
}
