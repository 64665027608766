import "./SideBar.scss";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { CustomSideTab } from "./CustomSideTab/CustomSideTab";
import logo from "../../../assets/images/logo.png";
import logoSmall from "../../../assets/images/logo-small.svg";
import homeFont from "../../../assets/images/homeInActive.svg";
import contactsListFont from "../../../assets/images/HouseholdsIcon.svg";
import reportsFont from "../../../assets/images/TeamIcon.svg";
import routesFont from "../../../assets/images/RoutesIcon.svg";
import tutorialsFonts from "../../../assets/fonts/tutorials.svg";
import contactsFont from "../../../assets/fonts/contacts.svg";
import { NavLink } from "react-router-dom";
import { useEffect, useMemo, useState, useContext } from "react";
import {
  actions,
  notificationsTriggerActions,
} from "../../../backend/store/reducers/notificationsReducer";
import Pusher from "pusher-js";
import { useDispatch, useSelector } from "react-redux";
import { ECHO } from "../../../index";
import { UserPermissionsEnum } from "../../../enums/userPermissionsEnum";
import { useAddNotification } from "../../../hooks/useAddNotification";
import { useTranslation } from "../../../hooks/useTranslation";
import { LanguageSelect } from "../../../components/LanguageSelect";
import { markAsRead } from "../../../backend/store/actions/creators/notification";
import { Box } from "@mui/material";
import { routes } from "../../../constants/sideBarRoutes";
import { SelectWorkspace } from "../SelectWorkspace/index";
import { UserContext } from "../../../contexts/UserContext";

import { string } from "yargs";
import { SwitchWorkspaceModal } from "../SwitchWorkspaceModal";
import { changeUserLanguage } from "../../../backend/store/actions/creators/changeUserLanguage";
import { getUser } from "../../../backend/store/actions/creators/getUser";

interface IworkspacesUser {
  id: number;
  name: string;
}
export const SideBar = () => {
  const { p, t, i18n } = useTranslation('sidebar');
  const [locale, setLocale] = useState(i18n.language);
  const dispatch = useDispatch();
  const storedUser = useSelector((state: any) => state.authStore.currentUser);
  const currentUser = useMemo(() => storedUser, [storedUser]);
  const addNotification = useAddNotification();
  const [valueSelect, setHandleSelect] = useState("");
  const [workspacesUser, setWorkspacesUser] = useState([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!currentUser) return;
    setWorkspacesUser(
      currentUser.workspaces.map((workspace: { name: string; id: number }) => ({
        name: workspace.name,
        id: workspace.id,
      }))
    );

    let myValue: string = "";
    currentUser.workspaces.forEach((element: any) => {
      if (element.id === currentUser.currentWorkspace) {
        myValue = element.name;
        return;
      }
    });
    setHandleSelect(myValue);

    const pusher = new Pusher("8f8fff1f6e6c4b6f2d0e", {
      cluster: "mt1",
      forceTLS: true,
      userAuthentication: {
        endpoint: "/broadcasting/auth",
        transport: "ajax",
        params: {},
        headers: {
          Accept: "application/json",
        },
      },
      channelAuthorization: {
        headers: {
          Accept: "application/json",
        },
        endpoint: "/broadcasting/auth",
        transport: "ajax",
      },
    });

    const privateChannel = pusher.subscribe(`private-admin.${currentUser.id}`);

    privateChannel.bind(
      "App\\Events\\BroadcastNotificationToAdminEvent",
      (data: any) => { }
    );

    ECHO.private(`admin.${currentUser.id}`).subscribed((e: any) => {
      console.log(e);
    });
    // .listen('AdminAlertEvent',(notification:any) => {
    //   console.log('here', notification);
    // });

    ECHO.private(`admin.${currentUser.id}`).listen(
      "BroadcastNotificationToAdminEvent",
      (notification: any) => {
        dispatch(notificationsTriggerActions.getNotificationsCountTriggerAC());
        addNotification({
          time: (notification.notification as any).created_at_formatted,
          description: notification.notification.title.startOfTitle,
          additionalDescription: notification.notification.title.endOfTitle,
          userName: notification.notification.title.householdName,
          id: notification.notification.id,
          onClick: (id: string) => {
            dispatch(actions.setSelectedNotification(id));
            dispatch(actions.setVisibilityNotificationModal(true));
            dispatch(
              markAsRead({
                payload: {
                  id: notification.notification.id,
                },
              })
            );
          },
        });
      }
    );
  }, [currentUser]);

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
    setLocale(language);
    dispatch(changeUserLanguage({
      language,
      onSuccess: () => {
        dispatch(getUser());
      },
    }))
  };

  return (
    <>
      {currentUser?.hasNewWorkspace && (
        <SwitchWorkspaceModal
          setHandleSelect={setHandleSelect}
          // currentUser={currentUser}
          workspacesUser={workspacesUser}
          valueSelect={""}
        />
      )}
      <Box className="sidebar" sx={{ display: { xs: "none", md: "block" } }}>
        <div className="sidebar__divider" />
        <div className="sidebar__tab-wrapper">
          <Typography className="sidebar__title-general">{p('general')}</Typography>
          <List className="sidebar__ul-general">
            {routes
              .filter((item) => item.group === "general")
              .map((element, index) => {
                if (element.route === "duchot" || element.route === "my_team") {
                  if (!user?.permissions[UserPermissionsEnum.readInvitations]) {
                    return
                  }
                }
                return (<NavLink
                  to={element.link}
                  key={index}
                  style={{ textDecoration: "none" }}
                >
                  {({ isActive }) => (
                    <CustomSideTab
                      icon={element.icon}
                      text={element.route}
                      isActive={isActive}
                    />
                  )}
                </NavLink>)
              }
              )
            }
          </List>
          <Typography className="sidebar__title-about">{p('about_us')}</Typography>
          <List className="sidebar__ul-about">
            {routes
              .filter((item) => item.group === "about")
              .map((element, index) => (
                <NavLink
                  to={element.link}
                  key={index}
                  style={{ textDecoration: "none" }}
                >
                  {({ isActive }) => (
                    <CustomSideTab
                      icon={element.icon}
                      text={element.route}
                      isActive={isActive}
                    />
                  )}
                </NavLink>
              ))}
          </List>
          <LanguageSelect language={locale} onChange={handleLanguageChange} />

          <SelectWorkspace
            label={""}
            list={workspacesUser} // норм
            // list={[
            //   //test
            //   { name: "Workspace name1", id: 1 },
            //   { name: "Workspace name2", id: 2 },
            //   { name: "Workspace name3", id: 3 },
            // ]}
            value={valueSelect}
            placeholder={"Workspace name"}
            hasMultipleWorkspaces={
              currentUser?.hasMultipleWorkspaces ||
              workspacesUser.length > 1 ||
              false
            } // норм
            // hasMultipleWorkspaces={true} //test
            handleSelect={setHandleSelect} // label="Salutation"
            onChangeClose
          //
          // list={salutationOptions}
          // placeholder="Select"
          // value={formik.values.persons[personIndex].salutation}
          // handleSelect={getSalutation}
          />
        </div>
      </Box>
    </>
  );
};
