import { Modal } from "@mui/material";
import { FC } from "react";
import { FilterHeader20 } from "../../base/FilterHeader20";
import { BlackButton } from "../BlackButton";
import { SaveOrOptimizeChildren } from "../SaveOrOptimizeChildren";
import { WhiteButton } from "../WhiteButton";
import routeLogo from "../../../assets/images/routeLogo.svg";
import "./styles.scss";
import { useTranslation } from "../../../hooks/useTranslation";

type Props = {
  openSaveModal: boolean;
  setOpenSaveModal: (value: boolean) => void;
  setOpenSaveNameModal: (value: boolean) => void;
  setOptimize: (value: boolean) => void;
};

export const SaveRouteModal: FC<Props> = ({
  openSaveModal,
  setOpenSaveModal,
  setOpenSaveNameModal,
  setOptimize,
}) => {
  const handleConfirm = (type: "optimize" | "save") => {
    type == "optimize" ? setOptimize(true) : setOptimize(false);
    setOpenSaveModal(false);
    setOpenSaveNameModal(true);
  };
  const { p } = useTranslation('map');
  const { p: pGeneral } = useTranslation('general');

  return (
    <Modal
      disableAutoFocus
      disableRestoreFocus
      disableEnforceFocus
      open={openSaveModal}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
    >
      <div className="home-page-save-route-modal">
        <FilterHeader20
          title={p('save_route')}
          disableUnderline
          onClose={() => {
            setOpenSaveModal(false);
            setOptimize(false);
          }}
        />
        <div className="home-page-save-route-modal__container">
          <SaveOrOptimizeChildren
            title={p('optimize_this_route')}
            image={routeLogo}
          />
        </div>
        <div className="home-page-save-route-modal__footer">
          <WhiteButton
            title={pGeneral('save')}
            forModal
            onClick={() => handleConfirm("save")}
          />
          <BlackButton
            title={p('optimize_and_save')}
            forModal
            onClick={() => handleConfirm("optimize")}
          />
        </div>
      </div>
    </Modal>
  );
};
