import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { authTriggerActions } from "../../../backend/store/reducers/authReducer";
import { Error404Page } from "../../../pages/404/404";
import { ContactsPage } from "../../../pages/ContactsPage";
import { TeamPage } from "../../../pages/TeamPage";
import { VisitPage } from "../../../pages/VisitPage";
import { CreateHouseholdPage } from "../../../pages/CreateHouseholdPage";
import { VisitReportPage } from "../../../pages/VisitReportPage";
import { HomePage } from "../../../pages/HomePage/HomePage";
import { LoginPage } from "../../../pages/LoginPage/LoginPage";
import { RegistrationPage } from "../../../pages/RegistrationPage/RegistrationPage";
import { UpdateCategoriesPage } from "../../../pages/UpdateCategoriesPage";
import UpdateHouseholdInfoPage from "../../../pages/UpdateHouseholdInfoPage";
import { HouseholdLayout } from "../../CreateHouseholdPage/HouseholdLayout";
import { AuthLayout } from "../AuthLayout/AuthLayout";
import { SidebarAndAppbarLayout } from "../SidebarAndAppbarLayout";
import { SavedRoutesPage } from "../../../pages/SavedRoutesPage";
import { SavedRoutesLayout } from "../../SavedRoutesPage/SavedRoutesLayout";
import { RoutePage } from "../../../pages/RoutePage";
import { TestPage } from "../../../pages";
import { PrivateRoute } from "../../../hocs/privateRoute";
import { useSelector } from "react-redux";
import { WelcomePage } from "../../../pages/WelcomePage";
import { useJsApiLoader } from "@react-google-maps/api";
import { Preloader } from "../Preloader/Preloader";
import { SnackbarMUI } from "../Snackbar";
import { getUser } from "../../../backend/store/actions/creators/getUser";
import { NotificationContext } from "../../../contexts/NotificationContext";
import { useNotifications } from "../../../hooks/useNotifications";
import { AlertNotifications } from "../../AlertNotifications";
import Cookies from "js-cookie";
import { UserContext } from "../../../contexts/UserContext";
import { ForgotPassword } from "../../../pages/ForgotPasswordPage";
import { ResetPasswordPage } from "../../../pages/ResetPasswordPage";
import { UnAuthPage } from "../../../pages/UnAuthPage";

const libraries: any = ["places", "drawing"];

export const App = () => {
  const dispatch = useDispatch();
  const { addNotification, notifications } = useNotifications();
  const isUser = localStorage.getItem("user");

  useEffect(() => {
    // getRoutific()
    if (!Cookies.get("XSRF-TOKEN")) {
      dispatch(authTriggerActions.getTokenTriggerAC());
    }
    // dispatch(householdsTriggerActions.getHouseholdsCategories());
  }, []);

  useEffect(() => {
    if (!isUser) return;
    dispatch(getUser());
  }, [isUser]);

  const navigate = useNavigate();
  const storedUser = useSelector((state: any) => state.authStore.user);
  const storedCurrentUser = useSelector(
    (state: any) => state.authStore.currentUser
  );
  const user = useMemo(() => storedUser, [storedUser]);
  const currentUser = useMemo(() => storedCurrentUser, [storedCurrentUser]);

  useEffect(() => {
    if (user) {
      navigate("/households");
    }
  }, [user]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
    libraries,
  });

  if (!isLoaded) {
    return <Preloader />;
  }

  return (
    <UserContext.Provider value={{ user: currentUser }}>
      <NotificationContext.Provider value={{ addNotification, notifications }}>
        <Routes>
          <Route path="test" element={<TestPage />} />
          <Route path="/" element={<WelcomePage />} />
          <Route
            path="/"
            element={
              user ? (
                user.isApproved ? (
                  <Navigate to={"/households"} />
                ) : (
                  <Navigate to={"/unAuthPage"} />
                )
              ) : (
                <AuthLayout />
              )
            }
          >
            <Route path="login" element={<LoginPage />} />
            <Route path="registration" element={<RegistrationPage />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route
              path="password-reset/:token"
              element={<ResetPasswordPage />}
            />
          </Route>

          <Route
            path="unAuthPage"
            element={
              <PrivateRoute>
                <UnAuthPage />
              </PrivateRoute>
            }
          />
          <Route path="/" element={<SidebarAndAppbarLayout />}>
            <Route
              path="home"
              element={
                <PrivateRoute>
                  <HomePage />
                </PrivateRoute>
              }
            />
            <Route
              path="households"
              element={
                <PrivateRoute>
                  <ContactsPage />
                </PrivateRoute>
              }
            />
            <Route
              path="duchot"
              element={
                <PrivateRoute>
                  <VisitPage />
                </PrivateRoute>
              }
            />

            <Route
              path="households/create"
              element={
                <PrivateRoute>
                  <CreateHouseholdPage />
                </PrivateRoute>
              }
            />
            <Route path="/" element={<HouseholdLayout />}>
              <Route
                path="households/update/household-info/:id"
                element={
                  <PrivateRoute>
                    <UpdateHouseholdInfoPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="households/update/categories/:id"
                element={
                  <PrivateRoute>
                    <UpdateCategoriesPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="households/update/history/:id"
                element={
                  <PrivateRoute>
                    <VisitReportPage />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route
              path="saved-routes"
              element={
                <PrivateRoute>
                  <SavedRoutesPage />
                </PrivateRoute>
              }
            />

            <Route
              path="my-team"
              element={
                <PrivateRoute>
                  <TeamPage />
                </PrivateRoute>
              }
            />
            <Route path="/" element={<SavedRoutesLayout />}>
              <Route
                path="saved-routes/:id"
                element={
                  <PrivateRoute>
                    <RoutePage />
                  </PrivateRoute>
                }
              />
            </Route>
          </Route>
          <Route path="*" element={<Error404Page />} />
        </Routes>
        <SnackbarMUI />
        <AlertNotifications notifications={notifications} />
      </NotificationContext.Provider>
    </UserContext.Provider>
  );
};
