import React, { FC, useEffect, useState } from "react";
import chevronRightFont from "../../../assets/fonts/chevron-right.svg";
import { SCHEDULER_OPTIONS } from "../../../constants/base";
import { RadioButton } from "../../base/RadioButton";
import { useTranslation } from "../../../hooks/useTranslation";

interface IScheduledCategoriesFilterProps {
  onChange: (value: string) => void;
  value: string;
  label: string;
}

export const ScheduledCategoriesFilter: FC<IScheduledCategoriesFilterProps> = ({
  onChange,
  value,
  label,
}) => {
  const [popapState, setPopapState] = useState<{ [key: string]: boolean }>({
    isGlobalOpen: false,
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest(".regular-categories-filter")) {
        setPopapState({ isGlobalOpen: false });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const { p } = useTranslation("contacts");
  return (
    <div className="regular-categories-filter" tabIndex={1}>
      <div
        className="regular-categories-filter__background"
        onClick={() => {
          setPopapState({
            isGlobalOpen: !popapState.isGlobalOpen,
          });
        }}
      >
        <div className="regular-categories-filter__background-title">
          {label}
        </div>
        <div className="regular-categories-filter__background-image">
          <img
            src={chevronRightFont}
            alt="chevron"
            style={{
              transform: popapState.isGlobalOpen
                ? "rotate(90deg)"
                : "rotate(270deg)",
            }}
          />
        </div>
      </div>
      <div
        className="regular-categories-filter__content-container"
        style={{
          display: popapState.isGlobalOpen ? "block" : "none",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="regular-categories-filter__content-title">
          {p("scheduled_categories").toUpperCase()}
        </div>
        <div
          className="regular-categories-filter__filters"
          style={{ marginTop: 15, paddingLeft: 12 }}
        >
          {SCHEDULER_OPTIONS.map((option) => {
            return (
              <ul key={option.value}>
                <li key={option.value}>
                  <div key={option.value}>
                    <div
                      className="home-section-body__checkbox"
                      key={option.value}
                    >
                      <RadioButton
                        label={option.label}
                        value={option.value}
                        name={option.value}
                        checked={option.value === value}
                        onClick={() => {
                          setPopapState({
                            isGlobalOpen: !popapState.isGlobalOpen,
                          });
                          onChange(option.value);
                        }}
                      />
                    </div>
                  </div>
                </li>
              </ul>
            );
          })}
        </div>
      </div>
    </div>
  );
};
