import categories from "../../../assets/fonts/categories.svg";
import deleteBasket from "../../../assets/fonts/deleteBasketRed.svg";
import history from "../../../assets/images/historyPrimary.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import "./styles.scss";
import { UpdateSidebarButton } from "../UpdateSidebarButton";
import { useDispatch, useSelector } from "react-redux";
import homeFont from "../../../assets/images/homeInActive.svg";
import { DeleteHouseholdModal } from "../../ContactsPage/DeleteHouseholdModal";
import { deleteHousehold } from "../../../backend/store/actions/creators/deleteHousehold";
import { SuccessModal } from "../../base/SuccessModal";
import { ErrorModal } from "../../base/ErrorModal";
import { UserPermissionsEnum } from "../../../enums/userPermissionsEnum";
import { UserContext } from "../../../contexts/UserContext";
import { useTranslation } from "../../../hooks/useTranslation";
import { personTriggerActions } from "../../../backend/store/reducers/personReducer";
import { addressTriggerActions } from "../../../backend/store/reducers/addressesReducer";
import { householdsTriggerActions } from "../../../backend/store/reducers/householdsReducer";
import { categoriesTriggerActions } from "../../../backend/store/reducers/categoriesReducer";
import { visitsTriggerActions } from "../../../backend/store/reducers/visitsReducers";
import { Preloader } from "../../base/Preloader/Preloader";

export const UpdateHouseholdSidebar = () => {
  const { p } = useTranslation("contact_info");
  const { p: pGeneral } = useTranslation("general");
  const { user } = useContext(UserContext);
  const dispatch = useDispatch();
  const [active, setActive] = useState("Contact info");
  const [isVisibleDeleteHouseHoldModal, setIsVisibleDeleteHouseholdModal] =
    useState(false);
  const [isSuccessDeleting, setIsSuccessDeleting] = useState(false);
  const [isErrorDeleting, setIsErrorDeleting] = useState(false);
  const { id } = useParams();
  const location = useLocation();

  const household = useSelector(
    (state: any) => state.householdsStore.household
  );

  const buttons = [
    {
      title: p("name"),
      path: `/households/update/household-info/${id}`,
      icon: homeFont,
    },
    {
      title: p("categories"),
      path: `/households/update/categories/${id}`,
      icon: categories,
    },
    {
      title: p("visit_history"),
      path: `/households/update/history/${id}`,
      icon: history,
    },
    {
      title: p("delete_household"),
      path: "",
      icon: deleteBasket,
      isDisabled: !user?.permissions[UserPermissionsEnum.deleteHouseholds],
    },
  ];

  const navigate = useNavigate();

  const handleClick = (path: string, title: string) => {
    if (!path)
      return setIsVisibleDeleteHouseholdModal((prevState) => !prevState);
    navigate(path);
    setActive(title);
  };

  const handleDelete = () => {
    if (!id) return;
    dispatch(
      deleteHousehold({
        data: +id,
        onSuccess: () => {
          setIsVisibleDeleteHouseholdModal((prevState) => !prevState);
          setIsSuccessDeleting((prevState) => !prevState);
          const timeoutId = setTimeout(() => {
            setIsSuccessDeleting(!isSuccessDeleting);
            navigate("/households");
            clearTimeout(timeoutId);
          }, 2000);
        },
        onError: () => {
          setIsVisibleDeleteHouseholdModal((prevState) => !prevState);
          setIsErrorDeleting((prevState) => !prevState);
          const timeoutId = setTimeout(() => {
            setIsErrorDeleting((prevState) => !prevState);
            navigate("/households");
            clearTimeout(timeoutId);
          }, 2000);
        },
      })
    );
  };

  useEffect(() => {
    const find = buttons.find((button) =>
      button.path.includes(location.pathname)
    );
    if (!find) return;
    setActive(find.title);
    dispatch(householdsTriggerActions.getHouseholdTriggerAC(Number(id)));
  }, []);
  const [loadedTabs, setLoadedTabs] = useState<
    { [key: string]: boolean } | undefined
  >();

  useEffect(() => {
    switch (active) {
      case "Contact info":
        if (!loadedTabs?.["Contact info"]) {
          console.log(loadedTabs);
          getInfoPage();
          setLoadedTabs((prevState) => ({
            ...prevState,
            "Contact info": true,
          }));
        }
        break;
      case "Categories":
        if (!loadedTabs?.["Categories"]) {
          getCategoryPage();
          setLoadedTabs((prevState) => ({ ...prevState, Categories: true }));
        }
        break;
      case "Visit history":
        if (!loadedTabs?.["Visit history"]) {
          getVisitHistory();
          setLoadedTabs((prevState) => ({
            ...prevState,
            "Visit history": true,
          }));
        }
        break;
      default:
        break;
    }
  }, [active]);

  const getInfoPage = useCallback(() => {
    dispatch(personTriggerActions.getSalutationsTriggerAC());
    dispatch(addressTriggerActions.getTypesTriggerAC());
  }, [dispatch, id]);

  const getCategoryPage = useCallback(() => {
    dispatch(categoriesTriggerActions.getCategoriesTriggerAC());
    dispatch(householdsTriggerActions.getPeriodicityTriggerAC());
  }, [dispatch, id]);

  const getVisitHistory = useCallback(() => {
    dispatch(visitsTriggerActions.getNextVisitTriggerAC(Number(id))); //TODO сделать этот запрос по нажатии на кнопку Add visit report
    dispatch(visitsTriggerActions.getStatusesTriggerAC());
    dispatch(visitsTriggerActions.getReactionsTriggerAC());
    dispatch(visitsTriggerActions.getAddressErrorTriggerAC());
  }, [dispatch, id]);

  return (
    <div className="update-household-layout__sidebar">
      <h2 className="update-household-layout__title">
        {household?.mainPersonFullName}
      </h2>
      {buttons.map((elem, index) => {
        return (
          <div className="update-household-layout_button-wrapper" key={index}>
            <UpdateSidebarButton
              icon={elem.icon}
              title={elem.title}
              active={active === elem.title}
              handleClick={() => handleClick(elem.path, elem.title)}
              isDisabled={elem.isDisabled}
            />
          </div>
        );
      })}

      <DeleteHouseholdModal
        handleDelete={handleDelete}
        isVisible={isVisibleDeleteHouseHoldModal}
        setIsVisible={setIsVisibleDeleteHouseholdModal}
      />
      <SuccessModal
        isVisible={isSuccessDeleting}
        setIsVisible={() => setIsSuccessDeleting(!isSuccessDeleting)}
        title={"Household deleted"}
      />
      <ErrorModal
        isVisible={isErrorDeleting}
        setIsVisible={() => setIsErrorDeleting(!isErrorDeleting)}
        title={
          "There was an error while deleting a household or performing an action."
        }
      />
    </div>
  );
};
