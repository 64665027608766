import React, {FC} from 'react';
import {Button, Menu, MenuItem} from '@mui/material';
import menu from '../../../assets/fonts/hor-menu.svg';
import {IRegularCategory} from '../../../interfaces/regularCategory';

interface IDropdownUpdateCategoryProps {
    options: {
        id: number,
        label: string,
        icon: any,
        alt: string,
        onClick: (category: IRegularCategory) => void,
        isDisabled?: boolean
    }[],
    category: IRegularCategory
}

export const DropdownUpdateCategory: FC<IDropdownUpdateCategoryProps> = ({options, category}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <Button
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{minWidth: 0}}
            >
                <div className={'saved-point__menu'}>
                    <img src={menu} alt="dots" />
                </div>
            </Button>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 5px 8px rgba(0,0,0,0.05))',
                        mt: 1.5,
                        color: "#0E0D5B",
                        fontFamily: "Poppins, sans-serif",
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 25,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {options.map((option: any) => (
                    <MenuItem
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        key={`${category.id}category.id`}
                        disabled={option.isDisabled}
                        onClick={() => option.onClick(category)}
                    >
                        <img src={option.icon} alt={option.alt} />{option.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}
