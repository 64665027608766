import i18n from '../res/languages';

export const GENDER_OPTIONS = [
  {
    label: 'A "him"',
    value: "male",
  },
  {
    label: 'A "her"',
    value: "female",
  },
  {
    label: 'A "him" only',
    value: "male_only",
  },
  {
    label: 'A "her" only',
    value: "female_only",
  },
];

export const STATUS_OPTIONS = [
  {
    label: 'Completed',
    value: "Completed",
  },
  {
    label: 'Deferred',
    value: "Deferred",
  },
];

// export const API_URL = "http://localhost:3000";
// export const API_URL = "https://telukt.calibrr.ai";
export const API_URL = process.env.REACT_APP_API_URL;

export const SCHEDULER_OPTIONS = [
  {
    label: "Bi-Monthly",
    value: "Bi-Monthly",
    key: "bi_monthly",
  },
  {
    label: "Monthly",
    value: "Monthly",
    key: "monthly",
  },
  {
    label: "Quarterly",
    value: "Quarterly",
    key: "quarterly",
  },
  {
    label: "Bi-Annually",
    value: "Bi-Annually",
    key: "bi_annually",
  },
  {
    label: "Annually",
    value: "Annually",
    key: "annually",
  },
  {
    label: "None",
    value: "None",
    key: "none",
  },
];

export const ERRORS = {
  short: "Too Short!",
  long: "Too Long!",
  required: "Required",
  email: "Error email",
  password: "Password does not match",
};


const fillTranslates = () => {
  SCHEDULER_OPTIONS.forEach(option => {
    option.label = i18n.t(`contacts.${option.key}`)
  })
  GENDER_OPTIONS.forEach(option => {
    option.label = i18n.t(`contacts.${option.value}`)
  })
}

i18n.on('languageChanged init',() => {
  fillTranslates();
});