import Modal from "@mui/joy/Modal"
import closeIconFont from "../../../assets/fonts/close.svg"
import { Button } from "../Button"
import "./ContactsModal.scss"

type Props = {
  isOpen: boolean
  confirmTitleButton: string
  cancelTitleButton: string
  title: string
  children: React.ReactNode
  onClose: (value: boolean) => void
  handleConfirm: () => void
}

export const ContactsModal: React.FC<Props> = ({
  isOpen,
  confirmTitleButton,
  cancelTitleButton,
  children,
  title,
  onClose,
  handleConfirm,
}) => {
  const handleClick = () => {
    handleConfirm()
    onClose(false)
  }

  return (
    <div className="contacts-modal">
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={isOpen}
        onClose={() => onClose(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <div className="contacts-modal__container">
          <div className="contacts-modal__title-container">
            <h3>{title.toUpperCase()}</h3>
            <div
              className="contacts-modal__img-container"
              onClick={() => onClose(false)}
            >
              <img src={closeIconFont} alt="close" />
            </div>
          </div>
          <div>{children}</div>
          <div className="contacts-modal__buttons-group">
            <Button
              title={cancelTitleButton}
              white
              onClick={() => onClose(false)}
            />
            <Button title={confirmTitleButton} onClick={handleClick} />
          </div>
        </div>
      </Modal>
    </div>
  )
}
