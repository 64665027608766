import {FC, useMemo, useState } from 'react'
import { Notification, Person } from '../../../types/global/types'
import { Button } from "../../ContactsPage/Button"
import { BlueButton } from "../BlueButton"
import "./styles.scss"
import {UpdatedPersonNotification} from '../../Notifications/UpdatedPersonNotification';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { personSchema } from '../../../validationSchemas/personSchema';
import {
  confirmUpdateUpdatedPersonNotification
} from '../../../backend/store/actions/creators/confirmUpdateUpdatedPersonsNotification';
import {generalActions} from '../../../backend/store/reducers/generalReducer';
import {updateUpdatedPersonNotification} from '../../../backend/store/actions/creators/updatePersonNotification';

type Props = {
  notification: Notification,
}

export const ContactInfoNotification: FC<Props> = ({
  notification,
}) => {
  const dispatch = useDispatch();

  const [isVisibleUpdatedInfoModal, setIsVisibleUpdatedInfoModal] = useState(false);

  const formik = useFormik<{ updated_persons: Person[] }>({
    initialValues: { updated_persons: notification.updatedPersons },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      updated_persons: Yup.array().of(personSchema)
    }),
    onSubmit: (values) => {
      dispatch(updateUpdatedPersonNotification({
        id: notification.id,
        data: values.updated_persons,
        onSuccess: () => {
          generalActions.successAC({ message: "Success", type: "success" });
          setIsVisibleUpdatedInfoModal(false);
        },
        onError: () => {
          generalActions.successAC({ message: "Something went wrong", type: "error" });
          setIsVisibleUpdatedInfoModal(false);
        }
      }))
    }
  });

  const storedSalutations = useSelector(
      (state: any) => state.personsStore.salutations
  )

  const storedAddressesTypes = useSelector(
      (state: any) => state.addressesStore.types
  )

  const salutations = useMemo(
      () => storedSalutations || [],
      [storedSalutations]
  )
  const addressesTypes = useMemo(
      () => storedAddressesTypes || [],
      [storedAddressesTypes]
  );

  const handleConfirm = () => {
    dispatch(confirmUpdateUpdatedPersonNotification({
      id: notification.id,
      data: formik.values.updated_persons,
      onSuccess: () => {
        generalActions.successAC({ message: "Success", type: "success" });
        // setIsVisibleUpdatedInfoModal(false);
      },
      onError: () => {
        generalActions.successAC({ message: "Something went wrong", type: "error" });
        // setIsVisibleUpdatedInfoModal(false);
      }
    }))
  }

  return (
    <div
      className="notification"
    >
      <div className="notification__header">
        <div className="notification__title">
          <h3>
            {notification.title.startOfTitle}
            {notification.title.householdName}
            {notification.title.endOfTitle}
          </h3>
          <div className="notification__time">
            {notification.created_at_formatted}
          </div>
        </div>
      </div>

      <div className="notification__info">
        You can view information and change information
      </div>
      <div className="notification__info-button">
        <BlueButton title="View changed information" onClick={() => setIsVisibleUpdatedInfoModal(prevState => !prevState)} />
      </div>
      <div className="notification__button">
        <Button title="Confirm Changes" onClick={handleConfirm} />
      </div>
      <UpdatedPersonNotification
          isVisible={isVisibleUpdatedInfoModal}
          setIsVisible={() => {
            setIsVisibleUpdatedInfoModal(prevState => !prevState)
          }}
          formik={formik}
          addressesTypes={addressesTypes}
          salutations={salutations}
      />
    </div>
  )
}
