import * as Yup from 'yup';
import { ERRORS } from '../constants/base';
import { emailRegex } from '../regex';
import { addressSchema } from './addressSchema';

export const personSchema = Yup.object().shape({
    salutation: Yup.string().required(ERRORS.required).min(1, ERRORS.required),
    first_name: Yup.string().required(ERRORS.required).min(1, ERRORS.required),
    last_name: Yup.string().required(ERRORS.required),
    gender: Yup.string().required(ERRORS.required),
    email: Yup.string().matches(emailRegex, ERRORS.email).nullable(),
    addresses: Yup.array().of(addressSchema),
    phones: Yup.array().required(ERRORS.required),
    "phones[0]": Yup.mixed().test(
        ERRORS.required,
        ERRORS.required,
        function () {
            return (
                this.parent &&
                this.parent.phones &&
                this.parent.phones[0] &&
                this.parent.phones[0].number
            );
        }
    ),
})