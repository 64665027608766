import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { addressTriggerActions } from "../../backend/store/reducers/addressesReducer";
import { personTriggerActions } from "../../backend/store/reducers/personReducer";
import { visitsTriggerActions } from "../../backend/store/reducers/visitsReducers";
import { FormikType, Visit } from "../../types/VisitReports/types";
import { VisitReportPageLayout } from "./VisitReportPageLayout";
import { householdsTriggerActions } from "../../backend/store/reducers/householdsReducer";
import { selectProps } from "../../utils/getSpecificProperties";
import { VISITS_ADDRESS } from "../../constants/CreateHousehold";
import { Household } from "../../types/global/types";
import { Preloader } from "../../components/base/Preloader/Preloader";
import "./styles.scss";
import { ERRORS } from "../../constants/base";
import * as Yup from "yup";
import { emailRegex } from "../../regex";
import { TMeta } from "../../types/types";
import { creationVisit } from "../../backend/store/actions/creators/creationVisit";
import dayjs from "dayjs";
import { useTranslation } from "../../hooks/useTranslation";

export const VisitReportPage = () => {
  const { p } = useTranslation("contact_info");
  const dispatch = useDispatch();
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);

  const [isVisibleSuccessModal, setIsVisibleSuccessModal] = useState(false);
  const [isVisibleErrorModal, setIsVisibleErrorModal] = useState(false);

  const handleFetchVisits = () => {
    dispatch(
      visitsTriggerActions.getVisitsTriggerAC(Number(id), currentPage, 0)
    );
  };

  useEffect(() => {
    dispatch(visitsTriggerActions.getVisitsTriggerAC(Number(id), currentPage, 1))
    handleFetchVisits();
  }, [currentPage]);

  const storedVisits = useSelector((state: any) => state.visitsStore.visits);
  const storedScheduledVisits = useSelector(
    (state: any) => state.visitsStore.scheduledVisits
  );

  const storedStatuses = useSelector(
    (state: any) => state.visitsStore.statuses
  );
  const storedReactions = useSelector(
    (state: any) => state.visitsStore.reactions
  );
  const storedAddressError = useSelector(
    (state: any) => state.visitsStore.addressError
  );

  const storedSalutations = useSelector(
    (state: any) => state.personsStore.salutations
  );

  const storedAddressesTypes = useSelector(
    (state: any) => state.addressesStore.types
  );

  const storedNextVisit: Visit = useSelector(
    (state: any) => state.visitsStore.nextVisit
  );

  const storedHousehold: Household = useSelector(
    (state: any) => state.householdsStore.household
  );

  const visits = useMemo(() => storedVisits?.data || [], [storedVisits]);

  const scheduledVisits = useMemo(
    () => storedScheduledVisits?.data || [],
    [storedScheduledVisits]
  );

  const visitsMeta = useMemo(() => storedVisits?.meta, [storedVisits]);

  const statuses = useMemo(() => storedStatuses || [], [storedStatuses]);
  const reactions = useMemo(() => storedReactions || [], [storedReactions]);
  const addressError = useMemo(
    () => storedAddressError || [],
    [storedAddressError]
  );

  const salutations = useMemo(
    () => storedSalutations || [],
    [storedSalutations]
  );
  const addressesTypes = useMemo(
    () => storedAddressesTypes || [],
    [storedAddressesTypes]
  );
  if (storedNextVisit) {
    storedNextVisit.my_categories = [];
    storedNextVisit.date_formatted = dayjs().format("YYYY-MM-DD");
  }
  const nextVisit = storedNextVisit || [];

  const household = useMemo(() => {
    if (storedHousehold) {
      storedHousehold.persons.forEach((elem: any, index) => {
        elem.addresses = storedHousehold.persons[index].addresses.map(
          selectProps(...Object.keys(VISITS_ADDRESS))
        );
      });
    }

    return storedHousehold || [];
  }, [storedHousehold]);

  const personSchema = Yup.object().shape({
    salutation: Yup.string().required(ERRORS.required).min(1, ERRORS.required),
    first_name: Yup.string().required(ERRORS.required).min(1, ERRORS.required),
    last_name: Yup.string().required(ERRORS.required),
    gender: Yup.string().required(ERRORS.required),
    email: Yup.string().matches(emailRegex, ERRORS.email).nullable(),
    addresses: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().required(ERRORS.required),
        fullAddress: Yup.string().required(ERRORS.required),
        // street_name: Yup.string().required(ERRORS.required),
      })
    ),
    phones: Yup.array().required(ERRORS.required),
    "phones[0]": Yup.mixed().test(
      ERRORS.required,
      ERRORS.required,
      function () {
        return (
          this.parent &&
          this.parent.phones &&
          this.parent.phones[0] &&
          this.parent.phones[0].number
        );
      }
    ),
  });

  const validationSchema = Yup.object().shape({
    status: Yup.string().required(ERRORS.required).nullable(),
    updated_persons: Yup.array().of(personSchema),
    new_persons: Yup.array().of(personSchema),
    new_address: Yup.object()
      .default(undefined)
      .shape({
        type: Yup.string().required(ERRORS.required),
        place_id: Yup.string().required(ERRORS.required),
      }),
  });

  const formik: FormikType = useFormik({
    enableReinitialize: true,
    initialValues: nextVisit,
    validationSchema,
    onSubmit: (data: any) => {
      data.checked_categories = data.my_categories;
      delete data.my_categories;

      data.updated_persons?.forEach((person: any) => {
        person.addresses = person.addresses.map(
          ({ fullAddress, ...rest }: any) => rest
        );
      });

      data.new_persons?.forEach((person: any) => {
        person.addresses = person.addresses.map(
          ({ fullAddress, ...rest }: any) => rest
        );
      });

      const modifiedData = {
        ...data,
        date_formatted: dayjs(data.date_formatted).format("DD.MM.YYYY"),
        new_persons: data.new_persons?.map((person: any) => {
          return {
            ...person,
            phones: person.phones.filter((phone: any) => !!phone.number),
          };
        }),
      };

      dispatch(
        creationVisit({
          data: modifiedData,
          id: modifiedData.id,
          onSuccess: () => {
            handleFetchVisits();
            setIsVisibleSuccessModal((prevState) => !prevState);
            const id = setTimeout(() => {
              setIsVisibleSuccessModal((prevState) => !prevState);
              clearTimeout(id);
            }, 2000);
            formik.resetForm();
          },
          onError: () => {
            const id = setTimeout(() => {
              setIsVisibleErrorModal((prevState) => !prevState);
              clearTimeout(id);
            }, 2000);
            formik.resetForm();
          },
        })
      );
    },
  });

  return (
    <VisitReportPageLayout
      formik={formik}
      visits={visits}
      scheduledVisits={scheduledVisits}
      statuses={statuses}
      reactions={reactions}
      addressError={addressError}
      household={household}
      salutations={salutations}
      addressesTypes={addressesTypes}
      paginationVisits={{
        currentPage,
        setCurrentPage,
        totalItemsCount: (visitsMeta as TMeta)?.total,
        pageSize: 5,
      }}
      successModalProps={{
        isVisible: isVisibleSuccessModal,
        setIsVisible: () => setIsVisibleSuccessModal((prevState) => !prevState),
        title: p("visit_report_created"),
      }}
      errorModalProps={{
        isVisible: isVisibleErrorModal,
        setIsVisible: () => setIsVisibleErrorModal((prevState) => !prevState),
        title:
          "There was an error while creating a visit report or performing an action.",
      }}
    />
  );
};
