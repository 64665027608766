import { Modal } from "@mui/material";
import { FC } from "react";
import { FilterHeader20 } from "../FilterHeader20";
import googleMapsIcon from "../../../assets/fonts/GoogleMaps.svg";
import AppleMapsIcon from "../../../assets/fonts/AppleMaps.svg";
import wazeIcon from "../../../assets/fonts/Waze.svg";
import "./styles.scss";
import { useTranslation } from "../../../hooks/useTranslation";

type Props = {
  googleLink?: string;
  appleLink?: string;
  wazeLink?: string;
  openNavModal: boolean;
  setOpenNavModal: (value: boolean) => void;
  setOptimize?: (value: boolean) => void;
};

export const NavMethodModal: FC<Props> = ({
  openNavModal,
  googleLink,
  appleLink,
  wazeLink,
  setOptimize,
  setOpenNavModal,
}) => {
  const { p } = useTranslation('map');
  const handleClose = () => {
    setOptimize && setOptimize(false);
    setOpenNavModal(false);
  };

  const handleGoogle = () => {
    setOpenNavModal(false);
  };
  const handleApple = () => {
    setOpenNavModal(false);
  };
  const handleWaze = () => {
    setOpenNavModal(false);
  };

  return (
    <Modal
      disableAutoFocus
      disableRestoreFocus
      disableEnforceFocus
      open={openNavModal}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
    >
      <div className="route-nav-method-modal">
        <FilterHeader20 title={p('navigation_method')} onClose={handleClose} />
        <div className="route-nav-method-modal__container">
          <a
            href={googleLink}
            target="_blank"
            className="route-nav-method-modal__item"
            onClick={handleGoogle}
          >
            <img
              src={googleMapsIcon}
              alt="google-maps"
              className="route-nav-method-modal__img"
            />
            Google Maps
          </a>
          {/* <a
            href={appleLink}
            target="_blank"
            className="route-nav-method-modal__item"
            onClick={handleApple}
          >
            <img
              src={AppleMapsIcon}
              alt="apple-maps"
              className="route-nav-method-modal__img"
            />
            Apple Maps
          </a> */}
          {/* <a
            href={wazeLink}
            target="_blank"
            className="route-nav-method-modal__item"
            onClick={handleWaze}
          >
            <img
              src={wazeIcon}
              alt="waze-maps"
              className="route-nav-method-modal__img"
            />
            Waze
          </a> */}
        </div>
      </div>
    </Modal>
  );
};
