import dayjs, { Dayjs } from "dayjs"
import TextField from "@mui/material/TextField"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker"
import vectorMini from "../../../assets/fonts/vectorMini.svg"
import {FC, useEffect, useState} from 'react'
import "./styles.scss"

type Props = {
  open: boolean
  setRemindedDate: (value: string) => void;
  isRequired?: boolean
  remindedDate: string
}

export const VisitReportCalendar: FC<Props> = ({ open, setRemindedDate, isRequired, remindedDate }) => {
  const [value, setValue] = useState<Dayjs | null>(dayjs())

  const handleDate = (value: Dayjs | null) => {
    setValue(value)
    if (value) {
      const time =
        value?.date() +
        "." +
        (value?.month() < 9
          ? 0 + "" + (value?.month() + 1)
          : value?.month() + 1) +
        "." +
        value?.year()
      setRemindedDate(time)
    }
  }

  useEffect(() => {
    setRemindedDate(dayjs(value).format('DD.MM.YYYY'))
  }, [])

  return (
    <div className="calendar">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="calendar__input-container">
          <div className="calendar__input">
            <div className="calendar__input-label">Date{isRequired && <span style={{color: "red"}}>*</span>}</div>
            <div className="calendar__input-placeholder">{dayjs(value).format('DD.MM.YYYY')}</div>
            <div
              className="calendar__input-arrow"
              style={{
                transform: open ? "rotate(180deg)" : "",
              }}
            >
              <img src={vectorMini} alt="arrow" />
            </div>
          </div>
        </div>
        <div
          className={`calendar__desktop ${
            !open ? "calendar__desktop_closed" : ""
          }`}
        >
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={value}
            onChange={(newValue) => {
              handleDate(newValue)
            }}
            renderInput={(params) => <TextField {...params} />}
            dayOfWeekFormatter={(day) => `${day}`}
          />
        </div>
      </LocalizationProvider>
    </div>
  )
}
