import { FC } from "react"
import { Checkbox } from "../../base/Checkbox"
import "./styles.scss"

type Props = {
  value: string[],
  onChange: (e: any) => void

  options: Object[]
}

export const SectionBody: FC<Props> = ({ options, onChange, value  }) => {
  const handleOptionClick = (changed: string) => ({ target: { checked } }: any) => {
    if (checked) {
      onChange([...value, changed]);
    } else {
      onChange(value.filter(v => String(v) !== String(changed)));
    }
  }

  return (
    <div className="home-section-body">
      {options.map(({value: curr, label} : any) => {
        return (
          <div className="home-section-body__checkbox" key={curr}>
            <Checkbox
              key={curr}
              label={label}
              checked={value.indexOf(String(curr)) !== -1}
              value={curr}
              onCheck={handleOptionClick(String(curr))}
            />
          </div>
        )
      })}
    </div>
  )
}
