import { FC } from "react"
import { useDispatch } from "react-redux";
import { Notification } from "../../../types/global/types"
import { Link } from "react-router-dom"
import { actions } from "../../../backend/store/reducers/notificationsReducer";
import "./styles.scss"

type Props = {
  notification: Notification,
}

export const ImportedAddressNotFoundNotification: FC<Props> = ({
  notification,
}) => {
  const dispatch = useDispatch();

  return (
    <div
      className="notification"
    >
      <div className="notification__header">
        <div className="notification__title">
          <h3>
            
            {notification.title.startOfTitle}
            <Link 
              to={`/households/update/household-info/${notification.household_id}?tab=info`} 
              onClick={() =>
                dispatch(actions.setVisibilityNotificationModal(false))
              }
            >
              {notification.title.linkText}
            </Link>
            {notification.title.endOfTitle}
          </h3>
        </div>
      </div>
    </div>
  )
}
