import { PersonPanel } from "../PersonPanel";
import user from "../../../assets/fonts/userGrey.svg";
import male from "../../../assets/fonts/male.svg";
import female from "../../../assets/fonts/female.svg";
import check from "../../../assets/fonts/vector.svg";
import { Select } from "../Select";
import { GenderButton } from "../GenderButton";
import { Checkbox } from "../../base/Checkbox";
import { Textarea } from "../Textarea";
import { FC } from "react";
import { FormikType } from "../../../types/VisitReports/types";
import "./styles.scss";
import {Input} from '../../base/Input';
import {FormErrorMessage} from '../../base/FormErrorMessage';
import {ErrorText} from '../../base/ErrorText';
import {FormikTouched} from 'formik';
import { useTranslation } from "../../../hooks/useTranslation";

type Props = {
  formik: FormikType;
  personIndex: number;
  salutations: string[];
  path: "updated_persons" | "new_persons";
  handleSalutation: (value: string) => void;
  setGender: (value: string) => void;
  handleFirstName: (e: any) => void;
  handleLastName: (e: any) => void;
  setIsJewish: (e: any) => void;
  setIsChild: (e: any) => void;
  handleTextarea: (e: any) => void;
};

export const PersonalInfo: FC<Props> = ({
  formik,
  personIndex,
  salutations,
  path,
  handleSalutation,
  setGender,
  handleFirstName,
  handleLastName,
  setIsJewish,
  setIsChild,
  handleTextarea,
}) => {
  const { p } = useTranslation('contact_info');
  const { p: pGeneral } = useTranslation('general');
  return (
    <div className="visit-report-personal-info">
      <div className="visit-report-personal-info__container">
        <div className="visit-report-personal-info__panel">
          <PersonPanel icon={user} title={p('personal_info')} />
        </div>
        <div className="visit-report-personal-info__select">
          <Select
            isRequired
            onChangeClose
            placeholder={p('select')}
            label={p('salutation')}
            value={formik?.values?.[path]?.[personIndex]?.salutation || ""}
            list={salutations}
            handleSelect={handleSalutation}
            isError={((formik.touched[path] as { [key: string]: boolean }[])?.[personIndex] as { [key: string]: boolean })?.salutation &&
                !!(formik.errors[path]?.[personIndex] as { [key: string]: string })?.salutation}
          />
          {((formik.touched[path] as { [key: string]: boolean }[])?.[personIndex] as { [key: string]: boolean })?.salutation && (formik.errors[path]?.[personIndex] as { [key: string]: string })?.salutation && (
              <FormErrorMessage message={(formik.errors[path]?.[personIndex] as { [key: string]: string})?.salutation} />
          )}
        </div>
        <div className="visit-report-personal-info__gender-button">
          <GenderButton
            endIcon={
              formik?.values?.[path]?.[personIndex]?.gender === "male" && check
            }
            icon={male}
            title={pGeneral('male')}
            value="male"
            setGender={setGender}
          />
          <GenderButton
            endIcon={
              formik?.values?.[path]?.[personIndex]?.gender === "female" &&
              check
            }
            icon={female}
            title={pGeneral('female')}
            value="female"
            setGender={setGender}
          />
        </div>
        <div className='visit-report-personal-info__error-message'>
          {((formik.touched[path] as { [key: string]: boolean }[])?.[personIndex] as { [key: string]: boolean })?.gender && (formik.errors[path]?.[personIndex] as { [key: string]: string })?.gender && (
              <FormErrorMessage message={(formik.errors[path]?.[personIndex] as { [key: string]: string})?.gender} />
          )}
        </div>
        <div className="visit-report-personal-info__input-first-name">
          <Input
            isRequired
            placeholder={p('write_your_name')}
            value={formik?.values?.[path]?.[personIndex]?.first_name || ''}
            label={pGeneral('first_name')}
            onChange={handleFirstName}
            isError={((formik.touched[path] as { [key: string]: boolean }[])?.[personIndex] as { [key: string]: boolean })?.first_name &&
                !!(formik.errors[path]?.[personIndex] as { [key: string]: string })?.first_name}
          />
          {((formik.touched[path] as { [key: string]: boolean }[])?.[personIndex] as { [key: string]: boolean })?.first_name && (formik.errors[path]?.[personIndex] as { [key: string]: string })?.first_name && (
              <FormErrorMessage message={(formik.errors[path]?.[personIndex] as { [key: string]: string})?.first_name} />
          )}
        </div>
        <div className="visit-report-personal-info__input-last-name">
          <Input
            isRequired
            placeholder={p('write_your_last_name')}
            value={formik?.values?.[path]?.[personIndex]?.last_name || ''}
            label={pGeneral('last_name')}
            onChange={handleLastName}
            isError={((formik.touched[path] as { [key: string]: boolean }[])?.[personIndex] as { [key: string]: boolean })?.last_name &&
                !!(formik.errors[path]?.[personIndex] as { [key: string]: string })?.last_name}
          />
          {((formik.touched[path] as { [key: string]: boolean }[])?.[personIndex] as { [key: string]: boolean })?.last_name && (formik.errors[path]?.[personIndex] as { [key: string]: string })?.last_name && (
              <FormErrorMessage message={(formik.errors[path]?.[personIndex] as { [key: string]: string})?.last_name} />
          )}
        </div>
        <div className="visit-report-personal-info__checkbox-container">
          <div className="visit-report-personal-info__checkbox">
            <Checkbox
              label={p('jewish')}
              checked={!!formik?.values?.[path]?.[personIndex]?.is_jewish}
              onCheck={setIsJewish}
            />
          </div>
          <div className="visit-report-personal-info__checkbox">
            <Checkbox
              label={p('child')}
              checked={!!formik?.values?.[path]?.[personIndex]?.is_child}
              onCheck={setIsChild}
            />
          </div>
        </div>
        <div className="visit-report-personal-info__textarea">
          <Textarea
            label={p('contact_info_note')}
            placeholder={p('write_some_note')}
            value={formik?.values?.[path]?.[personIndex]?.info_note}
            onChange={handleTextarea}
          />
        </div>
      </div>
    </div>
  );
};
