import {FC, useContext, useState} from 'react'
import menu from "../../../assets/fonts/hor-menu.svg"
import { useClickOutside } from "../../../hooks/useClickOutside"
import "./styles.scss"
import {UserContext} from '../../../contexts/UserContext';
import {UserPermissionsEnum} from '../../../enums/userPermissionsEnum';
import {useTranslation} from "../../../hooks/useTranslation";

type Props = {
  id: number
  handleStartRoute: (id: number) => void
  handleDeleteRoute: (id: number) => void
}

export const ListActions: FC<Props> = ({ id, handleStartRoute, handleDeleteRoute }) => {
    const { user } = useContext(UserContext);
    const { p, t, i18n } = useTranslation('saved_routes');
    const [open, setOpen] = useState(false)

  const handleBlur = () => {
    setOpen(false)
  }

  const ref = useClickOutside({ handler: handleBlur })

  return (
    <div className="saved-routes-list-actions" ref={ref}>
      <div
        className="saved-routes-list-actions__image-container"
        onClick={() => setOpen(!open)}
      >
        <img src={menu} alt="menu" className="saved-routes-list-actions__img" />
      </div>
      <div
        className={`saved-routes-list-actions__container ${
          !open ? "saved-routes-list-actions__container_closed" : ""
        }`}
      >
        <div className="saved-routes-list-actions__text" onClick={() => handleStartRoute(id)}>
            {p('start_route')}
        </div>
        <div
            className={`saved-routes-list-actions__text 
            ${!user?.permissions[UserPermissionsEnum.deleteRoutes] ? "isDisabled" : ""}`}
            onClick={() => handleDeleteRoute(id)}
        >
            {p('delete_route')}
        </div>
      </div>
    </div>
  )
}
