import chevronRight from "../../assets/fonts/chevron-right.svg";
import { ContactsList } from "../../components/ContactsPage/ContactsList/ContacstList";
import { Link, useNavigate } from "react-router-dom";
import { Paginator } from "../../components/ContactsPage/Paginator/Paginator";
import { IconButton } from "../../components/ContactsPage/IconButton";
import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  householdsActions,
  householdsTriggerActions,
} from "../../backend/store/reducers/householdsReducer";
import { useSelector } from "react-redux";
import { Preloader } from "../../components/base/Preloader/Preloader";
import { ImportChildren } from "../../components/ContactsPage/ContactsModal/childrens/ImportChildren";
import { ExportChildren } from "../../components/ContactsPage/ContactsModal/childrens/ExportChildren";
import { SortFilter } from "../../components/ContactsPage/SortFilter";
import { GlobalFilter } from "../../components/ContactsPage/GlobalFilter";
import { ContactsModal } from "../../components/ContactsPage/ContactsModal/ContactsModal";
import { Filters } from "../../components/HomePage/Filters";
import "./styles.scss";
import { DeleteHouseholdModal } from "../../components/ContactsPage/DeleteHouseholdModal";
import { useUrlQueryParams } from "../../hooks/useUrlQueryParams";
import { EmptyStateContactsPage } from "../../components/ContactsPage/EmptyStateContactsPage";
import { ManageCategoriesModal } from "../../components/ContactsPage/ManageCategoriesModal";
import { useUpdateRegularCategories } from "./hooks/useUpdateRegularCategories";
import { useGlobalSelectedContacts } from "./hooks/useGlobalSelectedContacts";
import { DeleteHouseholdsModal } from "../../components/ContactsPage/DeleteHouseholdsModal";
import { UpdateScheduledCategories } from "../../components/ContactsPage/UpdateScheduledCategories";
import { UpdateRegularCategories } from "../../components/ContactsPage/UpdateRegularCategories";
import { useSortHouseholds } from "./hooks/useSortHouseholds";
import { FiltersHousehold } from "../../enums/filtersHouseholdEnum";
import emptyStateIcon from "../../assets/images/EmptyStateContacts.svg";
import { Box } from "@mui/material";
import { useDeleteHousehold } from "./hooks/useDeleteHousehold";
import { SuccessModal } from "../../components/base/SuccessModal";
import { ErrorModal } from "../../components/base/ErrorModal";
import { exportContacts } from "../../backend/store/actions/creators/exportContacts";
import fileDownload from "js-file-download";
import { importContacts } from "../../backend/store/actions/creators/importContacts";
import { UserContext } from "../../contexts/UserContext";
import { UserPermissionsEnum } from "../../enums/userPermissionsEnum";
import { useTranslation } from "../../hooks/useTranslation";

export enum FiltersHouseholdsPage {
  currentPage = "currentPage",
  searchValue = "searchValue",
  perPage = "perPage",
  sortOrder = "sortOrder",
}

export const ContactsPage = () => {
  const { p } = useTranslation("contacts");
  const { p: pGeneral } = useTranslation("general");
  const { user } = useContext(UserContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setUrlQueryParam, deleteUrlQueryParam, getUrlQueryParam } =
    useUrlQueryParams();
  const {
    handleDelete,
    isSuccessDeletingHouseholdModalProps,
    setIsVisibleDeleteModal,
    isErrorDeletingHouseholdModalProps,
    isVisibleDeleteModal,
  } = useDeleteHousehold();

  const pageFromUrl = getUrlQueryParam(FiltersHouseholdsPage.currentPage);
  const searchValueFromUrl = getUrlQueryParam(
    FiltersHouseholdsPage.searchValue
  );
  const perPageFromUrl = getUrlQueryParam(FiltersHouseholdsPage.perPage);
  let scheduledFromUrl = getUrlQueryParam(FiltersHousehold.periodicity);
  const regularCategoriesFromUrl = getUrlQueryParam(
    FiltersHousehold.regular_categories
  );
  const genderFromURl = getUrlQueryParam(FiltersHousehold.gender);
  const dueVisitStartDateFromUrl = getUrlQueryParam(
    FiltersHousehold.due_visit_start_date
  );
  const deferredVisitStartDateFromUrl = getUrlQueryParam(
    FiltersHousehold.deferred_visit_start_date
  );

  const deferredVisitFromUrl = getUrlQueryParam(
    FiltersHousehold.deferred_visit
  );

  const notVisitFromUrl = getUrlQueryParam(
    FiltersHousehold.not_visit
  );
  
  const dataRangeStartDateFromUrl = getUrlQueryParam(
    FiltersHousehold.data_range_start_date
  );

  const dataRangeEndDateFromUrl = getUrlQueryParam(
    FiltersHousehold.data_range_end_date
  );

  const [file, setFile] = useState<File>();
  const [page, setCurrentPage] = useState<number>(
    (pageFromUrl && +pageFromUrl) || 1
  );
  const [openSortBy, setOpenSortBy] = useState(false);
  const [globalValue, setGlobalValue] = useState("");
  const [openGlobal, setOpenGlobal] = useState(false);
  const [searchValue, setSearchValue] = useState<string | null>(
    searchValueFromUrl || null
  );
  const [perPageValue, setPerPageValue] = useState(perPageFromUrl || "25");
  const [openPerPage, setOpenPerPage] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [householdId, setHouseHoldId] = useState<number | null>(null);

  const regularCategories = useUpdateRegularCategories();
  const { sortByValue, handleChangeSort } = useSortHouseholds();

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const categories = useSelector(
    (state: any) => state.householdsStore.categories?.data
  );

  const households = useSelector(
    (state: any) => state.householdsStore.households
  );

  const isLoading = useSelector(
    (state: any) => state.householdsStore.isLoading
  );

  const globalSelectedContacts = useGlobalSelectedContacts({ households });

  const handleSearch = (str: string | null): void => {
    setSearchValue(str);
    if (!str) return deleteUrlQueryParam(FiltersHouseholdsPage.searchValue);
    return setUrlQueryParam(FiltersHouseholdsPage.searchValue, str);
  };
  const getDataHouseHolds = () => {
    dispatch(
      householdsTriggerActions.getHouseholdsTriggerAC({
        page,
        perPage: perPageValue,
        mainPersonFullName: searchValue,
        sortOrder: sortByValue,
        sortBy: sortByValue === null ? undefined : "mainPersonFullName",
        search: {
          periodicity: scheduledFromUrl?.split(",") || undefined,
          persons_gender: genderFromURl || null,
          deferred_visit_start_date: deferredVisitStartDateFromUrl || undefined,
          due_visit_start_date: dueVisitStartDateFromUrl || undefined,
          categories: regularCategoriesFromUrl?.length
            ? regularCategoriesFromUrl?.split(",").map((id) => ({ id: id }))
            : undefined,
            data_range_start_date: dataRangeStartDateFromUrl || undefined,
            data_range_end_date: dataRangeEndDateFromUrl || undefined,
            deferred_visit: deferredVisitFromUrl,
            not_visit: notVisitFromUrl
        },
      })
    );
  };
  useEffect(() => {
    getDataHouseHolds();
    if (!categories) {
      dispatch(householdsTriggerActions.getHouseholdsCategories());
    }
  }, [sortByValue, page, perPageValue, sortByValue, searchValue]);

  useEffect(() => {
    if (!pageFromUrl) {
      setUrlQueryParam(FiltersHouseholdsPage.currentPage, "1");
    }
    if (!perPageFromUrl) {
      setUrlQueryParam(FiltersHouseholdsPage.perPage, "25");
    }
  }, []);

  const handleFile = (event: any) => {
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList) {
      setFile(fileList[0]);
    }
  };
  const [isSuccessExport, setIsSuccessExport] = useState(false);
  const [isErrorExport, setIsErrorExport] = useState(false);

  const [isSuccessImport, setIsSuccessImport] = useState(false);
  const [isErrorImport, setIsErrorImport] = useState(false);

  const handleConfirmModal = () => {
    if (globalValue === "Export Contacts") {
      dispatch(
        exportContacts({
          onSuccess: (data: any) => {
            fileDownload(data, "households.csv");
            setIsSuccessExport((prevState) => !prevState);
            const timeoutId = setTimeout(() => {
              setIsSuccessExport((prevState) => !prevState);
              clearTimeout(timeoutId);
            }, 2000);
          },
          onError: () => {
            setIsErrorExport((prevState) => !prevState);
            const timeoutId = setTimeout(() => {
              setIsErrorExport((prevState) => !prevState);
              clearTimeout(timeoutId);
            }, 2000);
          },
        })
      );
    }

    if (globalValue === "Import Contacts" && file) {
      const formData = new FormData();
      formData.append("file", file);
      dispatch(
        importContacts({
          data: formData,
          onSuccess: () => {
            setIsSuccessImport((prevState) => !prevState);
            getDataHouseHolds();
            const timeoutId = setTimeout(() => {
              setIsSuccessImport((prevState) => !prevState);
              window.location.reload();
              clearTimeout(timeoutId);
            }, 2000);
          },
          onError: () => {
            setIsErrorImport((prevState) => !prevState);
            const timeoutId = setTimeout(() => {
              setIsErrorImport((prevState) => !prevState);
              clearTimeout(timeoutId);
            }, 2000);
          },
        })
      );
    }
  };

  const resetFilters = () => {
    dispatch(
      householdsTriggerActions.getHouseholdsTriggerAC({
        page: page,
        perPage: perPageValue,
      })
    );
  };
  const createHousehold = () => {
    navigate("/households/create");
  };

  const isExistFilterOrSearchParams = !!(
    searchValueFromUrl ||
    scheduledFromUrl ||
    genderFromURl ||
    regularCategoriesFromUrl ||
    deferredVisitStartDateFromUrl ||
    dueVisitStartDateFromUrl ||
    deferredVisitFromUrl ||
    notVisitFromUrl ||
    dataRangeStartDateFromUrl ||
    dataRangeEndDateFromUrl
  );

  return (
    <div className="contacts">
      <Box
        sx={{
          display: { xs: "flex", md: "block" },
          justifyContent: { xs: "space-between" },
        }}
      >
        <Box
          sx={{
            flexDirection: { xs: "column", md: "row" },
            justifyContent: { xs: "space-between" },
            alignItems: { xs: "flex-start", md: "center" },
          }}
          className="contacts__container"
        >
          <h2>{p("contact")}</h2>
          <div className="contacts__link-container">
            <Link className="contacts__link-dashboard" to={"/households"}>
              {pGeneral("dashboard")}
            </Link>
            <div className="contacts__img">
              <img src={chevronRight} alt="chevron" />
            </div>
            <Link className="contacts__link-contacts" to={"/households"}>
              {p("households")}
            </Link>
          </div>
        </Box>
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <IconButton
            isDisabled={
              !user?.permissions[UserPermissionsEnum.createHouseholds]
            }
            title={p("new_contact")}
            onClick={createHousehold}
          />
        </Box>
      </Box>
      <div className="contacts__filters-container">
        <div className="contacts__filters-container-inner">
          <div className="contacts__filter">
            <Filters
              isDisabled={
                !households?.data?.length && !isExistFilterOrSearchParams
              }
              open={openFilters}
              setOpen={setOpenFilters}
              resetFilters={resetFilters}
            />
          </div>
          <div className="contacts__filter">
            <SortFilter
              open={openSortBy}
              value={sortByValue}
              setSortByValue={handleChangeSort}
              setOpen={setOpenSortBy}
              isDisabled={
                !households?.data?.length && !isExistFilterOrSearchParams
              }
            />
          </div>
          <div className="contacts__filter">
            <GlobalFilter
              open={openGlobal}
              value={globalValue}
              setOpen={setOpenGlobal}
              setGlobalValue={setGlobalValue}
              setIsOpenModal={setIsOpenModal}
              isDisabled={
                !households?.data?.length && !isExistFilterOrSearchParams
              }
              setIsVisibleManageCategoriesModal={regularCategories.setIsVisible}
            />
          </div>
        </div>
        <Box
          sx={{ display: { xs: "none", md: "block" } }}
          className="contacts__button"
        >
          <IconButton
            isDisabled={
              !user?.permissions[UserPermissionsEnum.createHouseholds]
            }
            title={p("new_contact")}
            onClick={createHousehold}
          />
        </Box>
      </div>
      {!households?.data?.length &&
        !isExistFilterOrSearchParams &&
        !isLoading && (
          <EmptyStateContactsPage
            img={emptyStateIcon}
            title={p("no_households_yet")}
          />
        )}
      <ContactsList
        isLoading={isLoading}
        page={page}
        handleDelete={(id) => {
          setHouseHoldId(id);
          setIsVisibleDeleteModal(!isVisibleDeleteModal);
        }}
        perPageValue={perPageValue}
        openPerPage={openPerPage}
        setOpenPerPage={setOpenPerPage}
        setPerPageValue={(page: string) => {
          setPerPageValue(page);
          setUrlQueryParam(FiltersHouseholdsPage.perPage, page);
        }}
        households={households}
        searchValue={searchValue}
        handleSearch={handleSearch}
        isExistFilterOrSearchParams={isExistFilterOrSearchParams}
        categories={categories}
        {...globalSelectedContacts}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", md: "flex-end" },
        }}
        className="contacts__pagination"
      >
        <Box>
          <Paginator
            pageSize={
              households?.meta?.per_page
                ? households?.meta?.per_page
                : Number(perPageValue)
            }
            currentPage={
              households?.meta?.current_page
                ? households?.meta?.current_page
                : 1
            }
            totalItemsCount={households?.meta?.total}
            setCurrentPage={(page: number) => {
              setCurrentPage(page);
              setUrlQueryParam(FiltersHouseholdsPage.currentPage, String(page));
            }}
          />
        </Box>
      </Box>
      <ContactsModal
        isOpen={isOpenModal}
        onClose={setIsOpenModal}
        confirmTitleButton={
          globalValue === "Import Contacts" ? "Upload" : "Export"
        }
        cancelTitleButton="Cancel"
        title={globalValue}
        handleConfirm={handleConfirmModal}
      >
        {globalValue === "Import Contacts" ? (
          <ImportChildren
            file={file}
            setFile={setFile}
            handleFile={handleFile}
          />
        ) : (
          <ExportChildren />
        )}
      </ContactsModal>
      <DeleteHouseholdModal
        isVisible={isVisibleDeleteModal}
        setIsVisible={setIsVisibleDeleteModal}
        handleDelete={() => handleDelete(householdId)}
      />
      <ManageCategoriesModal categories={categories} {...regularCategories} />
      <DeleteHouseholdsModal
        {...globalSelectedContacts}
        selectedCount={globalSelectedContacts.totalSelected()}
        setIsVisible={globalSelectedContacts.setIsVisibleDeleteHouseholds}
        isVisible={globalSelectedContacts.isVisibleDeleteHouseholds}
      />
      <UpdateScheduledCategories
        {...globalSelectedContacts}
        isVisible={globalSelectedContacts.isVisibleUpdateScheduledCategories}
        setIsVisible={
          globalSelectedContacts.setIsVisibleUpdateScheduledCategories
        }
      />
      <UpdateRegularCategories
        {...globalSelectedContacts}
        categories={categories}
        isVisible={globalSelectedContacts.isVisibleUpdateRegularCategories}
        setIsVisible={
          globalSelectedContacts.setIsVisibleUpdateRegularCategories
        }
      />
      <SuccessModal {...isSuccessDeletingHouseholdModalProps} />
      <SuccessModal
        title={"Export of contacts completed successfully"}
        isVisible={isSuccessExport}
        setIsVisible={() => setIsSuccessExport((prevState) => !prevState)}
      />
      <SuccessModal
        isVisible={isSuccessImport}
        setIsVisible={() => setIsSuccessImport((prevState) => !prevState)}
        title={"When the import is completed, you will receive a notification"}
      />
      <ErrorModal {...isErrorDeletingHouseholdModalProps} />
      <ErrorModal
        title={
          "There was an error while export of contacts or performing an action."
        }
        isVisible={isErrorExport}
        setIsVisible={() => setIsErrorExport((prevState) => !prevState)}
      />
      <ErrorModal
        isVisible={isErrorImport}
        setIsVisible={() => setIsErrorImport((prevState) => !prevState)}
        title={
          "There was an error while import of contacts or performing an action."
        }
      />
    </div>
  );
};
