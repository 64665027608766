import { call } from 'redux-saga/effects';
import ApiService from "../services/ApiService";

// const resolveFilters = filters => {
//   return Object.keys(filters || {}).reduce?.((a, f) => {
//     if (filters[f] === '') {
//       return a;
//     }
//
//     if (~f.toLocaleLowerCase().indexOf('start_date') && filters[f]) {
//       return {
//         ...a,
//         [f]: moment(filters[f]).startOf('day'),
//       };
//     }
//
//
//     if (~f.toLocaleLowerCase().indexOf('end_date') && filters[f]) {
//       return {
//         ...a,
//         [f]: moment(filters[f]).endOf('day'),
//       };
//     }
//
//     if (f?.startsWith?.('$')) {
//       return {
//         ...a,
//         [f.substring(1)]: filters[f],
//       };
//     }
//
//     return {
//       ...a,
//       [f]: filters[f],
//     }
//   }, {});
// };

const createRequest = action => {
  return function* request({
    method = 'get',
    endpoint,
    headers,
    params,
    body,
    onUploadProgress,
    responseType,
    disableTimeout = true,
  }) {
    try {
      const { data, error, status } = yield call(
        ApiService.call,
        method,
        endpoint,
        body,
        { ...params,
          // ...resolveFilters(action.filters),
          ...(action.pagination || {}),
          ...(action.ordering || {}),
        },
        headers,
        responseType,
        onUploadProgress,
        disableTimeout,
      );

      if (error) {
        // @TODO: Instantiate error
        return { error: { error, data } };
      }

      return { data, status };
    } catch (error) {
      console.log('Error', error );
      // @TODO: Instantiate possible Network error
      return { error };
    }
  }
}

export default createRequest;
