import { FC } from "react"
import { Notification } from "../../../types/global/types"
import "./styles.scss"

type Props = {
  notification: Notification,
}

export const RemindMeNotification: FC<Props> = ({
  notification,
}) => {

  return (
    <div
      className="notification"
    >
      <div className="notification__header">
        <div className="notification__title">
          <h3>
            {notification.title.startOfTitle}
            {notification.title.householdName}
            {notification.title.endOfTitle}
          </h3>
          <div className="notification__time">
            {notification.created_at_formatted}
          </div>
        </div>
      </div>

      <div className="notification__notes">
        <div className="notification__notes-header">Date</div>
        <div className="notification__notes-body">
          {notification.remind_me_at_date}
        </div>
      </div>

      <div className="notification__notes">
        <div className="notification__notes-header">Time</div>
        <div className="notification__notes-body">
          {notification.remind_me_at_time}
        </div>
      </div>

      <div className="notification__notes">
        <div className="notification__notes-header">Notes</div>
        <div className="notification__notes-body">
          {notification.notes}
        </div>
      </div>
    </div>
  )
}
