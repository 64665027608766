import { FC, useState } from "react"
import logo from "../../../assets/images/logo.png"
import { useNavigate } from "react-router-dom"
import "./styles.scss";
import welcomeScreenBackgroundImg from '../../../assets/images/WelcomeIcon.svg';
import {WhiteButton} from '../../HomePage/WhiteButton';
import {BlackButton} from '../../HomePage/BlackButton';
import { Box } from "@mui/material";
import { useTranslation } from "../../../hooks/useTranslation";
import { LanguageSelect } from "../../../components/LanguageSelect";

export const Layout: FC = () => {
  const { p, i18n } = useTranslation('first_page');
  const navigate = useNavigate()

  const toLogin = () => {
    navigate("/login")
  }

  const toRegistration = () => {
    navigate("registration")
  }

  const [locale, setLocale] = useState(i18n.language);
  const handleLanguageChange = (language:string) => {
    i18n.changeLanguage(language);
    setLocale(language);
  };
  return (
    <Box
      className="welcome-page-layout"
      sx={{
        flexDirection: { xs: "column", md: "row" },
        justifyContent: { xs: "stretch", md: "flex-start" },
        height: { xs: "100vh", md: "auto" }
      }}
    >
        <div className='welcome-page-layout__left-side'>
          <Box
            className='welcome-page-layout__left-side-container'
            sx={{
              marginBottom: { xs: "60px", md: "0" },
              width: { xs:"90%",md: "60%" },
            }}
          >
            <img src={logo} alt={"logo"} className={"welcome-page-layout__left-side-logo"} />
            <Box 
              className='welcome-page-layout__left-side-title'
              sx={{
                fontSize: { xs: "28px", md: "49px" },
              }}
            >
              { p('manage_your_routes') }
            </Box>
            <div className='welcome-page-layout__left-side-buttons-wrapper'>
                <div className='welcome-page-layout__left-side-buttons-wrapper buttons'>
                    <WhiteButton title={p('register')} onClick={toRegistration} />
                    <BlackButton title={p('login')} onClick={toLogin} />
                </div>
            </div>
            
            <Box 
              sx={{
                marginTop: { xs: "60px", md: "60px" },
              }}
            >
              <LanguageSelect language={locale} onChange={handleLanguageChange} />
            </Box>
          </Box>
        </div>
        <div className='welcome-page-layout__right-side'>
            <img src={welcomeScreenBackgroundImg} alt={'welcome-icon'} />
        </div>
    </Box>
  )
}
