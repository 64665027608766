import {useContext, useEffect, useMemo, useState} from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { AddVisitorModal } from "../../components/TeamPage/AddVisitorModal";
import { EditVisitorModal } from "../../components/TeamPage/EditVisitorModal";
import { TeamList } from "../../components/TeamPage/TeamList";
import { IconButton } from "../../components/ContactsPage/IconButton";
import chevronRight from "../../assets/fonts/chevron-right.svg";
import { teamTriggerActions } from "../../backend/store/reducers/teamReducer";
import { Preloader } from "../../components/base/Preloader/Preloader";
import "./styles.scss";
import { DeleteVisitorModal } from '../../components/TeamPage/DeleteVisitorModal';
import { useUrlQueryParams } from '../../hooks/useUrlQueryParams';
import { Box } from "@mui/material";
import {UserPermissionsEnum} from '../../enums/userPermissionsEnum';
import {UserContext} from '../../contexts/UserContext';
import {EmptyStateContactsPage} from '../../components/ContactsPage/EmptyStateContactsPage';
import emptyStateIcon from '../../assets/images/EmptyStateContacts.svg';
import { Team } from '../../types/global/types';
import {useTranslation} from "../../hooks/useTranslation";

enum FiltersTeamPage {
    currentPage = "currentPage",
    searchValue = "searchValue",
    perPage = "perPage"
}

export const TeamPage = () => {
    const { p } = useTranslation('my_team');
    const { p: pGeneral } = useTranslation('general');
    const { user } = useContext(UserContext);
  const dispatch = useDispatch();
  const [isVisibleDeleteModal,setIsVisibleDeleteModal] = useState<boolean>(false);
  const [userId, setUserId] = useState<string | null>(null);
  const [userForUpdate, setUserForUpdate] = useState<Team | null>(null);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [perPageValue, setPerPageValue] = useState<string>("10");
  const [openPerPage, setOpenPerPage] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { setUrlQueryParam, deleteUrlQueryParam, getUrlQueryParam } = useUrlQueryParams()

  const storedTeam = useSelector((state: any) => state.teamStore.team?.data);
  const metaData = useSelector((state: any) => state.teamStore.team?.meta);
  const team = useMemo(() => storedTeam || [], [storedTeam]);
  const meta = useMemo(() => metaData || [], [metaData]);

  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  useEffect(() => {
      dispatch(teamTriggerActions.getTeamTriggerAC(searchValue, currentPage, perPageValue));
  }, [searchValue, perPageValue, currentPage]);

  useEffect(() => {
      const currPageFromUrl = getUrlQueryParam(FiltersTeamPage.currentPage);
      const perPageFromUrl = getUrlQueryParam(FiltersTeamPage.perPage);
      const searchValueFromUrl = getUrlQueryParam(FiltersTeamPage.searchValue);
      if(!currPageFromUrl) {
          setCurrentPage(1);
          setUrlQueryParam(FiltersTeamPage.currentPage, "1");
      }
      if(currPageFromUrl) setCurrentPage(+currPageFromUrl)
      if(!perPageFromUrl) {
          setPerPageValue("10");
          setUrlQueryParam(FiltersTeamPage.perPage, '10')
      }
      if(perPageFromUrl) setPerPageValue(perPageFromUrl);
      if(searchValueFromUrl) {
          setSearchValue(searchValueFromUrl)
          setSearchValue(searchValueFromUrl);
      }
  }, [])

  const handleDelete = (id: string): void => {
    dispatch(teamTriggerActions.deleteTeamTriggerAC(id))
  };

  const handleSearch = (str: string | null): void => {
      setSearchValue(str);
      if(!str) return deleteUrlQueryParam(FiltersTeamPage.searchValue);
      return setUrlQueryParam(FiltersTeamPage.searchValue, str);
  };

  const createVisitor = (values: any): void => {
    dispatch(teamTriggerActions.createVisitorTriggerAC(values));
    setOpenModal(false);
  };
  const updateVisitorName = (values: any): void => {
    dispatch(teamTriggerActions.updateVisitorTriggerAC(values));
    setOpenEditModal(false);
  };

  if(!user?.permissions[UserPermissionsEnum.readInvitations]) {
      return (
          <EmptyStateContactsPage img={emptyStateIcon} title={"You don't have permissions to view this page"} />
      )
  }

  if (!storedTeam) {
    return <Preloader />;
  }

  return (
    <div className="team">
      <Box
        className="contacts__container"
        sx={{
          justifyContent: "space-between",
          flexDirection: { xs: "column ", md: "row" },
          alignItems:{ xs: "start", md: "center" },
          marginBottom: { xs: "20px", md: "65px" }
        }}
      >
        <Box
          className="contacts__header-container"
          sx={{
            alignItems: "center",
            justifyContent: { xs: "space-between", md: "start" },
            width: { xs: "100%", md: "auto" },
          }}
        >
          <h2>{p('name')}</h2>

          <div className="team__button">
            <IconButton
              isDisabled={!user?.permissions[UserPermissionsEnum.createInvitations]}
              title={p('invite')}
              onClick={() => setOpenModal(true)}
            />
          </div>
        </Box>
        <div className="contacts__link-container">
          <Link className="contacts__link-dashboard" to={"/households"}>
            { pGeneral('dashboard')}
          </Link>
          <div className="contacts__img">
            <img src={chevronRight} alt="chevron" />
          </div>
          <Link className="contacts__link-contacts" to={"/households"}>
          {p('name')}
          </Link>
        </div>
      </Box>
      <TeamList
          searchValue={searchValue}
          perPageValue={perPageValue}
          openPerPage={openPerPage}
          setOpenPerPage={setOpenPerPage}
          setPerPageValue={(newValue: string) => {
              setUrlQueryParam(FiltersTeamPage.perPage, newValue)
              setPerPageValue(newValue);
          }}
          allEntries={meta?.total}
          handleSearch={handleSearch}
          handleDelete={(id: string) => {
            setUserId(id);
            setIsVisibleDeleteModal(!isVisibleDeleteModal)
          }}
          handleChangeName={(teamItem: Team) => {
            setUserForUpdate(teamItem)
            setOpenEditModal(true)
          }}
          team={team}
          currentPage={currentPage}
          setCurrentPage={(page: number) => {
              setUrlQueryParam(FiltersTeamPage.currentPage, String(page))
              setCurrentPage(page)
          }}
      />

      <AddVisitorModal
        onSubmit={createVisitor}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />

      {userForUpdate && <EditVisitorModal
        visitor={userForUpdate}
        searchValue={searchValue}
        perPageValue={perPageValue}
        currentPage={currentPage}
        onSubmit={updateVisitorName}
        openModal={openEditModal}
        setOpenModal={setOpenEditModal}
        resetUserForUpdate={() => setUserForUpdate(null)}
      />}
      
      <DeleteVisitorModal
          isVisible={isVisibleDeleteModal}
          setIsVisible={setIsVisibleDeleteModal}
          handleDelete={() => {
            if(!userId) return ;
            handleDelete(userId);
            setIsVisibleDeleteModal(!isVisibleDeleteModal);
            setUserId(null);
          }}
      />
    </div>
  );
};
