import { FC } from "react"
import searchIcon from "../../../assets/fonts/search.svg"
import { Household } from "../../../types/global/types"
import { SearchList } from "../SearchList"
import "./styles.scss"

type Props = {
  value: string
  filteredHouseholds: Household[]
  onChange: (e: any) => void
  setValue: (value: string) => void
}

export const Search: FC<Props> = ({
  filteredHouseholds,
  value,
  onChange,
  setValue,
}) => {
  return (
    <div className="route-search">
      <div className="route-search__input-container">
        <label className="route-search__label">Contact name</label>
        <img src={searchIcon} alt="search" className="route-search__icon" />
        <input
          className="route-search__input"
          type="text"
          placeholder="Find name"
          value={value}
          onChange={onChange}
        />
      </div>
      <div className="route-search__help-container">
        {filteredHouseholds.length !== 0 && (
          <SearchList
            filteredHouseholds={filteredHouseholds}
            setValue={setValue}
          />
        )}
      </div>
    </div>
  )
}
