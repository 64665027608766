import { FC } from "react"
import "./styles.scss"

type Props = {
  label: string
  placeholder: string
  value: string
  onChange: (e: any) => void
}

export const Input: FC<Props> = ({ label, placeholder, value, onChange }) => {
  return (
    <div className="route-page-input">
      <label className="route-page-input__label">{label}</label>
      <input
        className="route-page-input__input"
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  )
}
