import "./styles.scss";
import vectorMini from "../../../assets/fonts/vectorMini.svg";
import vectorSelect from "../../../assets/fonts/vectorSelect.svg";
import { FC, useState, useEffect } from "react";
import { useClickOutside } from "../../../hooks/useClickOutside";
import axios from "axios";
import { API_URL } from "../../../constants/base";
import { useDispatch } from "react-redux";
import { actions } from "../../../backend/store/reducers/authReducer";
import {householdsActions, householdsTriggerActions} from "../../../backend/store/reducers/householdsReducer"
import {getUser} from "../../../backend/store/actions/creators/getUser";
import {useNavigate} from "react-router-dom";


interface User {
  name: string;
  id: number;
}

type Props = {
  isRequired?: boolean;
  label: string;
  list: User[];
  value: string;
  placeholder: string;
  handleSelect: (value: string) => void;
  onChangeClose?: boolean;
  hasMultipleWorkspaces: boolean;
  // setIdSelectedWorkspaces: any;
};

export const SelectWorkspace: FC<Props> = ({
  onChangeClose,
  label,
  list,
  value,
  placeholder,
  handleSelect,
  isRequired,
  hasMultipleWorkspaces,
  // setIdSelectedWorkspaces,
}) => {
  const reset = () => {
    dispatch(
        householdsTriggerActions.getHouseholdsTriggerAC({
          page: 1,
          perPage: '25',
        })
    );
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [idSelectedWorkspaces, setIdSelectedWorkspaces] = useState(null);


  const navigateToHouseholds = () => {
    navigate("/households");
  };
  const handleOpen = () => {
    setOpen(!open);
  };
  useEffect(() => {
    if (idSelectedWorkspaces) {
      // dispatch(switchWorkspace({ workspaceId: 10 }));
      axios
        .post(API_URL + "/api/auth/switch-workspace", {
          workspaceId: idSelectedWorkspaces,
        })
        .then((response) => {
          if (response.status === 200) {
            dispatch((getUser()));
            reset()
            navigateToHouseholds()
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [idSelectedWorkspaces]);

  const handleBlur = () => {
    setOpen(false);
  };

  const ref = useClickOutside({ handler: handleBlur });

  return (
    <>
      {hasMultipleWorkspaces && (
        <div className="select-workspace-create-household-select" ref={ref}>
          <label className="select-workspace-create-household-select__label">
            {label}
            {isRequired && <span style={{ color: "red" }}>*</span>}
          </label>
          <span
            className={`select-workspace-create-household-select__span ${
              open && "select-workspace-create-household-select_active-arrow"
            }`}
          >
            <img src={vectorMini} alt="vector-mini" style={{ width: "10px" }} />
          </span>
          <div
            className="select-workspace-create-household-select__input"
            onClick={handleOpen}
          >
            {value ? value : placeholder}
          </div>
          <div
            className={`select-workspace-create-household-select__list-container ${
              open && "select-workspace-create-household-select_open"
            }`}
          >
            <div className="select-workspace-create-household-multi-select__list-items">
              {list.map((elem) => {
                return (
                  <div
                    className={`select-workspace-create-household-select__list ${
                      value === elem.name &&
                      "select-workspace-create-household-select_active"
                    }`}
                    key={elem.id}
                    onClick={() => {
                      if (onChangeClose) handleOpen();
                      handleSelect(elem.name);
                      //@ts-ignore
                      setIdSelectedWorkspaces(elem.id);
                    }}
                  >
                    {elem.name}
                    <div
                      className={`select-workspace-create-household-select__list-arrow ${
                        value === elem.name &&
                        "select-workspace-create-household-select_active-check"
                      }`}
                    >
                      <img src={vectorSelect} alt="arrow" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
