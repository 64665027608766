import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 48px;
  padding: 4px 0;
`;

export const Language = styled.div`
  font-family: Rubik-Regular;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  user-select: none;
  cursor: pointer;
  padding: 6px;
  transition: color 280ms ease;

  &:hover {
    color: blue;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
  opacity: 0;
  padding: 4px 0;
  transition: opacity 280ms ease;
  
  &:hover {
    opacity: 1;
    background: white;
    border-radius: 2px;
  }
`;
