import { FC } from "react"
import "./styles.scss"

interface IProps {
  disabled?: boolean
  title: string
  onClick: () => void
}

export const Button: FC<IProps> = ({ disabled, title, onClick }) => {
  const handleClick = () => {
    if (!disabled) {
      onClick()
    }
  }

  return (
    <button
      className={`login-page-button ${
        disabled ? "login-page-button_disabled " : ""
      }`}
      onClick={handleClick}
      type="button"
    >
      {title}
    </button>
  )
}
