import { FC } from "react"
import { WarningPanel } from "../WarningPanel"
import { ScheduledVisitsPanel } from "../ScheduledVisitsPanel"
import { Visit } from "../../../types/VisitReports/types"
import "./styles.scss"
import { Household } from "../../../types/global/types"
import { useTranslation } from "../../../hooks/useTranslation"

type Props = {
  household: Household
  scheduledVisits: Visit[]
}

export const ScheduledVisit: FC<Props> = ({ household, scheduledVisits }) => {
  const { p } = useTranslation('contact_info');
  return (
    <div className="scheduled-visit-report">
      <div className="scheduled-visit-report__container">
        <div className="scheduled-visit-report__warning-panel">
          <WarningPanel household={household} />
        </div>
        <div className="scheduled-visit-report__title">
          {p('subsequent_scheduled_visits')}
        </div>
        <ScheduledVisitsPanel scheduledVisits={scheduledVisits} />
      </div>
    </div>
  )
}
