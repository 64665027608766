import { PersonPanel } from "../PersonPanel"
import contactsIcon from "../../../assets/fonts/contactsList.svg"
import { PhoneNumberInput } from "../PhoneNumberInput"
import { Input } from "../Input"
import { FC } from "react"
import { FormikType } from "../../../types/VisitReports/types"
import "./styles.scss"
import {FormErrorMessage} from '../../base/FormErrorMessage';
import { useTranslation } from "../../../hooks/useTranslation"

type Props = {
  formik: FormikType
  personIndex: number
  path: "updated_persons" | "new_persons"
  handlePhoneNumber: (number: string, type: string, phoneIndex: string) => void
  handleEmail: (e: any) => void
}

export const ContactInfo: FC<Props> = ({
  formik,
  personIndex,
  path,
  handlePhoneNumber,
  handleEmail,
}) => {
    const { p } = useTranslation('contact_info');
    const { p: pGeneral } = useTranslation('general');
    return (
    <div className="visit-report-contact-info">
      <div className="visit-report-contact-info__panel">
        <PersonPanel icon={contactsIcon} title={p('name')} />
      </div>
      <div className="visit-report-contact-info__phone">
        <PhoneNumberInput
          isRequired
          label={`${p('mobile')} ${p('number')} 1`}
          index="0"
          type="Mobile"
          value={formik?.values?.[path]?.[personIndex]?.phones[0]?.number || ""}
          onChange={handlePhoneNumber}
          isError={!!((formik.touched[path] as { [key: string]: boolean }[])?.[personIndex]?.phones as unknown as { [key: string]: boolean }[])?.length &&
              !!((formik.touched[path] as { [key: string]: boolean }[])?.[personIndex]?.phones as unknown as { [key: string]: boolean }[])[0]?.number &&
              !!((formik.errors[path] as { [key: string]: string }[])?.[personIndex]?.phones as unknown as string[])?.length &&
              !!((formik.errors[path] as { [key: string]: string }[])?.[personIndex]?.phones as unknown as string[])[0]}
        />
          {((formik.touched[path] as { [key: string]: boolean }[])?.[personIndex]?.phones as unknown as { [key: string]: boolean }[])?.length &&
              ((formik.touched[path] as { [key: string]: boolean }[])?.[personIndex]?.phones as unknown as { [key: string]: boolean }[])[0]?.number &&
              ((formik.errors[path] as { [key: string]: string }[])?.[personIndex]?.phones as unknown as string[])?.length &&
              ((formik.errors[path] as { [key: string]: string }[])?.[personIndex]?.phones as unknown as string[])[0] &&
           <FormErrorMessage message={((formik.errors[path] as { [key: string]: string }[])?.[personIndex]?.phones as unknown as string[])[0]} />
          }
      </div>
      <div className="visit-report-contact-info__phone">
        <PhoneNumberInput
          label={`${p('mobile')} ${p('number')} 2`}
          index="1"
          type="Other"
          value={formik?.values?.[path]?.[personIndex]?.phones[1]?.number || ""}
          onChange={handlePhoneNumber}
        />
      </div>
      <div className="visit-report-contact-info__phone">
        <PhoneNumberInput
          label={`${p('work')} ${p('number')} `}
          index="2"
          type="Work"
          value={formik?.values?.[path]?.[personIndex]?.phones[2]?.number || ""}
          onChange={handlePhoneNumber}
        />
      </div>
      <div className="visit-report-contact-info__phone">
        <PhoneNumberInput
          label={`${p('home')} ${p('number')} `}
          index="3"
          type="Home"
          value={formik?.values?.[path]?.[personIndex]?.phones[3]?.number || ""}
          onChange={handlePhoneNumber}
        />
      </div>
      <div className="visit-report-contact-info__email">
        <Input
          label={pGeneral('email')}
          placeholder={pGeneral('email')}
          value={formik?.values?.[path]?.[personIndex]?.email}
          onChange={handleEmail}
        />
      </div>
    </div>
  )
}
