import { FC } from "react"
import "./styles.scss"

type Props = {
  icon?: string
  title: string
  onClick?: () => void
  background?: string
}

export const BlueButton: FC<Props> = ({ icon, title, onClick, background }) => {
  return (
    <button className={background?(`visit-report-telegram-button`+`-${background}`):`visit-report-telegram-button`}  onClick={onClick}>
      <div className="visit-report-telegram-button__container">
        {icon && <img src={icon} alt="blue-icon" />}
        <label className="visit-report-telegram-button__label">{title}</label>
      </div>
    </button>
  )
}
