import "./styles.scss";
import { Link } from "react-router-dom";
import chevronRight from "../../../assets/fonts/chevron-right.svg";
import arrowLeft from "../../../assets/fonts/arrow-left.svg";
import { FC, useEffect, useState, useContext } from "react";
import { Box } from '@mui/material';
import { addressTriggerActions } from "../../../backend/store/reducers/addressesReducer";
import { useDispatch, useSelector } from "react-redux";
import { SuccessModal } from "../../../components/base/SuccessModal";
import { ErrorModal } from "../../../components/base/ErrorModal";
import { Button } from "../../ContactsPage/Button";
import { UserContext } from "../../../contexts/UserContext";
import { UserPermissionsEnum } from "../../../enums/userPermissionsEnum";

type Elem = {
  title: string;
  path: string;
};

type Props = {
  title: string;
  link: string;
  subtitles: Elem[];
};

export const LayoutHeader: FC<Props> = ({ title, subtitles, link }) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    if (!refreshPlace) {
      dispatch(addressTriggerActions.getRefreshPlaceIdsTriggerAC());
      setRefreshPlace(true)
      setTitle("Refresh Addresses Start")
      setIsSuccess(true)
    }
  };

  const status = useSelector(
    (state: any) => state.addressesStore.status
  );
  const { user } = useContext(UserContext);


  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [titleModal, setTitle] = useState("");
  const [refreshPlace, setRefreshPlace] = useState(false);

  useEffect(() => {
    if (refreshPlace) {
      if (status.status === 200) {
        setIsSuccess(true)
        setTitle("All addresses update")
      }
      else {
        if (status.data.message) {
          setIsError(true)
          setTitle(status.data.message)
        }
        else {
          setIsError(true)
          setTitle("some error")
        }
      }
    }
  }, [status]);

  return (
    <Box
      style={{ justifyContent: 'space-between' }}
      className="layout-header"
      sx={{
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: { xs: 'start', md: 'center' }
      }}
    >
      <div className="layout-header__title-container">
        <Box
          className="layout-header__arrow-left"
          sx={{
            display: { xs: 'none', md: 'block' },
          }}
        >
          <Link to={link}>
            <img src={arrowLeft} alt="arrow-left" />
          </Link>
        </Box>
        <h2>{title}</h2>
      </div>
      {user?.permissions[UserPermissionsEnum.readInvitations] ?
        <div style={{ width: '170px' }}>
          <Button title={"Refresh Addresses"} white onClick={handleClick} />
        </div> : null}


      <Box
        className="layout-header__link-container"
        sx={{
          justifyContent: { xs: 'start', md: 'end' },
          marginTop: { xs: 1, md: 0 },
        }}
      >
        {subtitles.map((elem, index) => {
          return (
            <div className="layout-header__container-inner" key={index}>
              <div className="layout-header__img">
                {index !== 0 && <img src={chevronRight} alt="chevron" />}
              </div>
              <Link className="layout-header__link" to={elem.path}>
                {elem.title}
              </Link>
            </div>
          );
        })}
      </Box>
      <SuccessModal
        isVisible={isSuccess}
        setIsVisible={() => { setIsSuccess((prevState) => !prevState); setRefreshPlace(false) }}
        title={titleModal}
      />
      <ErrorModal
        isVisible={isError}
        setIsVisible={() => { setIsError((prevState) => !prevState); setRefreshPlace(false) }}
        title={titleModal}
      />
    </Box>

  );
};
