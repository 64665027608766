import { FC } from "react"
import lockedUser from "../../../assets/fonts/lockedUser.svg"
import "./styles.scss"
import { useTranslation } from "../../../hooks/useTranslation";

export const ChabadButton: FC = () => {
  const { p } = useTranslation('first_page');
  return (
    <button className="login-page-chabad-button" type="button">
      <img src={lockedUser} alt="user" />
      <div>{p('login_with_chabad')}</div>
    </button>
  )
}
