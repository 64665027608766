import { FC, useMemo, useState } from "react"
import { RegistrFormik } from "../../../pages/RegistrationPage/RegistrationPage"
import { FirstStep } from "../FirstStep"
import { SecondStep } from "../SecondStep"

interface IProps {
  formik: RegistrFormik
}

export const Layout: FC<IProps> = ({ formik }) => {
  const [confirmPassword, setConfirmPassword] = useState("")
  const [step, setStep] = useState("first step")

  const disabledFirstStep = useMemo(() => {
    return (
      !formik.values.email ||
      !formik.values.password ||
      !!formik.errors.email ||
      !!formik.errors.password ||
      formik.values.password !== confirmPassword
    )
  }, [formik, confirmPassword])

  const disabledSecondStep = useMemo(() => {
    return (
      !formik.values.firstName ||
      !formik.values.lastName ||
      !!formik.errors.firstName ||
      !!formik.errors.lastName
    )
  }, [formik])

  const handleEmail = (e: any) => {
    formik.setFieldValue("email", e.target.value)
  }

  const handlePassword = (e: any) => {
    formik.setFieldValue("password", e.target.value)
  }

  const handleConfirmPassword = (e: any) => {
    setConfirmPassword(e.target.value)
  }
  const handleCheck = (e: any) => {
    let check = +e.target.value ? 0 : 1
    formik.setFieldValue("remember", check);

  }

  const handleFirstName = (e: any) => {
    formik.setFieldValue("firstName", e.target.value)
  }

  const handleLastName = (e: any) => {
    formik.setFieldValue("lastName", e.target.value)
  }

  return (
    <>
      {step === "first step" ? (
        <FirstStep
          formik={formik}
          confirmPassword={confirmPassword}
          disabledFirstStep={disabledFirstStep}
          handleEmail={handleEmail}
          handlePassword={handlePassword}
          handleConfirmPassword={handleConfirmPassword}
          setStep={setStep}
          handleCheck={handleCheck}
        />
      ) : (
        <SecondStep
          formik={formik}
          isDisabled={disabledSecondStep}
          handleFirstName={handleFirstName}
          handleLastName={handleLastName}
          setStep={setStep}
          handleCheck={handleCheck}
        />
      )}
    </>
  )
}
