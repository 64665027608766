import { FC } from "react"

import "./styles.scss"

type Props = {
  title: string
  subtitle?: string
  icon: string
  active: boolean
  handleClick?: () => void,
  isDisabled?: boolean
}

export const UpdateSidebarButton: FC<Props> = ({
  title,
  subtitle,
  icon,
  active,
  handleClick,
  isDisabled
}) => {
  return (
    <div
      className={`create-household-layout-button__button ${
        active ? "create-household-layout-button_active" : ""
      } ${isDisabled ? "isDisabled" : ""}`}
      onClick={handleClick}
    >
      <div className="create-household-layout-button__button-container">
        <div className="create-household-layout-button__button-image">
          <img src={icon} alt="house" />
        </div>
        <div className={`create-household-layout-button__button-title ${title === "Delete household" ? "delete-title" : ""}`}>
          {title}
        </div>
      </div>
      <div className="create-household-layout-button__button-subtitle">
        {subtitle && subtitle.toUpperCase()}
      </div>
    </div>
  )
}
