import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import Snackbar from "@mui/material/Snackbar"
import MuiAlert, { AlertProps } from "@mui/material/Alert"
import { forwardRef, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export const SnackbarMUI = () => {
  const [open, setOpen] = useState(false)
  const storedSnackMessage = useSelector(
    (state: any) => state.generalStore.snackMessage
  )

  useEffect(() => {
    setOpen(true)
  }, [storedSnackMessage])

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return
    }

    setOpen(false)
  }

  return (
    <>
      {storedSnackMessage && (
        <Stack sx={{ width: "100%" }}>
          <Snackbar
            sx={{ minWidth: "320px" }}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={storedSnackMessage.type}
              sx={{ width: "100%" }}
            >
              {storedSnackMessage.message}
            </Alert>
          </Snackbar>
        </Stack>
      )}
    </>
  )
}
