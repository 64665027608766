import { FC } from "react"
import modalFileFont from "../../../../../assets/fonts/modalFileCSV.svg"
import modalArrowFont from "../../../../../assets/fonts/modalArrow.svg"
import { useSelector } from "react-redux"
import "./styles.scss"

export const ExportChildren: FC = () => {
  const households = useSelector(
    (state: any) => state.householdsStore.households
  )

  return (
    <div className="contacts__export-children">
      <div className="contacts__export-children-image-first">
        <img src={modalFileFont} alt="file" />
      </div>
      <div className="contacts__export-children-image-second">
        <img src={modalArrowFont} alt="arrow" />
      </div>
      <div className="contacts__export-children-title">
        {households.length} contacts
      </div>
    </div>
  )
}
