import {asCreator, withCallbacks} from '../../../../lib/utils';
import {compose} from '../../../../utils/functions';
import {actionTypes} from '../../reducers/notificationsReducer';

export const changeRegularCategoriesNotification = compose(
    withCallbacks,
    asCreator((data: number[], id: number) => ({
        type: actionTypes.CHANGE_REGULAR_CATEGORIES,
        payload: data,
        id
    }))
);