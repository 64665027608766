import { Dispatch, FC, SetStateAction, useContext } from 'react';
import { Team } from "../../../types/global/types";
import { ListHeader } from "../ListHeader";
import "./styles.scss";
import { Paginator } from '../../ContactsPage/Paginator/Paginator';
import { Box } from '@mui/material';
import { PerPageFilter } from "../../ContactsPage/PerPageFilter";
import { UserContext } from '../../../contexts/UserContext';
import { UserPermissionsEnum } from '../../../enums/userPermissionsEnum';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";
import { MenuActions } from '../../base/MenuActions';
import {useTranslation} from "../../../hooks/useTranslation";

type Props = {
    team: Team[];
    handleDelete: (id: string) => void;
    handleChangeName: (teamItem: Team) => void;
    handleSearch: (str: string | null) => void;
    perPageValue: string,
    openPerPage: boolean,
    setOpenPerPage: Dispatch<SetStateAction<boolean>>,
    setPerPageValue: (value: string) => void,
    allEntries: number,
    currentPage: number,
    setCurrentPage: (page: number) => void,
    searchValue: string | null
};

export const TeamList: FC<Props> = ({ team, searchValue,
    handleSearch, handleDelete, handleChangeName, perPageValue,
    openPerPage, setOpenPerPage, setPerPageValue,
    allEntries, currentPage, setCurrentPage
}) => {
    const { p } = useTranslation('my_team');
    const { p: pGeneral } = useTranslation('general');
    
    const { user } = useContext(UserContext);
    const actionMenuOptions = [
        {
            label: p('change_name'),
            action: 'changeName',
        },
        {
            label: p('delete_visitor'),
            action: 'deleteVisitor',
            variant: 'danger',
            isDisabled: !user?.permissions[UserPermissionsEnum.deleteInvitations],
        }
    ]

    const hanldeMenuActionsItemClick = (action: string, teamItem: Team) => {
        if (action === 'deleteVisitor') {
            handleDelete(teamItem.id)
        }
        if (action === 'changeName') {
            handleChangeName(teamItem)
        }
    }

    return (
        <div className="my-team-list">
            <ListHeader handleSearch={handleSearch}
                perPageValue={perPageValue}
                searchValue={searchValue}
                openPerPage={openPerPage}
                allEntries={allEntries}
                setOpenPerPage={setOpenPerPage}
                setPerPageValue={setPerPageValue} />
            <div className="my-team-list__container">
                <TableContainer component={Paper}>
                    <Table className='my-team-list__table'>
                        <TableHead>
                            <TableRow>
                                <TableCell>{p('visitor_name')}</TableCell>
                                <TableCell>{pGeneral('email')}</TableCell>
                                <TableCell>{p('status')}</TableCell>
                                <TableCell className='my-team-list-table-cell_center'>{p('actions')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {team.map((item) => {
                                return (
                                    <TableRow key={item.id} hover>
                                        <TableCell>{item.first_name} {item.last_name}</TableCell>
                                        <TableCell>{item.email}</TableCell>
                                        <TableCell>
                                            <span className={`my-team-list-table-cell__status ${
                                                item.status === "Registered" &&
                                                "my-team-list-body-item__status-active"
                                              }`}>
                                                {item.status}
                                            </span>
                                        </TableCell>
                                        <TableCell>
                                            {/* handleChangeName={handleChangeName} */}
                                            <MenuActions
                                                onItemClick={(action) => hanldeMenuActionsItemClick(action, item)}
                                                options={actionMenuOptions}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <Box
                className="contacts-list__search-panel"
                sx={{
                    display: { xs: 'flex', md: 'none' },
                    justifyContent: 'center',
                    paddingRight: '20px',
                }}
            >
                <Box
                    className="contacts-list__input-container"
                    sx={{ display: { xs: "flex", md: "none" } }}
                >
                    { pGeneral('showing') }
                    <span>
                        <PerPageFilter
                            value={perPageValue}
                            open={openPerPage}
                            setOpen={setOpenPerPage}
                            setValue={setPerPageValue}
                        />
                    </span>
                    { pGeneral('of') } {allEntries} { pGeneral('entries') }
                </Box>
            </Box>

            <Box
                className={'my-team-list__footer'}
                sx={{ justifyContent: { xs: "center", md: "end" } }}
            >
                <Paginator
                    pageSize={+perPageValue}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalItemsCount={allEntries} />
            </Box>
        </div>
    );
};
