import React, { FC } from 'react'
import userIcon from "../../../assets/fonts/userGrey.svg"
import marker from "../../../assets/fonts/Location-marker.svg"
import telegram from "../../../assets/fonts/telegram.svg"
import menu from "../../../assets/fonts/hor-menu.svg"
import basket from "../../../assets/fonts/deleteBasket.svg"
import doubleDots from "../../../assets/fonts/double-dots-vert.svg"
import "./styles.scss";
import { Button, MenuItem, Menu, Box } from '@mui/material';
import {useNavigate} from 'react-router-dom';


type Props = {
  name: string
  address: string
  id: number
  handleDeletePoint: (id: number) => void,
  handleTakeMeThere?: (fullAddress: string) => void;
  householdId?: number
}

export const SavedPoint: FC<Props> = ({
  name,
  address,
  id,
  handleDeletePoint,
  handleTakeMeThere,
  householdId
}) => {
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const options = [
        { id: 1, label: "Duch", path: `/households/update/history/${householdId}?tab=duch` },
        { id: 2, label: "Contact info", path: `/households/update/household-info/${householdId}?tab=info` },
        { id: 3, label: "History", path: `/households/update/history/${householdId}?tab=history` },
    ];
  return (
    <Box
      className="saved-point"
      sx={{
        padding: {xs: "5px 0 5px 10px", md: "20px 25px"}
      }}
    >
      <div className="saved-point__container">
        <Box
          className="saved-point__double-dots"
          sx={{
            marginRight: {xs: '10px', sm: '40px'}
          }}
        >
          <img src={doubleDots} alt="double-dots" />
        </Box>
        <div className="saved-point__address-container">
          <div className="saved-point__name">
            <img src={userIcon} alt="user" />
            {name}
          </div>
          <div className="saved-point__address">
            <img src={marker} alt="marker" />
            {address}
          </div>
          <div
              onClick={() => handleTakeMeThere && handleTakeMeThere(address)}
              className="saved-point__take-me-there">
            <img src={telegram} alt="user" />
            Take me there
          </div>
        </div>
        <Box
          className="saved-point__delete-container"
          sx={{
            gap: {xs: '0', sm: '10px'}
          }}
        >
            <Box sx={{ order: {xs: 2, md: 1} }}>
                <Button
                    id="demo-positioned-button"
                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <div className={'saved-point__menu'}>
                        <img src={menu} alt="dots" />
                    </div>
                </Button>
                <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            color: "#0E0D5B",
                            fontFamily: "Poppins, sans-serif",
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 25,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    {options.map(option => (
                        <MenuItem key={option.id} onClick={() => navigate(option.path)}>{option.label}</MenuItem>
                    ))}
                </Menu>
            </Box>
          <Box
            className="saved-point__basket"
            sx={{
              order: {xs: 1, md: 2},
              marginLeft: {xs: "10px", md: "0"},
            }}
            onClick={() => handleDeletePoint(id)}
          >
            <img src={basket} alt="basket" />
          </Box>
        </Box>
      </div>
    </Box>
  )
}
