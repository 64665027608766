import { FC, useState } from "react"
import { useClickOutside } from "../../../hooks/useClickOutside"
import vectorMini from "../../../assets/fonts/vectorMini.svg"
import vectorSelect from "../../../assets/fonts/vectorSelect.svg"
import "./styles.scss"

type Props = {
  label: string
  list: string[]
  value: string | null | undefined
  placeholder: string
  less?: boolean
  handleSelect: (value: string) => void;
  isRequired?: boolean;
  onChangeClose?: boolean;
  isError?: boolean
}

export const Select: FC<Props> = ({
  label,
  list,
  value,
  placeholder,
  less,
  handleSelect,
  isRequired,
  onChangeClose,
  isError,
}) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(!open)
  }

  const handleBlur = () => {
    setOpen(false)
  }

  const ref = useClickOutside({ handler: handleBlur })

  return (
    <div
      className={`visit-report-select ${
        less ? "visit-report-select_less" : ""
      }`}
      ref={ref}
    >
      <label className="visit-report-select__label">{label}{isRequired && <span style={{color: "red"}}>*</span>}</label>
      <span
        className={`visit-report-select__span ${
          open && "visit-report-select_active-arrow"
        }`}
      >
        <img src={vectorMini} alt="vector-mini" />
      </span>
      <div className={`visit-report-select__input ${isError ? "error" : ""}`} onClick={handleOpen}>
        {value ? value : placeholder}
      </div>
      <div
        className={`visit-report-select__list-container ${
          open && "visit-report-select_open"
        }`}
      >
        {list.map((elem) => {
          return (
            <div
              className={`visit-report-select__list ${
                value === elem && "visit-report-select_active"
              }`}
              key={elem}
              onClick={() => {
                if(onChangeClose) handleOpen();
                handleSelect(elem)
              }}
            >
              {elem}
              <div
                className={`visit-report-select__list-arrow ${
                  value === elem && "visit-report-select_active-check"
                }`}
              >
                <img src={vectorSelect} alt="arrow" />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
