import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import { FC } from "react"
import { SavedPoint } from "../SavedPoint"
import { StepContent } from "@mui/material"
import { ChosenHousehold } from "../Layout"
import "./styles.scss"

interface IProps {
  chosenHouseholds: ChosenHousehold[]
  dragStartHandler: (e: any, card: any) => void
  dragEndHandler: (e: any) => void
  dragOverHandler: (e: any) => void
  dropHandler: (e: any, card: any) => void
  setOpenDeleteModal: (value: boolean) => void
  setDeletingID: (id: number) => void
}

export const VerticalStepper: FC<IProps> = ({
  chosenHouseholds,
  dragStartHandler,
  dragEndHandler,
  dragOverHandler,
  dropHandler,
  setDeletingID,
  setOpenDeleteModal,
}) => {
  return (
    <Stepper orientation="vertical" className="home-page-stepper">
      {chosenHouseholds?.map((item: ChosenHousehold) => {
        const coordinates = item.lat + "; " + item.lng
        return (
          <Step
            key={item.household_id}
            active
            className="home-page-stepper__step"
          >
            <StepLabel />
            <StepContent className="home-page-stepper__content">
              <div
                className="home-page-stepper__point"
                draggable
                onDragStart={(e) => dragStartHandler(e, item)}
                onDragLeave={dragEndHandler}
                onDragEnd={dragEndHandler}
                onDragOver={dragOverHandler}
                onDrop={(e) => dropHandler(e, item)}
              >
                <SavedPoint
                  name={item.name}
                  address={item?.address}
                  id={item.household_id}
                  coordinates={coordinates}
                  setDeletingID={setDeletingID}
                  setOpenDeleteModal={setOpenDeleteModal}
                />
              </div>
            </StepContent>
          </Step>
        )
      })}
    </Stepper>
  )
}
