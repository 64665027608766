import { all } from "redux-saga/effects";
import { addressWatcher } from "./addressSaga";
import { authWatcher } from "./authSaga";
import { categoriesWatcher } from "./categoriesSaga";
import { householdsWatcher } from "./householdsSaga";
import { personWatcher } from "./personSaga";
import { routesWatcher } from "./routesSaga";
import { visitsWatcher } from "./visitsSaga";
import { teamWatcher } from "./teamSaga";
import {createWrapper} from "../../lib/utils";
import { notificationsWatcher } from "./notificationsSaga";

export const SagaWrapper = createWrapper();

export function* rootWatcher() {
  yield all([
    authWatcher(),
    householdsWatcher(),
    personWatcher(),
    addressWatcher(),
    categoriesWatcher(),
    visitsWatcher(),
    routesWatcher(),
    teamWatcher(),
    notificationsWatcher(),
  ]);
}
