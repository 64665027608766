import { put, takeEvery } from "redux-saga/effects"
import { updateToken } from "../../utils/updateToken"
import { visitReportsAPI } from "../api/api"
import { generalActions } from "../store/reducers/generalReducer"
import { actions, actionTypes } from "../store/reducers/visitsReducers"
import { SagaWrapper } from './index';

function* getAllVisits({ payload }: any) {
  try {
    yield put(actions.toggleIsLoading(true))
    const response: { status: number; data: { data: {} } } =
      yield visitReportsAPI.getAllVisits({ ...payload })
    if (response.status === 200) {
      updateToken()
      yield put(actions.getAllVisitsAC(response.data))
      yield put(actions.toggleIsLoading(false))
    }
  } catch (error) {
    console.log(error)
  }
}

function* getVisits(payload: any) {
  try {
    const response: { status: number; data: { data: {} } } =
      yield visitReportsAPI.getVisits(payload.id, payload.page, payload.perPage, payload.scheduled)
    if (response.status === 200) {
      updateToken()
      if (!payload.scheduled) {
        yield put(actions.getVisitsAC(response.data))
      } else {
        yield put(actions.getScheduledVisitsAC(response.data))
      }
    }
  } catch (error) {
    console.log(error)
  }
}

function* getStatuses() {
  try {
    const response: { status: number; data: { data: {} } } =
      yield visitReportsAPI.getStatuses()
    if (response.status === 200) {
      updateToken()
      yield put(actions.getStatusesAC(response.data))
    }
  } catch (error) {
    console.log(error)
  }
}

function* getReactions() {
  try {
    const response: { status: number; data: { data: {} } } =
      yield visitReportsAPI.getReactions()
    if (response.status === 200) {
      updateToken()
      yield put(actions.getReactionsAC(response.data))
    }
  } catch (error) {
    console.log(error)
  }
}

function* getAddressError() {
  try {
    const response: { status: number; data: { data: {} } } =
      yield visitReportsAPI.getAddressError()
    if (response.status === 200) {
      updateToken()
      yield put(actions.getAddressErrorAC(response.data))
    }
  } catch (error) {
    console.log(error)
  }
}

function* getNextVisit(payload: any) {
  try {
    const response: { status: number; data: { data: {} } } =
      yield visitReportsAPI.getNextHouseholdVisit(payload.id)
    if (response.status === 200) {
      updateToken()
      yield put(actions.getNextVisitAC(response.data.data))
    }
  } catch (error) {
    console.log(error)
  }
}

function* createNextVisit(payload: any) {
  try {
    const response: { status: number; data: { data: {} } } =
      yield visitReportsAPI.createNextVisit(payload.id, payload.data)

    if (response.status === 200) {
      updateToken()
      yield put(
        generalActions.successAC({ message: "Created", type: "success" })
      )
      yield getVisits({ id: payload.userId, scheduled: 1 })
      yield getVisits({ id: payload.userId, scheduled: 0 })
    }
  } catch (error: any) {
    yield put(
      generalActions.errorAC({
        message: error.response.data.message,
        type: "error",
      })
    )
  }
}

function* creationNextVisit({ request, action, dispatchResponse }: any) {
  const { data, error } = yield request({
    method: "put",
    endpoint: `/api/auth/visits/${action.payload.id}`,
    body: action.payload.data
  })

  yield dispatchResponse(
    { data, error },
    actionTypes.SUCCESS_CREATION_NEXT_VISIT,
    actionTypes.ERROR_CREATION_NEXT_VISIT
  )
}

export function* visitsWatcher() {
  yield takeEvery(actionTypes.GET_ALL_VISITS_TRIGGER, getAllVisits)
  yield takeEvery(actionTypes.GET_VISITS_TRIGGER, getVisits)
  yield takeEvery(actionTypes.GET_STATUSES_TRIGGER, getStatuses)
  yield takeEvery(actionTypes.GET_REACTIONS_TRIGGER, getReactions)
  yield takeEvery(actionTypes.GET_ADDRESS_ERROR_TRIGGER, getAddressError)
  yield takeEvery(actionTypes.GET_NEXT_VISIT_TRIGGER, getNextVisit)
  yield takeEvery(actionTypes.CREATE_NEXT_VISIT_TRIGGER, createNextVisit)
  yield takeEvery(actionTypes.CREATION_NEXT_VISIT, SagaWrapper.withRequest(creationNextVisit))
}
