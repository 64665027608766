import { Box, Button } from "@mui/material";
import unverified from "../../assets/images/unverified.png";
import { authTriggerActions } from "../../backend/store/reducers/authReducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
export const UnAuthPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = () => {
    dispatch(authTriggerActions.logoutUserTriggerAC());
    navigate("/login");
  };
  
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh", // This ensures the Box takes the full height of the viewport
      }}
    >
      <img
        src={unverified}
        alt=""
        style={{ width: 240, height: 240, marginBottom: "10px" }} // Adds some space between the image and text
      />
      <h1 className="notification__info">You are Registered</h1>
      <p
        style={{
          textAlign: "center",
          width: "20%",
          fontSize: 14,
          marginTop: 5,
        }}
      >
        But you need to wait for administrator confirmation and you will be able
        to manage the panel
      </p>
      <Button
        onClick={logout}
        variant="contained"
        style={{
          background: "#25245E",
          color: "white",
          borderRadius: "50px",
          padding: "10px 30px",
          marginTop: "20px",
          textTransform: "none",
        }}
      >
        Go Out
      </Button>
    </Box>
  );
};
