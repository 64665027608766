import React, {Dispatch, FC, SetStateAction} from 'react';
import './styled.scss';
import { Modal } from '@mui/material';
import {FilterHeader20} from '../../base/FilterHeader20';
import {WhiteButton} from '../../HomePage/WhiteButton';
import {RedButton} from '../../HomePage/RedButton';
import deleteHouseholdsIcon from '../../../assets/images/DeleteHouseholdsIcon.svg'

interface IDeleteHouseholdsModalProps {
    isVisible: boolean,
    setIsVisible: Dispatch<SetStateAction<boolean>>
    handleDeleteHouseholds: () => void;
    selectedCount: number
}

export const DeleteHouseholdsModal:
    FC<IDeleteHouseholdsModalProps> = ({
                                           isVisible,
                                           setIsVisible,
                                           handleDeleteHouseholds,
                                           selectedCount
    }) => {
    return (
        <Modal
            sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            open={isVisible}
            onClose={() => setIsVisible(prevState => !prevState)}
        >
            <div className='delete-households-modal'>
                <FilterHeader20
                    title="DELETING HOUSEHOLDS"
                    disableUnderline
                    onClose={() => setIsVisible(!isVisible)}
                />
                <div className='delete-households-modal__body'>
                    <img src={deleteHouseholdsIcon} alt='delete-households-icon' />
                    <h5 className='delete-households-modal__title'>{selectedCount} entries selected</h5>
                    <p className='delete-households-modal__subtite'>Are you sure to delete choosing households?</p>
                </div>
                <div className='delete-households-modal__footer'>
                    <WhiteButton
                        forModal
                        title={"Cancel"}
                        onClick={() => setIsVisible(prevState => !prevState)} />
                    <RedButton
                        forModal
                        title={'Delete Anyway'}
                        onClick={handleDeleteHouseholds}
                    />
                </div>
            </div>
        </Modal>
    )
}