import { InferActionsTypes } from "../index";

export const actionTypes = {
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
  GET_NOTIFICATIONS_COUNT: "GET_NOTIFICATIONS_COUNT",
  GET_NOTIFICATIONS_COUNT_SUCCESS: "GET_NOTIFICATIONS_COUNT_SUCCESS",
  MARK_NOTIFICATION_AS_READ: "MARK_NOTIFICATION_AS_READ",
  MARK_NOTIFICATION_AS_READ_SUCCESS: "MARK_NOTIFICATION_AS_READ_SUCCESS",
  MARK_NOTIFICATION_AS_READ_ERROR: "MARK_NOTIFICATION_AS_READ_ERROR",
  CHANGE_REGULAR_CATEGORIES: "CHANGE_REGULAR_CATEGORIES",
  CHANGES_REGULAR_CATEGORIES_SUCCESS: "CHANGES_REGULAR_CATEGORIES_SUCCESS",
  CHANGES_REGULAR_CATEGORIES_ERROR: "CHANGES_REGULAR_CATEGORIES_ERROR",
  CHANGE_SCHEDULE: "CHANGE_SCHEDULE",
  CHANGE_SCHEDULE_SUCCESS: "CHANGE_SCHEDULE_SUCCESS",
  CHANGE_SCHEDULE_ERROR: "CHANGE_SCHEDULE_ERROR",
  UPDATE_UPDATED_PERSONS: 'UPDATE_UPDATED_PERSONS',
  UPDATE_UPDATED_PERSONS_SUCCESS: 'UPDATE_UPDATED_PERSONS_SUCCESS',
  UPDATE_UPDATED_PERSONS_ERROR: 'UPDATE_UPDATED_PERSONS_ERROR',
  UPDATE_ADDRESS_NOTIFICATION: "UPDATE_ADDRESS_NOTIFICATION",
  UPDATE_ADDRESS_NOTIFICATION_SUCCESS: "UPDATE_ADDRESS_NOTIFICATION_SUCCESS",
  UPDATE_ADDRESS_NOTIFICATION_ERROR: "UPDATE_ADDRESS_NOTIFICATION_ERROR",
  UPDATE_ADDED_PERSON_NOTIFICATION: "UPDATE_ADDED_PERSON_NOTIFICATION",
  UPDATE_ADDED_PERSON_NOTIFICATION_SUCCESS: "UPDATE_ADDED_PERSON_NOTIFICATION_SUCCESS",
  UPDATE_ADDED_PERSON_NOTIFICATION_ERROR: "UPDATE_ADDED_PERSON_NOTIFICATION_ERROR",
  CONFIRM_UPDATE_ADDED_PERSON: "CONFIRM_UPDATE_ADDED_PERSON",
  CONFIRM_UPDATE_ADDED_PERSON_SUCCESS: "CONFIRM_UPDATE_ADDED_PERSON_SUCCESS",
  CONFIRM_UPDATE_ADDED_PERSON_ERROR: "CONFIRM_UPDATE_ADDED_PERSON_ERROR",
  CONFIRM_UPDATE_UPDATED_PERSONS: "CONFIRM_UPDATE_UPDATED_PERSONS",
  CONFIRM_UPDATE_UPDATED_PERSONS_SUCCESS: "CONFIRM_UPDATE_UPDATED_PERSONS_SUCCESS",
  CONFIRM_UPDATE_UPDATED_PERSONS_ERROR: "CONFIRM_UPDATE_UPDATED_PERSONS_ERROR",
  SET_SELECTED_NOTIFICATION: "SET_SELECTED_NOTIFICATION",
  SET_VISIBILITY_NOTIFICATION_MODAL: "SET_VISIBILITY_NOTIFICATION_MODAL",
  DELETE_NOTIFICATION_TRIGGER: "DELETE_NOTIFICATION_TRIGGER",
  DELETE_NOTIFICATION_SUCCESS: "DELETE_NOTIFICATION_SUCCESS",
  DELETE_NOTIFICATION_ERROR: "DELETE_NOTIFICATION_ERROR",
};

type InitialState = {
  notifications: null;
  notificationsCount: null;
  selectedNotification: null | string,
  isVisibleNotificationModal: boolean
};

const initialState: InitialState = {
  notifications: null,
  notificationsCount: null,
  selectedNotification: null,
  isVisibleNotificationModal: false,
};

export const notificationsReducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case actionTypes.GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
    };

    case actionTypes.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
      };
    case actionTypes.GET_NOTIFICATIONS_COUNT:
      return {
        ...state,
        notificationsCount: action.payload,
      };
    case actionTypes.GET_NOTIFICATIONS_COUNT_SUCCESS:
      return {
        ...state,
        notificationsCount: action.payload,
      };
    case actionTypes.SET_SELECTED_NOTIFICATION:
      return {
        ...state,
        selectedNotification: action.payload
      }
    case actionTypes.SET_VISIBILITY_NOTIFICATION_MODAL:
      return {
        ...state,
        isVisibleNotificationModal: action.payload
      }
    default:
      return state;
  }
};

export const actions = {
  getNotificationsAC: (data: any) => ({
    type: actionTypes.GET_NOTIFICATIONS,
    payload: data,
  }),
  getNotificationsCountAC: (data: any) => ({
    type: actionTypes.GET_NOTIFICATIONS_COUNT,
    payload: data,
  }),
  setSelectedNotification: (data: string | null) => ({
    type: actionTypes.SET_SELECTED_NOTIFICATION,
    payload: data
  }),
  setVisibilityNotificationModal: (data: boolean) => ({
    type: actionTypes.SET_VISIBILITY_NOTIFICATION_MODAL,
    payload: data
  })
};

export const notificationsTriggerActions = {
  getNotificationsTriggerAC: (currentPage: number, perPageValue: string, typeSearch?: string[]) => ({
    type: actionTypes.GET_NOTIFICATIONS,
    page: currentPage,
    perPage: perPageValue,
    typeSearch,
  }),
  getNotificationsCountTriggerAC: () => ({
    type: actionTypes.GET_NOTIFICATIONS_COUNT,
  }),
  markNotificationAsReadTriggerAC: (id: string) => ({
    type: actionTypes.MARK_NOTIFICATION_AS_READ,
    id,
  })
};

export type Actions = InferActionsTypes<typeof actions>;
