import React, {Dispatch, FC, SetStateAction} from 'react';
import {Modal} from '@mui/material';
import checkIcon from "../../../assets/images/CheckIcon.svg";
import './styles.scss';

interface ISuccessfulCreationHouseholdModalProps {
    isVisible: boolean;
    setIsVisible: Dispatch<SetStateAction<boolean>>
}

export const SuccessfulCreationHouseholdModal: FC<ISuccessfulCreationHouseholdModalProps> = ({ isVisible, setIsVisible }) => {
    return (
        <Modal
            sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            open={isVisible} onClose={() => setIsVisible(!isVisible)}>
            <div className={'successful-creation-household-modal'}>
                <img src={checkIcon} alt={'check-icon'} />
                <h5 className={'successful-creation-household-modal__title'}>Household created</h5>
            </div>
        </Modal>
    )
}