import { InferActionsTypes } from "./../index"

export const actionTypes = {
  GET_SALUTATIONS_TRIGGER: "GET_SALUTATIONS_TRIGGER",
  GET_SALUTATIONS: "GET_SALUTATIONS",
}

type InitialState = {
  salutations: null
}

const initialState: InitialState = {
  salutations: null,
}

export const personsReducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case actionTypes.GET_SALUTATIONS:
      return { ...state, salutations: action.payload }

    default:
      return state
  }
}

export const actions = {
  getSalutationsAC: (data: any) => ({
    type: actionTypes.GET_SALUTATIONS,
    payload: data,
  }),
}

export const personTriggerActions = {
  getSalutationsTriggerAC: () => ({
    type: actionTypes.GET_SALUTATIONS_TRIGGER,
  }),
}

export type Actions = InferActionsTypes<typeof actions>
