import { Modal } from "@mui/material"
import { FC, ReactNode } from "react"
import { FilterHeader } from "../FilterHeader"
import { Footer } from "../Footer"
// import { VisitReportsStepper } from "../VisitReportsStepper"
// import { VisitReportsStepper } from "../VisitReportsStepperCheckBox"
import arrow from "../../../assets/fonts/blueArrowLeft.svg"
import "./styles.scss"
import { useTranslation } from "../../../hooks/useTranslation"

type Props = {
  children: ReactNode
  open: boolean
  stepCount: number
  setOpen: (value: boolean) => void
  setStep: (value: string) => void
  handleNextStep: () => void
  setStepCount: (value: number) => void
  handleSaveReport: () => void;
}

export const VisitReportModal: FC<Props> = ({
  children,
  open,
  stepCount,
  setOpen,
  setStep,
  handleNextStep,
  setStepCount,
  handleSaveReport
}) => {
  const { p } = useTranslation('contact_info');
  const handleBackStep = () => {
    if (stepCount > 0) {
      setStepCount(stepCount - 1)
    }
  }

  return (
    <Modal
      disableAutoFocus
      disableRestoreFocus
      disableEnforceFocus
      open={open}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
    >
      <div className="visit-report-modal">
        <div className="visit-report-modal__header">
          <FilterHeader
            title={p('adding_visit_report')}
            onClose={() => setOpen(false)}
          />
          {stepCount !== 0 && (
              <div className="visit-report-modal__backstep">
                <div className="visit-report-modal__backstep__container">
                  <img
                      src={arrow}
                      alt="arrow"
                      className="visit-report-modal__backstep-arrow"
                      onClick={handleBackStep}
                  />
                  Back to the previous step
                </div>
              </div>
          )}
          {/*<div className="visit-report-modal__sepper">*/}
          {/*  <VisitReportsStepper stepCount={stepCount} setStep={setStep} />*/}
          {/*</div>*/}
        </div>
        <div className="visit-report-modal__container">{children}</div>
        <div>
          <Footer handleSaveReport={handleSaveReport} step={stepCount} handleNextStep={handleNextStep} />
        </div>
      </div>
    </Modal>
  )
}
