import { all, put, takeLatest } from "redux-saga/effects";
import { IResponse, Notification } from "../../types/global/types";
import { notificationsAPI } from "../api/api";
import { actionTypes } from "../store/reducers/notificationsReducer";
import { SagaWrapper } from "./index";

function* getNotifications(payload: any) {
  try {
    const response: IResponse<Notification> = yield notificationsAPI.getNotifications({
      ...payload,
    });

    if (response.status === 200) {
      yield put({
        type: actionTypes.GET_NOTIFICATIONS_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getUnreadNotificationsCount() {
  try {
    const response: { 
      status: number; 
      data: { 
        numberOfUnreadNotifications: number 
      }
    } = yield notificationsAPI.getUnreadNotificationsCount();
    
    if (response.status === 200) {
      yield put({
        type: actionTypes.GET_NOTIFICATIONS_COUNT_SUCCESS,
        payload: response.data.numberOfUnreadNotifications,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* markNotificationAsRead({ request, action, dispatchResponse }: any) {  
  const { data, error } = yield request({
    method: 'post',
    endpoint: `/api/auth/notifications/${action.payload.id}/mark-as-read`,
  });

  yield dispatchResponse(
      { data, error },
      actionTypes.MARK_NOTIFICATION_AS_READ_SUCCESS,
      actionTypes.MARK_NOTIFICATION_AS_READ_ERROR,
  );
}

function* changeRegularCategoriesNotification({ request, action, dispatchResponse }: any) {
  const { data, error } = yield request({
    method: "post",
    endpoint: `/api/auth/notifications/${action.payload.id}/change-categories`,
    body: { categories: action.payload.data }
  })

  yield dispatchResponse(
      { data, error },
      actionTypes.CHANGES_REGULAR_CATEGORIES_SUCCESS,
      actionTypes.CHANGES_REGULAR_CATEGORIES_ERROR,
  )
}

function* changeScheduleNotification({ request, action, dispatchResponse }: any) {
  const { data, error } = yield request({
    method: "post",
    endpoint: `/api/auth/notifications/${action.payload.id}/change-periodicity`,
    body: { periodicity: action.payload.data }
  })

  yield dispatchResponse(
      { data, error },
      actionTypes.CHANGE_SCHEDULE_SUCCESS,
      actionTypes.CHANGE_SCHEDULE_ERROR,
  )
}

function* updateUpdatedPersons({ request, action, dispatchResponse }: any) {
  const { data, error } = yield request({
    method: 'post',
    endpoint: `/api/auth/notifications/${action.payload.id}/update-updated-persons`,
    body: { updated_persons: action.payload.data }
  })

  yield dispatchResponse(
      { data, error },
      actionTypes.UPDATE_UPDATED_PERSONS_SUCCESS,
      actionTypes.UPDATE_UPDATED_PERSONS_ERROR,
  )
}

function* confirmUpdateUpdatedPersons({ request, action, dispatchResponse }: any) {
  const { data, error } = yield request({
    method: 'post',
    endpoint: `/api/auth/notifications/${action.payload.id}/confirm-updated-persons`,
    body: { updated_persons: action.payload.data }
  })

  yield dispatchResponse(
      { data, error },
      actionTypes.CONFIRM_UPDATE_UPDATED_PERSONS_SUCCESS,
      actionTypes.CONFIRM_UPDATE_UPDATED_PERSONS_ERROR,
  )
}

function* updateAddress({ request, action, dispatchResponse }: any) {
  const { data, error } = yield request({
    method: 'post',
    endpoint: `/api/auth/notifications/${action.payload.id}/update-new-address`,
    body: action.payload.data
  })

  yield dispatchResponse(
      { data, error },
      actionTypes.UPDATE_ADDRESS_NOTIFICATION_SUCCESS,
      actionTypes.UPDATE_ADDRESS_NOTIFICATION_ERROR,
  )
}

function* updateAddedPerson({ request, action, dispatchResponse }: any) {
  const { data, error } = yield request({
    method: 'post',
    endpoint: `/api/auth/notifications/${action.payload.id}/update-new-persons`,
    body: { new_persons: action.payload.data }
  })

  yield dispatchResponse(
      { data, error },
      actionTypes.UPDATE_ADDED_PERSON_NOTIFICATION_SUCCESS,
      actionTypes.UPDATE_ADDED_PERSON_NOTIFICATION_ERROR,
  )
}

function* confirmUpdateAddedPerson({ request, action, dispatchResponse }: any) {
  const { data, error } = yield request({
    method: "post",
    endpoint: `/api/auth/notifications/${action.payload.id}/confirm-updated-persons`,
    body: { new_persons: action.payload.data }
  })

  yield dispatchResponse(
      { data, error },
      actionTypes.CONFIRM_UPDATE_ADDED_PERSON_SUCCESS,
      actionTypes.CONFIRM_UPDATE_ADDED_PERSON_ERROR,
  )
}

function* deleteNotification({ request, action, dispatchResponse }: any) {  
  const { data, error } = yield request({
    method: 'delete',
    endpoint: `/api/auth/notifications/${action.payload.id}`,
  });

  yield dispatchResponse(
      { data, error },
      actionTypes.DELETE_NOTIFICATION_SUCCESS,
      actionTypes.DELETE_NOTIFICATION_ERROR,
  );
}

export function* notificationsWatcher() {
  yield all([
    takeLatest(actionTypes.GET_NOTIFICATIONS, getNotifications),
    takeLatest(actionTypes.GET_NOTIFICATIONS_COUNT, getUnreadNotificationsCount),
    takeLatest(actionTypes.MARK_NOTIFICATION_AS_READ, SagaWrapper.withRequest(markNotificationAsRead)),
    takeLatest(actionTypes.CHANGE_REGULAR_CATEGORIES, SagaWrapper.withRequest(changeRegularCategoriesNotification)),
    takeLatest(actionTypes.CHANGE_SCHEDULE, SagaWrapper.withRequest(changeScheduleNotification)),
    takeLatest(actionTypes.UPDATE_UPDATED_PERSONS, SagaWrapper.withRequest(updateUpdatedPersons)),
    takeLatest(actionTypes.UPDATE_ADDRESS_NOTIFICATION, SagaWrapper.withRequest(updateAddress)),
    takeLatest(actionTypes.UPDATE_ADDED_PERSON_NOTIFICATION, SagaWrapper.withRequest(updateAddedPerson)),
    takeLatest(actionTypes.CONFIRM_UPDATE_ADDED_PERSON, SagaWrapper.withRequest(confirmUpdateAddedPerson)),
    takeLatest(actionTypes.CONFIRM_UPDATE_UPDATED_PERSONS, SagaWrapper.withRequest(confirmUpdateUpdatedPersons)),
    takeLatest(actionTypes.DELETE_NOTIFICATION_TRIGGER, SagaWrapper.withRequest(deleteNotification)),
  ]);

}
