import {Dispatch, FC, SetStateAction} from 'react'
import chevronFont from "../../../assets/fonts/chevron.svg"
import { useClickOutside } from "../../../hooks/useClickOutside"
import "./styles.scss"
import {useTranslation} from "../../../hooks/useTranslation";

interface IProps {
  open: boolean
  value: string
  setGlobalValue: (value: string) => void
  setOpen: (value: boolean) => void
  setIsOpenModal: (value: boolean) => void;
  isDisabled?: boolean;
  setIsVisibleManageCategoriesModal: Dispatch<SetStateAction<boolean>>
}

export const GlobalFilter: FC<IProps> = ({
  open,
  value,
  setOpen,
  setGlobalValue,
  setIsOpenModal,
  isDisabled,
  setIsVisibleManageCategoriesModal
}) => {
  const { p, t, i18n } = useTranslation('contacts');

  const handleSelect = (value: string) => {
    setOpen(false)
    setGlobalValue(value);
    if(value === 'Manage Categories') setIsVisibleManageCategoriesModal(prevState => !prevState)
    if (value === "Import Contacts" || value === "Export Contacts") {
      setIsOpenModal(true)
    }
  }

  const handleBlur = () => {
    setOpen(false)
  }

  const ref = useClickOutside({ handler: handleBlur })

  return (
    <div
      className={`contacts-page-global-filter`}
      ref={ref}
      onClick={() => setOpen(!open)}
    >
      <div
        className={`contacts-page-global-filter__header ${
          open ? "contacts-page-global-filter__header_active" : ""
        }`}
      >
        {p('global_action')}
        <img
          src={chevronFont}
          alt="chevron"
          className={`contacts-page-global-filter__img ${
            open ? "contacts-page-global-filter__img_active" : ""
          }`}
        />
      </div>
      {open && (
        <div className="contacts-page-global-filter__container">
          {[p('import'), p('export'), p('manage')].map(
            (item) => {
              return (
                <div
                  className={`contacts-page-global-filter__item ${
                    value === item
                      ? "contacts-page-global-filter__item_active"
                      : ""
                  }`}
                  key={item}
                  onClick={() => handleSelect(item)}
                >
                  {item}
                </div>
              )
            }
          )}
        </div>
      )}
    </div>
  )
}
