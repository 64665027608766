import {FC, useState} from 'react'
import { Checkbox } from "../../../../base/Checkbox"
import addressIcon from "../../../../../assets/fonts/Location-marker.svg"
import "./styles.scss"
import { Panel } from "../../../Panel"
import { Select } from "../../../Select"
import { Textarea } from "../../../Textarea"
import { FormikType } from "../../../../../types/VisitReports/types"
import { VISITS_ADDRESS } from "../../../../../constants/CreateHousehold"
import { Input } from '../../../../base/Input';
import {FormErrorMessage} from '../../../../base/FormErrorMessage';
import {Autocomplete,  } from '@react-google-maps/api';
import {geocodePlaceId} from '../../../../../utils/geocodePlaceId';
import { useTranslation } from '../../../../../hooks/useTranslation'

type Props = {
  formik: FormikType
  addressesTypes: string[]
  openLeftTown: boolean
  setOpenLeftTown: (value: boolean) => void,
  showCheckbox?: boolean
}

export const LeftTown: FC<Props> = ({
  formik,
  addressesTypes,
  openLeftTown,
  setOpenLeftTown,
  showCheckbox
}) => {
  const { p } = useTranslation('contact_info');

  const [searchResult, setSearchResult] = useState<any>("");

  const handleLeftTown = (value: boolean) => {
    setOpenLeftTown(value)
    if (!openLeftTown) {
      formik.setFieldValue("new_address", VISITS_ADDRESS)
    } else {
      //this syntax deleting field
      formik.setFieldValue("new_address", undefined)
    }
  }

  const handleAddressType = async (value: string) => {
    await formik.setFieldValue("new_address.type", value)
    await formik.setTouched({ ...formik.touched, new_address: { ...(formik.touched?.new_address as any), type: true } })
  }

  const handleAddress = async (e: any) => {
    if(!e.target.value) return formik.setFieldValue('new_address', {...VISITS_ADDRESS, type: formik.values?.new_address?.type});
    await formik.setFieldValue(`new_address.fullAddress`, e.target.value);
  }

  const handleAddressNote = (e: any) => {
    formik.setFieldValue(`new_address.info_note`, e.target.value)
  }

  const onLoad = (autocomplete: any) => {
    setSearchResult(autocomplete);
  }

  const handleCoordinates = (
      place: any,
  ) => {
    const latitude = place.geometry.location.lat();
    const longitude = place.geometry.location.lng();

    const streetNumber = place.address_components.find((element: any) => {
      return element.types[0] === "street_number";
    });

    const streetName = place.address_components.find((element: any) => {
      return element.types[0] === "route";
    });
    const city = place.address_components.find((element: any) => {
      return element.types[0] === "locality";
    });
    const state = place.address_components.find((element: any) => {
      return element.types[0] === "administrative_area_level_1";
    });
    const country = place.address_components.find((element: any) => {
      return element.types[0] === "country";
    });
    const zipCode = place.address_components.find((element: any) => {
      return element.types[0] === "postal_code";
    });
    const placeId = place.place_id;
    formik.setFieldValue(
        `new_address.unit_number`,
        streetNumber ? streetNumber.long_name : ""
    );
    formik.setFieldValue(
        `new_address.street_name`,
        streetName ? streetName.long_name : ""
    );
    formik.setFieldValue(
        `new_address.city`,
        city ? city.long_name : ""
    );
    formik.setFieldValue(
        `new_address.state`,
        state ? state.long_name : ""
    );
    formik.setFieldValue(
        `new_address.country`,
        country ? country.long_name : ""
    );
    formik.setFieldValue(
        `new_address.zip_code`,
        zipCode ? zipCode.long_name : ""
    );
    formik.setFieldValue(
        `new_address.place_id`,
        placeId
    );

    formik.setFieldValue(
        `new_address.latitude`,
        latitude
    );
    formik.setFieldValue(
        `new_address.longitude`,
        longitude
    );
  };


  const onPlaceChanged = async () => {
    if (searchResult != null) {
      const place = searchResult.getPlace();

      const address = (await geocodePlaceId(place.place_id)) as string;

      handleCoordinates(place);
      formik.setFieldValue(
          `new_address.fullAddress`,
          address
      );
    }
  };

  return (
    <div className="left-town-report">
      {showCheckbox && (
          <div className="left-town-report__checkbox">
            <Checkbox
                label={p('left_town')}
                checked={openLeftTown}
                onCheck={() => handleLeftTown(!openLeftTown)}
            />
          </div>
      )}
      <div
        className={`left-town-report__container ${
          !openLeftTown ? "left-town-report__container_closed" : ""
        }`}
      >
        <div className="left-town-report__panel">
          <Panel icon={addressIcon} title={p('new_address')}/>
        </div>
        <div className="left-town-report__select">
          <Select
            isRequired
            onChangeClose
            label={p('type')}
            list={addressesTypes}
            value={formik.values.new_address?.type}
            placeholder={p('select')}
            handleSelect={handleAddressType} isError={
              (formik.touched?.new_address as unknown as { type: boolean })?.type && !!(formik.errors?.new_address as unknown as { type: string })?.type
          }
          />
          {(formik.touched?.new_address as unknown as { type: boolean })?.type && (formik.errors?.new_address as unknown as { type: string })?.type && (
              <FormErrorMessage message={(formik.errors.new_address as unknown as { type: string })['type']} />
          )}
        </div>
        <div className="left-town-report__input" onChange={() => {
          formik.setTouched({ ...formik.touched, new_address: { ...(formik.touched?.new_address as any), place_id: true } })
        }}>
          <Autocomplete
              onLoad={onLoad}
              onPlaceChanged={onPlaceChanged}
              types={["address"]}
          >
            <Input
                isRequired
                label={p('address')}
                value={formik.values.new_address?.fullAddress || ""}
                placeholder={p('write_address')}
                onChange={handleAddress}
                isError={(formik.touched?.new_address as unknown as { place_id: boolean })?.place_id &&
                !!(formik.errors?.new_address as unknown as { place_id: string })?.place_id}
            />
          </Autocomplete>
          {(formik.touched?.new_address as unknown as { place_id: boolean })?.place_id &&
              !!(formik.errors?.new_address as unknown as { place_id: string })?.place_id && (
                  <FormErrorMessage message={(formik.errors.new_address as unknown as { place_id: string })?.place_id} />
              )}
        </div>
        <div className="left-town-report__input">
          <Input
            isRequired
            label={p('coordinates')}
            value={`${formik.values.new_address?.latitude ? `${formik.values.new_address?.latitude}; ${formik.values.new_address?.longitude}` : ""}`}
            placeholder={p('write_coordinates')}
            onChange={() => {}}
            isDisabled
          />
        </div>
        <div>
          <Textarea
            label={p('address_note')}
            placeholder={p('write_some_note')}
            value={formik.values.new_address?.info_note}
            onChange={handleAddressNote}
          />
        </div>
      </div>
    </div>
  )
}
