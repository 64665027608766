import { FC } from "react"
import routeMarker from "../../../assets/fonts/routeMarker.svg"
import closeIconFont from "../../../assets/fonts/close.svg"
import "./styles.scss"
import { useTranslation } from "../../../hooks/useTranslation"

interface IProps {
  onClick?: () => void
}

export const RouteHeader: FC<IProps> = ({ onClick }) => {
  const { p } = useTranslation('map');
  return (
    <div className="home-page-route-header">
      <div className="home-page-route-header__container">
        <div>
          <img src={routeMarker} alt="marker" />
        </div>
        <div className="home-page-route-header__title">{ p('route') }</div>
      </div>
      <img
        src={closeIconFont}
        alt="cross"
        className="home-page-route-header__cross"
        onClick={onClick}
      />
    </div>
  )
}
