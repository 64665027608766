import {ChangeEvent, Dispatch, FC, SetStateAction, useEffect} from 'react'
import { RadioButton } from "../../../../base/RadioButton"
import { Checkbox } from "../../../../base/Checkbox"
import { Textarea } from "../../../Textarea"
import { FormikType } from "../../../../../types/VisitReports/types"
import "./styles.scss"
import {FormErrorMessage} from '../../../../base/FormErrorMessage';
import { useTranslation } from '../../../../../hooks/useTranslation'

type Props = {
  formik: FormikType
  addressError: string[]
  openAddressError: boolean
  setOpenAddressError: Dispatch<SetStateAction<boolean>>
}

export const AddressError: FC<Props> = ({
  formik,
  addressError,
  openAddressError,
  setOpenAddressError,
}) => {
  const { p } = useTranslation('contact_info');
  const handleAddressErrorDetails = (e: any) => {
    formik.setFieldValue("address_error_details", e.target.value)
  }

  useEffect(() => {
    if(openAddressError) {
      formik.setFieldValue('address_error_options', "Address not found")
    } else {
      formik.setFieldValue('address_error_options', null)
    }
  }, [openAddressError])

  return (
    <div className="address-error-report">
      <div className="address-error-report__checkbox">
        <Checkbox
          label={p('address_error')}
          checked={openAddressError}
          onCheck={() => {
            setOpenAddressError(!openAddressError)
          }}
        />
      </div>
      <div
        className={`address-error-report__container ${
          !openAddressError ? "address-error-report__container_closed" : ""
        }`}
      >
        <div className="address-error-report__radio">
          <RadioButton
            label={p('address_not_found')}
            checked={formik.values.address_error_options === "Address not found"}
            value={addressError[0]}
            name="address-error"
            onClick={(event: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('address_error_options', event.target.value)}
          />
        </div>
        <div className="address-error-report__radio">
          <RadioButton
            label={p('not_at_address')}
            checked={formik.values.address_error_options === "Contact not at address"}
            value={addressError[1]}
            name="address-error"
            onClick={(event: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('address_error_options', event.target.value)}
          />
          <div className='address-error-report__error-message'>
            {formik.touched['address_error_options'] && formik.errors['address_error_options'] && (
                <FormErrorMessage message={'Need to select address error option'} />
            )}
          </div>
        </div>
        <div className="address-error-report__textarea">
          <Textarea
            label={p('not_at_address_details')}
            placeholder={p('write_your_text')}
            value={formik.values.address_error_details}
            onChange={handleAddressErrorDetails}
          />
        </div>
      </div>
    </div>
  )
}
