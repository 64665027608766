import { Dispatch, FC, SetStateAction, useContext, useState } from "react";
import { RegularCategoriesFilter } from "../RegularCategoriesFilter/RegularCategoriesFilter";
import { CustomLinkButton } from "../../base/CustomLinkButton/CustomLinkButton";
import { CustomSearch } from "../CustomSearch/CustomSearch";
import { useDispatch } from "react-redux";
// import { householdsActions, householdsTriggerActions } from "../../../backend/store/reducers/householdsReducer";
import { Preloader } from "../../base/Preloader/Preloader";
import { PerPageFilter } from "../PerPageFilter";
import Info from "../../../assets/images/info.svg";
import "./VisitsList.scss";
import { updateHousehold } from "../../../backend/store/actions/creators/household";
import { GlobalActionsPanel } from "../GlobalActionsPanel";
import { EmptyStateContactsPage } from "../EmptyStateContactsPage";
import emptyStateImg from "../../../assets/images/EmptyStateContactsWIthMagnifyingGlass.svg";
import { ScheduledCategoriesFilter } from "../ScheduledCategoriesFilter";
import { Box } from "@mui/material";
import { SuccessModal } from "../../base/SuccessModal";
import { ErrorModal } from "../../base/ErrorModal";
import { UserContext } from "../../../contexts/UserContext";
import { useTranslation } from "../../../hooks/useTranslation";
import { visitsTriggerActions } from "../../../backend/store/reducers/visitsReducers";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

interface IProps {
  perPageValue: string;
  isLoading: boolean;
  page: number;
  openPerPage: boolean;
  setOpenPerPage: (value: boolean) => void;
  setPerPageValue: (value: string) => void;
  // handleDelete: (id: number) => void;
  visits: any;
  searchValue: string | null;
  handleSearch: (value: string | null) => void;
  categories: any;
  selectAllHouseholds: (val: any) => void;
  handleChecked: (id: number) => void;
  ids: number[];
  totalSelected: () => number;
  checkedGeneralCheckbox: boolean;
  isVisibleUpdateRegularCategories: boolean;
  isVisibleUpdateScheduledCategories: boolean;
  isVisibleDeleteHouseholds: boolean;
  setIsVisibleUpdateRegularCategories: Dispatch<SetStateAction<boolean>>;
  setIsVisibleUpdateScheduledCategories: Dispatch<SetStateAction<boolean>>;
  setIsVisibleDeleteHouseholds: Dispatch<SetStateAction<boolean>>;
  isExistFilterOrSearchParams: boolean;
}


const TableRowWithTooltip = ({ element }: { element: any }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (

    <TableCell style={{ position: 'relative' }}>
      <div style={{ position: 'relative', display: 'flex', alignItems: 'center', gap: '8px', whiteSpace: 'nowrap' }}>
        {element?.name}
        {element?.user_name && <img
          src={Info}
          alt="info"
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          style={{ cursor: 'pointer', width: '16px' }}
        />}
        {showTooltip && (
          <div
            style={{
              position: 'absolute',
              backgroundColor: 'white',
              border: '1px solid #ccc',
              padding: '5px',
              borderRadius: '4px',
              boxShadow: '0 0 10px rgba(0,0,0,0.1)',
              zIndex: 1000,
              top: '20px',
              right: '10px'
            }}
          >
            by {element?.user_name}
          </div>
        )}
      </div>

    </TableCell>
  )
};

export const VisitsList: FC<IProps> = ({
  page,
  isLoading,
  perPageValue,
  openPerPage,
  setOpenPerPage,
  setPerPageValue,
  // handleDelete,
  visits,
  handleSearch,
  searchValue,
  categories,
  selectAllHouseholds,
  handleChecked,
  ids,
  totalSelected,
  checkedGeneralCheckbox,
  setIsVisibleUpdateRegularCategories,
  setIsVisibleUpdateScheduledCategories,
  isExistFilterOrSearchParams,
  setIsVisibleDeleteHouseholds,
}) => {
  const { p } = useTranslation("visits");

  const { p: pGeneral } = useTranslation("general");
  const { user } = useContext(UserContext);
  const dispatch = useDispatch();

  const [isSuccessChangedSchedule, setIsSuccessChangedSchedule] =
    useState(false);
  const [isErrorChangedSchedule, setIsErrorChangedSchedule] = useState(false);

  const [isSuccessChangedCategory, setIsSuccessChangedCategory] =
    useState(false);
  const [isErrorChangedCategory, setIsErrorChangedCategory] = useState(false);
  let syncFlight = false;
  const onChangeSchedule = (value: string, id: any) => {
    if (syncFlight) return;
    syncFlight = true;
    dispatch(
      updateHousehold({
        data: { periodicity: value },
        id: id,
        onSuccess: () => {
          syncFlight = false;
          setIsSuccessChangedSchedule(true);
          dispatch(
            visitsTriggerActions.getAllVisitsTriggerAC({
              page: page,
              perPage: perPageValue,
            })
          );
          const timeoutId = setTimeout(() => {
            setIsSuccessChangedSchedule(false);
            clearTimeout(timeoutId);
          }, 2000);
        },
        onError: () => {
          syncFlight = false;
          setIsErrorChangedSchedule(true);
          const timeoutId = setTimeout(() => {
            setIsErrorChangedSchedule(false);
            clearTimeout(timeoutId);
          }, 2000);
        },
      })
    );
  };

  const onChangeCategory = (value: any, id: any) => {
    if (syncFlight) return;
    syncFlight = true;
    dispatch(
      updateHousehold({
        data: { categories: value },
        id: id,
        onSuccess: () => {
          syncFlight = false;
          setIsSuccessChangedCategory(true);
          dispatch(
            visitsTriggerActions.getAllVisitsTriggerAC({
              page: page,
              perPage: perPageValue,
            })
          );
          const timeoutId = setTimeout(() => {
            setIsSuccessChangedCategory(false);
            clearTimeout(timeoutId);
          }, 2000);
        },
        onError: () => {
          syncFlight = false;
          setIsErrorChangedCategory(true);
          const timeoutId = setTimeout(() => {
            setIsErrorChangedCategory(false);
            clearTimeout(timeoutId);
          }, 2000);
        },
      })
    );
  };

  if (!visits) {
    return <Preloader />;
  }

  const filterOptions = ["25", "50", "100"];

  return (
    <>
      {(visits.data?.length ||
        (!visits.data?.length && isExistFilterOrSearchParams) ||
        isLoading) && (
          <>
            <div className="contacts-list">
              <div className="contacts-list__search-panel">
                <Box sx={{ width: { xs: "100%", md: "fit-content" } }}>
                  <CustomSearch
                    text={searchValue || ""}
                    placeholder={p("search_household_name")}
                    handleSearch={handleSearch}
                  />
                </Box>
                <Box
                  sx={{ display: { xs: "none", md: "flex" } }}
                  className="contacts-list__input-container"
                >
                  {pGeneral("showing")}
                  <span>
                    <PerPageFilter
                      value={perPageValue}
                      filterOptions={filterOptions}
                      open={openPerPage}
                      setOpen={setOpenPerPage}
                      setValue={setPerPageValue}
                    />
                  </span>
                  {pGeneral("of")} {visits?.meta?.total} {pGeneral("entries")}
                </Box>
              </div>
              {ids.length !== 0 && (
                <GlobalActionsPanel
                  selectedCount={totalSelected()}
                  onDelete={() =>
                    setIsVisibleDeleteHouseholds((prevState) => !prevState)
                  }
                  onUpdateCategories={() =>
                    setIsVisibleUpdateRegularCategories((prevState) => !prevState)
                  }
                  onUpdateScheduling={() =>
                    setIsVisibleUpdateScheduledCategories(
                      (prevState) => !prevState
                    )
                  }
                />
              )}

              {(isLoading || !visits.data?.length) && (
                <Table className='my-team-list__table'>
                  <TableBody>
                    {isLoading && (
                      <TableRow>
                        <TableCell colSpan={10} className="contacts-list__loader">
                          <Preloader />
                        </TableCell>
                      </TableRow>
                    )}

                    {!visits.data?.length && (
                      <TableRow>
                        <TableCell colSpan={10}>
                          <EmptyStateContactsPage
                            img={emptyStateImg}
                            title={p("visit_not_found")}
                            subtitle={p("try_another_name")}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              )}

              {(!isLoading && !!visits.data?.length) && (
                <TableContainer component={Paper} style={{ overflowX: 'visible' }}>
                  <Table className='my-team-list__table'>
                    <TableHead>
                      <TableRow>
                        <TableCell>{p("household_name")}</TableCell>
                        <TableCell>{p("status")}</TableCell>
                        <TableCell>{p("reaction_name")}</TableCell>
                        <TableCell>{p("visit_details")}</TableCell>
                        <TableCell>{p("follow_up_details")}</TableCell>
                        <TableCell>{p("scheduled_categories")}</TableCell>
                        <TableCell>{p("regular_categories")}</TableCell>
                        <TableCell>{p("contact_info")}</TableCell>
                        <TableCell>{p("duch")}</TableCell>
                        <TableCell>{p("history")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!!visits.data?.length && (
                        visits.data.map(((element: any) => {
                          return (
                            <TableRow key={element.id} className="contacts-list__list">
                              <TableRowWithTooltip
                                key={element.id}
                                element={element}
                              />
                              <TableCell>{element?.status}</TableCell>
                              <TableCell>{element?.reaction_name}</TableCell>
                              <TableCell>{element?.visit_details}</TableCell>
                              <TableCell>{element?.follow_up_details}</TableCell>
                              <TableCell >
                                <ScheduledCategoriesFilter
                                  onChange={(value) => {
                                    onChangeSchedule(value, element.household_id);
                                  }}
                                  label={element.periodicity}
                                  value={element.periodicity}
                                />
                              </TableCell>
                              <TableCell >
                                <RegularCategoriesFilter
                                  id={element.household_id}
                                  onChange={onChangeCategory}
                                  options={
                                    categories?.map(({ id, name }: any) => ({
                                      value: id,
                                      label: name,
                                    })) ?? []
                                  }
                                  value={
                                    element?.categories.map(({ id }: any) => id) ?? []
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <CustomLinkButton
                                  title={p("contact_info")}
                                  link={`/households/update/household-info/${element.household_id}?tab=info`}
                                />
                              </TableCell>
                              <TableCell>
                                <CustomLinkButton
                                  title={p("duch")}
                                  isDisabled={!element.scheduledVisitExists}
                                  link={`/households/update/history/${element.household_id}?tab=duch`}
                                />
                              </TableCell>
                              <TableCell>
                                <CustomLinkButton
                                  title={p("history")}
                                  link={`/households/update/history/${element.household_id}?tab=history`}
                                />
                              </TableCell>
                            </TableRow>
                          )
                        }
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              <Box
                sx={{
                  backgroundColor: "#fff",
                  padding: "15px 0px",
                  display: { xs: "block", md: "none" },
                  borderTop: "1px solid #C5CAE3",
                }}
              >
                <Box
                  sx={{
                    display: { xs: "flex", md: "none" },
                    justifyContent: "center",
                  }}
                  className="contacts-list__input-container"
                >
                  {pGeneral("showing")}
                  <span>
                    <PerPageFilter
                      value={perPageValue}
                      open={openPerPage}
                      setOpen={setOpenPerPage}
                      setValue={setPerPageValue}
                      menuPlacement={"top"}
                    />
                  </span>
                  {pGeneral("of")} {visits?.meta?.total} {pGeneral("entries")}
                </Box>
              </Box>
            </div>
          </>
        )}
      <SuccessModal
        isVisible={isSuccessChangedSchedule}
        setIsVisible={() =>
          setIsSuccessChangedSchedule((prevState) => !prevState)
        }
        title={"The scheduled category updated"}
      />
      <ErrorModal
        isVisible={isErrorChangedSchedule}
        setIsVisible={() =>
          setIsErrorChangedSchedule((prevState) => !prevState)
        }
        title={"Something went wrong"}
      />
      <SuccessModal
        isVisible={isSuccessChangedCategory}
        setIsVisible={() =>
          setIsSuccessChangedCategory((prevState) => !prevState)
        }
        title={"The regular categories updated"}
      />
      <ErrorModal
        isVisible={isErrorChangedCategory}
        setIsVisible={() =>
          setIsSuccessChangedCategory((prevState) => !prevState)
        }
        title={"Something went wrong"}
      />
    </>
  );
};
