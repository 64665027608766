import { InferActionsTypes } from "./../index"

export const actionTypes = {
  ADD_SNACK_MESSAGE_SUCCESS: "ADD_SNACK_MESSAGE_SUCCESS",
  ADD_SNACK_MESSAGE_ERROR: "ADD_SNACK_MESSAGE_ERROR",
  SET_SUCCESS_ACTION: "SET_SUCCESS_ACTION",
  SET_VISIBLE_SIDEBAR: "SET_VISIBLE_SIDEBAR"
}

type InitialState = {
  snackMessage: null | {
    message: string
    type: string
  }
  successAction: boolean,
  visibleSideBar: boolean
}

const initialState: InitialState = {
  snackMessage: null,
  successAction: false,
  visibleSideBar: false
}

export const generalReducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case actionTypes.ADD_SNACK_MESSAGE_SUCCESS:
      return {
        ...state,
        snackMessage: {
          message: action.payload.message,
          type: action.payload.type,
        },
      }
    case actionTypes.ADD_SNACK_MESSAGE_ERROR:
      return {
        ...state,
        snackMessage: {
          message: action.payload.message,
          type: action.payload.type,
        },
      }
    case actionTypes.SET_SUCCESS_ACTION:
      return {
        ...state,
        successAction: action.payload,
      }
    case actionTypes.SET_VISIBLE_SIDEBAR:
      return {
        ...state,
        visibleSideBar: action.payload
      }

    default:
      return state
  }
}

export const generalActions = {
  successAC: (data: { message: string; type: string }) => ({
    type: actionTypes.ADD_SNACK_MESSAGE_SUCCESS,
    payload: data,
  }),
  errorAC: (data: { message: string; type: string }) => ({
    type: actionTypes.ADD_SNACK_MESSAGE_ERROR,
    payload: data,
  }),
  setSuccessActionsAC: (data: any) => ({
    type: actionTypes.SET_SUCCESS_ACTION,
    payload: data,
  }),
  setVisibleSideBar: (data: boolean) => ({
    type: actionTypes.SET_VISIBLE_SIDEBAR,
    payload: data
  })
}

export type Actions = InferActionsTypes<typeof generalActions>
