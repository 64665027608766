import { AddAddressButton } from "../AddAddressButton"
import { Address } from "../Address"
import { ContactInfo } from "../ContactInfo"
import { PersonalInfo } from "../PersonalInfo"
import { PersonHeader } from "../PersonHeader"
import { PersonPanel } from "../PersonPanel"
import marker from "../../../assets/fonts/Location-marker.svg"
import { FC } from "react"
import { FormikType } from "../../../types/VisitReports/types"
import { VISITS_ADDRESS } from "../../../constants/CreateHousehold"
import "./styles.scss"
import { useTranslation } from "../../../hooks/useTranslation"

type Props = {
  formik: FormikType
  personIndex: number
  salutations: string[]
  addressesTypes: string[]
  isUpdate?: boolean
}

export const Person: FC<Props> = ({
  formik,
  personIndex,
  salutations,
  addressesTypes,
  isUpdate,
}) => {
  const { p } = useTranslation('contact_info');
  const path = isUpdate ? "updated_persons" : "new_persons"

  const addAddress = () => {
    formik.setFieldValue(`${path}[${personIndex}].addresses`, [
      ...(formik?.values?.[path]?.[personIndex]?.addresses || []),
      VISITS_ADDRESS,
    ])
  }

  const handleIsPrimaryPerson = (e: any, index: number) => {
    if (e.target.type === "radio") {
      formik?.values?.[path]?.forEach((_, ind) => {
        if (index === ind) {
          formik.setFieldValue(`${path}[${ind}].isMain`, true)
        } else {
          formik.setFieldValue(`${path}[${ind}].isMain`, false)
        }
      })
    }
  }

  const handleDeletePerson = () => {
    formik?.values?.[path]?.splice(personIndex, 1)
    formik.setFieldValue(`${path}`, [...(formik?.values?.[path] || [])])
  }

  const handleSalutation = (value: string) => {
    formik.setFieldValue(`${path}[${personIndex}].salutation`, value)
  }

  const setGender = (value: string) => {
    formik.setFieldValue(`${path}[${personIndex}].gender`, value)
  }

  const handleFirstName = (e: any) => {
    formik.setFieldValue(`${path}[${personIndex}].first_name`, e.target.value)
  }

  const handleLastName = (e: any) => {
    formik.setFieldValue(`${path}[${personIndex}].last_name`, e.target.value)
  }

  const setIsJewish = (e: any) => {
    formik.setFieldValue(
      `${path}[${personIndex}].is_jewish`,
      e.target.checked ? 1 : 0
    )
  }

  const setIsChild = (e: any) => {
    formik.setFieldValue(
      `${path}[${personIndex}].is_child`,
      e.target.checked ? 1 : 0
    )
  }

  const handleTextarea = (e: any) => {
    formik.setFieldValue(`${path}[${personIndex}].info_note`, e.target.value)
  }

  const handleAddressType = (value: string, addressIndex: number) => {
    formik.setFieldValue(
      `${path}[${personIndex}].addresses[${addressIndex}].type`,
      value
    )
  }

  const handleAddress = (e: any, addressIndex: number) => {
    formik.setFieldValue(
      `${path}[${personIndex}].addresses[${addressIndex}].fullAddress`,
      e.target.value
    )
  }

  const handleCoordinates = (place: any, addressIndex: number) => {
    const latitude = place.geometry.location.lat()
    const longitude = place.geometry.location.lng()

    const streetNumber = place.address_components.find((element: any) => {
      return element.types[0] === "street_number"
    })

    const streetName = place.address_components.find((element: any) => {
      return element.types[0] === "route"
    })
    const city = place.address_components.find((element: any) => {
      return element.types[0] === "locality"
    })
    const state = place.address_components.find((element: any) => {
      return element.types[0] === "administrative_area_level_1"
    })
    const country = place.address_components.find((element: any) => {
      return element.types[0] === "country"
    })
    const zipCode = place.address_components.find((element: any) => {
      return element.types[0] === "postal_code"
    })
    const placeId = place.place_id
    formik.setFieldValue(
      `${path}[${personIndex}].addresses[${addressIndex}].unit_number`,
      streetNumber ? streetNumber.long_name : ""
    )
    formik.setFieldValue(
      `${path}[${personIndex}].addresses[${addressIndex}].street_name`,
      streetName ? streetName.long_name : ""
    )
    formik.setFieldValue(
      `${path}[${personIndex}].addresses[${addressIndex}].city`,
      city ? city.long_name : ""
    )
    formik.setFieldValue(
      `${path}[${personIndex}].addresses[${addressIndex}].state`,
      state ? state.long_name : ""
    )
    formik.setFieldValue(
      `${path}[${personIndex}].addresses[${addressIndex}].country`,
      country ? country.long_name : ""
    )
    formik.setFieldValue(
      `${path}[${personIndex}].addresses[${addressIndex}].zip_code`,
      zipCode ? zipCode.long_name : ""
    )
    formik.setFieldValue(
      `${path}[${personIndex}].addresses[${addressIndex}].place_id`,
      placeId
    )

    formik.setFieldValue(
      `${path}[${personIndex}].addresses[${addressIndex}].street_type`,
      ""
    )

    formik.setFieldValue(
      `${path}[${personIndex}].addresses[${addressIndex}].latitude`,
      latitude
    )
    formik.setFieldValue(
      `${path}[${personIndex}].addresses[${addressIndex}].longitude`,
      longitude
    )
  }

  const handleAddressNote = (e: any, addressIndex: number) => {
    formik.setFieldValue(
      `${path}[${personIndex}].addresses[${addressIndex}].info_note`,
      e.target.value
    )
  }

  const handlePhoneNumber = (
    number: string,
    type: string,
    phoneIndex: string
  ) => {
    formik.setFieldValue(
      `${path}[${personIndex}].phones[${phoneIndex}].type`,
      type
    )
    formik.setFieldValue(
      `${path}[${personIndex}].phones[${phoneIndex}].number`,
      number
    )
  }

  const handleEmail = (e: any) => {
    formik.setFieldValue(`${path}[${personIndex}].email`, e.target.value)
  }

  return (
    <>
      <div className="visit-report-update-contact__header">
        <PersonHeader
          formik={formik}
          personIndex={personIndex}
          path={path}
          setPrimaryPerson={handleIsPrimaryPerson}
          deletePerson={handleDeletePerson}
        />
      </div>
      <div className="visit-report-update-contact__personal-info">
        <PersonalInfo
          formik={formik}
          personIndex={personIndex}
          salutations={salutations}
          path={path}
          handleSalutation={handleSalutation}
          setGender={setGender}
          handleFirstName={handleFirstName}
          handleLastName={handleLastName}
          setIsJewish={setIsJewish}
          setIsChild={setIsChild}
          handleTextarea={handleTextarea}
        />
      </div>
      <div className="visit-report-update-contact__address-panel">
        <PersonPanel icon={marker} title={p('address')} />
      </div>
      {formik?.values?.[path]?.[personIndex].addresses.map(
        (_, addressIndex: number) => {
          return (
            <div
              key={addressIndex}
              className="visit-report-update-contact__address"
            >
              <Address
                formik={formik}
                personIndex={personIndex}
                addressIndex={addressIndex}
                addressesTypes={addressesTypes}
                path={path}
                handleAddressType={handleAddressType}
                handleAddress={handleAddress}
                handleCoordinates={handleCoordinates}
                handleAddressNote={handleAddressNote}
              />
            </div>
          )
        }
      )}

      <div className="visit-report-update-contact__add-button">
        <AddAddressButton title={p('add_address')} onClick={addAddress} />
      </div>
      <div>
        <ContactInfo
          formik={formik}
          personIndex={personIndex}
          path={path}
          handlePhoneNumber={handlePhoneNumber}
          handleEmail={handleEmail}
        />
      </div>
    </>
  )
}
