import { FC } from "react";

import user from "../../../assets/fonts/userGrey.svg";
import male from "../../../assets/fonts/male.svg";
import female from "../../../assets/fonts/female.svg";
import check from "../../../assets/fonts/vector.svg";
import { FormikType } from "../../../types/CreateHousehold/types";
import { SelectGender } from "../../CreateHouseholdPage/SelectGender";
import { Textarea } from "../../CreateHouseholdPage/Textarea";
import { Panel } from "../../CreateHouseholdPage/Panel/Index";
import { Select } from "../../CreateHouseholdPage/Select";
import { Checkbox } from "../../base/Checkbox";
import { Input } from "../../base/Input";
import { ErrorText } from "../../base/ErrorText";
import "./styles.scss";
import {Box} from '@mui/material';
import { useTranslation } from "../../../hooks/useTranslation";

type Props = {
  formik: FormikType | any;
  personIndex: number;
  salutationOptions: string[];
  setGender: (value: string, index: number) => void;
  setIsChild: (value: number, index: number) => void;
  setIsJewish: (value: number, index: number) => void;
  handleTextarea: (value: string, index: number) => void;
  handleSalutation: (value: string, index: number) => void;
  handleFirstName: (value: string, index: number) => void;
  handleLastName: (value: string, index: number) => void;
};

export const PersonalInfo: FC<Props> = ({
  formik,
  personIndex,
  salutationOptions,
  setGender,
  setIsChild,
  setIsJewish,
  handleTextarea,
  handleSalutation,
  handleFirstName,
  handleLastName,
}) => {
  const { p } = useTranslation('contact_info');
  const { p: pGeneral } = useTranslation('general');
  const getSalutation = (value: string) => {
    handleSalutation(value, personIndex);
  };

  const getFirstName = (e: any) => {
    handleFirstName(e.target.value, personIndex);
  };

  const getLastName = (e: any) => {
    handleLastName(e.target.value, personIndex);
  };

  const getTextareaValue = (e: any) => {
    handleTextarea(e.target.value, personIndex);
  };

  const getIsJewish = (e: any) => {
    setIsJewish(e.target.checked ? 1 : 0, personIndex);
  };

  const getIsChild = (e: any) => {
    setIsChild(e.target.checked ? 1 : 0, personIndex);
  };

  const validationCondition =
    formik?.errors?.persons &&
    formik?.errors?.persons[personIndex] &&
    formik?.touched?.persons &&
    formik?.touched?.persons[personIndex];

  return (
    <form className="create-household-personal-info">
      <div className="create-household-personal-info__panel">
        <Panel icon={user} title={p('personal_info')} />
      </div>
      <Box
          sx={{
            flexDirection: { xs: "column", md: "row" }
          }}
          className="create-household-personal-info__input-container">
      <div className="create-household-personal-info__select">
        <Select
          label={p('salutation')}
          onChangeClose
          list={salutationOptions}
          placeholder={p('select')}
          value={formik.values.persons[personIndex].salutation}
          handleSelect={getSalutation}
        />

        {validationCondition &&
          formik?.errors?.persons[personIndex]?.salutation &&
          formik?.touched?.persons[personIndex]?.salutation && (
            <ErrorText
              title={formik?.errors?.persons[personIndex]?.salutation}
            />
          )}
      </div>
      <Box
            sx={{justifyContent: { xs: "start" } } }
            className="create-household-personal-info__gender-button-container">
          <div>
            <SelectGender
              title={pGeneral('male')}
              icon={male}
              value="male"
              endIcon={
                formik.values.persons[personIndex].gender === "male" && check
              }
              index={personIndex}
              setGender={setGender}
            />
          </div>
          <div>
            <SelectGender
              title={pGeneral('female')}
              icon={female}
              value="female"
              endIcon={
                formik.values.persons[personIndex].gender === "female" && check
              }
              index={personIndex}
              setGender={setGender}
            />
          </div>
        </Box>
      </Box>

      <Box
          sx={{
            flexDirection: { xs: "column", md: "row" }
          }}
          className="create-household-personal-info__input-container">
        <div className="create-household-personal-info__input">
          <Input
            isRequired
            label={pGeneral('first_name')}
            placeholder={pGeneral('first_name')}
            value={formik.values.persons[personIndex].first_name}
            onChange={getFirstName}
            error={
              validationCondition &&
              formik?.touched?.persons[personIndex]?.first_name &&
              formik?.errors?.persons[personIndex]?.first_name
            }
          />
        </div>
        <div className="create-household-personal-info__input">
          <Input
            isRequired
            label={pGeneral('last_name')}
            placeholder={pGeneral('last_name')}
            value={formik.values.persons[personIndex].last_name}
            onChange={getLastName}
            error={
              validationCondition &&
              formik?.touched?.persons[personIndex]?.last_name &&
              formik?.errors?.persons[personIndex]?.last_name
            }
          />
        </div>
      </Box>
      <Box
          sx={{ flexDirection: { xs: "column", md: "row" } }}
          className="create-household-personal-info__gender-container">


        <div className="create-household-personal-info__gender-checkbox-container">
          <div className="create-household-personal-info__gender-checkbox">
            <Checkbox
              label={p('jewish')}
              onCheck={getIsJewish}
              checked={!!formik.values.persons[personIndex].is_jewish}
            />
          </div>
          <div className="create-household-personal-info__gender-checkbox">
            <Checkbox
              label={p('child')}
              onCheck={getIsChild}
              checked={!!formik.values.persons[personIndex].is_child}
            />
          </div>
        </div>
      </Box>

      <div className="create-household-personal-info__error-text">
        {validationCondition &&
          formik?.errors?.persons[personIndex]?.gender &&
          formik?.touched?.persons[personIndex]?.gender && (
            <ErrorText title={formik?.errors?.persons[personIndex]?.gender} />
          )}
      </div>

      <div className="create-household-personal-info__textarea">
        <Textarea
          label={p('contact_info_note')}
          value={formik.values.persons[personIndex].info_note}
          onChange={getTextareaValue}
        />
      </div>
    </form>
  );
};
