import { FC } from "react"
import { Household } from "../../../types/global/types"
import "./styles.scss"

type Props = {
  filteredHouseholds: Household[]
  setValue: (value: string) => void
}

export const SearchList: FC<Props> = ({ filteredHouseholds, setValue }) => {
  return (
    <div className="route-search-list">
      <div className="route-search-list__panel">
        <div>Name</div>
        <div className="route-search-list__panel-container">
          <div>Last visit</div>
          <div>Periodicity next</div>
        </div>
      </div>
      {filteredHouseholds.map((elem: Household, index: number) => {
        return (
          <div
            className="route-search-list__item"
            key={index}
            onClick={() => setValue(elem.main_person.fullName)}
          >
            <div className="route-search-list__item-name">
              {elem.main_person.fullName}
            </div>
            <div className="route-search-list__item-container">
              <div>
                {filteredHouseholds[index].last_completed_visit_date_formatted}
              </div>
              <div>{filteredHouseholds[index].next_visit_date_formatted}</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
