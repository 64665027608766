import {
  FC,
  useContext,
  useEffect,
  useState,
  useRef,
  SetStateAction,
  Dispatch,
} from "react";
import { Autocomplete } from "@react-google-maps/api";

import startPosition from "../../../assets/icons/startPosition.png";
import { Input } from "../../base/InputStart";
import { NavMethodModal } from "../../base/NavMethodModal";
import { ChosenHousehold } from "../Layout";
import { OptimizeRouteModal } from "../OptimizeRouteModal";
import { RemoveRouteModal } from "../RemoveContactModal";
import { RouteFooter } from "../RouteFooter";
import { RouteHeader } from "../RouteHeader";
import { SaveRouteModal } from "../SaveRouteModal";
import { SaveRouteNameModal } from "../SaveRouteNameModal";
import { VerticalStepper } from "../VerticalStepper";
import { geocodePlaceId } from "../../../utils/geocodePlaceId";
import "./styles.scss";
import { UserContext } from "../../../contexts/UserContext";
import { UserPermissionsEnum } from "../../../enums/userPermissionsEnum";
import { useTranslation } from "../../../hooks/useTranslation";
import { Box, Pagination } from "@mui/material";

interface IProps {
  routeList: any[];
  pageList: any[];
  chosenHouseholds: ChosenHousehold[];
  routeName: string;
  setRouteName: (name: string) => void;
  setMenuOpen: (value: boolean) => void;
  handleCreateRoute: () => void;
  setChosenHouseholds: (value: any) => void;
  dragStartHandler: (e: any, card: any) => void;
  dragEndHandler: (e: any) => void;
  dragOverHandler: (e: any) => void;
  dropHandler: (e: any, card: any) => void;
  handleDeletePoint: (id: number | undefined, routeId: string) => void;
  fullStartPosition: any;
  setFullStartPosition: any;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  currentPage: number;
  setRouteList: Dispatch<SetStateAction<any[]>>;
}

export const AddRoute: FC<IProps> = ({
  routeList,
  chosenHouseholds,
  routeName,
  setRouteName,
  handleCreateRoute,
  setMenuOpen,
  setChosenHouseholds,
  dragStartHandler,
  dragEndHandler,
  dragOverHandler,
  dropHandler,
  handleDeletePoint,
  fullStartPosition,
  setFullStartPosition,
  pageList,
  setCurrentPage,
  currentPage,
  setRouteList,
}) => {
  const { p: pMap } = useTranslation("map");
  const { p: pContact } = useTranslation("contacts");
  const { user } = useContext(UserContext);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [openSaveNameModal, setOpenSaveNameModal] = useState(false);
  const [openOptimizeModal, setOpenOptimizeModal] = useState(false);
  const [openNavModal, setOpenNavModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletingID, setDeletingID] = useState<number>();
  const [optimize, setOptimize] = useState(false);

  const [startPositionName, setStartPositionName] = useState("");
  const [searchResult, setSearchResult] = useState<any>(null);

  useEffect(() => {
    const currentHouseHoldList = routeList?.[currentPage - 1]?.households;
    if (optimize && routeList?.[currentPage - 1]?.households?.length > 0) {
      const directionsService = new google.maps.DirectionsService();

      const waypoints: google.maps.DirectionsWaypoint[] =
        currentHouseHoldList?.length > 1
          ? //@ts-ignore
          currentHouseHoldList?.map((el) => {
            return {
              location: new google.maps.LatLng(el.lat, el.lng),
              stopover: true,
            };
          })
          : [];

      directionsService.route(
        {
          origin: new google.maps.LatLng(
            fullStartPosition?.latitude,
            fullStartPosition?.longitude
          ),
          waypoints: waypoints,
          destination: new google.maps.LatLng(
            fullStartPosition?.latitude,
            fullStartPosition?.longitude
          ),
          optimizeWaypoints: true,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === "OK") {
            const orderPoints = response?.routes[0]?.waypoint_order?.map(
              (el) => {
                return currentHouseHoldList?.[el];
              }
            );
            if (orderPoints &&
              orderPoints?.length > 0) {
              setChosenHouseholds([...orderPoints]);
            }

            if (orderPoints &&
              orderPoints?.length > 0 && openSaveNameModal) {
              console.log("optimize");
              setRouteList((cRoute) =>
                cRoute?.map((route) => {
                  if (route?.id === routeList?.[currentPage - 1]?.id) {
                    return { ...route, households: orderPoints };
                  }
                  return route;
                })
              );
            }

          }
        }
      );
    }
  }, [optimize]);

  ////////////////////////////////////////////////

  const [dis, setDis] = useState(false);
  const geocoder = new window.google.maps.Geocoder();
  ////////////////////////////////////////////////
  let list = optimize ? chosenHouseholds : routeList?.[currentPage - 1]?.households
  const coordinates = list?.map(
    (household: any) => {
      return `${household?.address}`;
    }
  );

  const placeIds = routeList?.[currentPage - 1]?.households.map(
    (household: any) => {
      return `${household?.place_id}`;
    }
  );

  const origin =
    (fullStartPosition?.unit_number ? fullStartPosition?.unit_number : "")
    + ", " +
    fullStartPosition?.street_name +
    ", " +
    fullStartPosition?.street_name +
    ", " +
    fullStartPosition?.state +
    ", " +
    fullStartPosition?.country;


  const googleLink = `https://www.google.com/maps/dir/${origin}/${coordinates?.length !== 0 && coordinates?.join("/")
    }`;

  const appleLink = `http://maps.apple.com/?daddr=50.4468721,30.4898581&dirflg=d`;

  const handleCloseRoute = () => {
    setChosenHouseholds([]);
    setMenuOpen(false);
  };

  const getAddress = (e: any) => {
    setStartPositionName(e.target.value);
  };
  const getAddressVal = (val: string) => {
    setStartPositionName(val);
  };

  function onLoad(autocomplete: any) {
    setSearchResult(autocomplete);
  }

  const handleCoordinates = (place: any) => {
    const latitude = place.geometry.location.lat();
    const longitude = place.geometry.location.lng();

    const unit_number = place.address_components.find((element: any) => {
      return element.types[0] === "street_number";
    })?.long_name;

    const street_name = place.address_components.find((element: any) => {
      return element.types[0] === "route";
    })?.long_name;

    const city = place.address_components.find((element: any) => {
      return element.types[0] === "locality";
    })?.long_name;

    const state = place.address_components.find((element: any) => {
      return element.types[0] === "administrative_area_level_1";
    })?.long_name;

    const country = place.address_components.find((element: any) => {
      return element.types[0] === "country";
    })?.long_name;

    const zip_code = place.address_components.find((element: any) => {
      return element.types[0] === "postal_code";
    })?.long_name;

    const place_id = place.place_id;

    setFullStartPosition({
      place_id,
      zip_code,
      state,
      city,
      country,
      street_name,
      unit_number,
      latitude,
      longitude,
    });
  };

  async function onPlaceChanged() {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      const address = (await geocodePlaceId(place.place_id)) as string;
      setStartPositionName(address);
      handleCoordinates(place);
    }
  }

  const myCoordinates = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          if (geocoder) {
            geocoder.geocode(
              { location: { lat: latitude, lng: longitude } },
              (results, status) => {
                // if (status === 'OK' && results?.length > 0) {
                if (status === "OK" && results) {
                  setStartPositionName(results[0].formatted_address);
                  handleCoordinates({
                    geometry: {
                      location: {
                        lat: () => latitude,
                        lng: () => longitude,
                      },
                    },
                    address_components: results[0].address_components,
                    place_id: results[0].place_id,
                  });
                } else {
                  console.log("Address not found");
                }
              }
            );
          }
        },
        (error) => {
          console.error("Error getting geolocation:", error);
        }
      );
    }
  };
  const myCoordinatesOnes = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setDis(false);
      },
      (error: any) => {
        setDis(true);
      }
    );
  };

  return (
    <div className="home-page-add-route">
      <RouteHeader onClick={handleCloseRoute} />

      <div className="home-page-add-route__points">
        <div className="home-page-add-route__start-position_header">
          {routeList?.[currentPage - 1]?.households?.length}{" "}
          {pContact("households").toLocaleLowerCase()}
          <Box
            style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
          >
            <Pagination
              count={pageList ? pageList?.length : 1}
              page={currentPage}
              defaultPage={2}
              onChange={(event, value) => {
                setCurrentPage(value);
                setRouteName(routeList?.[value - 1]?.routeName);
              }}
            />
          </Box>
        </div>
        <Box style={{ marginTop: 10, marginBottom: 10 }}>
          <input
            className={`home-page-input__input`}
            type="text"
            value={routeList?.[currentPage - 1]?.routeName}
            placeholder={"Route Name"}
            onChange={(e) => {
              setRouteName(e.target.value);
              setRouteList((cRoute) =>
                cRoute?.map((route) => {
                  if (route?.id === routeList?.[currentPage - 1]?.id) {
                    return { ...route, routeName: e.target.value };
                  }
                  return route;
                })
              );
            }}
          // disabled={isDisabled}
          />
        </Box>

        <div className="home-page-add-route__start-position">
          <img
            className="home-page-add-route__start-position_img"
            src={startPosition}
            alt="close"
          />

          <div className="home-page-add-route__start-position_input">
            <Autocomplete
              onLoad={onLoad}
              onPlaceChanged={onPlaceChanged}
              types={["address"]}
            >
              <Input
                placeholder={pMap("start_position")}
                value={startPositionName}
                isRequired
                label={pMap("start_position")}
                onChange={getAddress}
                myCoordinates={myCoordinates}
                getAddressVal={getAddressVal}
                dis={dis}
                myCoordinatesOnes={myCoordinatesOnes}
              />
            </Autocomplete>
            {/*<button*/}
            {/*    onClick={myCoordinates}*/}
            {/*>*/}
            {/*  MyLock*/}
            {/*</button>*/}
          </div>
        </div>
        <VerticalStepper
          chosenHouseholds={routeList?.[currentPage - 1]?.households}
          dragStartHandler={dragStartHandler}
          dragEndHandler={dragEndHandler}
          dragOverHandler={dragOverHandler}
          dropHandler={dropHandler}
          setOpenDeleteModal={setOpenDeleteModal}
          setDeletingID={setDeletingID}
        />
      </div>
      <div className="home-page-add-route__footer">
        <RouteFooter
          canOpen={
            !!fullStartPosition ||
            !user?.permissions[UserPermissionsEnum.createRoutes]
          }
          setOpenSaveModal={setOpenSaveModal}
          setOpenOptimizeModal={setOpenOptimizeModal}
        />
      </div>
      <SaveRouteModal
        openSaveModal={openSaveModal}
        setOpenSaveModal={setOpenSaveModal}
        setOpenSaveNameModal={setOpenSaveNameModal}
        setOptimize={setOptimize}
      />
      <SaveRouteNameModal
        setOptimize={setOptimize}
        openSaveNameModal={openSaveNameModal}
        routeName={routeName}
        setRouteName={setRouteName}
        setOpenSaveNameModal={setOpenSaveNameModal}
        handleCreateRoute={handleCreateRoute}
      />
      <OptimizeRouteModal
        open={openOptimizeModal}
        setOpen={setOpenOptimizeModal}
        setOpenNavModal={setOpenNavModal}
        setOptimize={setOptimize}
      />
      <NavMethodModal
        setOptimize={setOptimize}
        openNavModal={openNavModal}
        googleLink={googleLink}
        appleLink={appleLink}
        wazeLink="waze"
        setOpenNavModal={setOpenNavModal}
      />
      <RemoveRouteModal
        open={openDeleteModal}
        deletingID={deletingID}
        setOpen={setOpenDeleteModal}
        handleDeletePoint={(id) => {
          console.log(routeList?.[currentPage - 1]?.id);
          handleDeletePoint(id, routeList?.[currentPage - 1]?.id);
        }}
      />
    </div>
  );
};
