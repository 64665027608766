import React, {ChangeEvent, useState} from 'react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {Box} from '@mui/material';
import {Link, useNavigate, useParams} from 'react-router-dom';
import blueChevron from '../../assets/fonts/blueChevron24.svg';
import Typography from '@mui/material/Typography';
import {Input} from '../../components/LoginPage/Input';
import {Button} from '../../components/LoginPage/Button';
import loginImageMobile from '../../assets/images/loginImageMobile.png';
import loginImage from '../../assets/images/loginImage.png';
import {useDispatch, useSelector} from 'react-redux';
import {resetPassword} from '../../backend/store/actions/creators/resetPassword';
import {useUrlQueryParams} from '../../hooks/useUrlQueryParams';

export const ResetPasswordPage = () => {
    const navigate = useNavigate();
    const { token } = useParams();
    const [errorMessage, setErrorMessage] = useState<string>('')
    const dispatch = useDispatch();
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const { getUrlQueryParam } = useUrlQueryParams();
    const formik = useFormik({
        initialValues: {
            password: "",
            password_confirmation: ""
        },
        validationSchema: yup.object().shape({
            password: yup
                .string()
                .required('Password is required')
                .min(8, "Min length 8 characters"),
            password_confirmation: yup
                .string()
                .oneOf([yup.ref('password'), ''], 'Passwords must match'),
        }),
        onSubmit: values => {
            const email = getUrlQueryParam('email');
            dispatch(resetPassword({
                data: {
                    ...values,
                    email,
                    token
                },
                onSuccess: () => {
                    setIsSuccess(true);
                    navigate('/login')
                },
                onError: (error: any) => {
                    setErrorMessage(error.response.data.message)
                }
            }))
        }
    })
    return (
        <div className="registr-page-first-step">
            <Box
                className="registr-page-first-step__container"
                sx={{
                    flexDirection: { xs: "column", md: "row" },
                }}
            >
                <Box
                    className="registr-page-first-step__form-container"
                    sx={{
                        width: { xs: "100%", md: "50vw" },
                        order: { xs: "2", md: "1" },
                        padding: { xs: "25px 0", md: "125px 0" },
                    }}
                >
                    <Box
                        className="registr-page-first-step__form-container-inner"
                        sx={{
                            width: { xs: "90%", md: "100%" },
                            height: '100vh'
                        }}
                    >
                        <Link to={"/"}>
                            <img
                                src={blueChevron}
                                alt="chevron"
                                className="registr-page-first-step__chevron"
                            />
                        </Link>
                        <form
                            className="registr-page-first-step__form"
                            noValidate
                            autoComplete="new-password"
                        >
                            <Box
                                sx={{
                                    fontWeight: 700,
                                    color: 'rgba(14, 13, 91, 1)',
                                    fontSize: { xs: "22px", md: "32px" },
                                    textAlign: { xs: "left", md: "center" },
                                    marginBottom: 3
                                }}
                            >
                                New password
                            </Box>
                            {!isSuccess && (
                                <>
                                    <Box
                                        className="registr-page-first-step__form-email"
                                        sx={{
                                            marginBottom: { xs: '15px' },
                                        }}
                                    >
                                        <Input
                                            label="Password"
                                            placeholder="Password"
                                            value={formik.values.password}
                                            error={formik.touched['password'] && formik?.errors?.password}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                formik.setTouched({ ...formik.touched, password: true })
                                                formik.setFieldValue('password', event.target.value)
                                            }}
                                        />
                                        <Typography sx={{marginTop: 2, marginBottom: 2,}} align={'left'}>And repeat password</Typography>
                                        <Input
                                            label="Password"
                                            placeholder="Password"
                                            value={formik.values.password_confirmation}
                                            error={formik.touched['password_confirmation'] && formik?.errors?.password_confirmation}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                formik.setTouched({ ...formik.touched, password_confirmation: true })
                                                formik.setFieldValue('password_confirmation', event.target.value)
                                            }}
                                        />
                                    </Box>
                                    {errorMessage && <Typography align={'left'} fontSize={14} marginY={1} color={'red'} fontWeight={500}>{errorMessage}</Typography>}
                                    <div className="registr-page-first-step__button">
                                        <Button
                                            title="Reset password"
                                            onClick={() => formik.handleSubmit()}
                                        />
                                    </div>
                                </>
                            )}
                        </form>
                    </Box>
                </Box>
                <Box
                    className="registr-page-first-step__image-container"
                    sx={{
                        order: { xs: "1", md: "2" },
                        height: { xs: "17vh", md: "100vh" },
                        backgroundImage: { xs: `url(${loginImageMobile})`, md: `url(${loginImage})` },
                        backgroundPosition: { xs: "bottom", md: "top" },
                    }}
                />
            </Box>
        </div>
    )
};