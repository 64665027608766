import { FC } from "react"
import modalFileFont from "../../../../../assets/fonts/modalFile.svg"
import modalArrowFont from "../../../../../assets/fonts/modalArrow.svg"
import closeIconFont from "../../../../../assets/fonts/close.svg"
import "./styles.scss"

type Props = {
  handleFile: (event: any) => void
  file: File | undefined
  setFile: (value: File | undefined) => void
}

export const ImportChildren: FC<Props> = ({ handleFile, file, setFile }) => {
  return (
    <div className="contacts__import-children">
      <div className="contacts__import-children-image-first">
        <img src={modalFileFont} alt="file" />
      </div>
      <div className="contacts__import-children-image-second">
        <img src={modalArrowFont} alt="arrow" />
      </div>
      {!file?.size ? (
        <div className="contacts__import-change-container">
          <div className="contacts__import-children-title-first">
            <input hidden type="file" id="file" onChange={handleFile} />
            <label className="contacts__import-children-label" htmlFor="file">
              Select file for upload
            </label>
          </div>
        </div>
      ) : (
        <div className="contacts__import-children-input">
          {file.name}
          <img
            src={closeIconFont}
            alt="close"
            onClick={() => setFile(undefined)}
          />
        </div>
      )}
    </div>
  )
}
