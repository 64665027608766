import React, { Dispatch, FC, SetStateAction } from 'react';
import { Modal } from '@mui/material';
import './styled.scss';
import { FilterHeader20 } from '../../base/FilterHeader20';
import { WhiteButton } from '../../HomePage/WhiteButton';
import { SCHEDULER_OPTIONS } from '../../../constants/base';
import { RadioButton } from "../../base/RadioButton"
import { BlackButton } from '../../HomePage/BlackButton';

interface IUpdateScheduledCategoriesProps {
    isVisible: boolean,
    setIsVisible: Dispatch<SetStateAction<boolean>>,
    handleSubmitScheduledCategories: () => void;
    selectedScheduledCategory: null | { value: string, label: string };
    setSelectedScheduledCategory: Dispatch<SetStateAction<null | { value: string, label: string }>>
}

export const UpdateScheduledCategories:
    FC<IUpdateScheduledCategoriesProps> = ({
                                               isVisible,
                                               setIsVisible,
                                               handleSubmitScheduledCategories,
                                               selectedScheduledCategory,
                                               setSelectedScheduledCategory
    }) => {
    return (
        <Modal
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999 }}
            open={isVisible}
            onClose={() => {
                setSelectedScheduledCategory(null)
                setIsVisible(prevState => !prevState)
            }}>
            <div className='update-scheduled-categories'>
                <FilterHeader20
                    title="UPDATE SCHEDULED CATEGORIES"
                    disableUnderline
                    onClose={() => setIsVisible(!isVisible)}
                />
                <div className='update-scheduled-categories__body'>
                    {SCHEDULER_OPTIONS.map((option: { value: string, label: string }) => {
                        return (
                            <div
                                key={option.value}
                                className={"update-scheduled-categories__item"}>
                                <RadioButton label={option.label}
                                    value={'scheduled-category'}
                                    onClick={() => setSelectedScheduledCategory(option)}
                                    checked={option.value === selectedScheduledCategory?.value} />
                            </div>
                        )
                    })}
                </div>
                <div className='update-scheduled-categories__footer'>
                    <WhiteButton
                        forModal
                        title={"Cancel"}
                        onClick={() => {
                            setSelectedScheduledCategory(null)
                            setIsVisible(prevState => !prevState)
                        }} />
                    <BlackButton
                        forModal
                        title={'Apply'}
                        onClick={handleSubmitScheduledCategories}
                    />
                </div>
            </div>
        </Modal>
    )
}