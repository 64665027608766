import deleteBasket from "../../../assets/fonts/deleteBasket.svg";
import vectorUp from "../../../assets/fonts/vectorUp.svg";
import locationMarker from "../../../assets/fonts/Location-marker.svg";
import { FC, useState } from "react";
import { FormikType } from "../../../types/CreateHousehold/types";
import { PersonalInfo } from "../PersonalInfo";
import { Panel } from "../../CreateHouseholdPage/Panel/Index";
import { Adress } from "../../CreateHouseholdPage/Adress";
import { Button } from "../Button";
import { ContactInfo } from "../ContactInfo";
import { BlueButton } from "../../base/BlueButton";
import "./styles.scss";
import {Box, Typography} from '@mui/material';
import { useTranslation } from "../../../hooks/useTranslation";

type Props = {
  formik: FormikType;
  personIndex: number;
  salutationOptions: string[];
  addressesTypesOptions: string[];
  setGender: (value: string, index: number) => void;
  setIsChild: (value: number, index: number) => void;
  setIsJewish: (value: number, index: number) => void;
  handleTextarea: (value: string, index: number) => void;
  handleSalutation: (value: string, index: number) => void;
  handleAddressType: (
    value: string,
    personIndex: number,
    addressIndex: number
  ) => void;
  handleAddress: (
    value: string,
    personIndex: number,
    addressIndex: number
  ) => void;
  addAddress: (index: number) => void;
  addPhone: (index: number) => void;
  handleFirstName: (value: string, index: number) => void;
  handleLastName: (value: string, index: number) => void;
  handleCoordinates: (
    value: string,
    personIndex: number,
    addressIndex: number
  ) => void;
  handleAddressNote: (
    value: string,
    personIndex: number,
    addressIndex: number
  ) => void;
  handlePhoneNumber: (
    number: string,
    type: string,
    personIndex: number,
    phoneIndex: string
  ) => void;
  handlePhoneNumberType: (
    type: string,
    personIndex: number,
    phoneIndex: string
  ) => void;
  handleEmail: (value: string, personIndex: number) => void;
  handleIsPrimary: (e: any, index: number) => void;
  handleDeletePerson: (index: number) => void;
  handleDeleteAddress: (personIndex: number, addressIndex: number) => void;
  handleDeletePhone: (personIndex: number, addressIndex: number) => void;
  onAddressPrimaryChange: (personIndex: number, addressIndex: number) => void;
};

export const HouseholdInfo: FC<Props> = ({
  formik,
  personIndex,
  salutationOptions,
  addressesTypesOptions,
  setGender,
  setIsChild,
  setIsJewish,
  handleTextarea,
  handleSalutation,
  handleAddressType,
  handleAddress,
  addAddress,
  addPhone,
  handleFirstName,
  handleLastName,
  handleCoordinates,
  handleAddressNote,
  handlePhoneNumber,
  handlePhoneNumberType,
  handleDeletePhone,
  handleEmail,
  handleIsPrimary,
  handleDeletePerson,
  handleDeleteAddress,
  onAddressPrimaryChange
}) => {
  const { p } = useTranslation('contact_info');
  const addAddresses = () => {
    addAddress(personIndex);
  };

  const [open, setOpen] = useState(true);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className="create-household-info">
      <div className="create-household-info__header">
        <div className="create-household-info__header-container">
          <label
            className="create-household-info__header-form-control"
            onClick={(e: any) => {
              handleIsPrimary(e, personIndex)
            }}
          >
            <input
              className={`${formik.values.persons[personIndex].isMain ? 'create-household-info__header-form-control-radio-checked':'create-household-info__header-form-control-radio'}`}
              name="create-household-info__person"
              checked={formik.values.persons[personIndex].isMain}
              value={formik.values.persons[personIndex].set_as_main}
              type="radio"
              onChange={(e: any) => handleIsPrimary(e, personIndex)}
            />
            {p('primary')}
          </label>
          {!open && (
              <Box
                  sx={{borderLeft: "1px solid #C5CAE3", marginLeft: "25px", paddingLeft: "25px"}}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    color: "#0E0D5B",
                    fontFamily: 'Open Sans, sans-sarif',
                    fontWeight: "600"
                  }}
                >{formik.values.persons[personIndex]?.first_name} {formik.values.persons[personIndex].last_name}</Typography>
              </Box>
          )}
        </div>
        <div className="create-household-info__header-container">
          <div
            className={`create-household-info__header-container-delete ${
                formik.values.persons[personIndex].isMain &&
              "create-household-info__header-container-delete_hidden"
            }`}
            onClick={() => handleDeletePerson(personIndex)}
          >
            <img src={deleteBasket} alt="delete" />
          </div>
          <div
            className={`create-household-info__header-container-chevron ${
              !open && "create-household-info__header-container-chevron_open"
            }`}
            onClick={handleOpen}
          >
            <img src={vectorUp} alt="vector" />
          </div>
        </div>
      </div>
      <div
        className={`create-household-info__container ${
          !open ? "create-household-info__container_hidden" : ""
        }`}
      >
        <div className="create-household-info__container-inner">
          <PersonalInfo
            salutationOptions={salutationOptions}
            formik={formik}
            personIndex={personIndex}
            setGender={setGender}
            setIsChild={setIsChild}
            setIsJewish={setIsJewish}
            handleTextarea={handleTextarea}
            handleSalutation={handleSalutation}
            handleFirstName={handleFirstName}
            handleLastName={handleLastName}
          />
        </div>
        <div className="create-household-info__container-inner">
          <div className="create-household-info__title-panel">
            <Panel title={p('address')} icon={locationMarker} />
          </div>
          {formik.values.persons[personIndex].addresses &&
            formik.values.persons[personIndex].addresses.map((_, index) => {
              return (
                <div className="create-household-info__address" key={index}>
                  <Adress
                    formik={formik}
                    addressesTypesOptions={addressesTypesOptions}
                    addressesIndex={index}
                    personIndex={personIndex}
                    handleAddressType={handleAddressType}
                    handleAddress={handleAddress}
                    handleCoordinates={handleCoordinates}
                    handleAddressNote={handleAddressNote}
                    handleDeleteAddress={handleDeleteAddress}
                    onAddressPrimaryChange={onAddressPrimaryChange}
                  />
                </div>
              );
            })}
          {/* <div className="create-household-info__address-button">
            <Button title="Add address" onClick={addAddresses} />
          </div> */}
             <div className="create-household-contact-info__container-inner ">
          <BlueButton title={`+ ${p('add_address')}`} onClick={addAddresses} background={'white'} />
        </div>
          <div className="create-household-info__contact-info">
            <ContactInfo
              formik={formik}
              personIndex={personIndex}
              handlePhoneNumber={handlePhoneNumber}
              handlePhoneNumberType={handlePhoneNumberType}
              handleEmail={handleEmail}
              addPhone={addPhone}
              handleDeletePhone={handleDeletePhone}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
