import { FC } from "react"
import "./styles.scss"
import { useTranslation } from "../../../hooks/useTranslation"

type Props = {
  label: string
  value: string
  onChange: (e: any) => void
}

export const Textarea: FC<Props> = ({ label, value, onChange }) => {
  const { p } = useTranslation('contact_info');
  return (
    <div className="create-household-textarea">
      <label className="create-household-textarea__label">{label}</label>
      <textarea
        className="create-household-textarea__textarea"
        rows={4}
        cols={30}
        placeholder={p('write_some_note')}
        value={value}
        onChange={onChange}
      />
    </div>
  )
}
