import { Modal } from "@mui/material";
import { FC } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { FilterHeader20 } from "../../base/FilterHeader20";
import { emailRegex } from "../../../regex";
import { Input } from "../../CreateHouseholdPage/Input";
import { BlackButton } from "../../HomePage/BlackButton";
import "./styles.scss";
import { useTranslation } from "../../../hooks/useTranslation";

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("Field is required"),
  last_name: Yup.string().required("Field is required"),
  email: Yup.string()
    .required("Field is required")
    .matches(emailRegex, "Invalid email"),
});

type Props = {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  onSubmit: (values: IVisitor) => void;
};

interface IVisitor {
  first_name: string,
  last_name: string,
  email: string
}

export const AddVisitorModal: FC<Props> = ({
  openModal,
  onSubmit,
  setOpenModal,
}) => {
  const { p } = useTranslation('my_team');
  const { p: pGeneral } = useTranslation('general');
  const formik = useFormik<IVisitor>({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
    },
    validationSchema,
    onSubmit: (values: IVisitor) => {
      onSubmit(values);
      formik.resetForm();
    },
  });

  const onChangeText = (e: any, field: string) => {
    formik.setFieldValue(`${field}`, e.target.value);
  };

  return (
    <Modal
      disableAutoFocus
      disableRestoreFocus
      disableEnforceFocus
      open={openModal}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
    >
      <div className="home-page-save-route-modal">
        <FilterHeader20
          title={p('inviting_visitor')}
          disableUnderline
          onClose={() => {
            formik.resetForm();
            setOpenModal(false);
          }}
        />
        <div className="team-save-route-modal__container">
          <form className="team-form">
            <div className="team-form_input">
              <Input
                //  error={formik?.errors?.password}
                placeholder={pGeneral('email')}
                formik={formik}
                value={formik.values.email}
                onChange={(e) => onChangeText(e, "email")}
              />

              {formik?.errors?.email && formik?.touched?.email && (
                <span className="team-form_input-error">
                  {formik?.errors?.email}
                </span>
              )}
            </div>

            <div className="team-form_input">
              <Input
                placeholder={pGeneral('first_name')}
                formik={formik}
                value={formik.values.first_name}
                onChange={(e) => onChangeText(e, "first_name")}
              />
              {formik?.errors?.first_name && formik?.touched?.first_name && (
                <span className="team-form_input-error">
                  {formik?.errors?.first_name}
                </span>
              )}
            </div>

            <div className="team-form_input">
              <Input
                placeholder={pGeneral('last_name')}
                formik={formik}
                value={formik.values.last_name}
                onChange={(e) => onChangeText(e, "last_name")}
              />
              {formik?.errors?.last_name && formik?.touched?.last_name && (
                <span className="team-form_input-error">
                  {formik?.errors?.last_name}
                </span>
              )}
            </div>
          </form>
        </div>
        <div className="team-save-route-modal__footer">
          <BlackButton
            onClick={formik.handleSubmit}
            title={p('send_invite')}
            forModal
          />
        </div>
      </div>
    </Modal>
  );
};
