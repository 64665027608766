import { FC } from "react"

import "./styles.scss"

type Props = {
  title: string
  subtitle?: string
  icon: string
  active: boolean
  handleClick?: () => void
}

export const LayoutButton: FC<Props> = ({
  title,
  subtitle,
  icon,
  active,
  handleClick,
}) => {
  return (
    <div
      className={`create-household-layout-button__button ${
        active ? "create-household-layout-button_active" : ""
      }`}
      onClick={handleClick}
    >
      <div className="create-household-layout-button__button-container">
        <div
          className={`create-household-layout-button__button-image ${
            active ? "create-household-layout-button_active-image" : ""
          }`}
        >
          <img src={icon} alt="house" />
        </div>
        <div className="create-household-layout-button__button-title">
          {title}
        </div>
      </div>
      <div className="create-household-layout-button__button-subtitle">
        {subtitle && subtitle.toUpperCase()}
      </div>
    </div>
  )
}
