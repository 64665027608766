import { put, takeEvery } from "redux-saga/effects"
import { updateToken } from "../../utils/updateToken"
import { addressAPI } from "../api/api"
import { actions, actionTypes } from "../store/reducers/addressesReducer"

function* getTypes() {
  try {
    const response: { status: number; data: { data: {} } } =
      yield addressAPI.getTypes()
    if (response.status === 200) {
      updateToken()
      yield put(actions.getTypesAC(response.data))
    }
  } catch (error) {
    console.log(error)
  }
}
function* getAddresses() {
  try {
    const response: { status: number; data: any } =
      yield addressAPI.getAddress()
    if (response.status === 200) {
      updateToken()
      const reducedData = []
      reducedData.push(
        response.data.data.reduce((acc: any, elem: any) => {
          acc[elem.id] = elem
          return acc
        }, {})
      )
      yield put(actions.getAddressAC(reducedData))
    }
  } catch (error) {
    console.log(error)
  }
}

function* refreshPlaceIds() {
  try {
    const response: { status: number; data: { data: {} } } =
      yield addressAPI.refreshPlaceIds()
    if (response.status === 200) {
      updateToken()
      yield put(actions.getRefreshPlaceIdsAC(response))
    }
  } catch (error) {
    console.log(error)
  }
}

export function* addressWatcher() {
  yield takeEvery(actionTypes.GET_ADDRESSES_TYPES_TRIGGER, getTypes)
  yield takeEvery(actionTypes.GET_ADDRESSES_TRIGGER, getAddresses)
  yield takeEvery(actionTypes.GET_REFRESH_PLACE_IDS_TRIGGER, refreshPlaceIds)
}
