import { FC } from "react"
import "./styles.scss"

type Props = {
  title: string
  onClick?: () => void
}

export const AddAddressButton: FC<Props> = ({ title, onClick }) => {
  return (
    <button className="visit-report-add-address-button" onClick={onClick}>
      {title}
    </button>
  )
}
