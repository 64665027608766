import React, {ChangeEvent, Dispatch, FC, SetStateAction, useContext, useState} from 'react';
import {Box, Modal} from '@mui/material';
import './styled.scss';
import { FilterHeader20 } from '../../base/FilterHeader20';
import basket from "../../../assets/fonts/deleteBasket.svg";
import pencil from "../../../assets/images/PencilPrimary.svg"
import { IRegularCategory } from '../../../interfaces/regularCategory';
import { ChangeNameCategoryInput } from '../ChangeNameCategoryInput';
import { DropdownUpdateCategory } from '../DropdownUpdateCategory';
import checkIcon from '../../../assets/images/CheckIcon.svg';
import deleteConfirmationIcon from '../../../assets/images/DeleteConfirmationIcon.svg';
import {WhiteButton} from '../../HomePage/WhiteButton';
import {RedButton} from '../../HomePage/RedButton';
import {UserContext} from '../../../contexts/UserContext';
import {UserPermissionsEnum} from '../../../enums/userPermissionsEnum';

interface IManageCategoriesModal {
    isVisible: boolean,
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    categories: IRegularCategory[],
    inputValue: string,
    handleInputChange: (value: string) => void;
    handleSave: (id: number | null) => void;
    handleDelete: (id: IRegularCategory) => void;
    setInputValue: Dispatch<SetStateAction<string>>;
    isSuccess: boolean;
    successMessage: string;
    handleDeleteCategoryWithHouseholds: () => void;
    handleCancelDeleteCategory: () => void;
    deleteCategoryModal: boolean;
    categoryForDeleting: null | IRegularCategory
}

export const ManageCategoriesModal:
    FC<IManageCategoriesModal> = ({
                                      isVisible,
                                      setIsVisible,
                                      categories,
                                      setInputValue,
                                      inputValue,
                                      handleInputChange,
                                      handleDelete,
                                      successMessage,
                                      isSuccess,
                                      handleDeleteCategoryWithHouseholds,
                                      handleCancelDeleteCategory,
                                      deleteCategoryModal,
                                      categoryForDeleting,
                                      handleSave }) => {
    const { user } = useContext(UserContext);
    const [isVisibleInputAddNewCategory, setIsVisibleInputAddNewCategory] = useState(false);
    const [isVisibleInputEditCategory, setIsVisibleInputEditCategory] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null)
    const options = [
        {
            id: 1,
            label: "Edit",
            icon: pencil,
            alt: "Pencil",
            isDisabled: !user?.permissions[UserPermissionsEnum.updateCategories],
            onClick: (category: IRegularCategory) => {
                if(isVisibleInputEditCategory) {
                    setSelectedCategoryId(category.id);
                    return setInputValue(category.name)
                }
                setIsVisibleInputEditCategory(prevState => !prevState);
                setInputValue(category.name);
                return setSelectedCategoryId(category.id);
            }},
        {
            id: 2,
            label: "Delete",
            icon: basket,
            alt: "Basket",
            onClick: (category: IRegularCategory) => handleDelete(category),
            isDisabled: !user?.permissions[UserPermissionsEnum.deleteCategories]
        },
    ];

    if(deleteCategoryModal) {
        return (
            <Modal
                sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                open={deleteCategoryModal}
            >
                <Box
                    sx={{
                        width: {xs: "90%", md: "40%"},
                        height: "60%",
                        minHeight: "60%",
                    }}
                    className='manage-categories-modal'>
                    <FilterHeader20
                        title="DELETING CATEGORY"
                        disableUnderline
                        onClose={handleCancelDeleteCategory}
                    />
                    <div className='manage-categories-modal__delete-confirmation-modal'>
                        <img src={deleteConfirmationIcon} alt={'Delete-confirmation-icon'} />
                        <h5
                            className={'manage-categories-modal__delete-confirmation-modal__title'}>
                            Do you really want to delete the {categoryForDeleting?.name} category?
                        </h5>
                    </div>
                    <div className='manage-categories-modal__footer'>
                        <WhiteButton
                            forModal
                            title={"Cancel"}
                            onClick={handleCancelDeleteCategory} />
                        <RedButton
                            forModal
                            title={'Delete Anyway'}
                            onClick={handleDeleteCategoryWithHouseholds}
                        />
                    </div>
                </Box>
            </Modal>
        )
    }

    if(isSuccess) {
        return (
            <Modal
                sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                open={isSuccess}
            >
                <div className='manage-categories-modal__successful-modal'>
                    <img src={checkIcon} alt={'check-icon'} />
                    <h5 className={'manage-categories-modal__successful-modal__title'}>{successMessage}</h5>
                </div>
            </Modal>
        )
    }

    return(
        <Modal
            sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            open={isVisible}
            onClose={setIsVisible}>
            <Box
                sx={{
                    width: {xs: "100%", md: "40%"},
                    height: {xs: "100%", md: "60%"},
                    minHeight: {xs: "100%", md: "60%"},
                }}
                className='manage-categories-modal'>
                <FilterHeader20
                    title="MANAGE CATEGORIES"
                    disableUnderline
                    onClose={() => setIsVisible(!isVisible)}
                />
                <div className='manage-categories-modal__body'>
                    <h6 className='manage-categories-modal__subtitle'>Regular Categories</h6>
                    <div className='manage-categories-modal__category-item-box'>
                        {categories?.map(category => {
                            if(isVisibleInputEditCategory && category.id === selectedCategoryId) {
                                return (
                                    <div
                                        className={'manage-categories-modal__input-wrapper'}>
                                        <ChangeNameCategoryInput
                                            value={inputValue}
                                            onClose={() => {
                                                setIsVisibleInputEditCategory(prevState => !prevState);
                                            }}
                                            onSave={() => {
                                                setIsVisibleInputEditCategory(prevState => !prevState);
                                                handleSave(category.id)
                                            }}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e.target.value)}
                                            label={'Editing name category'}
                                            placeholder={"Editing name category"} />
                                    </div>
                                )
                            }
                            return (
                                <div
                                    key={category.id}
                                    className='manage-categories-modal__category-item-box items'
                                >
                                    <span className={'manage-categories-modal__category-item-box item'}>
                                        {category.name}
                                    </span>
                                    <DropdownUpdateCategory options={options} category={category} />
                                </div>
                            )
                        })}
                    </div>
                    {!isVisibleInputAddNewCategory && (
                        <button
                            disabled={!user?.permissions[UserPermissionsEnum.createCategories]}
                            onClick={() => setIsVisibleInputAddNewCategory(prevState => !prevState)}
                            className='manage-categories-modal__button-add'>+ Add category</button>
                    )}
                    {isVisibleInputAddNewCategory && (
                        <div className={'manage-categories-modal__input-wrapper'}>
                            <ChangeNameCategoryInput
                                value={inputValue}
                                onClose={() => {
                                    setIsVisibleInputAddNewCategory(prevState => !prevState);
                                }}
                                onSave={() => {
                                    setIsVisibleInputAddNewCategory(false);
                                    handleSave(null);
                                }}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e.target.value)}
                                label={'Add New Category'}
                                placeholder={"Add New Category"} />
                        </div>
                    )}
                </div>
            </Box>
        </Modal>
    )
}