import { FC } from "react"
import "./CustomSideTab.scss"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import chevronRight from "../../../../assets/fonts/chevron-right.svg"
import {useTranslation} from "../../../../hooks/useTranslation"
export const CustomSideTab: FC<CustomSideTabType> = ({
  icon,
  isActive,
  text,
}) => {
  const { t, p } = useTranslation('sidebar');

  return (
    <ListItem
      className={isActive ? "sidebar__tab_active" : "sidebar__tab"}
      key={text}
      disablePadding
    >
      <div
        className={
          isActive ? "sidebar__tab-chevron_active" : "sidebar__tab-chevron"
        }
      >
        <img src={chevronRight} alt="chevron" />
      </div>
      <span
        className={
          isActive ? "sidebar__tab-top-span_active" : "sidebar__tab-top-span"
        }
      />
      <span
        className={"sidebar__tab-bottom-span" + (isActive ? "_active" : "")}
      />

      <ListItemButton
        className="sidebar__tab-button"
        sx={{
          "&.MuiButtonBase-root:hover": {
            bgcolor: "rgba(0, 0, 0, 0)",
          },
        }}
        disableRipple
      >
        <ListItemIcon className="sidebar__tab-icon">
          <div
            className={
              isActive
                ? "sidebar__tab-icon-wrapper_active"
                : "sidebar__tab-icon-wrapper"
            }
          >
            <img className="sidebar__tab-icon-img" src={icon} alt="icon" />
          </div>
        </ListItemIcon>
        <ListItemText
          className={
            isActive ? "sidebar__tab-text_active" : "sidebar__tab-text"
          }
          primary={p(text)}
        />
      </ListItemButton>
    </ListItem>
  )
}

type CustomSideTabType = {
  icon: string
  isActive: boolean
  text: string
}
