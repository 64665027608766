import { FC, useState } from "react";
import { SectionHeaderCheckbox } from "../../base/SectionHeaderCheckbox";
import { Button } from "../../ContactsPage/Button";
import { Calendar } from "../../ContactsPage/Calendar/Calendar";
import { SectionBody } from "../SectionBody";
import { SectionHeader } from "../SectionHeader";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "../../base/Checkbox"
// import {extendMoment} from "moment-range";
import {
  householdsTriggerActions,
} from "../../../backend/store/reducers/householdsReducer";
import { GENDER_OPTIONS, SCHEDULER_OPTIONS } from "../../../constants/base";
import { useUrlQueryParams } from "../../../hooks/useUrlQueryParams";
import { RadioButton } from "../../base/RadioButton";
import { FiltersHousehold } from "../../../enums/filtersHouseholdEnum";
import { FiltersHouseholdsPage } from "../../../pages/ContactsPage";
import { useTranslation } from "../../../hooks/useTranslation";

interface IProps {
  setOpen: (value: boolean) => void;
  resFilters: () => void;
  handleSetFilters: any;
  scheduled: any;
  setScheduled: any;
  category: any;
  setCategory: any;
  gender: any;
  setGender: any;
  dueVisitStartDate: any;
  setDueVisitStartDate: any;
  deferredVisitStartDate: any;
  setDeferredVisitStartDate: any;
  setRender: any;
  deffered: any;
  setDeffered: any;
  notVisit: any;
  setNotVisit: any;
}

// export const FilterSection: FC<IProps> = ({ setOpen, resFilters, handleSetFilters, setCategory, category, scheduled, setScheduled, gender, setGender, dueVisitStartDate, setDueVisitStartDate, setDeferredVisitStartDate, deferredVisitStartDate }) => {
export const FilterSection: FC<IProps> = ({
  setOpen,
  resFilters,
  handleSetFilters,
}) => {
  const dispatch = useDispatch();
  const { p } = useTranslation("contacts");
  const { p: pGeneral } = useTranslation("general");
  const { setUrlQueryParam, getUrlQueryParam, deleteUrlQueryParam } =
    useUrlQueryParams();

  const categories = useSelector(
    (state: any) => state.householdsStore.categories?.data
  );

  const scheduledFromUrl = getUrlQueryParam(FiltersHousehold.periodicity);
  const regularCategoriesFromUrl = getUrlQueryParam(
    FiltersHousehold.regular_categories
  );
  const genderFromURl = getUrlQueryParam(FiltersHousehold.gender);
  const dueVisitStartDateFromUrl = getUrlQueryParam(
    FiltersHousehold.due_visit_start_date
  );
  const deferredVisitStartDateFromUrl = getUrlQueryParam(
    FiltersHousehold.deferred_visit_start_date
  );

  const deferredVisitFromUrl = getUrlQueryParam(
    FiltersHousehold.deferred_visit
  );

  const notVisitFromUrl = getUrlQueryParam(
    FiltersHousehold.not_visit
  );

  const dataRangeStartDateFromUrl = getUrlQueryParam(
    FiltersHousehold.data_range_start_date
  );

  const dataRangeEndDateFromUrl = getUrlQueryParam(
    FiltersHousehold.data_range_end_date
  );

  const [openDataRange, setOpenDataRange] = useState(
    !!notVisitFromUrl || !!deferredVisitFromUrl
  );


  const [openScheduled, setOpenScheduled] = useState(!!scheduledFromUrl);
  const [openCategories, setOpenCategories] = useState(
    !!regularCategoriesFromUrl?.length
  );
  const [openGender, setOpenGender] = useState(!!genderFromURl);
  const [openCurrently, setOpenCurrently] = useState(
    !!dueVisitStartDateFromUrl
  );
  const [openDeffered, setOpenDeffered] = useState(
    !!deferredVisitStartDateFromUrl
  );

  const [deffered, setDeffered] = useState(
    deferredVisitFromUrl || ""
  );

  const [notVisit, setNotVisit] = useState(
    notVisitFromUrl || ""
  );

  const [scheduled, setScheduled] = useState(
    scheduledFromUrl?.split(",") || []
  );
  const [category, setCategory] = useState(
    regularCategoriesFromUrl?.split(",") || []
  );
  const [gender, setGender] = useState<string | null>(genderFromURl || null);
  const [dueVisitStartDate, setDueVisitStartDate] = useState(
    dueVisitStartDateFromUrl || ""
  );
  const [deferredVisitStartDate, setDeferredVisitStartDate] = useState(
    deferredVisitStartDateFromUrl || ""
  );

  const [dataRangeStartDate, setDataRangeStartDate] = useState(
    dataRangeStartDateFromUrl || ""
  );
  const [dataRangeEndDate, setDataRangeEndDate] = useState(
    dataRangeEndDateFromUrl || ""
  );


  const resetFilters = () => {
    deleteUrlQueryParam(FiltersHousehold.periodicity);
    deleteUrlQueryParam(FiltersHousehold.gender);
    deleteUrlQueryParam(FiltersHousehold.regular_categories);
    deleteUrlQueryParam(FiltersHousehold.due_visit_start_date);
    deleteUrlQueryParam(FiltersHousehold.deferred_visit_start_date);
    setOpenScheduled(false);
    setOpenCategories(false);
    setOpenGender(false);
    setOpenCurrently(false);
    setOpenDeffered(false);
    setDeffered("");
    setNotVisit("");
    setDataRangeStartDate("")
    setDataRangeEndDate("")
    setScheduled([]);
    setCategory([]);
    setGender(null);
    setDueVisitStartDate("");
    setDeferredVisitStartDate("");
    resFilters();
  };

  const perPageFromUrl = getUrlQueryParam(FiltersHouseholdsPage.perPage);
  const pageFromUrl = getUrlQueryParam(FiltersHouseholdsPage.currentPage);

  const applyFilters = () => {
    if (scheduled.length)
      setUrlQueryParam(FiltersHousehold.periodicity, `${scheduled.join()}`);
    if (!scheduled.length) deleteUrlQueryParam(FiltersHousehold.periodicity);
    if (category.length)
      setUrlQueryParam(
        FiltersHousehold.regular_categories,
        `${category.join()}`
      );
    if (!category.length)
      deleteUrlQueryParam(FiltersHousehold.regular_categories);
    if (gender) setUrlQueryParam(FiltersHousehold.gender, gender);
    if (!gender) deleteUrlQueryParam(FiltersHousehold.gender);
    if (deffered) setUrlQueryParam(FiltersHousehold.deferred_visit, "deffered");
    if (!deffered) deleteUrlQueryParam(FiltersHousehold.deferred_visit);
    if (notVisit) setUrlQueryParam(FiltersHousehold.not_visit, "notVisit");
    if (!notVisit) deleteUrlQueryParam(FiltersHousehold.not_visit);
    if (dataRangeStartDate)
      setUrlQueryParam(
        FiltersHousehold.data_range_start_date,
        dataRangeStartDate
      );
    if (!dataRangeStartDate)
      deleteUrlQueryParam(FiltersHousehold.data_range_start_date);

    if (dataRangeEndDate)
      setUrlQueryParam(
        FiltersHousehold.data_range_end_date,
        dataRangeEndDate
      );
    if (!dataRangeEndDate)
      deleteUrlQueryParam(FiltersHousehold.data_range_end_date);
    if (dueVisitStartDate)
      setUrlQueryParam(
        FiltersHousehold.due_visit_start_date,
        dueVisitStartDate
      );
    if (!dueVisitStartDate)
      deleteUrlQueryParam(FiltersHousehold.due_visit_start_date);
    if (deferredVisitStartDate)
      setUrlQueryParam(
        FiltersHousehold.deferred_visit_start_date,
        deferredVisitStartDate
      );
    if (!openDeffered) {
      deleteUrlQueryParam(FiltersHousehold.deferred_visit_start_date);
    }
    if (!openCurrently) {
      deleteUrlQueryParam(FiltersHousehold.due_visit_start_date);
    }

    // const deferred = openDeffered ? deferredVisitStartDate : "";
    const currently = openCurrently ? dueVisitStartDate : "";

    dispatch(
      householdsTriggerActions.getHouseholdsTriggerAC({
        // @ts-ignore
        page: pageFromUrl,
        // @ts-ignore
        perPage: perPageFromUrl,
        search: {
          periodicity: scheduled || undefined,
          categories: category?.length
            ? category?.map((id) => ({ id: id }))
            : undefined,
          persons_gender: gender || null,
          data_range_start_date: dataRangeStartDate || undefined,
          data_range_end_date: dataRangeEndDate || undefined,
          deferred_visit: deffered,
          not_visit: notVisit,
          due_visit_start_date: currently || undefined,
          // deferred_visit_start_date: deferred || undefined,
        },
      })
    );
    if (handleSetFilters) {
      handleSetFilters(
        gender,
        scheduled,
        category,
        deferredVisitStartDate,
        dueVisitStartDate,
        notVisit,
        deffered,
        dataRangeStartDate,
        dataRangeEndDate
      );
    }

    setOpen(false);
  };

  return (
    <div className="home-page-filter-section">
      <div>
        <SectionHeader
          title={p("scheduled").toUpperCase()}
          open={openScheduled}
          onClick={() => setOpenScheduled(!openScheduled)}
        />
        {openScheduled && (
          <SectionBody
            onChange={setScheduled}
            options={SCHEDULER_OPTIONS}
            value={scheduled}
          />
        )}
      </div>
      <div>
        <SectionHeader
          title={p("regular_categories").toUpperCase()}
          open={openCategories}
          onClick={() => setOpenCategories(!openCategories)}
        />
        {openCategories && (
          <SectionBody
            onChange={setCategory}
            options={categories.map(({ id, name }: any) => ({
              value: id,
              label: name,
            }))}
            value={category}
          />
        )}
      </div>
      <div>
        <SectionHeader
          title={p("gender").toUpperCase()}
          open={openGender}
          onClick={() => setOpenGender(!openGender)}
        />
        {openGender && (
          <div className="home-page-filter-section__gender">
            {[{ value: "all", label: p("all_gender") }, ...GENDER_OPTIONS].map(
              (option) => (
                <div className="home-page-filter-section__gender__item">
                  <RadioButton
                    name={"gender-option"}
                    checked={option.value === gender}
                    onClick={() => setGender(option.value)}
                    value={option.label}
                    label={option.label}
                  />
                </div>
              )
            )}
          </div>
        )}
      </div>
      <div>
        <SectionHeaderCheckbox
          title={p("currently_due_contact").toUpperCase()}
          checked={openCurrently}
          onCheck={() => { }}
          onClick={() => setOpenCurrently(!openCurrently)}
        />
        {openCurrently && (
          <div className="home-page-filter-section__calendar">
            <Calendar
              value={dueVisitStartDate}
              handleDate={setDueVisitStartDate}
            />
          </div>
        )}
      </div>
      {/* <div>
        <SectionHeaderCheckbox
          title={p("deffered_contact").toUpperCase()}
          checked={openDeffered}
          onCheck={() => { }}
          onClick={() => setOpenDeffered(!openDeffered)}
        />
        {openDeffered && (
          <div className="home-page-filter-section__calendar">
            <Calendar
              value={deferredVisitStartDate}
              handleDate={setDeferredVisitStartDate}
            />
          </div>
        )}
      </div> */}
      <div>
        <SectionHeader
          title={p("dataRange").toUpperCase()}
          open={openDataRange}
          onClick={() => setOpenDataRange(!openDataRange)}
        />
        {openDataRange && (
          <>

            <div className="home-page-filter-section__calendar">
              <Checkbox
                onCheck={() => setDeffered(deffered ? "" : "deffered")}
                checked={deffered ? true : false}
                label={p('deffered')}
              />
            </div>
            <div className="home-page-filter-section__calendar">
              <Checkbox
                onCheck={() => setNotVisit(notVisit ? "" : "notVisit")}
                checked={notVisit ? true : false}
                label={p('not_visited')}
              />
            </div>
            <div className="home-page-filter-section__calendar">
              <Calendar
                value={dataRangeStartDate}
                placeholder={"Start Date"}
                handleDate={setDataRangeStartDate}
              />
            </div>
            <div className="home-page-filter-section__calendar">
              <Calendar
                value={dataRangeEndDate}
                placeholder={"End Date"}
                handleDate={setDataRangeEndDate}
              />
            </div>
          </>
        )}
      </div>
      <div className="home-page-filter-section__footer">
        <Button title={pGeneral("reset")} white onClick={resetFilters} />
        <Button title={pGeneral("apply")} onClick={applyFilters} />
      </div>
    </div>
  );
};
