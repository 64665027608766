import { useState } from "react";
import { householdsTriggerActions } from "../../../backend/store/reducers/householdsReducer";
import { useDispatch } from "react-redux";
import { deleteHousehold } from "../../../backend/store/actions/creators/deleteHousehold";

export const useDeleteHousehold = () => {
  const dispatch = useDispatch();
  const [isVisibleDeleteModal, setIsVisibleDeleteModal] =
    useState<boolean>(false);
  const [isSuccessDeleting, setIsSuccessDeleting] = useState(false);
  const [isErrorDeleting, setIsErrorDeleting] = useState(false);

  const handleDelete = (id: number | null) => {
    if (!id) return;
    dispatch(
      deleteHousehold({
        data: +id,
        onSuccess: () => {
          setIsVisibleDeleteModal((prevState) => !prevState);
          setIsSuccessDeleting((prevState) => !prevState);
          dispatch(
            householdsTriggerActions.getHouseholdsTriggerAC({
              page: 1,
              perPage: "25",
            })
          );
          const timeoutId = setTimeout(() => {
            setIsSuccessDeleting((prevState) => !prevState);
            clearTimeout(timeoutId);
          }, 2000);
        },
        onError: () => {
          setIsVisibleDeleteModal((prevState) => !prevState);
          setIsErrorDeleting((prevState) => !prevState);
          const timeoutId = setTimeout(() => {
            setIsErrorDeleting((prevState) => !prevState);
            clearTimeout(timeoutId);
          }, 2000);
        },
      })
    );
  };

  return {
    handleDelete,
    isVisibleDeleteModal,
    setIsVisibleDeleteModal,
    isSuccessDeletingHouseholdModalProps: {
      isVisible: isSuccessDeleting,
      setIsVisible: () => setIsSuccessDeleting((prevState) => !prevState),
      title: "Household deleted",
    },
    isErrorDeletingHouseholdModalProps: {
      isVisible: isErrorDeleting,
      setIsVisible: () => setIsErrorDeleting((prevState) => !prevState),
      title:
        "There was an error while deleting a household or performing an action.",
    },
  };
};
