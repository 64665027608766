import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import { NotificationProps } from '../interfaces/notificationProps';
import { wait } from '../utils/wait';

export const useNotifications = (dismissTime = 5000) => {
    const [notificationQueue, setNotificationQueue] = useState<
        Omit<NotificationProps, "show" | "setShow">[]
        >([]);
    const [showMap, setShowMap] = useState<{ [id: string]: boolean }>({});

    const showMapRef = useRef(showMap);

    useEffect(() => {
        showMapRef.current = showMap;
    }, [showMap]);

    const notifications = useMemo<NotificationProps[]>(
        () =>
            notificationQueue.map(notification => ({
                ...notification,
                show: !!showMap[notification.id],
                setShow: (show: boolean) =>
                    setShowMap({ ...showMap, [notification.id]: show }),
            })),
        [notificationQueue, showMap]
    );

    const autoDismiss = useCallback(
        async (id: string) => {
            await wait(dismissTime);
            setShowMap({ ...showMapRef.current, [id]: false });
        },
        [setShowMap]
    );

    const addNotification = useCallback(
        (notification: Omit<NotificationProps, "show" | "setShow">) => {
            const id = notification.id || _.uniqueId()
            setNotificationQueue(notificationQueue => [
                ...notificationQueue,
                { ...notification, id },
            ]);
            setShowMap(showMap => {
                return { ...showMap, [id]: true };
            });
            autoDismiss(id);
        },
        [notificationQueue, setNotificationQueue, showMap, setShowMap]
    );

    return { notifications, addNotification};
};
