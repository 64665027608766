import { FC, useEffect, useState } from "react";
import { Notification } from "../../../types/global/types";
import "./styles.scss";
import happyReactionIcon from "../../../assets/fonts/happyReaction.svg";
import rudeReactionIcon from "../../../assets/fonts/rudeReaction.svg";
import { NotificationTypes } from "./NotificationTypes";
import { Button } from "../../ContactsPage/Button";
import { UpdateRegularCategories } from "../../ContactsPage/UpdateRegularCategories";
import { useDispatch, useSelector } from "react-redux";
import { changeRegularCategoriesNotification } from "../../../backend/store/actions/creators/changeRegularCategoriesNotification";
import { UpdateScheduledCategories } from "../../ContactsPage/UpdateScheduledCategories";
import { SCHEDULER_OPTIONS } from "../../../constants/base";
import { householdsTriggerActions } from "../../../backend/store/reducers/householdsReducer";
import { changeScheduleNotification } from "../../../backend/store/actions/creators/changeScheduleNotification";

type Props = {
  notification: Notification;
};

export const ExtremeReactionNotification: FC<Props> = ({ notification }) => {
  const dispatch = useDispatch();
  const categories = useSelector(
    (state: any) => state.householdsStore.categories?.data
  );
  const [selectedCategories, setSelectedCategories] = useState(
    (notification.categories && notification.categories.map((cat) => cat.id)) ||
      []
  );
  const [selectedPeriodicity, setSelectedPeriodicity] = useState<{
    value: string;
    label: string;
  } | null>(
    SCHEDULER_OPTIONS.find(
      (option) => option.value === notification.periodicity
    ) || null
  );

  const [
    isVisibleChangeRegularCategoryModal,
    setIsVisibleChangeRegularCategoryModal,
  ] = useState(false);
  const [isVisibleChangeScheduleModal, setIsVisibleChangeScheduleModal] =
    useState(false);

  const handleChangeRegularCategory = (id: number): void => {
    if (selectedCategories.includes(id))
      return setSelectedCategories((prevState) =>
        prevState.filter((selId) => selId !== id)
      );
    return setSelectedCategories([...selectedCategories, id]);
  };

  const handleSubmitRegularCategories = (): void => {
    dispatch(
      changeRegularCategoriesNotification({
        id: notification.id,
        data: selectedCategories,
        onSuccess: () => {
          setIsVisibleChangeRegularCategoryModal((prevState) => !prevState);
        },
        onError: () => {
          setIsVisibleChangeRegularCategoryModal((prevState) => !prevState);
        },
      })
    );
  };

  const handleSubmitSchedule = () => {
    if (!selectedPeriodicity) return;
    dispatch(
      changeScheduleNotification({
        id: notification.id,
        data: selectedPeriodicity?.value,
        onSuccess: () => {
          setIsVisibleChangeScheduleModal((prevState) => !prevState);
        },
        onError: () => {
          setIsVisibleChangeScheduleModal((prevState) => !prevState);
        },
      })
    );
  };

  useEffect(() => {
    householdsTriggerActions.getHouseholdsCategories();
  }, []);

  return (
    <div className="notification">
      <div className="notification__header">
        {notification.type ===
          NotificationTypes.ExtremeReactionNotification && (
          <div className="notification__reaction">
            {notification.reaction_scale === 1 && (
              <img src={happyReactionIcon} alt={"happyReactionIcon"} />
            )}
            {notification.reaction_scale === 5 && (
              <img src={rudeReactionIcon} alt={"rudeReactionIcon"} />
            )}
          </div>
        )}

        <div className="notification__title">
          <h3>
            {notification.title.startOfTitle}
            {notification.title.householdName}
            {notification.title.endOfTitle}
          </h3>
          <div className="notification__time">
            {notification.created_at_formatted}
          </div>
        </div>
      </div>

      <div className="notification__notes">
        <div className="notification__notes-header">Notes</div>
        <div className="notification__notes-body">{notification.notes}</div>
      </div>
      <div className="notification__buttons">
        <Button
          title="Change category"
          onClick={() =>
            setIsVisibleChangeRegularCategoryModal((prevState) => !prevState)
          }
        />
        <Button
          title="Change scheduling"
          onClick={() =>
            setIsVisibleChangeScheduleModal((prevState) => !prevState)
          }
        />
      </div>
      <UpdateRegularCategories
        categories={categories}
        selectedRegularCategories={selectedCategories}
        isVisible={isVisibleChangeRegularCategoryModal}
        setIsVisible={setIsVisibleChangeRegularCategoryModal}
        handleChangeRegularCategory={handleChangeRegularCategory}
        handleSubmitRegularCategories={handleSubmitRegularCategories}
      />
      <UpdateScheduledCategories
        isVisible={isVisibleChangeScheduleModal}
        setIsVisible={setIsVisibleChangeScheduleModal}
        selectedScheduledCategory={selectedPeriodicity}
        setSelectedScheduledCategory={setSelectedPeriodicity}
        handleSubmitScheduledCategories={handleSubmitSchedule}
      />
    </div>
  );
};
