import { FC } from "react"
import "./styles.scss"

type Props = {
  title: string
  icon: string
  label: string
  cancelClick: () => void
  onClick: () => void
}

export const UpdateFooter: FC<Props> = ({
  title,
  icon,
  label,
  onClick,
  cancelClick,
}) => {
  return (
    <div className="create-household-footer">
      <div className="create-household-footer__container">
        <div className="create-household-footer__title" onClick={cancelClick}>
          {title}
        </div>
        <div className="create-household-footer__icon" onClick={onClick}>
          <img src={icon} alt="save" />
          <label className="create-household-footer__label">{label}</label>
        </div>
      </div>
    </div>
  )
}
