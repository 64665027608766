import {asCreator, withCallbacks} from "../../../../lib/utils";
import {compose} from '../../../../utils/functions';
import {actionTypes} from "../../reducers/notificationsReducer";

export const changeScheduleNotification = compose(
    withCallbacks,
    asCreator((data: string, id: number) => ({
        type: actionTypes.CHANGE_SCHEDULE,
        payload: data,
        id
    }))
);