import { LayoutButton } from "../LayoutButton"
import house from "../../../assets/fonts/house.svg"
import categories from "../../../assets/fonts/categories.svg"
import "./styles.scss"
import { FC } from "react"
import { useTranslation } from "../../../hooks/useTranslation"

type Props = {
  isActive?: string
  handleClick: (value: string) => void
}

export const CreateHouseholdSidebar: FC<Props> = ({
  isActive,
  handleClick,
}) => {
  const { p } = useTranslation('contact_info');
  return (
    <div className="create-household-layout__sidebar">
      <div className="create-household-layout__button-wrapper">
        <LayoutButton
          icon={house}
          title={p('name')}
          subtitle={p('step_1')}
          active={isActive === "step1"}
          handleClick={() => handleClick("step1")}
        />
      </div>
      <div className="create-household-layout__button-wrapper">
        <LayoutButton
          icon={categories}
          title={p('categories')}
          subtitle={p('step_1')}
          active={isActive === "step2"}
          handleClick={() => handleClick("step2")}
        />
      </div>
    </div>
  )
}
