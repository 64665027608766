import { FC, useState } from "react"
import closeEye from "../../../assets/fonts/close-eye.svg"
import openEye from "../../../assets/fonts/open-eye.svg"
import passed from "../../../assets/fonts/passedInput.svg"
import "./styles.scss"

interface IProps {
  label: string
  placeholder: string
  name?: string
  value?: string
  error?: any
  onChange?: (e: any) => void
}

export const Input: FC<IProps> = ({
  label,
  placeholder,
  name,
  value,
  error,
  onChange,
}) => {
  const [focus, setFocus] = useState(false)
  const [showPass, setShowPass] = useState(false)

  return (
    <div className="login-page-input">
      {(focus || value) && (
        <label className="login-page-input__label">
          {label}
          {!error && <img src={passed} alt="pass" />}
        </label>
      )}
      {label === "Password" && (
        <img
          src={showPass ? openEye : closeEye}
          alt="eye"
          className="login-page-input__image"
          onClick={() => setShowPass(!showPass)}
        />
      )}
      <input
        className={`login-page-input__input  ${
          error ? "login-page-input__input_error" : ""
        }`}
        type={
          label !== "Password" ? "text" : `${showPass ? "text" : "password"}`
        }
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      <label className="login-page-input__error-label">{error}</label>
    </div>
  )
}
