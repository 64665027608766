import { Modal } from "@mui/material"
import { FC } from "react"
import { FilterHeader20 } from "../../base/FilterHeader20"
import { SaveOrOptimizeChildren } from "../SaveOrOptimizeChildren"
import { WhiteButton } from "../WhiteButton"
import deleteRouteLogo from "../../../assets/images/deleteRouteLogo.svg"
import { RedButton } from "../RedButton"
import "./styles.scss"

interface IProps {
  open: boolean
  deletingID: number | undefined
  setOpen: (value: boolean) => void
  handleDeletePoint: (id: number | undefined) => void
}

export const RemoveRouteModal: FC<IProps> = ({
  open,
  deletingID,
  setOpen,
  handleDeletePoint,
}) => {
  const handleDelete = () => {
    setOpen(false)
    handleDeletePoint(deletingID)
  }

  return (
    <Modal
      disableAutoFocus
      disableRestoreFocus
      disableEnforceFocus
      open={open}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
    >
      <div className="home-page-remove-contact-modal">
        <FilterHeader20
          title="REMOVE ROUTE"
          disableUnderline
          onClose={() => setOpen(false)}
        />
        <div className="home-page-remove-contact-modal__container">
          <SaveOrOptimizeChildren
            title="Are you sure you want to remove this contact from the route?"
            image={deleteRouteLogo}
          />
        </div>
        <div className="home-page-remove-contact-modal__footer">
          <WhiteButton title="Cancel" forModal onClick={() => setOpen(false)} />
          <RedButton title="Delete" forModal onClick={handleDelete} />
        </div>
      </div>
    </Modal>
  )
}
