import "./CustomCheckbox.scss"
import { FC, useEffect, useState } from "react"

type Props = {
  id?: number
  onChange: (id: number | undefined) => void
  checked: boolean
}

export const CustomCheckbox: FC<Props> = ({
  id,
  onChange,
  checked
}) => {

  return (
    <div className="custom-base-checkbox">
      <div className="custom-base-checkbox__container">
        <input
          className="custom-base-checkbox__input"
          type="checkbox"
          style={{
            background: checked ? "rgba(14, 13, 91, 1)" : "none",
          }}
          checked={checked}
          onChange={() => onChange(id)}
        />
      </div>
    </div>
  )
}
