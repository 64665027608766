import { InferActionsTypes } from "./../index"

export const actionTypes = {
  GET_ADDRESSES_TYPES_TRIGGER: "GET_ADDRESSES_TYPES_TRIGGER",
  GET_ADDRESSES_TRIGGER: "GET_ADDRESSES_TRIGGER",
  GET_ADDRESSES_TYPES: "GET_ADDRESSES_TYPES",
  GET_ADDRESSES: "GET_ADDRESSES",
  GET_REFRESH_PLACE_IDS_TRIGGER: "GET_REFRESH_PLACE_IDS_TRIGGER",
  GET_REFRESH_PLACE_IDS: "GET_REFRESH_PLACE_IDS"
}

type InitialState = {
  types: null
  addresses: null
  status: null
}

const initialState: InitialState = {
  types: null,
  addresses: null,
  status: null,
}

export const addressesReducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case actionTypes.GET_ADDRESSES_TYPES:
      return { ...state, types: action.payload }

    case actionTypes.GET_ADDRESSES:
      return { ...state, addresses: action.payload }

    case actionTypes.GET_REFRESH_PLACE_IDS:
      return { ...state, status: action.payload }

    default:
      return state
  }
}

export const actions = {
  getTypesAC: (data: any) => ({
    type: actionTypes.GET_ADDRESSES_TYPES,
    payload: data,
  }),
  getAddressAC: (data: any) => ({
    type: actionTypes.GET_ADDRESSES,
    payload: data,
  }),
  getRefreshPlaceIdsAC: (data: any) => ({
    type: actionTypes.GET_REFRESH_PLACE_IDS,
    payload: data,
  }),
}

export const addressTriggerActions = {
  getTypesTriggerAC: () => ({
    type: actionTypes.GET_ADDRESSES_TYPES_TRIGGER,
  }),
  getAddressesTriggerAC: () => ({
    type: actionTypes.GET_ADDRESSES_TRIGGER,
  }),
  getRefreshPlaceIdsTriggerAC: () => ({
    type: actionTypes.GET_REFRESH_PLACE_IDS_TRIGGER,
  }),
}

export type Actions = InferActionsTypes<typeof actions>
