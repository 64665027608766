import {asCreator, withCallbacks} from '../../../../lib/utils';
import {compose} from '../../../../utils/functions';
import {actionTypes} from '../../reducers/authReducer';

export const forgetPassword = compose(
    withCallbacks,
    asCreator((data: { email: string }) => ({
        type: actionTypes.FORGET_PASSWORD,
        payload: data,
    }))
);