import { FC } from "react"
import "./styles.scss"

interface IProps {
  title: string
  forModal?: boolean
  onClick?: () => void
}

export const RedButton: FC<IProps> = ({ title, forModal, onClick }) => {
  return (
    <button
      type="button"
      className={`home-page-red-button ${
        forModal ? "home-page-red-button_padding" : ""
      }`}
      onClick={onClick}
    >
      {title}
    </button>
  )
}
