import { Modal } from "@mui/material";
import { FC } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { FilterHeader20 } from "../../base/FilterHeader20";
import { Input } from "../../CreateHouseholdPage/Input";
import { BlackButton } from "../../HomePage/BlackButton";
import "./styles.scss";
import { Team } from "../../../types/global/types";
import { useDispatch } from 'react-redux';
import { updateVisitor } from "../../../backend/store/actions/creators/updateTeamVisitor";
import { generalActions } from "../../../backend/store/reducers/generalReducer";
import { teamTriggerActions } from "../../../backend/store/reducers/teamReducer";
import { useTranslation } from "../../../hooks/useTranslation";

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("Field is required"),
  last_name: Yup.string().required("Field is required"),
});

type Props = {
  openModal: boolean;
  visitor: Team;
  searchValue: string | null,
  currentPage: number,
  perPageValue: string
  setOpenModal: (value: boolean) => void;
  onSubmit: (values: IVisitor) => void;
  resetUserForUpdate: () => void;
};

interface IVisitor {
  first_name: string,
  last_name: string,
}

export const EditVisitorModal: FC<Props> = ({
  openModal,
  visitor,
  searchValue,
  currentPage,
  perPageValue,
  onSubmit,
  setOpenModal,
  resetUserForUpdate,
}) => {
  const { p } = useTranslation('my_team');
  const { p: pGeneral } = useTranslation('general');
  const dispatch = useDispatch();

  const formik = useFormik<IVisitor>({
    initialValues: {
      first_name: visitor.first_name,
      last_name: visitor.last_name,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values: IVisitor) => {
      dispatch(updateVisitor({
        id: visitor.id,
        data: values,
        onSuccess: () => {
          dispatch(teamTriggerActions.getTeamTriggerAC(searchValue, currentPage, perPageValue))
          generalActions.successAC({ message: "Success", type: "success" });
          setOpenModal(false);

        },
        onError: () => {
          generalActions.successAC({ message: "Something went wrong", type: "error" });
          setOpenModal(false);
        }
      }))
    },
  });  

  const onChangeText = (e: any, field: string) => {
    formik.setFieldValue(`${field}`, e.target.value);
  };

  return (
    <Modal
      disableAutoFocus
      disableRestoreFocus
      disableEnforceFocus
      open={openModal}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
    >
      <div className="home-page-save-route-modal">
        <FilterHeader20
          title={p('change_visitor_name')}
          disableUnderline
          onClose={() => {
            setOpenModal(false)
            resetUserForUpdate()
          }}
        />
        <div className="team-save-route-modal__container">
          <form className="team-form">
            <div className="team-form_input">
            </div>

            <div className="team-form_input">
              <Input
                placeholder={pGeneral('first_name')}
                formik={formik}
                value={formik.values.first_name}
                onChange={(e) => onChangeText(e, "first_name")}
              />
              {formik?.errors?.first_name && formik?.touched?.first_name && (
                <span className="team-form_input-error">
                  {formik?.errors?.first_name}
                </span>
              )}
            </div>

            <div className="team-form_input">
              <Input
                placeholder={pGeneral('last_name')}
                formik={formik}
                value={formik.values.last_name}
                onChange={(e) => onChangeText(e, "last_name")}
              />
              {formik?.errors?.last_name && formik?.touched?.last_name && (
                <span className="team-form_input-error">
                  {formik?.errors?.last_name}
                </span>
              )}
            </div>
          </form>
        </div>
        <div className="team-save-route-modal__footer">
          <BlackButton
            onClick={formik.handleSubmit}
            title={pGeneral('save_changes')}
            forModal
          />
        </div>
      </div>
    </Modal>
  );
};
