import {asCreator, withCallbacks} from '../../../../lib/utils';
import {compose} from '../../../../utils/functions';
import {actionTypes} from '../../reducers/notificationsReducer';

export const confirmUpdateAddedPersonNotification = compose(
    withCallbacks,
    asCreator((data: any, id: number) => ({
        type: actionTypes.CONFIRM_UPDATE_ADDED_PERSON,
        payload: data,
        id
    }))
);