import { FormikValues, useFormik } from "formik"
import { useDispatch } from "react-redux"
import * as yup from "yup"
import { emailRegex, nameRegex, passwordRegex } from "../../regex"
import { authTriggerActions } from "../../backend/store/reducers/authReducer"
import { Layout } from "../../components/RegistrationsPage/Layout"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { generalActions } from "../../backend/store/reducers/generalReducer"
import { useTranslation } from "../../hooks/useTranslation"

const register = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  shlichus_country_code: '',
  shlichus_country: '',
  remember: 0,
}

type Formik<T extends FormikValues> = ReturnType<typeof useFormik<T>>
export type RegistrFormik = Formik<typeof register>

export const RegistrationPage = () => {
  const { p } = useTranslation('first_page');
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const storedSeccessAction = useSelector(
    (state: any) => state.generalStore.successAction
  )

  useEffect(() => {
    return () => {
      dispatch(generalActions.setSuccessActionsAC(false))
    }
  }, [])

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(p('name_is_required'))
      .min(2, p('min_2_characters_length'))
      .matches(nameRegex, p('use_only_latin')),
    lastName: yup
      .string()
      .required(p('lastname_is_required'))
      .min(2, p('min_2_characters_length'))
      .matches(nameRegex, p('use_only_latin')),
    email: yup
      .string()
      .required(p('email_is_required'))
      .matches(emailRegex, p('invalid_email')),
    password: yup
      .string()
      .required(p('password_is_required'))
      .min(8, p('min_8_characters_length')),
    shlichus_country: yup
        .string()
        .required(p('choose_your_country'))
  })

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      shlichus_country: 'United States',
      shlichus_country_code: '+1',
      remember: 0,
    },
    validationSchema,
    onSubmit: (data) => {
      dispatch(authTriggerActions.regUserTriggerAC(data))
    },
  })

  if (storedSeccessAction) {
    navigate("/login")
  }

  return <Layout formik={formik} />
}
