import React, {FC} from 'react';
import './styled.scss';
import { NotificationProps } from '../../interfaces/notificationProps';
import { AlertNotification } from '../AlertNotification';

interface IAlertNotificationsProps {
    notifications: NotificationProps[]
}

export const AlertNotifications: FC<IAlertNotificationsProps> = ({ notifications }) => {
    return (
        <div className='alert-notifications'>
            <div className='alert-notifications__container'>
                {notifications.map(notification => (
                    <AlertNotification key={notification.id} {...notification} />
                ))}
            </div>
        </div>
    )
}