import React, {FC} from 'react';
import {NotificationProps} from '../../interfaces/notificationProps';
import alertIcon from '../../assets/images/alertBlue.svg';
import './styled.scss';
import closeIconFont from '../../assets/fonts/close.svg';
import {Grow} from '@mui/material';

export const AlertNotification:
    FC<NotificationProps> = ({
                                 description,
                                 show,
                                 setShow,
                                 additionalDescription,
                                 userName,
                                 time,
                                 onClick,
                                 id
    }) => {
    return (
        <>
            {show && (
                <Grow in={show}>
                    <div className='alert-notification' onClick={() => onClick && onClick(id)}>
                        <div className={'alert-notification__header'}>
                            <div className='alert-notification__header__left-side'>
                                <img src={alertIcon} alt="alert-icon"/>
                                <span className='alert-notification__header__time'>{time}</span>
                            </div>
                            <img src={closeIconFont} onClick={() => setShow(false)} alt="close" />
                        </div>
                        <div className='alert-notification__body'>
                            <p
                                className='alert-notification__body__description'>{description}
                                <span className='alert-notification__body__name'> {userName}</span> {additionalDescription}</p>
                        </div>
                    </div>
                </Grow>
            )}
        </>
    )
}