import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { routesTriggerActions } from "../../backend/store/reducers/routesReducer";
import { LayoutHeader } from "../../components/base/LayoutHeader";
import { Preloader } from "../../components/base/Preloader/Preloader";
import { SavedRoutesList } from "../../components/SavedRoutesPage/SavedRoutesList";
import "./styles.scss";
import { useUrlQueryParams } from "../../hooks/useUrlQueryParams";
import { CustomSearch } from "../../components/ContactsPage/CustomSearch/CustomSearch";
import { PerPageFilter } from "../../components/ContactsPage/PerPageFilter";
import { Paginator } from "../../components/ContactsPage/Paginator/Paginator";
import { NavMethodModal } from "../../components/base/NavMethodModal";
import { DeleteRouteModal } from "../../components/RoutePage/DeleteRouteModal";
import { Box } from "@mui/material";
import {useTranslation} from "../../hooks/useTranslation";

enum FiltersRoutesPage {
  currentPage = "currentPage",
  searchValue = "searchValue",
  perPage = "perPage",
}

export const SavedRoutesPage = () => {
  const { p } = useTranslation('saved_routes');
  const { p: pGeneral } = useTranslation('general');
  const dispatch = useDispatch();
  const [openPerPage, setOpenPerPage] = useState<boolean>(false);
  const [isVisibleStartRouteModal, setIsVisibleStartRouteModal] =
    useState<boolean>(false);
  const [isVisibleDeleteModal, setIsVisibleDeleteModal] =
    useState<boolean>(false);
  const [googleLink, setGoogleLink] = useState<string>("");
  const [routeId, setRouteId] = useState<number | null>(null);

  const storedRoutes = useSelector(
    (state: any) => state.routesStore.routes?.data
  );
  const metaData = useSelector((state: any) => state.routesStore.routes?.meta);
  const routes = useMemo(() => storedRoutes, [storedRoutes]);
  const meta = useMemo(() => metaData, [storedRoutes]);

  const { setUrlQueryParam, deleteUrlQueryParam, getUrlQueryParam } =
    useUrlQueryParams();
  const currPageFromUrl = getUrlQueryParam(FiltersRoutesPage.currentPage);
  const perPageFromUrl = getUrlQueryParam(FiltersRoutesPage.perPage);
  const searchValueFromUrl = getUrlQueryParam(FiltersRoutesPage.searchValue);
  const [searchValue, setSearchValue] = useState<string | null>(
    searchValueFromUrl
  );
  const [perPageValue, setPerPageValue] = useState<string>(
    perPageFromUrl || "10"
  );
  const [currentPage, setCurrentPage] = useState<number>(
    (currPageFromUrl && +currPageFromUrl) || 1
  );
  useEffect(() => {
    dispatch(
      routesTriggerActions.getRoutesTriggerAC({
        address: true,
        household: true,
        page: currentPage,
        perPage: perPageValue,
        searchValue,
      })
    );
  }, [searchValue, currentPage, perPageValue]);

  useLayoutEffect(() => {
    if (!currPageFromUrl) {
      setUrlQueryParam(FiltersRoutesPage.currentPage, "1");
    }
    if (!perPageFromUrl) {
      setUrlQueryParam(FiltersRoutesPage.perPage, "10");
    }
  }, []);

  const handleSearch = (str: string | null): void => {
    setSearchValue(str);
    if (!str) return deleteUrlQueryParam(FiltersRoutesPage.searchValue);
    return setUrlQueryParam(FiltersRoutesPage.searchValue, str);
  };

  const handleDeleteRoute = () => {
    if (!routeId) return;
    dispatch(routesTriggerActions.deleteRouteTriggerAC(routeId));
    setIsVisibleDeleteModal(!isVisibleDeleteModal);
    setRouteId(null);
  };

  const handleStartRoute = (id: number) => {
    setRouteId(id);

    const points = routes
      ?.find((route: any) => route.id === id)
      .points?.map((household: any) => {
        return `${household?.address?.fullAddress}`;
      });
    const origin = routes?.find((route: any) => route.id === id)?.startAddress
      ?.fullAddress;

    const link = `https://www.google.com/maps/dir/${origin}/${
      points.length !== 0 && points.join("/")
    }`;

    setGoogleLink(link);
    setIsVisibleStartRouteModal(!isVisibleStartRouteModal);
  };

  if (!storedRoutes) {
    return <Preloader />;
  }

  return (
    <div className="saved-routes-page">
      <div className="saved-routes-page__container">
        <div className="saved-routes-page__header">
          <LayoutHeader
            title={p('name')}
            link=""
            subtitles={[
              { title: pGeneral('dashboard'), path: "" },
              { title: p('name'), path: "" },
            ]}
          />
        </div>
        <div className="saved-routes-page__routes-list-container">
          <Box
            className="contacts-list__search-panel"
            sx={{
              paddingRight: { xs: "20px", md: "40px" },
            }}
          >
            <Box
              className="contacts-list__search-container"
              sx={{
                width: { xs: "100%", md: "auto" },
              }}
            >
              <CustomSearch
                text={searchValue || ""}
                placeholder={pGeneral('search_name')}
                handleSearch={handleSearch}
              />
            </Box>
            <Box
              className="contacts-list__input-container"
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              { pGeneral('showing') }
              <span>
                <PerPageFilter
                  value={perPageValue}
                  open={openPerPage}
                  setOpen={setOpenPerPage}
                  setValue={(newValue: string) => {
                    setUrlQueryParam(FiltersRoutesPage.perPage, newValue);
                    setPerPageValue(newValue);
                  }}
                />
              </span>
              { pGeneral('of') } {meta?.total} { pGeneral('entries') }
            </Box>
          </Box>
          <SavedRoutesList
            savedRoutes={routes}
            handleStartRoute={handleStartRoute}
            handleDeleteRoute={(id: number) => {
              setRouteId(id);
              setIsVisibleDeleteModal(!isVisibleDeleteModal);
            }}
          />
          <Box
            className="contacts-list__search-panel"
            sx={{
              display: { xs: "flex", md: "none" },
              justifyContent: "center",
              paddingRight: "20px",
            }}
          >
            <Box
              className="contacts-list__input-container"
              sx={{ display: { xs: "flex", md: "none" } }}
            >
              { pGeneral('showing') }
              <span>
                <PerPageFilter
                  value={perPageValue}
                  open={openPerPage}
                  setOpen={setOpenPerPage}
                  setValue={(newValue: string) => {
                    setUrlQueryParam(FiltersRoutesPage.perPage, newValue);
                    setPerPageValue(newValue);
                  }}
                />
              </span>
              { pGeneral('of') } {meta?.total} { pGeneral('entries') }
            </Box>
          </Box>

          <Box
            className="saved-routes-page__footer"
            sx={{ justifyContent: { xs: "center", md: "end" } }}
          >
            <Paginator
              pageSize={+perPageValue}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalItemsCount={meta?.total}
            />
          </Box>
        </div>
      </div>
      <NavMethodModal
        googleLink={googleLink}
        openNavModal={isVisibleStartRouteModal}
        setOpenNavModal={setIsVisibleStartRouteModal}
      />
      <DeleteRouteModal
        isVisible={isVisibleDeleteModal}
        handleDelete={() => {
          handleDeleteRoute();
          setIsVisibleDeleteModal(!isVisibleDeleteModal);
        }}
        setIsVisible={setIsVisibleDeleteModal}
      />
    </div>
  );
};
