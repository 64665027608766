import { FC } from "react"
import closeIconFont from "../../../assets/fonts/close.svg"
import arrowLeft from "../../../assets/fonts/arrow-left.svg";
import "./styles.scss"

type Props = {
  title: string
  withBackArrow?: boolean
  onClose: () => void
  onClickBack: () => void
}

export const NotificationHeader: FC<Props> = ({
  title,
  withBackArrow,
  onClose,
  onClickBack,
}) => {
  const handleClickBack = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    onClickBack()
  }
  return (
    <div
      className="notifications-header"
    >
      <div className="notifications-header__title">
        {withBackArrow && (
          <div 
            className="notifications-header__arrow"
            onClick={handleClickBack}
          >
            <img src={arrowLeft} alt="arrow-left" />
          </div>
        )}

        <h3>{title}</h3>
      </div>

      <div className="notifications-header__close" onClick={onClose}>
        <img src={closeIconFont} alt="close" />
      </div>
    </div>
  )
}
