import homeFont from '../assets/images/homeInActive.svg';
import contactsListFont from '../assets/images/HouseholdsIcon.svg';
import routesFont from '../assets/images/RoutesIcon.svg';
import reportsFont from '../assets/images/TeamIcon.svg';
import tutorialsFonts from '../assets/fonts/tutorials.svg';
import contactsFont from '../assets/fonts/contacts.svg';
import duchotFont from '../assets/images/historyPrimary.svg';

export const routes = [
    {
        route: "contacts",
        icon: contactsListFont,
        link: "/households",
        group: "general",
    },
    {
        route: "map",
        icon: homeFont,
        link: "/home",
        group: "general"
    },
    {
        route: "saved_routes",
        icon: routesFont,
        link: "/saved-routes",
        group: "general",
    },
    {
        route: "duchot",
        icon: duchotFont,
        link: "/duchot",
        group: "general",
    },
    {
        route: "my_team",
        icon: reportsFont,
        link: "/my-team",
        group: "general"
    },
    {
        route: "tutorials",
        icon: tutorialsFonts,
        link: "/tutorials",
        group: "about",
    },
    {
        route: "contact_us",
        icon: contactsFont,
        link: "/contact-us",
        group: "about",
    },
];
