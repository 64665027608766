import { FC } from "react";
import "./styles.scss";

interface IProps {
  title: string;
  forModal?: boolean;
  onClick?: () => void;
  disable?: boolean;
}

export const BlackButton: FC<IProps> = ({
  title,
  disable,
  forModal,
  onClick,
}) => {
  return (
    <button
      type="button"
      className={`home-page-black-button ${
        forModal ? "home-page-black-button_padding" : ""
      } ${disable ? "home-page-black-disable" : ""}`}
      onClick={onClick}
    >
      {title}
    </button>
  );
};
