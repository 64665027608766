import {
  DrawingManagerF,
  GoogleMap,
  useLoadScript,
} from "@react-google-maps/api";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { routesTriggerActions } from "../../../backend/store/reducers/routesReducer";
import { Household } from "../../../types/global/types";
import { geocodePlaceId } from "../../../utils/geocodePlaceId";
import { getIsPointInsidePolygon } from "../../../utils/getIsPointInsidePolygon";
import { AddRoute } from "../AddRoute";
import { DrawPanel } from "../DrawPanel";
import { DrawToggler } from "../DrawToggler";
import { Filters } from "../Filters";
import "./styles.scss";
import pointMarker from "../../../assets/images/pointMarker.svg";
import checkedPointMarker from "../../../assets/images/CheckedPointMarker.svg";
import { householdsTriggerActions } from "../../../backend/store/reducers/householdsReducer";
import { Box } from "@mui/material";
import { ErrorModal } from "../../base/ErrorModal";
import { useTranslation } from "../../../hooks/useTranslation";
//@ts-ignore
import { v4 as uuidv4 } from "uuid";

const googleStyles = [
  {
    stylers: [
      {
        hue: "#2c3e50",
      },
      {
        saturation: 250,
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        lightness: 50,
      },
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

const drawingStyle = {
  strokeWeight: 3,
  strokeColor: "rgba(80, 77, 255, 1)",
  fillColor: "rgba(80, 77, 255, 0.5)",
};

export type ChosenHousehold = {
  household_id: number;
  address_id: number;
  lat: number;
  lng: number;
  name: string;
  address: string | undefined;
  place_id?: string;
};

interface IProps {
  households: Household[];
  setCoordinates: Dispatch<
    SetStateAction<google.maps.LatLngBounds | undefined | null>
  >;
  center: { lat: null | number; lng: number | null };
  handleSetFilters: any;
  filters: {
    scheduled: any;
    setScheduled: any;
    category: any;
    setCategory: any;
    gender: any;
    setGender: any;
    dueVisitStartDate: any;
    setDueVisitStartDate: any;
    deferredVisitStartDate: any;
    setDeferredVisitStartDate: any;
    deffered: any;
    setDeffered: any;
    notVisit: any;
    setNotVisit: any;
    dataRangeStartDate: any;
    setDataRangeStartDate: any;
    dataRangeEndDate: any;
    setDataRangeEndDate: any;
  };
}

export const Layout: FC<IProps> = ({
  filters,
  households,
  setCoordinates,
  center,
  handleSetFilters,
}) => {
  const { p } = useTranslation("map");
  const [
    isVisibleWarningSelectedCountHouseholds,
    setIsVisibleWarningSelectedCountHouseholds,
  ] = useState(false);
  const dispatch = useDispatch();
  const [openFilters, setOpenFilters] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [routeName, setRouteName] = useState("");
  const [chosenHouseholds, setChosenHouseholds] = useState<ChosenHousehold[]>(
    []
  );
  const [currentPoint, setCurrentPoint] = useState<null | any>(null);
  const [currentPolygonList, setCurrentPolygonList] = useState<
    google.maps.Polygon[] | any[]
  >([]);
  const [currentPolygon, setCurrentPolygon] =
    useState<google.maps.Polygon | null>(null);
  const [fullStartPosition, setFullStartPosition] = useState<any>(null);

  const [drawMode, setDrawMode] = useState<
    google.maps.drawing.OverlayType | null | undefined
  >(null);
  const [routeList, setRouteList] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  useEffect(() => {
    !menuOpen && setFullStartPosition(null);
  }, [menuOpen]);

  const handleHousehold = async (household: Household) => {
    const address = await geocodePlaceId(household.address?.place_id);
    const isSelected = chosenHouseholds.some(
      (item: ChosenHousehold) => item.household_id === household.id
    );

    if (!isSelected && household.householdName) {
      setChosenHouseholds([
        ...chosenHouseholds,
        {
          household_id: household.id,
          address_id: household.address_id,
          lat: Number(household.address?.latitude),
          lng: Number(household.address?.longitude),
          name: household?.householdName,
          address: address,
          place_id: household?.address?.place_id,
        },
      ]);
    } else {
      setChosenHouseholds(
        chosenHouseholds.filter(
          (current) => current.household_id !== household.id
        )
      );
    }
    setMenuOpen(true);
  };

  const handleCreateRoute = () => {
    const data = {
      name: routeName,
      startAddress: fullStartPosition,
      points: routeList?.[currentPage - 1].households?.map(
        (item: ChosenHousehold) => {
          return {
            household_id: item.household_id,
            address_id: item.address_id,
          };
        }
      ),
    };
    setRouteList(
      routeList?.filter(
        (route) => route?.id !== routeList?.[currentPage - 1]?.id
      )
    );
    currentPolygonList?.[currentPage - 1]?.polygon.setMap(null);
    setCurrentPolygonList(
      currentPolygonList?.filter(
        (polygon) => polygon?.id !== currentPolygonList?.[currentPage - 1]?.id
      )
    );
    const currentSizze = routeList?.filter(
      (route) => route?.id !== routeList?.[currentPage - 1]?.id
    );
    if (routeList?.length > 0) {
      setRouteName(routeList?.[0]?.routeName);
    }
    setCurrentPage(1);
    if (currentSizze.length < 1) {
      setRouteName("");
      setMenuOpen(false);
    }
    dispatch(routesTriggerActions.createRouteTriggerAC(data));
  };

  const dragStartHandler = (e: any, point: ChosenHousehold) => {
    setCurrentPoint(point);
    e.target.style.border = "2px solid rgba(77, 148, 255, 1)";
    e.target.style.boxShadow = "0px 4px 10px 0px rgba(14, 13, 91, 0.16)";
  };

  const dragEndHandler = (e: any) => {
    e.target.style.background = "";
    e.target.style.border = "";
    e.target.style.boxShadow = "";
  };

  const dragOverHandler = (e: any) => {
    e.preventDefault();
    e.target.style.background = "lightgray";
  };

  const dropHandler = (e: any, point: ChosenHousehold) => {
    e.preventDefault();
    setRouteList((currentRoutes) => {
      const newChosenHouseholds = currentRoutes?.[
        currentPage - 1
      ]?.households?.map((elem: any) => {
        if (elem?.household_id === point?.household_id) {
          return { household_id: elem?.household_id, ...currentPoint };
        }
        if (elem?.household_id === currentPoint?.household_id) {
          return { ...point };
        }
        return elem;
      });
      const newRoutes = [...currentRoutes];
      newRoutes[currentPage - 1] = {
        ...newRoutes[currentPage - 1],
        households: newChosenHouseholds,
      };

      return newRoutes;
    });
    e.target.style.background = "";
  };
  const handleDeletePoint = (
    houseHoldId: number | undefined,
    routeId: string
  ) => {
    setRouteList((currentData) =>
      currentData.map((route) => {
        if (route.id === routeId) {
          const filteredHouseholds = route.households.filter(
            (household: any) => household.household_id !== houseHoldId
          );
          return { ...route, households: filteredHouseholds };
        }
        return route;
      })
    );
  };

  const handleDrawMode = (
    value?: google.maps.drawing.OverlayType | null | undefined
  ) => {
    setDrawMode(value);
  };

  const onPolygonComplete = async (polygon: google.maps.Polygon) => {
    const coordinates = polygon
      .getPath()
      .getArray()
      .map((point: any) => ({
        lat: point.lat(),
        lng: point.lng(),
      }));

    const rectangle = coordinates.map((elem) => [elem.lat, elem.lng]);

    let count = 0;
    const data = households.map(async (household: Household) => {
      const lat = Number(household.address?.latitude);
      const lng = Number(household.address?.longitude);

      const isIncludes = getIsPointInsidePolygon([lat, lng], rectangle);

      if (count > 25) {
        setIsVisibleWarningSelectedCountHouseholds(true);
      }

      if (count <= 25 && isIncludes && household.householdName) {
        count++;
        return {
          household_id: household.id,
          address_id: household.address_id,
          lat: lat,
          lng: lng,
          name: household.householdName,
          address: await geocodePlaceId(household.address?.place_id),
        };
      }
    });
    const newId = uuidv4();
    Promise.all(data).then((data: Array<ChosenHousehold | undefined>) => {
      const households: any = data.filter((item: any) => item);
      setChosenHouseholds(households);
      setRouteList([
        ...routeList,
        { id: newId, households: households, routeName: "" },
      ]);
      setCurrentPage(routeList?.length + 1);
    });

    setCurrentPolygonList([
      ...currentPolygonList,
      { polygon: polygon, id: newId },
    ]);
    setCurrentPolygon(polygon);
    setMenuOpen(true);
  };

  const resetFilters = () => {
    dispatch(householdsTriggerActions.getHouseholdsTriggerAC({}));
  };

  const [map, setMap] = useState<google.maps.Map | null>(null);

  const handleChangeFocus = () => {
    if (!map) return;
    const res = map.getBounds();
    setCoordinates(res);
  };

  const handleOnLoad = (map: google.maps.Map) => {
    setCoordinates(map?.getBounds());
    setMap(map);
  };

  const specifyIcon = (household: Household) => {
    return !!chosenHouseholds?.find((el) => el.household_id == household.id)
      ? checkedPointMarker
      : pointMarker;
  };

  const [marked, setMarked] = useState<number[]>([]);
  const markerclustererRef = useRef<MarkerClusterer>(null);
  useEffect(() => {
    markerclustererRef.current?.clearMarkers();
    const markers = households
      .map((position) => {
        // if(marked.includes(position.id)) return ;
        const infoWindow = new google.maps.InfoWindow();
        setMarked((prevState) => [...prevState, position.id]);
        const marker = new google.maps.Marker({
          animation: null,
          icon: specifyIcon(position),
          map,
          clickable: true,
          position: {
            lat: Number(position.address?.latitude),
            lng: Number(position.address?.longitude),
          },
          title: position.householdName,
        });
        marker.addListener("click", () => {
          return handleHousehold(position);
        });
        marker.addListener("hover", () => {
          infoWindow.close();
          infoWindow.setContent(marker.getTitle());
          infoWindow.open(marker.getMap(), marker);
        });
        return marker;
      })
      .filter((marker) => !!marker);

    // @ts-ignore
    markerclustererRef.current = new MarkerClusterer({ markers, map });
  }, [households]);
  useEffect(() => {
    if (routeList?.length === 0) {
      setMenuOpen(false);
      setDrawMode(null);
    }
  }, [routeList]);
  let timeout: NodeJS.Timeout | null = null;

  const delayOnChangeFocus = () => {
    if (timeout) return;
    timeout = setTimeout(() => {
      handleChangeFocus();
    }, 500);
  };

  useEffect(() => {
    return () => {
      if (!timeout) return;
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className="home-page-layout">
      {drawMode && (
        <div className="home-page-layout__draw-panel">
          <DrawPanel
            currentPolygon={currentPolygon}
            setDrawMode={setDrawMode}
            setMenuOpen={setMenuOpen}
            setChosenHouseholds={setChosenHouseholds}
            handleDelete={() => {
              currentPolygonList?.[currentPage - 1]?.polygon?.setMap(null);
              setCurrentPolygonList(
                currentPolygonList.filter(
                  (item) => item.id !== currentPolygonList?.[currentPage - 1].id
                )
              );
              setRouteList(
                routeList?.filter(
                  (item) => item?.id !== routeList?.[currentPage - 1]?.id
                )
              );
              setCurrentPage(1);
            }}
          />
        </div>
      )}
      <div className="home-page-layout__filters">
        <Filters
          isDisabled={false}
          filters={filters}
          open={openFilters}
          setOpen={setOpenFilters}
          resetFilters={resetFilters}
          handleSetFilters={handleSetFilters}
        />
      </div>
      <div
        className="home-page-layout__toggler"
        onClick={() => handleDrawMode(google.maps.drawing.OverlayType.POLYGON)}
      >
        <DrawToggler />
      </div>
      <GoogleMap
        id="marker-example"
        center={center as any}
        onLoad={handleOnLoad}
        onDragEnd={delayOnChangeFocus}
        onZoomChanged={delayOnChangeFocus}
        zoom={10}
        options={{
          styles: googleStyles,
          zoomControl: true,
          streetViewControl: true,
          mapTypeControl: false,
          fullscreenControl: false,
          // myLocationButton: true,
        }}
        mapContainerClassName="home-page-layout__container"
      >
        <DrawingManagerF
          options={{
            drawingControl: false,
            drawingMode: drawMode,
            polygonOptions: drawingStyle,
            circleOptions: {},
          }}
          onPolygonComplete={onPolygonComplete}
        />
      </GoogleMap>
      {menuOpen && (
        <Box
          className={`home-page-layout__add-route ${menuOpen ? "home-page-layout__add-route_open" : ""
            }`}
          sx={{
            top: { xs: "auto", sm: "0" },
            bottom: { xs: "0", sm: "auto" },
            height: { xs: "60%", sm: "100%" },
            maxWidth: { xs: "100%", sm: "440px" },
          }}
        >
          <AddRoute
            setRouteList={setRouteList}
            routeList={routeList}
            pageList={currentPolygonList}
            fullStartPosition={fullStartPosition}
            setFullStartPosition={setFullStartPosition}
            chosenHouseholds={chosenHouseholds}
            routeName={routeName}
            setMenuOpen={setMenuOpen}
            setRouteName={setRouteName}
            handleCreateRoute={handleCreateRoute}
            setChosenHouseholds={setChosenHouseholds}
            dragStartHandler={dragStartHandler}
            dragEndHandler={dragEndHandler}
            dragOverHandler={dragOverHandler}
            dropHandler={dropHandler}
            handleDeletePoint={handleDeletePoint}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </Box>
      )}
      <ErrorModal
        isVisible={isVisibleWarningSelectedCountHouseholds}
        setIsVisible={() => setIsVisibleWarningSelectedCountHouseholds(false)}
        title={p("choose_only_25")}
      />
    </div>
  );
};
