import { FC } from "react"
import "./styles.scss"

interface IProps {
  title: string
  image: string
}

export const SaveOrOptimizeChildren: FC<IProps> = ({ title, image }) => {
  return (
    <div className="home-page-save-optimize-children">
      <div className="home-page-save-optimize-children__logo">
        <img src={image} alt="route" />
      </div>
      <div className="home-page-save-optimize-children__title">{title}</div>
    </div>
  )
}
