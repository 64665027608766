export enum NotificationTypes {
  ChangedContactInfoNotification = "ChangedContactInfoNotification",
  ExtremeReactionNotification = "ExtremeReactionNotification",
  AttemptedThreeTimesNotification = "AttemptedThreeTimesNotification",
  AddressVerificationNotification = "AddressVerificationNotification",
  RemindMeNotification = "RemindMeNotification",
  NewContactNotification = "NewContactNotification",
  NewHouseholdAddressNotification = "NewHouseholdAddressNotification",
  ImportCompletedNotification = "ImportCompletedNotification",
  ImportAbortedNotification = "ImportAbortedNotification",
  ImportedAddressNotFoundNotification = "ImportedAddressNotFoundNotification",
}
