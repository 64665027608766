import { FC } from "react";
import { Checkbox } from "../../../../base/Checkbox";
import { FormikType } from "../../../../../types/VisitReports/types";
import plus from "../../../../../assets/fonts/bluePlus.svg";
import { BlueButton } from "../../../../base/BlueButton";
import { PERSON } from "../../../../../constants/CreateHousehold";
import "./styles.scss";
import { Person } from "../../../Person";
import { useTranslation } from "../../../../../hooks/useTranslation";

type Props = {
  formik: FormikType;
  salutations: string[];
  addressesTypes: string[];
  openNewContact: boolean;
  setOpenNewContact: (value: boolean) => void;
};

export const NewContact: FC<Props> = ({
  formik,
  salutations,
  addressesTypes,
  openNewContact,
  setOpenNewContact,
}) => {
  const { p } = useTranslation('contact_info');
  const handleOpenNewContact = (value: boolean) => {
    if (value) {
      formik.setFieldValue("new_persons", [PERSON]);
    }
    if (!value) {
      formik.setFieldValue("new_persons", undefined);
    }
    setOpenNewContact(value);
  };

  const addPerson = () => {
    if (formik.values.new_persons) {
      formik.setFieldValue(`new_persons`, [
        ...formik.values.new_persons,
        PERSON,
      ]);
    }
  };

  return (
    <div className="visit-report-update-contact">
      <div className="visit-report-update-contact__checkbox">
        <Checkbox
          label={p('new_contact').toUpperCase()}
          checked={openNewContact}
          onCheck={() => handleOpenNewContact(!openNewContact)}
        />
      </div>
      <div
        className={`visit-report-update-contact__container ${
          !openNewContact ? "visit-report-update-contact__container_closed" : ""
        }`}
      >
        {formik.values.new_persons &&
          formik.values.new_persons.map((_, index) => {
            return (
              <Person
                key={index}
                formik={formik}
                personIndex={index}
                salutations={salutations}
                addressesTypes={addressesTypes}
              />
            );
          })}
        <div className="visit-report-update-contact__blue-button">
          <BlueButton icon={plus} title={p('add_contacts')} onClick={addPerson} />
        </div>
      </div>
    </div>
  );
};
