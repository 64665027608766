import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { householdsTriggerActions } from "../../backend/store/reducers/householdsReducer";
import { Preloader } from "../../components/base/Preloader/Preloader";
import { Layout } from "../../components/HomePage/Layout";
import { getMapHouseholds } from "../../backend/store/actions/creators/getMapHouseholds";
import { FiltersHousehold } from "../../enums/filtersHouseholdEnum";
import { useUrlQueryParams } from "../../hooks/useUrlQueryParams";

export const HomePage = () => {
  const { getUrlQueryParam } = useUrlQueryParams();
  const scheduledFromUrl = getUrlQueryParam(FiltersHousehold.periodicity);
  const regularCategoriesFromUrl = getUrlQueryParam(
    FiltersHousehold.regular_categories
  );
  const genderFromURl = getUrlQueryParam(FiltersHousehold.gender);
  const dueVisitStartDateFromUrl = getUrlQueryParam(
    FiltersHousehold.due_visit_start_date
  );
  const deferredVisitStartDateFromUrl = getUrlQueryParam(
    FiltersHousehold.deferred_visit_start_date
  );
  const deferredVisitFromUrl = getUrlQueryParam(
    FiltersHousehold.deferred_visit
  );

  const notVisitFromUrl = getUrlQueryParam(
    FiltersHousehold.not_visit
  );
  
  const dataRangeStartDateFromUrl = getUrlQueryParam(
    FiltersHousehold.data_range_start_date
  );

  const dataRangeEndDateFromUrl = getUrlQueryParam(
    FiltersHousehold.data_range_end_date
  );

  const [scheduled, setScheduled] = useState(
    scheduledFromUrl?.split(",") || []
  );
  const [category, setCategory] = useState(
    regularCategoriesFromUrl?.split(",") || []
  );
  const [gender, setGender] = useState<string | null>(genderFromURl || null);
  const [dueVisitStartDate, setDueVisitStartDate] = useState(
    dueVisitStartDateFromUrl || ""
  );
  const [deferredVisitStartDate, setDeferredVisitStartDate] = useState(
    deferredVisitStartDateFromUrl || ""
  );

  const [deffered, setDeffered] = useState(
    deferredVisitFromUrl || ""
  );

  const [notVisit, setNotVisit] = useState(
    notVisitFromUrl || ""
  );

  const [dataRangeStartDate, setDataRangeStartDate] = useState(
    dataRangeStartDateFromUrl || ""
  );
  const [dataRangeEndDate, setDataRangeEndDate] = useState(
    dataRangeEndDateFromUrl || ""
  );


  const [coordinates, setCoordinates] = useState<
    google.maps.LatLngBounds | undefined | null
  >(null);
  const [center, setCenter] = useState<{
    lat: null | number;
    lng: number | null;
  }>({
    lat: null,
    lng: null,
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCenter({
          lat: Number(position.coords.latitude),
          lng: Number(position.coords.longitude),
        });
      },
      () => {
        // Here location of New York city
        setCenter({
          lat: 40.73061,
          lng: -73.935242,
        });
      }
    );
  }, []);

  const dispatch = useDispatch();

  const storedHouseholds = useSelector(
    (state: any) => state.householdsStore.householdsMap?.data
  );

  const handleSetFilters = (
    gender: any,
    scheduled: any,
    category: any,
    deferredVisitStartDate: any,
    dueVisitStartDate: any,
    notVisit: any,
    deffered: any,
    dataRangeStartDate: any,
    dataRangeEndDate: any,
  ) => {
    setGender(gender);
    setScheduled(scheduled);
    setCategory(category);
    setDeferredVisitStartDate(deferredVisitStartDate);
    setDueVisitStartDate(dueVisitStartDate);
    setDeffered(deffered);
    setNotVisit(notVisit);
    setDataRangeStartDate(dataRangeStartDate)
    setDataRangeEndDate(dataRangeEndDate)
    dispatch(
      getMapHouseholds({
        data: {
          address_latitude_max: coordinates?.getNorthEast()?.lat().toString(),
          address_latitude_min: coordinates?.getSouthWest()?.lat().toString(),
          address_longitude_max: coordinates?.getNorthEast()?.lng().toString(),
          address_longitude_min: coordinates?.getSouthWest()?.lng().toString(),
          periodicity: scheduled,
          persons_gender: gender || undefined,
          deferred_visit_start_date: deferredVisitStartDate,
          due_visit_start_date: dueVisitStartDate,
          data_range_start_date: dataRangeStartDate || undefined,
          data_range_end_date: dataRangeEndDate || undefined,
          categories: category.map((id: any) => ({ id: id })),
          deferred_visit: deffered,
          not_visit: notVisit
        },
      })
    );
  };

  useEffect(() => {
    if (!center.lat || !center.lng) return;
    dispatch(
      getMapHouseholds({
        data: {
          address_latitude_max: (center.lat + 10).toString(),
          address_latitude_min: (center.lat - 10).toString(),
          address_longitude_max: (center.lng + 10).toString(),
          address_longitude_min: (center.lng - 10).toString(),
          periodicity: scheduled,
          persons_gender: gender || undefined,
          deferred_visit_start_date: deferredVisitStartDate,
          due_visit_start_date: dueVisitStartDate,
          data_range_start_date: dataRangeStartDate || undefined,
          data_range_end_date: dataRangeEndDate || undefined,
          categories: category.map((id: any) => ({ id: id })),
          deferred_visit: deffered,
          not_visit: notVisit
        },
      })
    );
    dispatch(householdsTriggerActions.getHouseholdsCategories());
  }, [center]);

  useEffect(() => {
    if (!coordinates) return;
    dispatch(
      getMapHouseholds({
        data: {
          address_latitude_max: coordinates?.getNorthEast()?.lat().toString(),
          address_latitude_min: coordinates?.getSouthWest()?.lat().toString(),
          address_longitude_max: coordinates?.getNorthEast()?.lng().toString(),
          address_longitude_min: coordinates?.getSouthWest()?.lng().toString(),
          periodicity: scheduled,
          persons_gender: gender || undefined,
          deferred_visit_start_date: deferredVisitStartDate,
          due_visit_start_date: dueVisitStartDate,
          data_range_start_date: dataRangeStartDate || undefined,
          data_range_end_date: dataRangeEndDate || undefined,
          categories: category.map((id: any) => ({ id: id })),
          deferred_visit: deffered,
          not_visit: notVisit
        },
      })
    );
  }, [coordinates]);

  const households = useMemo(() => storedHouseholds || [], [storedHouseholds]);

  if (!storedHouseholds) {
    return <Preloader />;
  }

  return (
    <Layout
      filters={{
        scheduled,
        setCategory,
        setGender,
        category,
        dueVisitStartDate,
        setDueVisitStartDate,
        deferredVisitStartDate,
        setDeferredVisitStartDate,
        gender,
        setScheduled,
        deffered,
        setDeffered,
        notVisit,
        setNotVisit,
        dataRangeStartDate,
        setDataRangeStartDate,
        dataRangeEndDate,
        setDataRangeEndDate
      }}
      handleSetFilters={handleSetFilters}
      households={households}
      setCoordinates={setCoordinates}
      center={center}
    />
  );
};
