import { FC, useMemo } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useClickOutside } from "../../../hooks/useClickOutside";
import { NotificationHeader } from "../../base/NotificationHeader";
import { NotificationItem } from "../../base/NotificationItem";
import {
  actions,
  notificationsTriggerActions,
} from "../../../backend/store/reducers/notificationsReducer";
import NoNotificationsIcon from "../../../assets/images/NoNotifications.svg";
import { Paginator } from "../../ContactsPage/Paginator/Paginator";
import { Preloader } from "../../base/Preloader/Preloader";

import "./styles.scss";
import { ImportAbortedNotification } from "../NotificationTypes/ImportAbortedNotification";
import { markAsRead, deleteNotification } from "../../../backend/store/actions/creators/notification";
import { Notification } from "../../../types/global/types";
import { NotificationTypes } from "../NotificationTypes/NotificationTypes";
import { ExtremeReactionNotification } from "../NotificationTypes/ExtremeReactionNotification";
import { AddressVerificationNotification } from "../NotificationTypes/AddressVerificationNotification";
import { ContactInfoNotification } from "../NotificationTypes/ContactInfoNotification";
import { AttemptedThreeTimesNotification } from "../NotificationTypes/AttemptedThreeTimesNotification";
import { RemindMeNotification } from "../NotificationTypes/RemindMeNotification";
import { NewHouseholdAddressNotification } from "../NotificationTypes/NewHouseholdAddressNotification";
import { ImportCompletedNotification } from "../NotificationTypes/ImportCompletedNotification";
import { NewContactNotification } from "../NotificationTypes/NewContactNotification";
import { ImportedAddressNotFoundNotification } from "../NotificationTypes/ImportedAddressNotFoundNotification";
import filterIcon from "../../../assets/fonts/filter.svg";
import { CustomCheckbox } from "../../ContactsPage/CustomCheckbox/CustomCheckbox";
import { Button } from "../../ContactsPage/Button";
import { useTranslation } from "../../../hooks/useTranslation";

export const Notifications: FC = () => {
  const { p } = useTranslation('notifications');
  const { p: pGeneral } = useTranslation('general');
  const dispatch = useDispatch();
  // const handleBlur = () => {
  //   setOpen(false)
  // }

  type notificationTypeTitlesOptions = {
    [key: string]: string;
  };
  const notificationTypeTitles: notificationTypeTitlesOptions = {
    ChangedContactInfoNotification: "CHANGED CONTACT INFO",
    ExtremeReactionNotification: "REACTION ALERT",
    AttemptedThreeTimesNotification: "VISIT DEFERRED",
    AddressVerificationNotification: "ADDRESS VERIFICATION",
    RemindMeNotification: "REMIND ME",
    NewContactNotification: "NEW CONTACT",
    NewHouseholdAddressNotification: "LEFT TOWN",
    ImportCompletedNotification: "IMPORT OF THE FILE",
    ImportAbortedNotification: "IMPORT OF THE FILE",
    ImportedAddressNotFoundNotification: "IMPORT OF THE FILE",
  };

  const options: { id: number; name: string; value: string }[] = [
    { id: 1, 
      name: p('reaction_alert'),
      value: "ExtremeReactionNotification" 
    },
    {
      id: 2,
      name: p('address_verification'),
      value: "AddressVerificationNotification",
    },
    {
      id: 3,
      name: p('change_contact_info'),
      value: "ChangedContactInfoNotification",
    },
    { 
      id: 4, 
      name: p('new_contact'), 
      value: "NewContactNotification" },
    { 
      id: 5, 
      name: p('left_town'), 
      value: "NewHouseholdAddressNotification" 
    },
    {
      id: 6,
      name: p('attempted_3_times'),
      value: "AttemptedThreeTimesNotification",
    },
    { 
      id: 7, 
      name: p('remind_me'), 
      value: "RemindMeNotification" 
    },
    { 
      id: 8, 
      name: p('import_completed'), 
      value: "ImportCompletedNotification" 
    },
    { 
      id: 9, 
      name: p('import_aborted'), 
      value: "ImportAbortedNotification" 
    },
    { 
      id: 10, 
      name: p('import_address_not_found'), 
      value: "ImportedAddressNotFoundNotification" 
    },
  ];

  const [selectedFilters, setSelectedFilters] = useState<
    { id: number; name: string; value: string }[]
  >([]);
  const [perPageValue, setPerPageValue] = useState<string>("10");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedNotification, setSelectedNotification] =
    useState<Notification | null>(null);

  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const storedNotifications = useSelector(
    (state: any) => state.notificationsStore.notifications?.data
  );
  const selectedNotificationId = useSelector(
    (state: any) => state.notificationsStore.selectedNotification
  );
  const metaData = useSelector(
    (state: any) => state.notificationsStore.notifications?.meta
  );

  const notifications = useMemo(
    () => storedNotifications || null,
    [storedNotifications]
  );
  const meta = useMemo(() => metaData || [], [metaData]);

  const handleApplyFilters = () => {
    dispatch(
      notificationsTriggerActions.getNotificationsTriggerAC(
        currentPage,
        perPageValue,
        selectedFilters.map((filter) => filter.value)
      )
    );
    setIsOpenDropdown(false);
  };

  const handleResetFilters = () => {
    setSelectedFilters([]);
    dispatch(
      notificationsTriggerActions.getNotificationsTriggerAC(
        currentPage,
        perPageValue
      )
    );
    setIsOpenDropdown(false);
  };

  useEffect(() => {
    dispatch(
      notificationsTriggerActions.getNotificationsTriggerAC(
        currentPage,
        perPageValue,
        selectedFilters.map((filter) => filter.value)
      )
    );
  }, [perPageValue, currentPage]);

  useEffect(() => {
    if (!selectedNotificationId || !storedNotifications) return;
    setSelectedNotification(
      notifications.find(
        (notification: any) => notification.id === selectedNotificationId
      ) || null
    );
  }, [selectedNotificationId, storedNotifications]);

  const markNotificationAsRead = (id: string) => {
    dispatch(
      markAsRead({
        payload: {
          id,
        },
        onSuccess: (res: any) => {
          dispatch(
            notificationsTriggerActions.getNotificationsTriggerAC(
              currentPage,
              perPageValue
            )
          );
          dispatch(
            notificationsTriggerActions.getNotificationsCountTriggerAC()
          );
        },
      })
    );
  };

  const handleDelete = (id: string) => {
    dispatch(
      deleteNotification({
        payload: {
          id,
        },
        onSuccess: (res: any) => {
          dispatch(
            notificationsTriggerActions.getNotificationsTriggerAC(
              currentPage,
              perPageValue
            )
          );
          dispatch(
            notificationsTriggerActions.getNotificationsCountTriggerAC()
          );
        },
      })
    );
  };

  const handleSelectNotification = (notification: Notification) => {
    setSelectedNotification(notification);

    if (!notification.is_read) {
      dispatch(
        markAsRead({
          payload: {
            id: notification.id,
          },
          onSuccess: (res: any) => {
            dispatch(
              notificationsTriggerActions.getNotificationsTriggerAC(
                currentPage,
                perPageValue
              )
            );
            dispatch(
              notificationsTriggerActions.getNotificationsCountTriggerAC()
            );
          },
        })
      );
    }
  };

  // const ref = useClickOutside({ handler: handleBlur })

  return (
    <div className="notifications">
      <div className="notifications__container">
        {!selectedNotification && (
          <div>
            <div className="notifications__title">
              <NotificationHeader
                title={p('notifications')}
                withBackArrow={false}
                onClose={() =>
                  dispatch(actions.setVisibilityNotificationModal(false))
                }
                onClickBack={() => setSelectedNotification(null)}
              />
            </div>
            <div className="notifications__content">
              <div
                className={`home-page-filters__header full-width ${
                  isOpenDropdown ? "home-page-filters__header_active" : ""
                }`}
                onClick={() => setIsOpenDropdown((prevState) => !prevState)}
              >
                <img
                  src={filterIcon}
                  alt="filter"
                  className={`home-page-filters__img ${
                    isOpenDropdown ? "home-page-filters__img_active" : ""
                  }`}
                />
                {p('filters')}
              </div>
              {isOpenDropdown && (
                <div className="home-page-filters__container">
                  <div className="home-page-filters__content">
                    {options.map((option) => (
                      <div
                        className="notifications-filter__container"
                        key={option.id}
                      >
                        <CustomCheckbox
                          id={option.id}
                          onChange={(id) => {
                            if (!id) return;
                            if (
                              !!selectedFilters.find(
                                (filter) => filter.id === id
                              )
                            ) {
                              return setSelectedFilters(() =>
                                selectedFilters.filter((fil) => fil.id !== id)
                              );
                            }
                            return setSelectedFilters([
                              ...selectedFilters,
                              option,
                            ]);
                          }}
                          checked={
                            !!selectedFilters.find(
                              (filter) => filter.id === option.id
                            )
                          }
                        />
                        <span className="notifications-filter__item">
                          {option.name}
                        </span>
                      </div>
                    ))}
                  </div>
                  <div className="home-page-filter-section__footer">
                    <Button title={pGeneral('reset')} white onClick={handleResetFilters} />
                    <Button title={pGeneral('apply')} onClick={handleApplyFilters} />
                  </div>
                </div>
              )}
              {!selectedNotification &&
                notifications &&
                notifications.map((element: any) => (
                  <NotificationItem
                    notification={element}
                    key={element.id}
                    markAsRead={markNotificationAsRead}
                    deleteNotification={handleDelete}
                    onSelectNotification={handleSelectNotification}
                  />
                ))}

              {!notifications && (
                <div className="notifications__loader-wrapper">
                  <Preloader />
                </div>
              )}

              {notifications && notifications.length === 0 && (
                <div className="notifications__no-items">
                  <img src={NoNotificationsIcon} alt={"NoNotificationsIcon"} />
                </div>
              )}

              {!!meta.total && (
                <div className="notifications__footer">
                  <Paginator
                    pageSize={+perPageValue}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalItemsCount={meta?.total}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        {selectedNotification && (
          <div>
            <div className="notifications__title">
              <NotificationHeader
                title={notificationTypeTitles[selectedNotification.type]}
                withBackArrow={true}
                onClose={() =>
                  dispatch(actions.setVisibilityNotificationModal(false))
                }
                onClickBack={() => {
                  dispatch(actions.setSelectedNotification(null));
                  setSelectedNotification(null);
                }}
              />
            </div>

            <div className="notifications__content">
              {selectedNotification.type ===
                NotificationTypes.ExtremeReactionNotification && (
                <ExtremeReactionNotification
                  notification={selectedNotification}
                />
              )}

              {selectedNotification.type ===
                NotificationTypes.AddressVerificationNotification && (
                <AddressVerificationNotification
                  notification={selectedNotification}
                />
              )}

              {selectedNotification.type ===
                NotificationTypes.ImportCompletedNotification && (
                <ImportCompletedNotification
                  notification={selectedNotification}
                />
              )}

              {selectedNotification.type ===
                NotificationTypes.ImportAbortedNotification && (
                <ImportAbortedNotification
                  notification={selectedNotification}
                />
              )}

              {selectedNotification.type ===
                NotificationTypes.ChangedContactInfoNotification && (
                <ContactInfoNotification notification={selectedNotification} />
              )}

              {selectedNotification.type ===
                NotificationTypes.NewContactNotification && (
                <NewContactNotification
                  notification={selectedNotification}
                  setSelectedNotification={setSelectedNotification}
                />
              )}

              {selectedNotification.type ===
                NotificationTypes.AttemptedThreeTimesNotification && (
                <AttemptedThreeTimesNotification
                  notification={selectedNotification}
                />
              )}

              {selectedNotification.type ===
                NotificationTypes.RemindMeNotification && (
                <RemindMeNotification notification={selectedNotification} />
              )}

              {selectedNotification.type ===
                NotificationTypes.NewHouseholdAddressNotification && (
                <NewHouseholdAddressNotification
                  notification={selectedNotification}
                />
              )}

              {selectedNotification.type ===
                NotificationTypes.ImportedAddressNotFoundNotification && (
                <ImportedAddressNotFoundNotification
                  notification={selectedNotification}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
