import { RadioButton } from "../../base/RadioButton"
import { Select } from "../Select"
import { Textarea } from "../Textarea"
import basket from "../../../assets/fonts/deleteBasket.svg"
import { FC, useEffect, useState } from "react"
import { FormikType } from "../../../types/VisitReports/types"
import "./styles.scss"
import { Autocomplete } from "@react-google-maps/api"
import { geocodePlaceId } from "../../../utils/geocodePlaceId"
import { Input } from '../../base/Input';
import {FormErrorMessage} from '../../base/FormErrorMessage';
import {ERRORS} from '../../../constants/base';
import { useTranslation } from "../../../hooks/useTranslation"

type Props = {
  formik: FormikType
  personIndex: number
  addressIndex: number
  addressesTypes: string[]
  path: "updated_persons" | "new_persons"
  handleAddressType: (value: string, addressIndex: number) => void
  handleAddress: (e: any, addressIndex: number) => void
  handleCoordinates: (e: any, addressIndex: number) => void
  handleAddressNote: (e: any, addressIndex: number) => void
}

export const Address: FC<Props> = ({
  formik,
  addressesTypes,
  personIndex,
  addressIndex,
  path,
  handleAddressType,
  handleAddress,
  handleCoordinates,
  handleAddressNote,
}) => {
  const { p } = useTranslation('contact_info');
  const [searchResult, setSearchResult] = useState<any>("")
  useEffect(() => {
    const myPromise = new Promise((resolve) => {
      resolve(
        geocodePlaceId(
          formik.values?.[path]?.[personIndex].addresses[addressIndex].place_id
        )
      )
    })
    myPromise.then((fullStreet: any) => {
      formik.setFieldValue(
        `${path}[${personIndex}].addresses[${addressIndex}].fullAddress`,
        fullStreet
      )
    })
  }, [formik.values?.[path]?.[personIndex].addresses[addressIndex].place_id])

  const longitudeAndLatitude = formik.values?.[path]?.[personIndex].addresses[
    addressIndex
  ].latitude
    ? formik.values?.[path]?.[personIndex].addresses[addressIndex].latitude +
      "; " +
      formik.values?.[path]?.[personIndex].addresses[addressIndex].longitude
    : ""

  async function onPlaceChanged() {
    if (searchResult != null) {
      const place = searchResult.getPlace()
      const address = (await geocodePlaceId(place.place_id)) as string

      handleCoordinates(place, addressIndex)
      formik.setFieldValue(
        `${path}[${personIndex}].addresses[${addressIndex}].fullAddress`,
        address
      )
    }
  }

  function onLoad(autocomplete: any) {
    setSearchResult(autocomplete)
  }

  const getAddressType = (value: string) => {
    handleAddressType(value, addressIndex)
  }

  const getAddress = (e: any) => {
    handleAddress(e, addressIndex)
  }

  const getAddressNote = (e: any) => {
    handleAddressNote(e, addressIndex)
  }

  const handleIsPrimaryAddress = (e: any) => {
    if (e.target.type === "radio") {
      formik.values?.[path]?.[personIndex]?.addresses.forEach((_, ind) => {
        if (addressIndex === ind) {
          formik.setFieldValue(
            `${path}[${personIndex}].addresses[${ind}].isMain`,
            true
          )
        } else {
          formik.setFieldValue(
            `${path}[${personIndex}].addresses[${ind}].isMain`,
            false
          )
        }
      })
    }
  }

  const handleDeleteAddress = () => {
    if (formik.values?.[path]) {
      formik.values?.[path]?.[personIndex].addresses.splice(addressIndex, 1)
      formik.setFieldValue(`${path}[${personIndex}].addresses`, [
        ...(formik.values?.[path]?.[personIndex].addresses || []),
      ])
    }
  }

  return (
    <div className="visit-report-address">
      <div className="visit-report-address__header">
        <div className="visit-report-address__header-container">
          <div className="visit-report-address__title-container">
            { p('address') } {addressIndex + 1}
            {addressIndex > 0 && (
              <img
                src={basket}
                alt={basket}
                className="visit-report-address__img"
                onClick={handleDeleteAddress}
              />
            )}
          </div>
          <RadioButton
            checked={
              !!formik.values?.[path]?.[personIndex]?.addresses[addressIndex]
                ?.isMain
            }
            label={p('primary_address')}
            value={
              formik.values?.[path]?.[personIndex].addresses[addressIndex]
                .isMain
                ? 1
                : 0
            }
            name="visit-report-address"
            onClick={handleIsPrimaryAddress}
          />
        </div>
      </div>
      <div className="visit-report-address__container">
        <div className="visit-report-address__select">
          <Select
            isRequired
            onChangeClose
            label={p('type')}
            list={addressesTypes}
            placeholder={p('select')}
            value={
              formik.values?.[path]?.[personIndex]?.addresses[addressIndex]
                ?.type
            }
            handleSelect={getAddressType}
            isError={(((formik.touched[path] as { [key: string]: boolean }[])?.[personIndex] as { [key: string]: boolean })?.addresses as unknown as { [key: string]: boolean }[])?.[addressIndex]?.type &&
                !formik.values?.[path]?.[personIndex]?.addresses[addressIndex]
                    ?.type}
          />
          {(((formik.touched[path] as { [key: string]: boolean }[])?.[personIndex] as { [key: string]: boolean })?.addresses as unknown as { [key: string]: boolean }[])?.[addressIndex]?.type &&
              !formik.values?.[path]?.[personIndex]?.addresses[addressIndex]
                  ?.type && (
                  <FormErrorMessage message={ERRORS.required} />
              )
          }
        </div>
        <div className="visit-report-address__address">
          <Autocomplete
            className="visit-report-address__autocomplete"
            onLoad={onLoad}
            onPlaceChanged={onPlaceChanged}
            types={["address"]}
          >
            <Input
              isRequired
              label={p('address')}
              placeholder={p('write_address')}
              value={
                formik.values?.[path]?.[personIndex]?.addresses[addressIndex]
                  ?.fullAddress || ""
              }
              onChange={getAddress}
            />
          </Autocomplete>
          {(((formik.touched[path] as { [key: string]: boolean }[])?.[personIndex] as { [key: string]: boolean })?.addresses as unknown as { [key: string]: boolean }[])?.[addressIndex]?.fullAddress &&
              !formik.values?.[path]?.[personIndex]?.addresses[addressIndex]
                  ?.fullAddress && (
              <FormErrorMessage message={ERRORS.required} />
          )}
        </div>
        <div className="visit-report-address__coordinates">
          <Input
            isRequired
            label={p('coordinates')}
            placeholder={p('write_address')}
            isDisabled
            value={longitudeAndLatitude}
            onChange={() => {}}
          />
        </div>
        <div className="visit-report-address__textarea">
          <Textarea
            label={p('address_note')}
            placeholder={p('write_some_note')}
            value={
              formik.values?.[path]?.[personIndex]?.addresses[addressIndex]
                ?.info_note
            }
            onChange={getAddressNote}
          />
        </div>
      </div>
    </div>
  )
}
