import { all, call, put, takeLatest } from "redux-saga/effects";
import { IResponse, Team } from "../../types/global/types";
import { GetTeam } from "../../types/sagas";
import { CreateVisitor } from "../../types/sagas";
import { updateToken } from "../../utils/updateToken";
import { teamAPI } from "../api/api";
import { actionTypes } from "../store/reducers/teamReducer";
import { generalActions } from "../store/reducers/generalReducer";
import { SagaWrapper } from "./index";

function* getTeam(payload: GetTeam) {
  try {
    const response: IResponse<Team> = yield teamAPI.getTeam({
      ...payload,
    });

    if (response.status === 200) {
      yield put({
        type: actionTypes.GET_TEAM_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* createVisitor(payload: CreateVisitor) {
  try {
    const response: { status: number; data: {} } = yield teamAPI.createVisitor(
      payload.data
    );

    if (response.status === 201) {
      updateToken();
      yield updateToken();
      yield call(getTeam, {});
    }
  } catch (error: any) {
    yield put(
      generalActions.errorAC({
        message: error.response.data.message,
        type: "error",
      })
    );
  }
}

function* deleteTeam({ type, id }: any) {
  try {
    const response: { status: number } = yield teamAPI.deleteTeam(id);
    if (response.status === 200) {
      yield updateToken();
      yield call(getTeam, {});
    }
  } catch (error: any) {
    yield put(
      generalActions.errorAC({
        message: error.response.data.message,
        type: "error",
      })
    );
  }
}

function* updateVisitor ({ request, action, dispatchResponse }: any) {
  const { data, error } = yield request({
    method: 'put',
    endpoint: '/api/auth/invitations/' + action.payload.id,
    body: action.payload.data,
  });

  yield dispatchResponse(
      { data, error },
      actionTypes.UPDATE_VISITOR_SUCCESS,
      actionTypes.UPDATE_VISITOR_ERROR,
  );
}

export function* teamWatcher() {
  yield all([
    takeLatest(actionTypes.GET_TEAM, getTeam),
    takeLatest(actionTypes.DELETE_TEAM_TRIGGER, deleteTeam),
    takeLatest(actionTypes.CREATE_VISITOR_TRIGER, createVisitor),
    takeLatest(actionTypes.UPDATE_VISITOR_TRIGER, SagaWrapper.withRequest(updateVisitor)),
  ]);

}
