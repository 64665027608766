import {asCreator, withCallbacks} from '../../../../lib/utils';
import {compose} from '../../../../utils/functions';
import {actionTypes} from '../../reducers/householdsReducer';

export const importContacts = compose(
    withCallbacks,
    asCreator((data: File) => ({
        type: actionTypes.IMPORT_HOUSEHOLDS_TRIGGER,
        payload: data,
    }))
);