import React, {FC} from 'react';
import './styled.scss';

interface IFormErrorMessageProps {
    message: string
}

export const FormErrorMessage: FC<IFormErrorMessageProps> = ({ message}) => {
    return (
        <div>
            <span className='form-error-message'>{message}</span>
        </div>
    )
}