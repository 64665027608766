import { GoogleMap, MarkerF } from "@react-google-maps/api"
import { FC } from 'react'
import "./styles.scss";
import pointMarker from '../../../assets/images/pointMarker.svg'

interface IGoogleMapComponent {
    points: any
}

export const GoogleMapComponent: FC<IGoogleMapComponent> = ({ points }) => {
  const containerStyle = {
    width: "100%",
    height: "200px",
    borderRadius: "12px",
  }

    return (
    <div className="saved-routes-google-map">
      <GoogleMap
          mapContainerStyle={containerStyle}
          center={{ lat: Number(+points[0]?.address?.latitude), lng: Number(+points[0]?.address?.longitude) }}
          zoom={8}
          options={{
              zoomControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
          }}>
          {points.map((point: any) => {
              const lat = Number(point.address?.latitude)
              const lng = Number(point.address?.longitude);
              return (
                  <MarkerF icon={pointMarker}
                      key={point.address_id}
                      position={{
                          lat,
                          lng,
                      }}
                      onClick={() => {}}
                  />
              )
          })}
      </GoogleMap>
    </div>
  )
}
