import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "@redux-saga/core";
import { authReducer } from "./reducers/authReducer";
import { rootWatcher } from "../sagas";
import { householdsReducer } from "./reducers/householdsReducer";
import { personsReducer } from "./reducers/personReducer";
import { addressesReducer } from "./reducers/addressesReducer";
import { categoriesReducer } from "./reducers/categoriesReducer";
import { visitsReducer } from "./reducers/visitsReducers";
import { routesReducer } from "./reducers/routesReducer";
import { generalReducer } from "./reducers/generalReducer";
import { teamReducer } from "./reducers/teamReducer";
import { notificationsReducer } from "./reducers/notificationsReducer";

const rootReducer = combineReducers({
  authStore: authReducer,
  householdsStore: householdsReducer,
  personsStore: personsReducer,
  addressesStore: addressesReducer,
  categoriesStore: categoriesReducer,
  visitsStore: visitsReducer,
  routesStore: routesReducer,
  generalStore: generalReducer,
  teamStore: teamReducer,
  notificationsStore: notificationsReducer,
});

const sagaMiddlewere = createSagaMiddleware();

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddlewere))
);

sagaMiddlewere.run(rootWatcher);

export type StateType = ReturnType<typeof rootReducer>;

type PropertiesTypes<T> = T extends { [key: string]: infer U } ? U : never;

export type InferActionsTypes<
  T extends { [key: string]: (...args: any[]) => any }
> = ReturnType<PropertiesTypes<T>>;
