import { actions, actionTypes } from "../store/reducers/personReducer"
import { put, takeEvery } from "redux-saga/effects"
import { updateToken } from "../../utils/updateToken"
import { personAPI } from "../api/api"

function* getSalutations() {
  try {
    const response: { status: number; data: { data: {} } } =
      yield personAPI.getSalutations()
    if (response.status === 200) {
      updateToken()
      yield put(actions.getSalutationsAC(response.data))
    }
  } catch (error) {
    console.log(error)
  }
}

export function* personWatcher() {
  yield takeEvery(actionTypes.GET_SALUTATIONS_TRIGGER, getSalutations)
}
