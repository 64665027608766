import {ChangeEvent, FC, useState} from 'react'
import loginImage from "../../../assets/images/loginImage.png";
import loginImageMobile from "../../../assets/images/loginImageMobile.png";
import blueChevron from "../../../assets/fonts/blueChevron24.svg"
import { RegistrFormik } from "../../../pages/RegistrationPage/RegistrationPage"
import { Link } from "react-router-dom"
import { Input } from "../../LoginPage/Input"
import { Checkbox } from "../../base/Checkbox"
import { Button } from "../../LoginPage/Button"
import { ChabadButton } from "../../LoginPage/ChabadButton"
import "./styles.scss"
import { Box } from '@mui/material'
import { useTranslation } from '../../../hooks/useTranslation';

interface IProps {
  formik: RegistrFormik
  confirmPassword: string
  disabledFirstStep: boolean
  handleEmail: (e: any) => void
  handlePassword: (e: any) => void
  handleConfirmPassword: (e: any) => void
  setStep: (value: string) => void
    handleCheck:  (e: any) => void
}

export const FirstStep: FC<IProps> = ({
  formik,
  confirmPassword,
  disabledFirstStep,
  handleEmail,
  handlePassword,
  handleConfirmPassword,
  setStep,
  handleCheck
}) => {
  const { p } = useTranslation('first_page');
  const { p: pGeneral } = useTranslation('general');
  return (
    <div className="registr-page-first-step">
      <Box
        className="registr-page-first-step__container"
        sx={{
          flexDirection: { xs: "column", md: "row" },
        }}
       >
        <Box
          className="registr-page-first-step__form-container"
          sx={{
            width: { xs: "100%", md: "50vw" },
            order: { xs: "2", md: "1" },
            padding: { xs: "25px 0", md: "125px 0" },
          }}
        >
          <Box
            className="registr-page-first-step__form-container-inner"
            sx={{
              width: { xs: "90%", md: "100%" },
            }}
          >
            <Link to={"/"}>
              <img
                src={blueChevron}
                alt="chevron"
                className="registr-page-first-step__chevron"
              />
            </Link>
            <form
              className="registr-page-first-step__form"
              noValidate
              autoComplete="new-password"
            >
              <Box
                className="registr-page-first-step__form-title"
                sx={{
                  fontSize: { xs: "22px", md: "32px" },
                  textAlign: { xs: "left", md: "center" },
                }}
              >
                { p('lets_register') }
              </Box>
              <Box
                className="registr-page-first-step__form-email"
                sx={{
                  marginBottom: { xs: "15px", md: "40px" },
                }}
              >
                <Input
                  label={pGeneral('email')}
                  placeholder={pGeneral('email')}
                  value={formik.values.email}
                  error={formik.touched['email'] && formik?.errors?.email}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    formik.setTouched({ ...formik.touched, email: true })
                    handleEmail(event)
                  }}
                />
              </Box>
              <Box
                className="registr-page-first-step__form-password"
                sx={{
                  marginBottom: { xs: "15px", md: "40px" },
                }}
              >
                <Input
                  label={pGeneral('password')}
                  placeholder={pGeneral('password')}
                  value={formik.values.password}
                  error={formik.touched['password'] && formik?.errors?.password}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    formik.setTouched({ ...formik.touched, password: true })
                    handlePassword(event)
                  }}
                />
              </Box>
              <Box
                className="registr-page-first-step__form-password"
                sx={{
                  marginBottom: { xs: "15px", md: "40px" },
                }}
              >
                <Input
                  label={pGeneral('password')}
                  placeholder={pGeneral('password')}
                  value={confirmPassword}
                  error={
                    formik.values.password !== confirmPassword
                      ? "Password does not match"
                      : ""
                  }
                  onChange={handleConfirmPassword}
                />
              </Box>
              <div className="registr-page-first-step__checkbox-container">
                {/*<Checkbox checked label="Remember me" onCheck={() => {}} />*/}
                  <Checkbox value={String(formik.values.remember)}
                            checked={!!formik.values.remember}
                            label={p('remember_me')}
                            onCheck={(event) => {
                                handleCheck(event);
                            }} />
                <Link to={"/"} className="registr-page-first-step__forgot">
                  { p('forgot_password_1') }
                </Link>
              </div>
              <div className="registr-page-first-step__button">
                <Button
                  disabled={disabledFirstStep}
                  title={p('next_step')}
                  onClick={() => setStep("second step")}
                />
              </div>
              <div className="registr-page-first-step__line-container">
                <div className="registr-page-first-step__line" />
              </div>
              <div className="registr-page-first-step__or-subtitle">{ p('or') }</div>
              <Box
                className="registr-page-first-step__chabad-button"
                sx={{
                  marginBottom: { xs: '30px' },
                }}
              >
                <ChabadButton />
              </Box>
              <div className="registr-page-first-step__subtitle">
              { p('or') }{" "}
                <Link
                  to={"/login"}
                  className="registr-page-first-step__subtitle-link"
                >
                  { p('login') }
                </Link>
              </div>
            </form>
          </Box>
        </Box>
        <Box
          className="registr-page-first-step__image-container"
          sx={{
            order: { xs: "1", md: "2" },
            height: { xs: "17vh", md: "100vh" },
            backgroundImage: { xs: `url(${loginImageMobile})`, md: `url(${loginImage})` },
            backgroundPosition: { xs: "bottom", md: "top" },
          }}
          />
      </Box>
    </div>
  )
}
