import { FC } from "react";
import { BlackButton } from "../BlackButton";
import { WhiteButton } from "../WhiteButton";
import "./styles.scss";
import { useTranslation } from "../../../hooks/useTranslation";

interface IProps {
  setOpenSaveModal: (value: boolean) => void;
  setOpenOptimizeModal: (value: boolean) => void;
  canOpen: boolean;
}

export const RouteFooter: FC<IProps> = ({
  setOpenSaveModal,
  setOpenOptimizeModal,
  canOpen,
}) => {
  const { p } = useTranslation('map');
  return (
    <div className="home-page-route-footer">
      <div>
        <WhiteButton
          disable={!canOpen}
          title={p('save_route')}
          onClick={() => canOpen && setOpenSaveModal(true)}

        />
      </div>
      <div>
        <BlackButton
          disable={!canOpen}
          title={p('start_route')}
          onClick={() => canOpen && setOpenOptimizeModal(true)}
        />
      </div>
    </div>
  );
};
