import { IUser } from "../../../types/reducers/authReducer"
import { InferActionsTypes } from "./../index"

export const actionTypes = {
  LOGIN_USER_TRIGGER: "LOGIN_USER_TRIGGER",
  REG_USER_TRIGGER: "REG_USER_TRIGGER",
  LOGOUT_TRIGGER: "LOGOUT_TRIGGER",
  GET_TOKEN_TRIGGER: "GET_TOKEN_TRIGGER",
  LOGIN_USER: "LOGIN_USER",
  LOGOUT: "LOGOUT",
  REG_USER: "REG_USER",
  GET_USER: 'GET_USER',
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_ERROR: "GET_USER_ERROR",
  SET_USER_TOKEN: "SET_USER_TOKEN",
  GET_TOKEN: "GET_TOKEN",
  GET_TOKEN_FOR_SOCKET_CONNECTION: "GET_TOKEN_FOR_SOCKET_CONNECTION",
  GET_TOKEN_FOR_SOCKET_CONNECTION_SUCCESS : "GET_TOKEN_FOR_SOCKET_CONNECTION_SUCCESS",
  GET_TOKEN_FOR_SOCKET_CONNECTION_ERROR : "GET_TOKEN_FOR_SOCKET_CONNECTION_ERROR",
  FORGET_PASSWORD: "FORGET_PASSWORD",
  FORGET_PASSWORD_SUCCESS: "FORGET_PASSWORD_SUCCESS",
  FORGET_PASSWORD_ERROR: "FORGET_PASSWORD_ERROR",
  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",
  CHANGE_USER_LANGUAGE: "CHANGE_USER_LANGUAGE",
  CHANGE_USER_LANGUAGE_SUCCESS: "CHANGE_USER_LANGUAGE_SUCCESS",
  CHANGE_USER_LANGUAGE_ERROR: "CHANGE_USER_LANGUAGE_ERROR"
}

interface InitialState {
  token: null
  user: IUser | null,
  currentUser: IUser | null,
}

const initialState: InitialState = {
  token: null,
  user: null,
  currentUser: null
}

export const authReducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case actionTypes.SET_USER_TOKEN:
      return { ...state, token: action.payload }
    case actionTypes.LOGIN_USER:
      return { ...state, user: action.payload }
    case actionTypes.GET_USER_SUCCESS:
      return { ...state, currentUser: action.payload }
    default:
      return state
  }
}

export const actions = {
  loginUserAC: (data: IUser | null) => ({
    type: actionTypes.LOGIN_USER,
    payload: data,
  }),
  setUser: (data: IUser | null) => {
    return ({
      type: actionTypes.GET_USER_SUCCESS,
      payload: data
    })
  }
}

export const authTriggerActions = {
  loginUserTriggerAC: (data: any) => ({
    type: actionTypes.LOGIN_USER_TRIGGER,
    data,
  }),
  regUserTriggerAC: (data: any) => ({
    type: actionTypes.REG_USER_TRIGGER,
    data,
  }),
  logoutUserTriggerAC: () => ({
    type: actionTypes.LOGOUT_TRIGGER,
  }),
  getTokenTriggerAC: () => ({
    type: actionTypes.GET_TOKEN_TRIGGER,
  }),
}

export type Actions = InferActionsTypes<typeof actions>
