import { FC } from "react"
import pencil from "../../../assets/fonts/pencil.svg"
import "./styles.scss"
import { useTranslation } from "../../../hooks/useTranslation";

export const DrawToggler: FC = () => {
  const { p } = useTranslation('map');
  return (
    <div className="home-page-draw-toggler">
      <img src={pencil} alt="pencil" />
      { p('draw') }
    </div>
  )
}
