import { FC } from "react";
import { Route } from "../../../types/global/types";
import { ListBodyItem } from "../ListBodyItem";
import { ListHeader } from "../ListHeader";
import "./styles.scss";

type Props = {
  savedRoutes: Route[];
  handleStartRoute: (id: number) => void
    handleDeleteRoute: (id: number) => void
};

export const SavedRoutesList: FC<Props> = ({ savedRoutes, handleStartRoute, handleDeleteRoute }) => {
  return (
    <div className="saved-routes-list">
      <ListHeader />
      <div className="saved-routes-list__container">
          {savedRoutes.map((savedRoute: Route, index) => (
              <ListBodyItem handleStartRoute={handleStartRoute} handleDeleteRoute={handleDeleteRoute} savedRoute={savedRoute} index={index} key={index} />))}
      </div>
    </div>
  );
};
