import { FC, useState } from "react"
import { useClickOutside } from "../../../hooks/useClickOutside"
import vectorMini from "../../../assets/fonts/vectorMini.svg"
import check from "../../../assets/fonts/check-black20.svg"
import { FormikType } from "../../../types/VisitReports/types"
import "./styles.scss"
import {FormErrorMessage} from '../../base/FormErrorMessage';
import {ERRORS} from '../../../constants/base';
import { useTranslation } from "../../../hooks/useTranslation"

type Props = {
  formik: FormikType
  label: string
  list: any,
  isRequired?: boolean
}

export const MultiSelect: FC<Props> = ({ formik, label, list, isRequired }) => {
  const { p } = useTranslation('contact_info');
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<string[]>([])

  const handleSelected = async (elem: any) => {
    await formik.setTouched({ ...formik.touched, my_categories: [{ id: true }] })
    await formik.setTouched({ ...formik.touched, checked_categories: [{ id: true }] })
    if (!selected.some((item: any) => item.name === elem.name)) {
      setSelected([...selected, elem])

      formik.setFieldValue("my_categories", [
        ...formik.values?.my_categories,
        {
          id: elem.id,
          status: true,
        },
      ])
    } else {
      setSelected((prev) => prev.filter((item: any) => item.name !== elem.name))
      formik.setFieldValue(
        "my_categories",
        formik.values.my_categories.filter((item: any) => item.id !== elem.id)
      )
    }
  }

  const handleOpen = () => {
    setOpen(!open)
  }

  const handleBlur = () => {
    setOpen(false)
  }

  const ref = useClickOutside({ handler: handleBlur })

  return (
    <div className="visit-report-multi-select" ref={ref}>
      <div className="visit-report-multi-select__input-container">
        <label className="visit-report-multi-select__label">{label}{isRequired && <span style={{color: "red"}}>*</span>}</label>
        <span
          className={`visit-report-multi-select__span ${
            open && "visit-report-multi-select_active-arrow"
          }`}
        >
          <img src={vectorMini} alt="vector-mini" />
        </span>
        <input
          className={`visit-report-multi-select__input ${!!formik.touched?.checked_categories?.length && formik.errors['my_categories'] ? "error" : ""}`}
          type="text"
          name="salutation"
          placeholder={p('select')}
          readOnly
          onFocus={handleOpen}
        />
        <div
          className={`visit-report-multi-select__list-container ${
            open && "visit-report-multi-select_open"
          }`}
        >
          {list?.map((elem: any) => {
            return (
              <div
                className={`visit-report-multi-select__list ${
                  selected.find((item: any) => item.name === elem.name) &&
                  "visit-report-multi-select__list_active"
                }`}
                key={elem.id}
                onClick={() => handleSelected(elem)}
              >
                <div className="visit-report-multi-select__list-item">
                  {elem.name}
                </div>
                {selected.find((item: any) => item.name === elem.name) && (
                  <img src={check} alt="check" />
                )}
              </div>
            )
          })}
        </div>
      </div>
      {(!!formik.touched?.checked_categories?.length && formik.errors['my_categories']) && (
          <FormErrorMessage message={ERRORS.required} />
      )}
    </div>
  )
}
