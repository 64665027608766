import { FC, useContext } from 'react'
import basket from "../../../assets/fonts/deleteBasketRed.svg"
import "./styles.scss"
import { UserPermissionsEnum } from '../../../enums/userPermissionsEnum';
import { UserContext } from '../../../contexts/UserContext';
import { useTranslation } from '../../../hooks/useTranslation';

type Props = {
  onClick: () => void
}

export const DeleteRouteButton: FC<Props> = ({ onClick }) => {
  const { p } = useTranslation('saved_routes');
  const { user } = useContext(UserContext);
  return (
    <button
        disabled={!user?.permissions[UserPermissionsEnum.deleteRoutes]}
        className="delete-route-button" onClick={onClick}>
      {p('delete_route')} <img src={basket} alt="basket" />
    </button>
  )
}
