import { FC } from "react"
import closeIconFont from "../../../assets/fonts/close.svg"
import "./styles.scss"

type Props = {
  title: string
  disableUnderline?: boolean
  onClose: () => void
}

export const FilterHeader20: FC<Props> = ({
  title,
  disableUnderline,
  onClose,
}) => {
  return (
    <div
      className={`home-filter-header ${
        disableUnderline ? "home-filter-header_underline-none" : ""
      }`}
    >
      <h3>{title}</h3>
      <div className="home-filter-header__img" onClick={onClose}>
        <img src={closeIconFont} alt="close" />
      </div>
    </div>
  )
}
