  import { useTranslation as useI18nextTranslation } from 'react-i18next';
import { useCallback } from 'react';

export const useTranslation = (page) => {
  const { t, i18n, ...rest } = useI18nextTranslation('translation');
  const { t: e } = useI18nextTranslation('errors');

  const p = useCallback((field, ...rest) => page ? t(`${page}.${field}`, ...rest) : t(field, ...rest), [page, t, i18n.language]);

  return {
    p,
    t,
    e,
    i18n,
    ...rest
  };
};
