import { FC } from "react"
import "./styles.scss"
import { useTranslation } from "../../../hooks/useTranslation"

type Props = {
  visitsType: string
  setVisitsType: (value: string) => void
}

export const SwitchButton: FC<Props> = ({ visitsType, setVisitsType }) => {
  const { p } = useTranslation('contact_info');
  return (
    <div className="switch-button">
      <div className="switch-button__container">
        <div
          className={`switch-button__half ${
            visitsType === "Past visits" ? "switch-button__half_active" : ""
          } `}
          onClick={() => setVisitsType("Past visits")}
        >
          { p('past_visits') }
        </div>
        <div
          className={`switch-button__half ${
            visitsType === "Scheduled visits"
              ? "switch-button__half_active"
              : ""
          } `}
          onClick={() => setVisitsType("Scheduled visits")}
        >
          { p('scheduled_visits') }
        </div>
      </div>
    </div>
  )
}
