import { FC } from "react"
import sortFont from "../../../assets/fonts/sortIcon.svg"
import { useClickOutside } from "../../../hooks/useClickOutside"
import "./styles.scss"
import {useTranslation} from "../../../hooks/useTranslation";

interface IProps {
  open: boolean
  value: string | null
  setSortByValue: (value: string | null) => void
  setOpen: (value: boolean) => void;
  isDisabled?: boolean
}

export const SortFilter: FC<IProps> = ({
  open,
  value,
  setOpen,
  setSortByValue,
  isDisabled
}) => {
  const { p, t, i18n } = useTranslation('contacts');

  const handleSelect = (value: string | null) => {
    setOpen(false)
    setSortByValue(value)
  }

  const handleBlur = () => {
    setOpen(false)
  }

  const ref = useClickOutside({ handler: handleBlur });

  const options = [
    {label: p('default'), value: null},
    {label: p('asc'), value: 'asc'},
    {label: p('desc'), value: 'desc'},
  ];

  return (
    <div
      className={`contacts-page-sort-filter  ${isDisabled ? "isDisabled" : ""} `}
      ref={ref}
      onClick={() => setOpen(!open)}
    >
      <div
        className={`contacts-page-sort-filter__header ${
          open ? "contacts-page-sort-filter__header_active" : ""
        } ${isDisabled ? "isDisabled" : ""} `}
      >
        <img
          src={sortFont}
          alt="sort"
          className="contacts-page-sort-filter__img"
        />
        {p('sort_by')}
      </div>
      {open && (
        <div className="contacts-page-sort-filter__container">
          {options.map((item) => {
            return (
              <div
                className={`contacts-page-sort-filter__item ${
                  value === item.value ? "contacts-page-sort-filter__item_active" : ""
                }`}
                key={item.value}
                onClick={() => handleSelect(item.value)}
              >
                {item.label}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
