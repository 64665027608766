import { FC } from "react"
import infoWarning from "../../../assets/fonts/infoWarning.svg"
import { Household } from "../../../types/global/types"
import "./styles.scss"
import { useTranslation } from "../../../hooks/useTranslation"

type Props = {
  household: Household
}

export const WarningPanel: FC<Props> = ({ household }) => {
  const { p } = useTranslation('contact_info');
  return (
    <div className="visit-report-warning-panel">
      <div className="visit-report-warning-panel__container">
        <img src={infoWarning} alt="info" />
        {p('recent_completed_visit')}{" "}
        {household.last_completed_visit_date_formatted}
      </div>
    </div>
  )
}
