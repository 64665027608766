import { Outlet, useNavigate } from 'react-router-dom'
import { useEffect } from 'react';

export const AuthLayout = () => {
    const navigate = useNavigate()
    const isUser = localStorage.getItem("user");
    useEffect(() => {
        if(!isUser) return ;
        navigate('/households')
    }, [isUser])
  return (
    <div>
      <Outlet />
    </div>
  )
}
