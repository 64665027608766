import React, { FC } from 'react';
import { Modal } from '@mui/material';
import { FilterHeader20 } from '../../base/FilterHeader20';
import { Person } from '../../VisitReportPage/Person';
import './styled.scss';
import {BlackButton} from '../../HomePage/BlackButton';

interface IUpdatedPersonNotificationProps {
    formik: any
    isVisible: boolean,
    setIsVisible: () => void;
    salutations: string[]
    addressesTypes: string[]
}

export const UpdatedPersonNotification:
    FC<IUpdatedPersonNotificationProps> = ({
                                               isVisible,
                                               setIsVisible,
                                               formik,
                                               salutations,
                                               addressesTypes }) => {
    return(
        <Modal
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999 }}
            open={isVisible}>
            <div className="update-person-notification__modal">
                <FilterHeader20 title={"CHANGED INFORMATION FOR THE CONTACT"} onClose={setIsVisible} />
                <div className='update-person-notification__modal__body'>
                    {formik.values.updated_persons.map((_: any, index: number) => (
                        <Person
                            isUpdate
                            key={index}
                            formik={formik}
                            personIndex={index}
                            salutations={salutations}
                            addressesTypes={addressesTypes}
                        />
                    ))}
                </div>
                <div className='update-person-notification__modal__footer'>
                    <div className="update-person-notification__modal__button_wrapper">
                        <BlackButton title={'Save changed'} onClick={formik.handleSubmit} />
                    </div>
                </div>
            </div>
        </Modal>
    )
}