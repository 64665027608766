import { FC } from "react"
import { Button } from "../Button"
import "./styles.scss"
import { useTranslation } from "../../../hooks/useTranslation"

type Props = {
  handleNextStep: () => void
  handleSaveReport: () => void;
  step: number;
}

export const Footer: FC<Props> = ({ handleNextStep, handleSaveReport, step }) => {
  const { p } = useTranslation('general');
  return (
    <div className="visit-report-children-footer">
      <Button title={p('save_and_continue')} onClick={() => {
        if(step === 0) return handleSaveReport()
        return handleNextStep()
      }} />
      {/*{step === 0 && <span onClick={handleNextStep} className={"visit-report-children-footer__button"}>Report Contact Changes</span>}*/}
    </div>
  )
}
