import { useFormik } from "formik";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { householdsTriggerActions } from "../../backend/store/reducers/householdsReducer";
import { Preloader } from "../../components/base/Preloader/Preloader";
import { Categories } from "../../components/CreateHouseholdPage/Categories";
import { FormikType } from "../../types/CreateHousehold/types";
import { updateHousehold } from "../../backend/store/actions/creators/household";
import { generalActions } from "../../backend/store/reducers/generalReducer";

export const UpdateCategoriesPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const regularCategoris = useSelector(
    (state: any) => state.categoriesStore.categories
  );

  const periodicity = useSelector(
    (state: any) => state.householdsStore.periodicity
  );

  const household = useSelector(
    (state: any) => state.householdsStore.household
  );

  const regularCategorisOptions = useMemo(
    () => regularCategoris || [],
    [regularCategoris]
  );

  const periodicityOptions = useMemo(() => periodicity || [], [periodicity]);
  const householdValue = useMemo(() => household || {}, [household]);

  const formik: FormikType = useFormik({
    enableReinitialize: true,
    initialValues: householdValue,
    onSubmit: (data) => {
      data.categories = data.categories.map((elem: any) => elem.id);
      dispatch(
        updateHousehold({
          data: data,
          id: id,
          onSuccess: (res: any) => {
            dispatch(
              generalActions.successAC({ message: "Updated", type: "success" })
            );
            dispatch(
              householdsTriggerActions.getHouseholdTriggerAC(Number(id))
            );
          },
          onError: (error: any) => {
            dispatch(
              generalActions.successAC({
                message: error?.response?.data?.message,
                type: "error",
              })
            );
          },
        })
      );
    },
  });

  if (!household) {
    return <Preloader />;
  }
  return (
    <Categories
      formik={formik}
      householdValue={householdValue}
      isUpdate
      regularCategorisOptions={regularCategorisOptions}
      periodicityOptions={periodicityOptions}
    />
  );
};
