import { FC } from "react";
import { Link } from "react-router-dom";
import { Route } from "../../../types/global/types";
import { ListActions } from "../ListActions";
import "./styles.scss";

type Props = {
  savedRoute: Route;
  index: number;
  handleStartRoute: (id: number) => void
    handleDeleteRoute: (id: number) => void
};

export const ListBodyItem: FC<Props> = ({ savedRoute, index, handleStartRoute, handleDeleteRoute }) => {
  return (
    <div className="saved-routes-list-body-item">
      <Link
        to={`/saved-routes/${savedRoute.id}`}
        className="saved-routes-list-body-item__container"
      >
        <div className="saved-routes-list-body-item__text">
          {savedRoute.name}
        </div>
        <div className="saved-routes-list-body-item__date">
          {savedRoute.created_at_formatted}
        </div>
        <div></div>
      </Link>
      <div className="saved-routes-list-body-item__action">
        <ListActions
            handleStartRoute={handleStartRoute}
            handleDeleteRoute={handleDeleteRoute}
            id={savedRoute.id} />
      </div>
    </div>
  );
};
