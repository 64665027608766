export enum FiltersHousehold {
    periodicity = 'periodicity',
    regular_categories = 'regular_categories',
    gender = 'gender',
    due_visit_start_date = 'due_visit_start_date',
    deferred_visit_start_date = 'deferred_visit_start_date',
    data_range_end_date = "data_range_end_date",
    data_range_start_date = "data_range_start_date",
    deferred_visit = "deferred_visit",
    not_visit = "not_visit"
}