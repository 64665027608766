import { createContext } from 'react';
import {NotificationProps} from '../interfaces/notificationProps';

export const NotificationContext = createContext<{
    addNotification: (
        notification: Omit<NotificationProps, "show" | "setShow">
    ) => void;
    notifications: NotificationProps[];
}>({
    addNotification: () => {},
    notifications: [],
});
