import {Dispatch, SetStateAction, useState} from 'react';
import {householdsTriggerActions} from '../../../backend/store/reducers/householdsReducer';
import {useDispatch} from 'react-redux';
import {IRegularCategory} from '../../../interfaces/regularCategory';

interface IUseUpdateRegularCategoriesRes {
    inputValue: string,
    handleInputChange: (value: string) => void;
    handleSave: (id: number | null) => void;
    isVisible: boolean;
    setIsVisible: () => void;
    handleDeleteCategoryWithHouseholds: () => void;
    handleCancelDeleteCategory: () => void;
    deleteCategoryModal: boolean
    setInputValue: Dispatch<SetStateAction<string>>
    handleDelete: (id: IRegularCategory) => void;
    isSuccess: boolean;
    successMessage: string,
    categoryForDeleting: null | IRegularCategory
}

export const useUpdateRegularCategories = (): IUseUpdateRegularCategoriesRes => {
    const [isVisible, setIsVisibleModal] = useState(false);
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [deleteCategoryModal, setDeleteCategoryModal] = useState(false);
    const [categoryForDeleting, setCategoryForDeleting] = useState<null | IRegularCategory>(null);
    const [successMessage, setSuccessMessage] = useState('');

    const handleInputChange = (value: string) => {
        setInputValue(value);
    }

    const handleSave = (id: number | null) => {
        if(!id) {
            dispatch(householdsTriggerActions.createHouseholdCategory(inputValue));
            setIsVisibleModal(prevState => !prevState)
            setIsSuccess(prevState => !prevState);
            setSuccessMessage('Category added');
            const timeoutId = setTimeout(() => {
                setIsSuccess(prevState => !prevState);
                clearTimeout(timeoutId);
            }, 3000)
            return ;
        }
        dispatch(householdsTriggerActions.updateHouseholdCategory(id, inputValue))
        setIsVisibleModal(prevState => !prevState)
        setIsSuccess(prevState => !prevState);
        setSuccessMessage('Changes for category saved');
        const timeoutId = setTimeout(() => {
            setIsSuccess(prevState => !prevState);
            clearTimeout(timeoutId);
        }, 3000)
    }

    const handleCancelDeleteCategory = () => {
        setDeleteCategoryModal(prevState => !prevState);
    }

    const handleDeleteCategoryWithHouseholds = () => {
        if(!categoryForDeleting) return ;
        dispatch(householdsTriggerActions.deleteHouseholdCategory(categoryForDeleting.id));
        setDeleteCategoryModal(prevState => !prevState);
    }

    const handleDelete = (category: IRegularCategory) => {
        if(category.has_households) {
            setCategoryForDeleting(category);
            return setDeleteCategoryModal(prevState => !prevState);
        }
        return dispatch(householdsTriggerActions.deleteHouseholdCategory(category.id))
    }

    return {
        inputValue,
        handleInputChange,
        isVisible,
        setIsVisible: () => {
            setIsVisibleModal(prevState => !prevState);
        },
        handleDelete,
        setInputValue,
        handleSave,
        isSuccess,
        successMessage,
        handleDeleteCategoryWithHouseholds,
        handleCancelDeleteCategory,
        deleteCategoryModal,
        categoryForDeleting,
    }
}