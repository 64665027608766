import { FC } from "react"
import "./styles.scss"

type Props = {
  title: string
  icon: string
}

export const Panel: FC<Props> = ({ icon, title }) => {
  return (
    <div className="visit-report-panel">
      <div className="visit-report-panel__item">
        <img src={icon} alt="user" />
      </div>
      <div className="visit-report-panel__item">{title}</div>
    </div>
  )
}
