import { FC } from "react"
import { GlobalButton } from "../GlobalButton"
import "./styles.scss"

interface IProps {
  selectedCount: number
  onDelete: () => void
  onUpdateCategories: () => void
  onUpdateScheduling: () => void
}

export const GlobalActionsPanel: FC<IProps> = ({
  selectedCount,
  onDelete,
  onUpdateScheduling,
  onUpdateCategories
}) => {
  return (
    <div className="contacts-page-global-actions">
      <div className="contacts-page-global-actions__global-select-title">
        {selectedCount} entries selected
      </div>
      <div className="contacts-page-global-actions__global-button-group">
        <div>
          <GlobalButton onClick={onUpdateScheduling} title="Update Scheduling" />
        </div>
        <div>
          <GlobalButton onClick={onUpdateCategories} title="Update categories" />
        </div>
        <div>
          <GlobalButton onClick={onDelete} title="Delete contacts" />
        </div>
      </div>
    </div>
  )
}
