import { Outlet } from "react-router-dom"
import AppBarComponent from "../AppBar/AppBar"
import { Footer } from "../Footer/Footer"
import { SideBar } from "../SideBar/SideBar"
import "./styles.scss"
import {Box} from '@mui/material';
import {SideBarMobile} from '../SideBarMobile';
import {useSelector} from 'react-redux';

export const SidebarAndAppbarLayout = () => {
    const isVisible = useSelector((state: any) => state.generalStore.visibleSideBar);
    return (
        <div>
            <Box sx={isVisible ? { filter: "blur(2px)", pointerEvents: "none", overflow: "hidden", maxHeight: "100vh" } : {}}>
                <AppBarComponent/>
                <Box className="sidebar-and-appbar-layout">
                    <Box className="sidebar-and-appbar-layout__sidebar-container" sx={{display: {xs: 'none', md: 'block'}}}>
                        <SideBar/>
                    </Box>
                    <div className="sidebar-and-appbar-layout__appbar-container">
                        <Outlet/>
                        <Footer/>
                    </div>
                </Box>
            </Box>
            <SideBarMobile/>
        </div>
    );
}
