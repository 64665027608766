import { FC } from "react"
import "./styles.scss"
import plusFont from "../../../assets/fonts/plus.svg"

type Props = {
  title: string
  onClick?: () => void
  isDisabled?: boolean
}

export const IconButton: FC<Props> = ({ title, onClick, isDisabled }) => {
  return (
    <button
      disabled={isDisabled}
      className="households-page-icon-button"
      type="button"
      onClick={onClick}
    >
      <img src={plusFont} alt="plus" />
      {title}
    </button>
  )
}
