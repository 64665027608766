import React, {Dispatch, FC, SetStateAction} from 'react';
import { Modal } from '@mui/material';
import './styled.scss';
import {FilterHeader20} from '../../base/FilterHeader20';
import {IRegularCategory} from '../../../interfaces/regularCategory';
import {CustomCheckbox} from '../CustomCheckbox/CustomCheckbox';
import {WhiteButton} from '../../HomePage/WhiteButton';
import {BlackButton} from '../../HomePage/BlackButton';

interface IUpdateRegularCategoriesProps {
    isVisible: boolean,
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    categories: IRegularCategory[],
    handleChangeRegularCategory: (id: number) => void;
    selectedRegularCategories: number[]
    handleSubmitRegularCategories: () => void;
}

export const UpdateRegularCategories:
    FC<IUpdateRegularCategoriesProps> = ({
                                             isVisible,
                                             setIsVisible,
                                             categories,
                                             selectedRegularCategories,
                                             handleChangeRegularCategory,
                                             handleSubmitRegularCategories
    }) => {
    return (
        <Modal
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999 }}
            open={isVisible}
            onClose={() => {
                setIsVisible(prevState => !prevState)
            }}
        >
            <div className='update-regular-categories'>
                <FilterHeader20
                    title="UPDATE REGULAR CATEGORIES"
                    disableUnderline
                    onClose={() => setIsVisible(!isVisible)}
                />
                <div className='update-regular-categories__body'>
                    {categories?.map((category) => (
                        <div
                            key={category.id}
                            className='update-regular-categories__item'>
                                <CustomCheckbox
                                    onChange={(id) => {
                                        if(!id) return ;
                                        handleChangeRegularCategory(id)
                                    }}
                                    checked={selectedRegularCategories.includes(category.id)}
                                    id={category.id}
                                />
                            <span>
                                {category?.name}
                            </span>
                        </div>
                    ))}
                </div>
                <div className='update-scheduled-categories__footer'>
                    <WhiteButton
                        forModal
                        title={"Cancel"}
                        onClick={() => {
                            setIsVisible(prevState => !prevState)
                        }} />
                    <BlackButton
                        forModal
                        title={'Apply'}
                        onClick={handleSubmitRegularCategories}
                    />
                </div>
            </div>
        </Modal>
    )
}