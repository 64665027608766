import "./styles.scss"
import vectorMini from "../../../assets/fonts/vectorMini.svg"
import vectorSelect from "../../../assets/fonts/vectorSelect.svg"
import { FC, useState } from "react"
import { useClickOutside } from "../../../hooks/useClickOutside"

type Props = {
  isRequired?: boolean;
  label: string
  list: string[]
  value: string
  placeholder: string
  handleSelect: (value: string) => void;
  onChangeClose?: boolean
}

export const Select: FC<Props> = ({
  onChangeClose,
  label,
  list,
  value,
  placeholder,
  handleSelect,
  isRequired
}) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(!open)
  }

  const handleBlur = () => {
    setOpen(false)
  }

  const ref = useClickOutside({ handler: handleBlur })

  return (
    <div className="create-household-select" ref={ref}>
      <label className="create-household-select__label">{label}{isRequired && <span style={{color: 'red'}}>*</span>}</label>
      <span
        className={`create-household-select__span ${
          open && "create-household-select_active-arrow"
        }`}
      >
        <img src={vectorMini} alt="vector-mini" />
      </span>
      <div className="create-household-select__input" onClick={handleOpen}>
        {value ? value : placeholder}
      </div>
      <div
        className={`create-household-select__list-container ${
          open && "create-household-select_open"
        }`}
      >
          <div className='create-household-multi-select__list-items'>
              {list.map((elem) => {
                  return (
                      <div
                          className={`create-household-select__list ${
                              value === elem && "create-household-select_active"
                          }`}
                          key={elem}
                          onClick={() => {
                              if(onChangeClose) handleOpen();
                              handleSelect(elem)
                          }}
                      >
                          {elem}
                          <div
                              className={`create-household-select__list-arrow ${
                                  value === elem && "create-household-select_active-check"
                              }`}
                          >
                              <img src={vectorSelect} alt="arrow" />
                          </div>
                      </div>
                  )
              })}
          </div>
      </div>
    </div>
  )
}
