import React, {Dispatch, FC, SetStateAction} from 'react';
import './styes.scss';
import Box from '@mui/material/Box';
import { FilterHeader20 } from '../../base/FilterHeader20';
import deleteRouteIcon from '../../../assets/images/DeleteRouteIcon2.svg';
import Typography from '@mui/material/Typography';
import { WhiteButton } from '../../HomePage/WhiteButton';
import { RedButton } from '../../HomePage/RedButton';
import { Modal } from '@mui/material';
import {useTranslation} from "../../../hooks/useTranslation";

interface IDeleteRouteModalProps {
    isVisible: boolean,
    setIsVisible: Dispatch<SetStateAction<boolean>>,
    handleDelete: () => void;
}

export const DeleteRouteModal: FC<IDeleteRouteModalProps> = ({ isVisible, setIsVisible, handleDelete }) => {
    const { p } = useTranslation('saved_routes');
    const { p: pGeneral } = useTranslation('general');

    return (
        <Modal
            open={isVisible}
            onClose={() => setIsVisible(!isVisible)}
            sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        >
            <Box className={'modal-delete-route'}>
                <FilterHeader20
                    title={p('deleting_route')}
                    disableUnderline
                    onClose={() => {
                        setIsVisible(!isVisible)
                    }}
                />
                <Box className={'modal-delete-route__body'}>
                    <img src={deleteRouteIcon} alt="route"/>
                    <Typography className={'modal-delete-visitor__title'}>{p('modal_delete')}</Typography>
                </Box>
                <Box className={'modal-delete-route__footer'}>
                    <WhiteButton title={pGeneral('cancel')} forModal onClick={() => setIsVisible(!isVisible)}/>
                    <RedButton title={pGeneral('delete')} forModal onClick={handleDelete}/>
                </Box>
            </Box>
        </Modal>
    )
};