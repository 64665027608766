import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { Provider as StoreProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "./backend/store";
import * as Sentry from "@sentry/browser";
import './res/languages';
import { App } from "./components/base/App";

import Echo from "laravel-echo";

export const Pusher = require("pusher-js");

export const ECHO = new Echo({
  broadcaster: "pusher",
  key: "73f5e5b23ed3dc36d070",
  cluster: "mt1",
  forceTLS: true,
});

Sentry.init({
  dsn: "https://1f3002fc8c264dc4b8f86b65d14c6ddc@o1157038.ingest.sentry.io/4504962637168640",
  integrations: [new Sentry.BrowserTracing()],

  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <StoreProvider store={store}>
        <App />
      </StoreProvider>
    </BrowserRouter>
  </React.StrictMode>
);
