import {compose} from "../../../../utils/functions";
import {asCreator, withCallbacks} from "../../../../lib/utils";
import {actionTypes} from "../../reducers/householdsReducer";

export const getMapHouseholds = compose(
    withCallbacks,
    asCreator((data: any) => ({
        type: actionTypes.GET_HOUSEHOLDS_TRIGGER_FOR_MAP,
        payload: data
    }))
);