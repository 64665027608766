import {FC, useContext} from 'react'
import categoriesIcon from "../../../assets/fonts/categories.svg"
import saveIcon from "../../../assets/fonts/save.svg"
import { FormikType } from "../../../types/CreateHousehold/types"
import "./styles.scss"
import { CreateFooter } from "../CreateFooter"
import { UpdateFooter } from "../../UpdateHouseholdPage/UpdateFooter"
import { MultiSelect } from "../MultiSelect"
import { Select } from "../Select"
import { RegularCategoris } from "../../../types/global/types"
import {UserPermissionsEnum} from '../../../enums/userPermissionsEnum';
import {UserContext} from '../../../contexts/UserContext';
import { useTranslation } from '../../../hooks/useTranslation'

type Props = {
  formik: FormikType
  householdValue?: any
  isUpdate?: boolean
  periodicityOptions: string[]
  regularCategorisOptions: RegularCategoris[]
}

export const Categories: FC<Props> = ({
  formik,
  isUpdate,
  householdValue,
  regularCategorisOptions,
  periodicityOptions,
}) => {
  const { p } = useTranslation('contact_info');
  const { p: pGeneral } = useTranslation('general');
  const { user } = useContext(UserContext);
  const handleScheduledCategories = (value: string) => {
    formik.setFieldValue("periodicity", value)
  }

  const cancelChanges = () => {
    formik.setValues({
      ...householdValue,
    })
  }

  const handleSelect = (elem: any) => {
    if (!formik.values.categories) {
      formik.setFieldValue("categories", [elem])
    } else {
      const categories: {}[] = formik.values.categories
      if (!categories.some((item: any) => item.id === elem.id)) {
        formik.setFieldValue("categories", [...formik.values.categories, elem])
      } else {
        formik.setFieldValue(
          "categories",
          categories.filter((item: any) => item.id !== elem.id)
        )
      }
    }
  }
  return (
    <div className="create-household-categories">
      <div className="create-household-categories__header">
        <div className="create-household-categories__img-container">
          <img src={categoriesIcon} alt="categories" />
        </div>
        <div className="create-household-categories__title">{p('categories')}</div>
      </div>
      <div className="create-household-categories__container">
        <div className="create-household-categories__multi-select">
          <MultiSelect
            selected={formik.values.categories || []}
            label={p('regular_categories')}
            checkbox
            list={regularCategorisOptions}
            handleSelect={handleSelect}
          />
        </div>
        <div className="create-household-categories__selects">
          <Select
            onChangeClose
            label={p('scheduled_categories')}
            list={periodicityOptions}
            value={formik.values.periodicity}
            placeholder={p('select')}
            handleSelect={handleScheduledCategories}
          />
        </div>
      </div>
      {isUpdate && !!user?.permissions[UserPermissionsEnum.updateHouseholds] && (
        <div className="create-household-layout__footer">
          {isUpdate ? (
            <UpdateFooter
              title={pGeneral('cancel')}
              label={pGeneral('save_changes')}
              icon={saveIcon}
              cancelClick={cancelChanges}
              onClick={() => formik.handleSubmit()}
            />
          ) : (
            <CreateFooter
              title={pGeneral('cancel')}
              onClick={() => formik.handleSubmit()}
            />
          )}
        </div>
      )}
    </div>
  )
}
