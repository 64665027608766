import {asCreator, withCallbacks} from '../../../../lib/utils';
import {compose} from '../../../../utils/functions';
import {actionTypes} from '../../reducers/householdsReducer';

export const deleteHousehold = compose(
    withCallbacks,
    asCreator((data: number) => ({
        type: actionTypes.DELETE_HOUSEHOLD_TRIGGER,
        payload: data,
    }))
);