import LaunchIcon from "@mui/icons-material/Launch"
import Button from "@mui/material/Button"
import { FC } from "react"
import { Link } from "react-router-dom"
import "./CustomLinkButton.scss"

type Props = {
  title: string
  link: string
  isDisabled?: boolean
}

export const CustomLinkButton: FC<Props> = ({ title, link, isDisabled }) => {
  return (
    <Link className="custom-link-button" to={link}>
      <button type="button" className={`custom-link-button__button ${isDisabled ? "disabled" : ""}`} disabled={isDisabled}>
        {title}
        <LaunchIcon className="custom-link-button__img" />
      </button>
    </Link>
  )
}
