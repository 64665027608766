import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";

import { ErrorText } from "../ErrorText";
import "./styles.scss";
import VectorImage from "./Vector.svg";
import VectorImageActive from "./VectorActive.svg";

export interface IInputProps {
  label?: string;
  placeholder: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  isRequired?: boolean;
  isDisabled?: boolean;
  error?: string;
  isError?: boolean;
  myCoordinates?: any;
  getAddressVal?: any;
  dis?: boolean;
  myCoordinatesOnes?: any;
}

export const Input: FC<IInputProps> = ({
  label,
  placeholder,
  value,
  onChange,
  isRequired,
  isDisabled,
  error,
  isError,
  myCoordinates,
  getAddressVal,
  dis,
  myCoordinatesOnes,
}) => {
  const [active, setActive] = useState(false);
  myCoordinatesOnes();
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setActive(false);
      },
      (error: any) => {
        setActive(true);
      }
    );
  }, []);
  const activeCor = (e: any) => {
    if (!active) onChange(e);
    else {
      getAddressVal(e.target.value);
    }
  };
  return (
    <div className="home-page-input home-page-input-before">
      <label className="home-page-input__label">
        {label}
        {isRequired && <span style={{ color: "red" }}>*</span>}
      </label>
      <input
        className={`home-page-input__input ${
          isError ? "error" : ""
        } home-page-input__padding`}
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={activeCor}
        disabled={isDisabled}
      />
      {error && <ErrorText title={error} />}
      <img
        style={{
          position: "absolute",
          top: "15px",
          right: "10px",
          cursor: "pointer",
        }}
        src={active ? VectorImageActive : VectorImage}
        onClick={() => {
          if (dis) {
            setActive(!active);
            if (!active) {
              myCoordinates();
            } else {
              getAddressVal("");
            }
          }
        }}
        alt="Image"
      />
    </div>
  );
};
