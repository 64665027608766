import { InferActionsTypes } from "./../index";
import { CreateVisitor, UpdateVisitor } from "../../../types/reducers/routesReducer";

export const actionTypes = {
  GET_TEAM: "GET_TEAM",
  GET_TEAM_SUCCESS: "GET_TEAM_SUCCESS",
  DELETE_TEAM_TRIGGER: "ВELETE_TEAM_TRIGGER",
  CREATE_VISITOR_TRIGER: "CREATE_VISITOR_TRIGER",
  UPDATE_VISITOR_TRIGER: "UPDATE_VISITOR_TRIGER",
  UPDATE_VISITOR_SUCCESS: "UPDATE_VISITOR_SUCCESS",
  UPDATE_VISITOR_ERROR: "UPDATE_VISITOR_ERROR",
};

type InitialState = {
  team: null;
};

const initialState: InitialState = {
  team: null,
};

export const teamReducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case actionTypes.GET_TEAM:
      return {
        ...state,
    };

    case actionTypes.GET_TEAM_SUCCESS:
      return {
        ...state,
        team: action.payload,
      };

    default:
      return state;
  }
};

export const actions = {
  getTeamAC: (data: any) => ({
    type: actionTypes.GET_TEAM,
    payload: data,
  }),
};

export const teamTriggerActions = {
  getTeamTriggerAC: (searchValue: string | null, currentPage: number, perPageValue: string) => ({
    type: actionTypes.GET_TEAM,
    searchValue,
    page: currentPage,
    perPage: perPageValue
  }),

  createVisitorTriggerAC: (data: CreateVisitor) => ({
    type: actionTypes.CREATE_VISITOR_TRIGER,
    data,
  }),

  updateVisitorTriggerAC: (data: UpdateVisitor) => ({
    type: actionTypes.UPDATE_VISITOR_TRIGER,
    data,
  }),

  deleteTeamTriggerAC: (id: string) => ({
    type: actionTypes.DELETE_TEAM_TRIGGER,
    id,
  }),
};

export type Actions = InferActionsTypes<typeof actions>;
