import { IconButton, InputBase, Paper } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import {ChangeEvent, FC, useEffect, useState} from 'react'
import "./CustomSearch.scss"
import {useDebounce} from '../../../hooks/useDebounce';

type PropsType = {
  handleSearch: (value: string | null) => void
  text: string
  placeholder: string
}

export const CustomSearch: FC<PropsType> = ({
  handleSearch,
  text,
  placeholder,
}) => {
  const [value, setValue] = useState<string>(text);

    const changeSearch = (value: string): void => {
        setValue(value);
    };

    const debounceValue = useDebounce({ value: value, delay: 500 });

    useEffect(() => {
        handleSearch(debounceValue);
    }, [debounceValue]);

    return (
    <div className="custom-search">
      <Paper
        className="custom-search__search"
        sx={{ 
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: {xs: "100%", sm: "320px"}
        }}
      >
        <IconButton
          sx={{ p: "10px" }}
          aria-label="search"
          // onClick={() => handleSearch(value)}
        >
          <SearchIcon />
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder={placeholder}
          onChange={(event: ChangeEvent<HTMLInputElement>) => changeSearch(event.target.value)}
          value={value}
        />
      </Paper>
    </div>
  )
}
