import { FC } from "react"
import "./styles.scss"

type Props = {
  label: string
  value: string | null | undefined
  placeholder: string
  onChange: (e: any) => void
}

export const Textarea: FC<Props> = ({
  label,
  value,
  placeholder,
  onChange,
}) => {
  return (
    <div className="visit-report-textarea">
      <label className="visit-report-textarea__label">{label}</label>
      <textarea
        className="visit-report-textarea__textarea"
        rows={4}
        cols={30}
        placeholder={placeholder}
        value={value || ""}
        onChange={onChange}
      />
    </div>
  )
}
