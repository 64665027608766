import { FC, useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { householdsActions } from "../../backend/store/reducers/householdsReducer"
import { routesTriggerActions } from "../../backend/store/reducers/routesReducer"
import { Preloader } from "../../components/base/Preloader/Preloader"
import { Layout } from "../../components/RoutePage/Layout"

export const RoutePage: FC = () => {
  const dispatch = useDispatch()
  const { id } = useParams()

  const storedRoute = useSelector((state: any) => state.routesStore.route)
  const route = useMemo(() => storedRoute || [], [storedRoute])

  useEffect(() => {
    dispatch(
      routesTriggerActions.getRouteTriggerAC({
        id: Number(id),
        address: true,
        household: true,
      })
    )
    return () => {
      dispatch(routesTriggerActions.getRouteTriggerAC({ id: null }))
      dispatch(householdsActions.getHouseholdsAC(null))
    }
  }, [])

  if (route.length === 0) {
    return <Preloader />
  }

  return <Layout id={Number(id)} route={route} />
}
