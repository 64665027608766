import { put, takeEvery } from "redux-saga/effects"
import { updateToken } from "../../utils/updateToken"
import { categoriesAPI } from "../api/api"
import { actions, actionTypes } from "../store/reducers/categoriesReducer"

function* getCategories() {
  try {
    const response: { status: number; data: { data: {} } } =
      yield categoriesAPI.getCategories()
    if (response.status === 200) {
      updateToken()
      yield put(actions.getCategoriesAC(response.data.data))
    }
  } catch (error) {
    console.log(error)
  }
}

export function* categoriesWatcher() {
  yield takeEvery(actionTypes.GET_CATEGORIES_TRIGGER, getCategories)
}
