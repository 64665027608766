export const selectProps = (...props: any) => {
  return (obj: any) => {
    const newObj: any = {}
    props.forEach((name: string) => {
      newObj[name] = obj[name]
    })

    return newObj
  }
}
