import { LayoutHeader } from "../../base/LayoutHeader";
import { Outlet } from "react-router-dom";
import { UpdateHouseholdSidebar } from "../../UpdateHouseholdPage/UpdateHouseholdSidebar";
import { Box } from "@mui/material";
import { useTranslation } from "../../../hooks/useTranslation";
import { Preloader } from "../../base/Preloader/Preloader";

export const HouseholdLayout = () => {
  const { p } = useTranslation("contact_info");
  const { p: pGeneral } = useTranslation("general");
  return (
    <div className="create-household-layout">
      <div className="create-household-layout__header">
        <LayoutHeader
          title={p("name")}
          link="/households"
          subtitles={[
            { title: pGeneral("dashboard"), path: "/households" },
            { title: p("contacts"), path: "/households" },
            { title: p("name"), path: "/households" },
          ]}
        />
      </div>
      <Box
        sx={{ flexDirection: { xs: "column", md: "row" } }}
        className="create-household-layout__container"
      >
        <UpdateHouseholdSidebar />
        <div className="create-household-layout__children-container">
          <div className="create-household-layout__children">
            <div className="create-household-layout__children-container-inner">
              <Outlet />
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};
