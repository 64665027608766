import Pagination from "@mui/material/Pagination"
import { FC } from "react"
import { useDispatch } from "react-redux"
import { householdsTriggerActions } from "../../../backend/store/reducers/householdsReducer"

type Props = {
  totalItemsCount: number
  pageSize: number
  currentPage: number
  setCurrentPage: (page: number) => void
}

export const Paginator: FC<Props> = ({
  totalItemsCount,
  pageSize,
  currentPage,
  setCurrentPage,
}) => {
  const pagesCount = Math.ceil(totalItemsCount / pageSize)

  const handleChange = (page: number) => {
    setCurrentPage(page)
  }

  return (
    <Pagination
      sx={{
        "& .MuiButtonBase-root": {
          background: "#fff",
          color: "rgba(84, 97, 137, 1)",
        },
        "& .MuiPaginationItem-root": {
          "&.Mui-selected": {
            background: "rgba(14, 13, 91, 1)",
            color: "white",
          },
        },
      }}
      count={pagesCount}
      variant="outlined"
      // showFirstButton
      // showLastButton
      page={currentPage}
      onChange={(e, page: number) => handleChange(page)}
    />
  )
}
