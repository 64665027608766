import {Dispatch, FC, SetStateAction} from 'react';
import { CustomSearch } from "../../ContactsPage/CustomSearch/CustomSearch";
import { PerPageFilter } from "../../ContactsPage/PerPageFilter";
import "./styles.scss";
import { Box } from '@mui/material';
import { useTranslation } from '../../../hooks/useTranslation';

type Props = {
    handleSearch: (str: string | null) => void;
    perPageValue: string,
    openPerPage: boolean,
    setOpenPerPage: Dispatch<SetStateAction<boolean>>,
    setPerPageValue: (value: string) => void,
    allEntries: number,
    searchValue: string | null
};

export const ListHeader: FC<Props> = ({ handleSearch,
                                        searchValue,
                                        setPerPageValue,
                                        setOpenPerPage,
                                        perPageValue,
                                        openPerPage,
                                        allEntries }) => {
  const { p } = useTranslation('my_team');
  const { p: pGeneral } = useTranslation('general');
  return (
    <div className="contacts-list__wrpr">
      <Box 
        className="contacts-list__search-panel"
        sx={{
          paddingRight: {xs: "20px", md: "40px"},
        }}
      >
        <Box
          sx={{
            width: {xs: "100%", md: "fit-content"},
          }}
        >
          <CustomSearch
            text={searchValue || ''}
            placeholder={pGeneral('search_name')}
            handleSearch={handleSearch}
          />
        </Box>
        <Box
          className="contacts-list__input-container"
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          { pGeneral('showing') }
          <span>
            <PerPageFilter
              value={perPageValue}
              open={openPerPage}
              setOpen={setOpenPerPage}
              setValue={setPerPageValue}
            />
          </span>
          { pGeneral('of') } {allEntries} { pGeneral('entries') }
        </Box>
      </Box>
    </div>
  );
};
