import { RefObject, useEffect, useRef } from "react"

type Props = {
  handler: () => void
}

export const useClickOutside = ({
  handler,
}: Props): RefObject<HTMLInputElement> => {
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        handler()
      }
    }
    document.body.addEventListener("click", handleClick)
    return () => {
      document.body.removeEventListener("click", handleClick)
    }
  }, [])

  return ref
}
