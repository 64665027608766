import { useFormik } from "formik";
import { memo, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import { ERRORS } from "../../constants/base";
import { householdsTriggerActions } from "../../backend/store/reducers/householdsReducer";
import { Preloader } from "../../components/base/Preloader/Preloader";
import { UpdateHouseholdInfo } from "../../components/UpdateHouseholdPage/UpdateHouseholdInfo";
import { FormikType } from "../../types/CreateHousehold/types";
import { useUrlQueryParams } from "../../hooks/useUrlQueryParams";
import { updateHousehold } from "../../backend/store/actions/creators/household";
import { generalActions } from "../../backend/store/reducers/generalReducer";

const validationSchema = Yup.object({
  persons: Yup.array().of(
    Yup.object().shape({
      first_name: Yup.string().required(ERRORS.required),
      last_name: Yup.string().required(ERRORS.required),
    })
  ),
});

const UpdateHouseholdInfoPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const salutations = useSelector(
    (state: any) => state.personsStore.salutations
  );

  const addressesTypes = useSelector(
    (state: any) => state.addressesStore.types
  );

  const household = useSelector(
    (state: any) => state.householdsStore.household
  );

  const salutationOptions = useMemo(() => salutations || [], [salutations]);
  const addressesTypesOptions = useMemo(
    () => addressesTypes || [],
    [addressesTypes]
  );

  const householdValue = useMemo(() => household || {}, [household]);

  const formik: FormikType = useFormik({
    enableReinitialize: true,
    initialValues: householdValue,
    validationSchema,
    onSubmit: (data) => {
      data.categories = data.categories.map((elem: any) => elem.id);
      data.persons.forEach((person: any) => {
        person.addresses = person.addresses.map(
          ({ fullAddress, ...rest }: any) => rest
        );
      });
      dispatch(
        updateHousehold({
          data: data,
          id: id,
          onSuccess: (res: any) => {
            dispatch(
              generalActions.successAC({ message: "Updated", type: "success" })
            );
            dispatch(
              householdsTriggerActions.getHouseholdTriggerAC(Number(id))
            );
          },
          onError: (error: any) => {
            dispatch(
              generalActions.successAC({
                message: error?.response?.data?.message,
                type: "error",
              })
            );
          },
        })
      );
    },
  });

  if (!household) {
    return <Preloader />;
  }

  return (
    <UpdateHouseholdInfo
      formik={formik}
      householdValue={householdValue}
      salutationOptions={salutationOptions}
      addressesTypesOptions={addressesTypesOptions}
    />
  );
};
export default memo(UpdateHouseholdInfoPage);
