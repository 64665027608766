export const geocodePlaceId = async (placeId: string | undefined) => {
  if (placeId) {
    const geocoder = new google.maps.Geocoder()

    let response = await geocoder.geocode({ placeId: placeId })
    if (response.results[0]) {
      return response.results[0].formatted_address
    }
  }
}
