import { FC } from "react"
import "./styles.scss"

type Props = {
  title: string
  onClick: () => void
}

export const CreateFooter: FC<Props> = ({ title, onClick }) => {
  return (
    <div className="create-household-footer">
      <div className="create-household-footer__container">
        <div className="create-household-footer__title" onClick={onClick}>
          {title}
        </div>
      </div>
    </div>
  )
}
