import { useEffect, useState } from "react";

interface UseDebounceProps<T> {
    /**
     * The value which change should be debounced
     */
    value: T;
    /**
     * Debounce time in milliseconds
     */
    delay: number;
}

export const useDebounce: <T>(props: UseDebounceProps<T>) => T = ({
                                                                      delay,
                                                                      value,
                                                                  }) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );

    return debouncedValue;
};
