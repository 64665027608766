import "./style.scss"
import dateIcon from "../../../assets/fonts/dateIcon.svg"
import menu from "../../../assets/fonts/hor-menu.svg"
import { FC } from "react"

type Props = {
  date: string
}

export const VisitReportHeader: FC<Props> = ({ date }) => {
  return (
    <div className="visit-report-header">
      <div className="visit-report-header__container">
        <div className="visit-report-header__title">Visit date</div>
        <div className="visit-report-header__date">
          <img src={dateIcon} alt="date" />
          {date}
        </div>
        <div className="visit-report-header__menu">
          {/*<img src={menu} alt="menu" />*/}
        </div>
      </div>
    </div>
  )
}
