import { FC } from "react"
import { Notification } from "../../../types/global/types"
import "./styles.scss"
import happyReactionIcon from '../../../assets/fonts/happyReaction.svg';
import rudeReactionIcon from '../../../assets/fonts/rudeReaction.svg';
import arrowRightIcon from "../../../assets/fonts/arrow-right.svg";
import closeIcon from "../../../assets/fonts/close.svg"
import { NotificationTypes } from "../NotificationTypes/NotificationTypes";

type Props = {
  notification: Notification,
  markAsRead: (id: string) => void,
  deleteNotification: (id: string) => void,
  onSelectNotification: (notification: Notification) => void,
}

export const NotificationItem: FC<Props> = ({
  notification,
  markAsRead,
  deleteNotification,
  onSelectNotification,
}) => {

  const handleMarkAsRead = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    markAsRead(notification.id)
  }

  const handleDeleteNotification = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    deleteNotification(notification.id)
  }

  const handleSelectNotification = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    onSelectNotification(notification)
  }

  return (
    <div
      className={`notification-item ${
        !notification.is_read ? "notification-item_unread" : ""
      }`}
    >
      {!notification.is_read && (
        <div className="notification-item__marked" 
        onClick={handleMarkAsRead}
      />
      )}
      <div className="notification-item__delete" 
        onClick={handleDeleteNotification}
      >
        <img src={closeIcon} alt={'closeIconFont'} />
      </div>
      { notification.type === NotificationTypes.ExtremeReactionNotification && (
        <div className="notification-item__reaction">
          { notification.reaction_scale === 1 
            && <img src={happyReactionIcon} alt={'happyReactionIcon'} />
          }
          { notification.reaction_scale === 5 
            && <img src={rudeReactionIcon} alt={'rudeReactionIcon'} />
          }
        </div>
      )}
      
      <div className="notification-item__content">
        <h3>
          {notification.title.startOfTitle}
          {notification.title.householdName}
          {notification.title.endOfTitle}
        </h3>
        <div className="notification-item__time">
          {notification.created_at_formatted}
        </div>
      </div>
      <div 
        className="notification-item__arrow" 
        onClick={handleSelectNotification}
      >
        <img src={arrowRightIcon} alt={'arrowRightIcon'} />
      </div>
    </div>
  )
}
