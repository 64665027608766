import { FC } from "react"
import chevron from "../../../assets/fonts/chevron20.svg"
import basket from "../../../assets/fonts/deleteBasket.svg"
import { FormikType } from "../../../types/VisitReports/types"
import { RadioButton } from "../../base/RadioButton"
import "./styles.scss"
import { useTranslation } from "../../../hooks/useTranslation"

type Props = {
  formik: FormikType
  personIndex: number
  path: "updated_persons" | "new_persons"
  setPrimaryPerson: (e: any, index: number) => void
  deletePerson?: () => void
}

export const PersonHeader: FC<Props> = ({
  formik,
  personIndex,
  path,
  setPrimaryPerson,
  deletePerson,
}) => {
  const { p } = useTranslation('contact_info');
  const setPrimary = (e: any) => {
    setPrimaryPerson(e, personIndex)
  }

  return (
    <div className="visit-report-person-header">
      <div className="visit-report-person-header__title">
        {p('contact')} {personIndex + 1}
      </div>
      <div className="visit-report-person-header__radio-container">
        <RadioButton
          checked={!!formik?.values?.[path]?.[personIndex].isMain}
          label={p('primary')}
          name="update-contact-info"
          value={formik?.values?.[path]?.[personIndex].isMain ? 1 : 0}
          onClick={setPrimary}
        />
      </div>
      <div className="visit-report-person-header__action-container">
        {personIndex > 0 && (
          <img
            src={basket}
            alt="basket"
            className="visit-report-person-header__basket"
            onClick={deletePerson}
          />
        )}
        <img
          src={chevron}
          alt="delete"
          onClick={() => {}}
          className="visit-report-person-header__arrow"
        />
      </div>
    </div>
  )
}
