import { FC } from "react"

import "./styles.scss"

type Props = {
  title: string
  img?: string
  onClick?: () => void
}

export const Button: FC<Props> = ({ title, img, onClick }) => {
  return (
    <button className="routes-page-button" onClick={onClick}>
      {img && (
        <img className="routes-page-button__image" src={img} alt="plus" />
      )}
      {title}
    </button>
  )
}
