import {useState} from 'react';
import {useUrlQueryParams} from '../../../hooks/useUrlQueryParams';
import {FiltersHouseholdsPage} from '../index';

export const useSortHouseholds = () => {
    const { setUrlQueryParam, getUrlQueryParam, deleteUrlQueryParam } = useUrlQueryParams();
    const sortValueFromUrl = getUrlQueryParam(FiltersHouseholdsPage.sortOrder)
    const [sortByValue, setSortByValue] = useState<string | null>(sortValueFromUrl || null);

    const handleChangeSort = (value: string | null) => {
        if(!value) {
            setSortByValue(value);
            return deleteUrlQueryParam(FiltersHouseholdsPage.sortOrder);
        }
        setSortByValue(value)
        return setUrlQueryParam(FiltersHouseholdsPage.sortOrder, value)
    }

    return {
        sortByValue,
        handleChangeSort
    }
}