import {asCreator, withCallbacks} from '../../../../lib/utils';
import {compose} from '../../../../utils/functions';
import {actionTypes} from '../../reducers/householdsReducer';

export const createContact = compose(
    withCallbacks,
    asCreator((data: any) => ({
        type: actionTypes.CREATE_HOUSEHOLD,
        payload: data,
    }))
);