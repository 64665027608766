import { FC } from "react"
import "./styles.scss"

type Props = {
  placeholder: string
  title?: string
  icon?: string
  formik?: any
  disabled?: boolean
  value: string | undefined
  label: string
  onChange: (e: any) => void
}

export const Input: FC<Props> = ({
  placeholder,
  icon,
  title,
  value,
  label,
  disabled,
  onChange,
}) => {
  return (
    <div className="visit-report-input">
      <label className="visit-report-input__label">{label}</label>
      {icon && (
        <label className="visit-report-input__end-icon">
          <img className="visit-report-input__img" src={icon} alt="end-icon" />
          {title && <span className="visit-report-input__span">{title}</span>}
        </label>
      )}
      <input
        className="visit-report-input__input"
        type="text"
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={onChange}
      />
    </div>
  )
}
