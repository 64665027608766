import { Modal } from "@mui/material";
import { FC } from "react";
import { FilterHeader20 } from "../../base/FilterHeader20";
import { BlackButton } from "../BlackButton";
import { SaveOrOptimizeChildren } from "../SaveOrOptimizeChildren";
import { WhiteButton } from "../WhiteButton";
import routeLogo from "../../../assets/images/routeLogo.svg";
import "./styles.scss";
import { useTranslation } from "../../../hooks/useTranslation";

interface IProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  setOpenNavModal: (value: boolean) => void;
  setOptimize: (value: boolean) => void;
}

export const OptimizeRouteModal: FC<IProps> = ({
  open,
  setOpen,
  setOpenNavModal,
  setOptimize,
}) => {
  const handleStartRoute = (type: "optimize" | "save") => {
    type == "optimize" ? setOptimize(true) : setOptimize(false);
    setOpenNavModal(true);
    setOpen(false);
  };
  const { p } = useTranslation('map');

  return (
    <Modal
      disableAutoFocus
      disableRestoreFocus
      disableEnforceFocus
      open={open}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
    >
      <div className="home-page-optimize-route-modal">
        <FilterHeader20
          title={p('start_route')}
          disableUnderline
          onClose={() => {
            setOpen(false);
            setOptimize(false);
          }}
        />
        <div className="home-page-optimize-route-modal__container">
          <SaveOrOptimizeChildren
            title={p('optimize_this_route')}
            image={routeLogo}
          />
        </div>
        <div className="home-page-optimize-route-modal__footer">
          <WhiteButton
            title={p('just_start_route')}
            forModal
            onClick={() => handleStartRoute("save")}
          />
          <BlackButton
            onClick={() => handleStartRoute("optimize")}
            title={p('optimize_route')}
            forModal
          />
        </div>
      </div>
    </Modal>
  );
};
