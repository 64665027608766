import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ERRORS } from "../../constants/base";
import { HOUSEHOLD } from "../../constants/CreateHousehold";
import { addressTriggerActions } from "../../backend/store/reducers/addressesReducer";
import { personTriggerActions } from "../../backend/store/reducers/personReducer";
import { categoriesTriggerActions } from "../../backend/store/reducers/categoriesReducer";
import { householdsTriggerActions } from "../../backend/store/reducers/householdsReducer";
import { FormikType } from "../../types/CreateHousehold/types";
import { CreateHousehold } from "../../components/CreateHouseholdPage/CreateHousehold";
import { generalActions } from "../../backend/store/reducers/generalReducer";
import { createContact } from "../../backend/store/actions/creators/createContact";

const validationSchema = Yup.object({
  persons: Yup.array().of(
    Yup.object().shape({
      first_name: Yup.string().required(ERRORS.required),
      last_name: Yup.string().required(ERRORS.required),
    })
  ),
});

export const CreateHouseholdPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isActive, setIsActive] = useState("step1");

  useEffect(() => {
    dispatch(personTriggerActions.getSalutationsTriggerAC());
    dispatch(addressTriggerActions.getTypesTriggerAC());
    dispatch(categoriesTriggerActions.getCategoriesTriggerAC());
    dispatch(householdsTriggerActions.getPeriodicityTriggerAC());
    formik.setFieldValue("persons[0].set_as_main", 1);
    return () => {
      dispatch(generalActions.setSuccessActionsAC(false));
    };
  }, []);

  const salutations = useSelector(
    (state: any) => state.personsStore.salutations
  );

  const [showSuccessfulCreation, setShowSuccessfulCreation] = useState(false);
  const [errorCreation, setErrorCreation] = useState(false);

  const addressesTypes = useSelector(
    (state: any) => state.addressesStore.types
  );

  const regularCategoris = useSelector(
    (state: any) => state.categoriesStore.categories
  );

  const periodicity = useSelector(
    (state: any) => state.householdsStore.periodicity
  );

  const storedSeccessAction = useSelector(
    (state: any) => state.generalStore.successAction
  );

  const salutationOptions = useMemo(() => salutations || [], [salutations]);
  const addressesTypesOptions = useMemo(
    () => addressesTypes || [],
    [addressesTypes]
  );

  const regularCategorisOptions = useMemo(
    () => regularCategoris || [],
    [regularCategoris]
  );

  const periodicityOptions = useMemo(() => periodicity || [], [periodicity]);

  const formik: FormikType = useFormik({
    enableReinitialize: true,
    initialValues: { ...HOUSEHOLD, periodicity: "Bi-Monthly" },
    validationSchema: validationSchema,
    onSubmit: (data: any) => {
      if (isActive == "step1") {
        setIsActive("step2");
      } else {
        data.categories = data.categories?.map((elem: any) => elem.id);

        data.persons.forEach((person: any) => {
          person.addresses = person.addresses.map(
            ({ fullAddress, ...rest }: any) => rest
          );
        });

        const modifiedData = {
          ...data,
          persons: data.persons.map((person: any) => {
            return {
              ...person,
              addresses: person.addresses
                .map((address: any) => {
                  if (address.country) return address;
                  return null;
                })
                .filter((address: any) => !!address),
              phones: person.phones.filter((phone: any) => !!phone.number),
            };
          }),
        };

        dispatch(
          createContact({
            data: modifiedData,
            onSuccess: () => {
              setShowSuccessfulCreation(!showSuccessfulCreation);
              const timeoutId = setTimeout(() => {
                setShowSuccessfulCreation(!showSuccessfulCreation);
                navigate("/households");
                clearTimeout(timeoutId);
              }, 2000);
            },
            onError: () => {
              setErrorCreation(!errorCreation);
              const id = setTimeout(() => {
                setErrorCreation(!errorCreation);
                clearTimeout(id);
              }, 2000);
            },
          })
        );
      }
    },
  });

  if (storedSeccessAction) {
    navigate("/households");
  }

  return (
    <CreateHousehold
      isActive={isActive}
      setIsActive={setIsActive}
      formik={formik}
      salutationOptions={salutationOptions}
      addressesTypesOptions={addressesTypesOptions}
      regularCategorisOptions={regularCategorisOptions}
      periodicityOptions={periodicityOptions}
      showSuccessfulCreation={showSuccessfulCreation}
      setShowSuccessFulCreation={setShowSuccessfulCreation}
      errorCreation={{
        title:
          "There was an error while creating a household or performing an action.",
        isVisible: errorCreation,
        setIsVisible: () => setErrorCreation(!errorCreation),
      }}
    />
  );
};
