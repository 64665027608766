import { FC } from "react";

import importFailure from "../../../assets/fonts/importFailure.svg";
import { Notification } from "../../../types/global/types";
import "./styles.scss";

type Props = {
  notification: Notification;
};

export const ImportAbortedNotification: FC<Props> = ({ notification }) => {
  return (
    <div className="notification">
      <div className="notification__header">
        <div className="notification__reaction">
          <img src={importFailure} alt={"importFailureIcon"} />
        </div>
        <div className="notification__title">
          <h3>
            {notification.title.startOfTitle}
            {notification.title.householdName}
            {notification.title.endOfTitle}
          </h3>
          <div className="notification__time">
            {notification.created_at_formatted}
          </div>
        </div>
      </div>
    </div>
  );
};
